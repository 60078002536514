export enum PAGE_TYPE {
  EVENT_CATEGORY = "1",
  EVENT_LIST = "2",
  EVENT_INFO = "3",
  PACKAGE_LIST = "4",
  PACKAGE_INFO = "5",
  UPSELLS = "6",
  DATE_AND_SESSION = "7",
  CHECKOUT = "8",
}
export enum PAYMENT_METHOD {
  CREDIT_DEBIT = "1",
  PAYPAL = "2",
}
export enum DELIVERY_METHOD {
  BY_POST = "8",
  NO_DELIVERY = "9",
  E_TICKET = "20",
  COLLECTION_F_VENUE = "21",
  STANDARD_DELIVERY = "22",
}
export enum ZOOM {
  IN,
  OUT,
  NEUTRAL
}
export enum SESSION_CONFIRMATION_M_TYPE {
  BOOKING_SESSION_RESET,
  SESSION_EXPIRATION_CONFIRMATION,
  NonCommonDeliveryMethodMessage,
  GLOBAL_SESSION_EXPIRATION_CONFIRMATION,
  GLOBAL_SESSION_EXPIRED
}
export enum BOOKING_CART {
  NEW_BOOKING = 1,
  EDIT_BOOKING = 2
}
export enum BOOKING_FROM {
  PUBLIC_WEB,
  BOOKING_APPLICATION
}

export enum FILE_TYPE {
  DOCX = 'docx',
  DOC = 'doc',
}

export enum SEAT_STATUS_COLOR {
  ALLOCATED = '#5EE527', //Green
  RESERVED = '#FF0000', //Red
  FROZEN = '#00FFFF', //Cyan
  UNAVAILABLE = '#B2BEB5' //Gray
}

export enum SEAT_STATUS_NAMING {
  ALLOCATED = 'Booked Seat',
  RESERVED = 'Reserved Seat',
  FROZEN = 'Frozen Seat',
  ACCESSIBLE = 'Accessible Seat',
  UNAVAILABLE = 'Unavailable Seat',
  AVAILABLE = 'Available Seat'
}