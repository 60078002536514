import { Contact } from './../client/Contact';
import { Note } from './../client/note';
import { BaseResource } from './../../../modules/models/base-resource';

export class Client extends BaseResource {

    public clientRef: string;
    public alternativeClientRef: string;
    public clientName: string;
    public profilePicture: string;
    public accountManager: User;
    public accountManagerName: string;
    public active: boolean;
    public clientCategoryId: number;
    public clientCategory: string;
    public alternativeClientCategoryRef: string;
    public clientType: string;
    public clientTypeId: number;
    public paymentTerm
    public creditStatus: string;
    public creditStatusId: number;
    public suspended: boolean;
    public suspendedComment: string;
    public annualBudget: number;
    public source: string;
    public sourceId: number;
    public defaultVenue: Venue;
    public dateRegistered: Date;
    public creditLimit: number;
    public openingBalance: number;
    public tax: Tax;
    public notes: Note[];
    public defaultCurrencyId: number;
    public defaultCurrency: string;
    public address: Address;
    public contacts: Contact[];
    public communicationMethods: CommunicationMethod[];
    public bookings : Booking[];
    public enquires: Enquiry;
    public tasks: Task;
    public auditInfo: AuditInfo;
    public additionalClientCategories: AdditionalClientCategories[];
    public documents: Document[];
    public priceConcessionId;
    public priceConcession: PriceConcession;

    public companyName: string;
    // public companyRegistrationNo: string;
    public bookingTypes: any;
}

export class User extends BaseResource {

    public userName: string;
}

export class PaymentTerm {

}

export class Venue extends BaseResource {
    public name: string;
}

export class Tax extends BaseResource{
    public name: string;
}


export class Address extends BaseResource {

    public address1: string;
    public address2: string;
    public address3: string;
    public firstName: string;
    public lastName: string;
    public companyName: string;
    public city: string;
    public county: string;
    public countryID: number;
    public country: string;
    public stateCode: string;
    public state: string;
    public postCode: string;
    public bookingDeliveryAddress: boolean;
    public bookingInvoiceAddress: boolean;
    public entityTypeID: number;
    public referenceEntityID: number;
    public themeId?: string;
    public zoneId?: number;
    public externalAddressId?: string;
    public Units?: number;
    constructor() {
      super();
      this.type = "Address";
    }

}

export class CommunicationMethod extends BaseResource {
    public communicationType: string;
    public communicationTypeID: string;
    public value: string;
    public format: string;
    public auditInfo: AuditInfo;
    public pattern: RegExp;
    public isValid=function(): boolean

    {
        if (this.value == undefined)
            return false;

        if (this.format = '1') {
            if (this.pattern != undefined && this.pattern != null) {
                return (new RegExp(this.pattern)).test(this.value);
            } else {
                return true;
            }
        }
        else if (this.format = '2')
        {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test((this.value).toLowerCase());
        }
        return true;
    }
    constructor() {
      super()
      this.type = "CommunicationMethod"
    }
}

export class PriceConcession extends BaseResource {
    public Name: string;
    public Active: boolean;
    public Default: boolean;

    constructor() {
        super();
        this.type = "priceConcession";
      }
}

export class Booking extends BaseResource{ }
export class Enquiry extends BaseResource { }
export class Task extends BaseResource{ }
export class AuditInfo extends BaseResource{ }
export class AdditionalClientCategories extends BaseResource{ }
export class Document extends BaseResource{ }
