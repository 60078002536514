import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AllocatedConfigurationDetail } from '../models/AllocatedConfigurationDetail';
import { BookingStatus } from 'src/modules/models/booking-status/booking-status';

@Injectable({
  providedIn: 'root'
})
export class AllocatedConfigurationDetailService {

  constructor() { }

  allocatedConfigurationDetails$ = new BehaviorSubject<AllocatedConfigurationDetail[]>([]);
  selectedBookingStatus$ = new BehaviorSubject<BookingStatus>(undefined);

  setValue(configurationDetails: AllocatedConfigurationDetail[]) {
    this.allocatedConfigurationDetails$.next(configurationDetails);
  }

  getValue() {
    return this.allocatedConfigurationDetails$.asObservable();
  }

  reSetValue() {
    this.allocatedConfigurationDetails$.next([]);
    this.selectedBookingStatus$.next(undefined);
  }

  setSelectedBookingStatusValue(status : BookingStatus) {
    this.selectedBookingStatus$.next(status);
  }

  getSelectedBookingStatusValue() {
    return this.selectedBookingStatus$.asObservable();
  }
}