export class ClientRegistration {
    public EnableManualAddress: boolean;
    public RegularHireAccessNoteMandatory: boolean;
    public PrivacyPolicyLink: string;
    public TermsandConditionLink: string;
    public DefaultCountryId: DefaultCountryId;
    public NameValidation: RegExp;
    // public AgreedText: string;    
    public CommunicationTypeValidation: CommunicationTypeValidation[];
    public QASIntegrationConfig: QASIntegration;
    public AccountManager: AccountManager;
    public Venue: Venue;
    public Tax: Tax;
    public CorporateClientConfig: CorporateClient;
    public IndividualClientConfig: IndividualClient;
    public GuestClientConfig : GuestClient;
    public ReCaptchaSiteKey: string;
    public Currency: BaseConfig;
    public ForgetPasswordPlaceHolder: string;
    public ForgetPasswordMessage: string;
    public PriceConcessionQuestionText: string;
    public PriceConcessionAnswerOptions: PriceConcessionAnswerOption[];
    public ForgotPasswordLink: string;
    public DisplayForgotUserNameLink: boolean;

    public IsEnableToCreateAdditionalDetailsSectionForIndividualClients: boolean;
    public IsEnableToCreateAdditionalDetailsSectionForCorporateClients: boolean;
    public IsEnableToUpdateAdditionalDetailsSectionForIndividualClients: boolean;
    public IsEnableToUpdateAdditionalDetailsSectionForCorporateClients: boolean;
    public IsEnableToCreateAdditionalDocumentSectionForIndividualClients: boolean;
    public IsEnableToCreateAdditionalDocumentSectionForCorporateClients: boolean;
    public IsEnableToUpdateAdditionalDocumentSectionForIndividualClients: boolean;
    public IsEnableToUpdateAdditionalDocumentSectionForCorporateClients: boolean;
    public IsPriceConcessionQuestionRequired: boolean;

    public RequestToAccessReqularBookingHeaderText: string;
    public RequestToAccessReqularBookingAcceptText: string;
    public ReqularBookingAccessRequestHeaderText: string;
    public ReqularBookingAccessRequestSubHeaderText: string;
    public ReqularBookingAccessRequestReviewText: string;
    public ReqularBookingAccessRequestCompleteText: string;
    public ProfileImageHeaderTextForIndividualClients: string;
    public ProfileImageHeaderTextForCorporateClients: string;
    public EnableProfileImageUpload: boolean;
    public EnableWebPortalAccess: boolean;
    public AutoPopulateUsernameFromEmail: boolean;
    public DisableUsernameTextBox: boolean;
    public MaximumDocUploadSize: number;
    public ClientFileUploadDescription: string;
    public Placeholder: Placeholder;
}


export class DefaultCountryId {
    public Name: string;
    public Id: string;
}

export class CommunicationTypeValidation{
    public CommunicationTypeId: string;
    public Rule: Rule[];
    public name: string;

}

export class Rule {
    public Value: string;
    public countryId: string;
    public SampleNumber: string;
}
export class QASIntegration {
    public Url: string;
    public Username: string;
    public Password: string;
}

export class BaseConfig {
    public Id: number;
    public Name: string;

}

export class AccountManager extends BaseConfig { }

export class Venue extends BaseConfig { }

export class Tax extends BaseConfig{ }


export class CorporateClient {
    public ClientCategoryConfig: ClientCategory;
    public ClientTypeConfig: ClientType;
}

export class IndividualClient {
    public ClientCategoryConfig: ClientCategory;
    public ClientTypeConfig: ClientType;
}
export class GuestClient {
    public ClientCategoryConfig: ClientCategory;
    public ClientTypeConfig: ClientType;
}

export class ClientCategory extends BaseConfig { }


export class ClientType extends BaseConfig { }

export class PriceConcessionAnswerOption {
    public Value: string;
    public Key: string;
    public PriceConcessionId: string;
}

export class Placeholder {
   public  Address: Address;
   public  Corporate: Corporate;
}

export class Address {
   // AddressLine1: string;
    AddressLine1: AddressField;
    AddressLine2: AddressField ;
    AddressLine3: AddressField ;
    City: AddressField ;
    PostCode: AddressField ;
    State: AddressField  ;
    Country: AddressField ;

}

export class AddressField {
    public IsMandatory: string;
    public Value: string;
    public Label: string;
}

export class Corporate {
    CompanyName: CorporateField;
    FirstName: CorporateField;
    LastName: CorporateField;
    CompanyPhoneNumber: CorporateField ;
    CompanyEmail: CorporateField ;
 
 }
 
export class CorporateField {
    public IsMandatory: string;
    public Label: string;
    public Value: string;
}