//import { enableProdMode } from '@angular/core'
import { environment } from "../environments/environment";
import { SystemConfig } from "src/modules/system-configuration";
import { SystemService } from "src/modules/services/system.service";
import { BaseService } from "src/modules/services/base/base.service";
import {
  HttpClient,
  HttpHandler,
  HTTP_INTERCEPTORS,
  HttpRequest,
  HttpEvent,
  HttpInterceptor,
  HttpXhrBackend,
} from "@angular/common/http";
import { Injector } from "@angular/core";

//import { OptHttpProvider, APIRequestHandler } from 'src/modules/services/base/api-request-handler';
import { forkJoin } from "rxjs";

export function SetEnvVariables()
{
    if (window["ApiUrl"])
        environment.ApiUrl = window["ApiUrl"];
    if (window["WebAPIBaseUrl"])
        environment.WebApiUrl = window["WebAPIBaseUrl"];
    if (window["Token"])
        environment.Token = window["Token"];
    if (window["Theme"])
        environment.Theme = window["Theme"];
    if (window["PublicWebPortalUrl"])
        environment.PublicWebPortalUrl = window["PublicWebPortalUrl"];
    if (window["PublicWebClientAddUrl"])
        environment.PublicWebClientAddUrl = window["PublicWebClientAddUrl"];
    if (window["PublicWebClientSearchUrl"])
        environment.PublicWebClientSearchUrl = window["PublicWebClientSearchUrl"];
    if (window["RegularBookingPortalUrl"])
        environment.RegularBookingPortalUrl = window["RegularBookingPortalUrl"];
    if (window["NotificationProjectUrl"])
        environment.NotificationProjectUrl = window["NotificationProjectUrl"];
    if (window["WasteBookingPortalUrl"])
        environment.WasteBookingPortalUrl = window["WasteBookingPortalUrl"];
    if (window["WasteBookingClientSearchUrl"])
        environment.WasteBookingClientSearchUrl = window["WasteBookingClientSearchUrl"];
    if (window["WasteBookingClientAddUrl"])
        environment.WasteBookingClientAddUrl = window["WasteBookingClientAddUrl"];
    if (window["WasteBookingInternalPortalUrl"])
        environment.WasteBookingInternalPortalUrl = window["WasteBookingInternalPortalUrl"];
    if (window["OptimoHomeUrl"])
        environment.OptimoHomeUrl = window["OptimoHomeUrl"];
    if (window["CasualBookingPortalUrl"])
        environment.CasualBookingPortalUrl = window["CasualBookingPortalUrl"];
    if (window["PortalType"])
        environment.PortalType = window["PortalType"];
    if (window["EnableCartDataLog"])
        environment.EnableCartDataLog = (window["EnableCartDataLog"] == 'true' ? true : false);
    if (window["WasteBookingCashPaymentUrl"])
        environment.WasteBookingCashPaymentUrl = window["WasteBookingCashPaymentUrl"];
    if (window["CorporateBookingUrl"])
        environment.CorporateBookingUrl = window["CorporateBookingUrl"];
    if (window["PaymentPortalUrl"])
        environment.PaymentPortalUrl = window["PaymentPortalUrl"];
    if (window["EnablePublicWebConcurrencySignalR"])
        environment.EnablePublicWebConcurrencySignalR = (window["EnablePublicWebConcurrencySignalR"] == 'true' ? true : false);
    if (window["EnableCartDataLog"])
        environment.EnableCartDataLog = (window["EnableCartDataLog"] == 'true' ? true : false);
    //added for 4.2.2 interna;
    if (window["RegularPortalInternalUrl"])
        environment.RegularPortalInternalUrl = window["RegularPortalInternalUrl"];
    if (window["CasualPortalInternalUrl"])
        environment.CasualPortalInternalUrl = window["CasualPortalInternalUrl"];
    if (window["CBClientSearchUrl"])
        environment.CBClientSearchUrl = window["CBClientSearchUrl"];
    if (window["CBClientAddUrl"])
        environment.CBClientAddUrl = window["CBClientAddUrl"];
    if (window["OptimoHomeUrl"])
        environment.OptimoHomeUrl = window["OptimoHomeUrl"];
    if (window["LoggedInUser"])
        environment.LoggedInUser = window["LoggedInUser"];

    if (window["IsInternalUser"])
        environment.IsInternalUser = window["IsInternalUser"] == "1";
    if (window["PortalLoginRedirectUrl"])
        environment.PortalLoginRedirectUrl = window["PortalLoginRedirectUrl"];
    if (window["HomeBaseUrl"])
        environment.HomeBaseUrl = window["HomeBaseUrl"];
}

export function Init() {

    SetEnvVariables();
    let _cart;
    if (localStorage.getItem('cart'))
        _cart = JSON.parse(localStorage.getItem('cart'));

    if (!window["LoggedInUser"] && !window["IsInternalUser"] && _cart != undefined && _cart != null && _cart["contact"] && _cart["contact"]["id"] && !_cart["booking"]["id"]) {
         ClearCart();

    }
    const injector = Injector.create({
        providers: [
            { provide: HttpClient, deps: [HttpHandler] },
            { provide: HttpHandler, useValue: new HttpXhrBackend({ build: () => new XMLHttpRequest }) },
        ],
    });

  const httpClient: HttpClient = injector.get(HttpClient);
  var sysConfig = new SystemConfig(new SystemService(new BaseService(httpClient)));
    //-1: casual portal, 2:payment portal, 3: regular portal, 4: Public web, 5: waste portal, 6:Booking Portal
    if (window["PortalType"] == "4" || window["PortalType"] == "6") {
        return forkJoin([
            sysConfig.loadAppConfiguration(),          
            sysConfig.loadPublicBookingConfiguration(),
            sysConfig.loadSystemOption()
        ]).toPromise();

    }
    else if (window["PortalType"] == "5" ) {
        return forkJoin([
            sysConfig.loadWasteBookingConfiguration()
        ]).toPromise();

    }
    else {
        return forkJoin([
            sysConfig.loadAppConfiguration(),
            sysConfig.loadRegularBookingConfiguration()           
        ]).toPromise();
    }
}
export function ClearCart() {
  let _cart;
  if (localStorage.getItem("cart"))
    _cart = JSON.parse(localStorage.getItem("cart"));
  if (
    _cart != undefined &&
    _cart != null &&
    _cart["contact"] &&
    _cart["contact"]["id"]
  ) {
    _cart["contact"] = { firstName: "", profilePicture: "" };
    localStorage.setItem("cart", JSON.stringify(_cart));
    localStorage.removeItem("rbStore");
    window["ShowSessionOut"] = "1";
  }
}