import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IPBReducer } from "./public-web-reducers";


const getAllSate = createFeatureSelector<IPBReducer>('PBReducer');

export const selectBooking = createSelector(
  getAllSate,
  (state) => {
    return state ? state.booking : null;
  }
);

export const selectPatchPreviewId = createSelector(
  getAllSate,
  (state) => {
    return state ? state.patchPreviewId : null;
  }
);

export const selectBookingWithEvent = createSelector(
  getAllSate,
  (state) => {
    return state ? {
      booking:state.booking,
      eventId:state.eventId
    } : null;
  }
)

export const selectSessionTimers = createSelector(
  getAllSate,
  (state) => {
    return state ? state.sessionTimers : null;
  }
);

export const selectBookingSession = createSelector(
  getAllSate,
  (state) => {
    return state ? state.sessionTimers.bookingSession : null;
  }
);
export const selectPaymentSession = createSelector(
  getAllSate,
  (state) => {
    return state ? state.sessionTimers.paymentSession : null;
  }
);
export const selectFreezedBooking = createSelector(
  getAllSate,
  (state) => {
    return state ? state.booking : null;
  }
);
export const selectedSessions = createSelector(
  getAllSate,
  (state) => {
    return state ? state.selectedSessions : null;
  }
);
export const selectedSessionTime = createSelector(
  getAllSate,
  (state) => {
    return state ? state.selectedSessionTime : null;
  }
);
export const selectBookedPackages = createSelector(
  getAllSate,
  (state) => {
    return state ? state.bookedPackages : null;
  }
);

export const selectPageType = createSelector(
  getAllSate,
  (state) => {
    return state ? state.pageType : null;
  }
);
export const selectPageConfiguration = createSelector(
  getAllSate,
  (state) => {
    return state ? state.pageConfiguration : null;
  }
);
export const selectedClient = createSelector(
  getAllSate,
  (state) => {
    return state ? state.selectedClient : null;
  }
);
export const selectedPublicPackageCarts = createSelector(
  getAllSate,
  (state) => {
    return state ? state.publicPackages : null;
  }
);

export const selectedPublicPackageById = (packageId) => createSelector(
  selectedPublicPackageCarts,
  (state) => {
    return state ? state.find(x => x.id === packageId) : null;
  }
);
export const selectBookingCartModel = createSelector(
  getAllSate,
  (state) => {
    return  state ? {
      booking : state.booking,
      bookedPackages : state.bookedPackages,
      freezedBooking: state.freezedBooking
    } : null;
  }
)
export const selectBookingMode = createSelector(
  getAllSate,
  (state)=> {
    return state ? state.bookingMode: null;
  }
)
export const selectUpsellState = createSelector(
  getAllSate,
  (state) => {
    return state ? {
      pageConfiguration: state.pageConfiguration,
      bookingMode: state.bookingMode,
      booking: state.booking,
      eventId: state.eventId,
      selectedSessions: state.selectedSessions,
      selectedSessionTime: state.selectedSessionTime,
    } : null;
  }
);
export const selectSessionModalState = createSelector(
  getAllSate,
  (state) => {
    return state ? {
      selectedSessions: state.selectedSessions,
      packageAvailability: state.packageAvailability,
      booking: state.booking,
      eventId: state.eventId,
    } : null;
  }
);
export const selectBookingId = createSelector(
  getAllSate,
  (state) => {
    return state ? state.selectedBooking: null;
  }
);
export const selectedBookingPackage = createSelector(
  getAllSate,
  (state) => {
    return state ? state.selectedBookingPackage: null
  }
);
export const selectedBookingFrom = createSelector(
  getAllSate,
  (state) => {
    return state ? state.bookingFrom: null
  }
)
export const selectUpdatedBookingPackage = createSelector(
  getAllSate,
  (state) => {
    return state ? state.updatedBookingPackages: null
  }
)
export const selectContactID = createSelector(
  getAllSate,
  (state) => {
    return state ? state.contactID : null;
  }
);

export const selectSeat = createSelector(
  getAllSate,
  (state) => {
    return state ? state.seat : null;
  }
);

export const selectDeselectSeat = createSelector(
  getAllSate,
  (state) => {
    return state ? state.deSelectSeat : null;
  }
);

export const selectAllSeat = createSelector(
  getAllSate,
  (state) => {
    return state ? state.allSeats : null;
  }
);

export const selectAllDeSelectSeat = createSelector(
  getAllSate,
  (state) => {
    return state ? state.allDeSelectSeats : null;
  }
);

export const selectCheckSeat = createSelector(
  getAllSate,
  (state) => {
    return state ? state.checkSeat : null;
  }
);

export const selectTaxLabelName = createSelector(
  getAllSate,
  (state) => {
    return state ? state.taxLabelName: null;
  }
);

export const selectBookedEvent = createSelector(
  getAllSate,
  (state) => {
    return state ? state.bookedEvents: null;
  }
);
