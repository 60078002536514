<div class="mmc_alert __warning-alert">
    <div class="row">
        <div class="col sm 12 col md 12 col lg 12">
            <div>
                <div class="mmc_flex--container inner-wrapper mmc--depth__2">
                    <div class="mmc_flex__item--vcenter">
                        <mat-icon class="material-icons alert--icon">highlight_off</mat-icon>
                    </div>
                    <div class="mmc_flex__item--vcenter middle-content">
                        <div class="text-content">
                            <div class="font__h5">Your request could not be processed.</div>
                            <div class="font__small" *ngFor="let errorMessage of data">{{errorMessage}}</div>
                        </div>
                    </div>
                    <div class="mmc_flex__item--inherit d-none">
                        <button mat-icon-button tabindex="0">
                            <mat-icon (click)="close()" (keydown.enter)="close()">close</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>