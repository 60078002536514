<div class="container pd-0">
    <div class="mmc-slider-wrapper" aria-label="selected venue images">
        <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
        <div class="slider-detail--wrapper">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-5">
                    <div class="mmc_flex--container flex-colomn mb-3">
                        <div class="mmc_flex__item">
                            <h3 class="pw-txt-h3 pw-text-color pw-text-semi-bold pw-txt-lh-2">{{facilityDetailDTO?.name}}</h3>
                        </div>
                        <div class="mmc_flex__item--vcenter">
                            <i class="material-icons pw-ico-color__secondary">
                                location_on
                            </i>
                            <span class="pw-txt-s3 pw-text-color__secondary pw-text-select-color">{{facilityDetailDTO?.directions}}</span>
                        </div>
                        <div class="mmc_flex__item--vcenter" *ngIf="viewOnly!=1">
                            <div class="mmc_flex--container avl-cage" (click)="openTimePopup()" (keydown.enter)="openTimePopup()" role="button" tabindex="0">
                                <div class="mmc_flex__item--inherit">
                                    <div class="icn-cage"><span class="pw-ico-color__secondary mmc icon-users icn-aminity"></span></div>
                                    <div class="pw-txt-b2 pw-text-semi-bold pw-text-color__special">{{facilityDetailDTO?.capacity}}</div>
                                </div>
                                <div class="mmc_flex__item--vcenter avl-lbl">
                                    <div class="pw-txt-s3 pw-text-color__quinary avl-state">{{ftc.getAvailabilityLabel()}}</div>
                                    <div class="pw-txt-s2 pw-text-color avl-date available">on {{timelableDate | dateFormat}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-5 d-flex mmc_fd--col cog-just-centre">

                    <div class="mmc_flex--container flex-end" *ngIf="displayPrice && searchFacilities && searchFacilities.length>0 && !isConfigurationEnabled">
                        <div class="pw-txt-h3 pw-text-color pw-text-semi-bold" *ngIf="searchFacilities[0].isPriceRange()">{{searchFacilities[0].minPrice?.priceIncludingTax | CurrencyFormat}} - {{searchFacilities[0].maxPrice?.priceIncludingTax | CurrencyFormat}}</div>
                        <div class="pw-txt-h3 pw-text-color pw-text-semi-bold" *ngIf="!searchFacilities[0].isPriceRange()">{{searchFacilities[0].getPrice()?.priceIncludingTax | CurrencyFormat}}</div>
                        </div>


                        <div class="mmc_flex--container flex-end" *ngIf="displayPrice && searchFacilities && searchFacilities.length>0 && (isConfigurationEnabled && searchFacilities[0].item)">
                          

                            <div class="pw-txt-h3 pw-text-color pw-text-semi-bold" *ngIf="searchFacilities[0].isItemPriceRange()">{{searchFacilities[0].item?.itemMinPrice?.priceIncludingTax | CurrencyFormat}} - {{searchFacilities[0].item?.itemMaxPrice?.priceIncludingTax | CurrencyFormat}}</div>
                            <div class="pw-txt-h3 pw-text-color pw-text-semi-bold" *ngIf="!searchFacilities[0].isItemPriceRange()">{{searchFacilities[0].getItemPrice()?.priceIncludingTax | CurrencyFormat}}</div>

                    </div>
                    <div class="mmc_flex--container confirmation">
                        <span class="pw-txt-s2 pw-text-color" *ngIf="displayPrice"  [innerHtml]="FacilityScreenMainDescription">
                           
                        </span>
                    </div>
                    <div class="mmc_flex--container flex-end sld-time ">
                        <div class="mmc_flex__item" *ngIf="viewOnly!=1">

                            <form class="example-form" *ngIf="isConfigurationEnabled">
                                <mat-form-field class="example-full-width">
                                    <mat-placeholder class="placeholder">SELECTED DATE & TIME</mat-placeholder>
                                    <input matInput value="{{SelectedDate | dateFormat}} {{timelable}}" (click)="addToBooking()" (keydown.enter)="addToBooking()" [readonly]="true" tabindex="0" aria-label="Booking">
                                    <!--<mat-icon (click)="addToBooking()" matSuffix>access_time</mat-icon>-->
                                </mat-form-field>
                            </form>

                            <form class="example-form" *ngIf="!isConfigurationEnabled">
                                <mat-form-field class="example-full-width" [matMenuTriggerFor]="menu">
                                    <mat-placeholder class="placeholder">SELECTED DATE & TIME</mat-placeholder>
                                    <input matInput value="{{SelectedDate | dateFormat}} {{timelable}}" [readonly]="true" aria-label="Booking">
                                    <mat-icon matSuffix>access_time</mat-icon>
                                </mat-form-field>
                            </form> 

                            <mat-menu #menu="matMenu" class="time-popup slid-menu-wrap" [overlapTrigger]="false">
                                <div>
                                    <div (click)="$event.stopPropagation();" (keydown.tab)="$event.stopPropagation();" class="main-time-pick" tabindex="0">
                                        <div class="mmc_flex--container">
                                            <div class="mmc_flex__item">
                                                <mat-form-field class="date-select">
                                                    <mat-placeholder class="pw-txt-b2 placeholder">DATE SELECTED</mat-placeholder>
                                                    <input matInput [matDatepicker]="picker" (focus)="picker.open()" [(ngModel)]="date" (dateChange)="dateChanged()" [min]="minDate" readonly>
                                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="mmc_flex--container time-container">
                                            <div class="mmc_flex__item add-padding table-no-border">
                                                <div class="mmc_flex--container">
                                                    <div class="mmc_flex__item--vcenter clock">
                                                        <i class="material-icons pw-ico-color__tertiary">access_time</i>
                                                    </div>
                                                    <div class="mmc_flex__item">
                                                        <mat-placeholder class="pw-txt-b2 placeholder">START TIME</mat-placeholder>
                                                        <timepicker [(ngModel)]="startTime"
                                                                    [min]="minStartDate"
                                                                    [max]="maxStartDate"
                                                                    (ngModelChange)="startTimeChanged()"
                                                                    (isValid)="isValidStart = $event">
                                                        </timepicker>
                                                        <pre *ngIf="!isValidStart" class="alert alert-danger">Invalid time format</pre>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mmc_flex__item table-no-border">
                                                <div class="mmc_flex--container">
                                                    <div class="mmc_flex__item--vcenter clock">
                                                        <i class="material-icons pw-ico-color__tertiary">access_time</i>
                                                    </div>
                                                    <div class="mmc_flex__item">
                                                        <mat-placeholder class="pw-txt-b2 placeholder">END TIME</mat-placeholder>
                                                        <timepicker [(ngModel)]="endTime"
                                                                    [min]="minEndDate"
                                                                    [max]="maxEndDate"
                                                                    (isValid)="isValidEnd = $event"
                                                                    (ngModelChange)="endTimeChanged()">
                                                        </timepicker>
                                                        <pre *ngIf="!isValidEnd" class="alert alert-danger">Invalid time format</pre>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mmc_flex--container btn-content">
                                        <div class="btn__card main mmc_flex__item--vcenter" [ngClass]="{'disable-div' : !isValidStart || !isValidEnd}" (click)="apply()" (keydown.enter)="apply()" tabindex="0" role="button">
                                            APPLY
                                        </div>
                                    </div>
                                </div>
                            </mat-menu>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-2 btn_book" *ngIf="searchFacilities && viewOnly!=1">
                    <div class="mmc_flex--container flex-end btn_wrp" [ngStyle]="bookingDetails?.id || (showUnavailableFutureDateMessage || !enableBookButton(searchFacilities[0])) ? {'cursor': 'not-allowed'} : {}">
                        <div class="btn__card main mmc_flex__item--vcenter" (click)="addToBooking()" [ngClass]="{'disable-div' : bookingDetails?.id || (showUnavailableFutureDateMessage || !enableBookButton(searchFacilities[0]))}"
                        [ngStyle]="bookingDetails?.id || ( showUnavailableFutureDateMessage || !enableBookButton(searchFacilities[0])) ? {'pointer-events': 'none'} : {}">
                            ADD TO BOOKING 
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="slider-available-msg mmc_flex--container" *ngIf="((facilityAvailablity==false && defaultTimeIsAny==true && isTimeChanged==true) || (defaultTimeIsAny==false && facilityAvailablity==false)) && !showUnavailableOperationalMessage">
            <div class="mmc_flex__item--vcenter text">
                The venue is unavailable for the selected time slot
            </div>
            <div class="mmc_flex__item--vcenter text-small" *ngIf="searchFacilities && searchFacilities[0].configuration && (searchFacilities[0].configuration.setupTime != 0 || searchFacilities[0].configuration.teardownTime != 0)">
                {{searchedStartDate | dateFormat:'time'}} - {{searchedEndDate | dateFormat:'time'}}
            </div>
            <div class="mmc_flex__item--vcenter icon--nt-available">
                <i class="mmc icon-error"></i>
            </div>
        </div>

        <div class="slider-available-msg mmc_flex--container" *ngIf="(((facilityAvailablity==true && defaultTimeIsAny==true && isTimeChanged==true) || (defaultTimeIsAny==false && facilityAvailablity==true)) && viewOnly!=1)">
            <div class="mmc_flex__item--vcenter text">
                The venue is available for the selected time slot
            </div>
            <div class="mmc_flex__item--vcenter icon--available">
                <i class="mmc icon-verified"></i>
            </div>
        </div>

        <div class="slider-available-msg mmc_flex--container" *ngIf="showUnavailableFutureDateMessage">
            <div class="mmc_flex__item--vcenter text">{{showUnavailableFutureDateErrorMessage}}</div>
            <div class="mmc_flex__item--vcenter icon--nt-available">
                <i class="mmc icon-error"></i>
            </div>
        </div>

        <div class="slider-available-msg mmc_flex--container" *ngIf="showUnavailableOperationalMessage && !showUnavailableFutureDateMessage">
            <div class="mmc_flex__item--vcenter text">
                Your selected time is outside operating hours,
            </div>
            <div class="mmc_flex__item text mmc_fd--col">
                <div *ngIf="facilityDetailDTO.openingTime">Opening Time: {{facilityDetailDTO.openingTime | dateFormat:'time'}} </div>
                <div *ngIf="facilityDetailDTO.closingTime">Closing Time: {{facilityDetailDTO.closingTime | dateFormat:'time'}} </div>
            </div>
            <div class="mmc_flex__item--vcenter icon--nt-available">
                <i class="mmc icon-error"></i>
            </div>
        </div>
    </div>
</div>

