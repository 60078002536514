import { CONFIGTYPE } from './utils';
import { AllocatedTable, Block, TableType } from './CommonDTOs';

export class Configuration {
  id: any;
  facilityId: string;
  type: CONFIGTYPE;
  name: string;
  blocks?: Block[];
  tables?: AllocatedTable[]
  initialize(data, facilityId) {
    if (data) {
      this.id = !!data.id ? data.id : '';
      this.name = data.name;
      this.facilityId = facilityId;
      this.blocks =
        !!data.blocks
          && data.blocks.length > 0 ?
          data.blocks.map(d => new Block().initialize(
            d,
            d.private,
            facilityId,
            data.rows && data.rows.filter(r => r.eventBlock?.id == d.id),
            data.seats && data.seats.filter(s => s.eventBlock?.id == d.id),
            !!data.tables && data.tables.length > 0 ? data.tables.filter(t => t.blockId == d.id) : [])) : [];
      this.type = data.configurationTypeId;
      if (data.configurationTypeId === CONFIGTYPE.TABLE) {
        this.tables = !!data.tables && data.tables.length > 0 && !data.tables.blockID ? data.tables.map(d => new AllocatedTable().initialize(d)) : [];
      }
    }
    return this;
  }

}
