<form [formGroup]="clientSaveForm" (ngSubmit)="SaveCorporateClient()">
  <div class="mmc_corporate client_wrapper">
    <section>
      <div class="font__semibold title">
        <div class="name">
          <div class="name__inner pw-txt-h3_small pw-text-semi-bold desktop corporate-name-detail">{{'GUESTCHECKOUTINFO.Customer Details'| optimotranslate}}</div>
          <div class="name__inner pw-txt-h3_small pw-text-semi-bold mobile">{{'SIGNUP.Corporate Customer Details'| optimotranslate}}s</div>
        </div>
      </div>

      <div class="row mb-5 profile-image-row"  style="display:none">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="mmc_flex--container user-profile">

            <div class="mmc_flex__item--inherit mmc_flex__item--vcenter">
              <div class="profile-image">
                <img alt="profile_image" class="img-circle" height="80" src="../dist/assets/images/user-icon.png" width="80">
              </div>
            </div>
            <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter ml-5">
              <div class="mmc_flex--container mmc_fd--col">
                <div class="mmc_flex__item upload-require-message">
                  <div class="pw-txt-s1">{{'SIGNUP.Upload your photo (maximum file size 3MB, resolution 2*2)'| optimotranslate}}</div>
                </div>

                <div class="mmc_flex__item">
                  <div class="d-flex"><span class="pw-txt-s2 link-txt">{{'SIGNUP.Upload Image'| optimotranslate}}</span></div>
                  <div class="d-flex"><span class="pw-txt-s2 link-txt">{{'SIGNUP.Remove Image'| optimotranslate}}</span></div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <mat-form-field appearance="outline">
            <mat-label>
              {{ this.selectedLanguage.toUpperCase() === 'EN' ? appData?.ClientRegistrationConfig?.Placeholder?.Corporate?.CompanyName?.Label : ('SIGNUP.CompanyName' | optimotranslate) }}
              {{ (appData?.ClientRegistrationConfig?.Placeholder?.Corporate?.CompanyName?.IsMandatory == "1") ? "*" : "" }}
            </mat-label>
            <input matInput [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Corporate?.CompanyName?.Value calss="form-control" formControlName="CompanyName" autocomplete="off">
            <mat-error *ngIf="clientSaveForm.get('CompanyName').errors?.maxlength">{{'SIGNUP.Organization name should not exceed 200 characters'| optimotranslate}}.</mat-error>
            <mat-hint></mat-hint>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <mat-form-field appearance="outline">
            <mat-label>{{'SIGNUP.Registration (ABN/ACN)'| optimotranslate}}</mat-label>
            <input matInput placeholder="" calss="form-control" formControlName="RegistrationName" autocomplete="off">
            <mat-hint></mat-hint>
          </mat-form-field>
        </div>
      </div>

      <!--<div class="row" style="display:none;">
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 xl-6 order-first order-1 order-sm-1 order-first order-md-1 order-lg-2 order-xl-2 img-col" style="display:none;">
            <div class="mmc_flex--container  img-upload client">
                <div class="mmc_flex__item--vcenter main-wrap">
                    <div class="dropzone" (click)="uploadClick()">
                        <i class="material-icons">cloud_upload</i>
                        <div class="content">Click here to upload a profile image</div>
                        <input type="file" class="form-control file-upload" #imageInput id="imageInput" (change)='imageChange($event)' (drop)="drop($event)" (dragover)="allowDrop($event)" onclick="event.stopPropagation()" accept='image/*'>
                    </div>

                    <div class="uplod-cl-image msg">
                        <div class="not-found" #imageError [style.display]="'none'"></div>
                        <img [src]="Image" id="imageInput" class="form-control" #imageDrop #imageInput (change)='imageChange($event)' />
                    </div>
                </div>
            </div>
        </div>
    </div>-->
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <mat-form-field appearance="outline">
            <mat-label>
              {{ this.selectedLanguage.toUpperCase() === 'EN' ? appData?.ClientRegistrationConfig?.Placeholder?.Corporate?.CompanyPhoneNumber?.Label : ('SIGNUP.Company Phone' | optimotranslate) }}
              {{(appData?.ClientRegistrationConfig?.Placeholder?.Corporate?.CompanyPhoneNumber?.IsMandatory == "1" )?"*":""}}
            </mat-label>
            <input matInput placeholder={{placholderSampleNumber}} calss="form-control" formControlName="CompanyPhoneNumber" autocomplete="off">
            <mat-error *ngIf="clientSaveForm.controls.CompanyPhoneNumber.errors !== null && clientSaveForm.controls.CompanyPhoneNumber.touched">{{'SIGNUP.Please enter valid company phone number'| optimotranslate}}.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <mat-form-field appearance="outline">
            <mat-label>
              {{ this.selectedLanguage.toUpperCase() === 'EN' ? appData?.ClientRegistrationConfig?.Placeholder?.Corporate?.CompanyEmail?.Label : ('SIGNUP.Company Email' | optimotranslate) }}
              {{(appData?.ClientRegistrationConfig?.Placeholder?.Corporate?.CompanyEmail?.IsMandatory == "1" )?"*":""}}
            </mat-label>
            <input type="email" matInput [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Corporate?.CompanyEmail?.Value calss="form-control" formControlName="CompanyEmail" autocomplete="new-password">
            <mat-error *ngIf="clientSaveForm.controls.CompanyEmail.errors !== null && clientSaveForm.controls.CompanyEmail.touched">{{'MYPROFILE.Please enter valid'| optimotranslate}} {{appData?.ClientRegistrationConfig?.Placeholder?.Corporate?.CompanyEmail?.Label|lowercase}}.</mat-error>
          </mat-form-field>
        </div>
      </div>


      <div class="font__bold title corporate-title-view main-view-div" style="display: none;">
        <div class="name">
          <div class="name__inner pw-txt-h3_small pw-text-semi-bold">{{'SIGNUP.Main Contact Details'| optimotranslate}}</div>
        </div>
      </div>


      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <div class="row contact-person">
            <div class="col-sm-12 col-md-12 col-lg-3 remove_right_padding">
              <mat-form-field appearance="outline">
                <mat-label>{{'MYPROFILE.Title'| optimotranslate}}</mat-label>
                <mat-select  [(value)]="SelectedTitleName" role="radiogroup">
                  <mat-option role="radio" *ngFor="let title of Titles" (click)="SelectTitle(title.id)" [value]="title?.name" (keydown.enter)="SelectTitle(title.id)" (onSelectionChange)="SelectTitle(title.id)">{{title.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <!--remove_left_padding-->
            <div class="col-sm-12 col-md-12 user-name" [ngClass]="isPublicPortal ? 'col-lg-9' : 'col-lg-12'">
              <mat-form-field appearance="outline">
                <mat-label>{{ this.selectedLanguage.toUpperCase() === 'EN' ? appData?.ClientRegistrationConfig?.Placeholder?.Corporate?.FirstName?.Label : ('SIGNUP.Contact First Name' | optimotranslate) }}</mat-label>
                <input matInput [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Corporate?.FirstName?.Value calss="form-control" formControlName="FirstName" required autocomplete="off">
                <mat-error *ngIf="clientSaveForm.get('FirstName').errors?.maxlength"> {{ this.selectedLanguage.toUpperCase() === 'EN' ? appData?.ClientRegistrationConfig?.Placeholder?.Corporate?.FirstName?.Label : ('SIGNUP.Contact First Name' | optimotranslate) }} {{'SIGNUP.should not exceed 50 characters' | optimotranslate}}.</mat-error>
                <mat-hint></mat-hint>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ this.selectedLanguage.toUpperCase() === 'EN' ? appData?.ClientRegistrationConfig?.Placeholder?.Corporate?.LastName?.Label : ('SIGNUP.Contact Last Name' | optimotranslate) }}</mat-label>
            <input matInput [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Corporate?.LastName?.Value calss="form-control" formControlName="LastName" required autocomplete="off">
            <mat-error *ngIf="clientSaveForm.get('LastName').errors?.maxlength"> {{ this.selectedLanguage.toUpperCase() === 'EN' ? appData?.ClientRegistrationConfig?.Placeholder?.Corporate?.LastName?.Label : ('SIGNUP.Contact Last Name' | optimotranslate) }} {{'SIGNUP.should not exceed 50 characters' | optimotranslate}}.</mat-error>
            <mat-hint></mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <div class="mmc_flex--container contact-detail">
            <div class="mmc_flex__item--inherit drop-area">
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{'MYPROFILE.Phone'| optimotranslate}}</mat-label>
                <mat-select placeholder="{{SelectedPhoneTypeName}}">
                  <mat-option *ngFor="let comTypeAdPhone of CommunicationTypesPhone" (click)="SelectComType(comTypeAdPhone.id, 1, false)" (keydown.enter)="SelectComType(comTypeAdPhone.id, 1, false)" (onSelectionChange)="SelectComType(comTypeAdPhone.id, 1, false)">{{comTypeAdPhone.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="mmc_flex__item">
              <mat-form-field appearance="outline">
                <mat-label>{{SelectedPhoneTypeName}}</mat-label>
                <input matInput placeholder="{{placholderSampleNumber}}" calss="form-control" formControlName="PersonalPhoneNumber_1" required autocomplete="off">
                <mat-error *ngIf="clientSaveForm.controls.PersonalPhoneNumber_1.errors !== null && clientSaveForm.controls.PersonalPhoneNumber_1.touched">Please enter valid {{SelectedPhoneTypeName|lowercase}} number.</mat-error>
              </mat-form-field>
            </div>
            <div class="mmc_flex__item--inherit add-more">
              <div class="img-upload mat-button" mat-button tabindex="0" (click)="AddPhone=1" (keydown.enter)="AddPhone=1">
                <i class="material-icons"> add </i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <div class="mmc_flex--container contact-detail">
            <div class="mmc_flex__item--inherit drop-area">
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{'MYPROFILE.Email'| optimotranslate}}</mat-label>
                <mat-select placeholder="{{SelectedEmailTypeName}}">
                  <mat-option *ngFor="let comTypeAdEmail of CommunicationTypesEmail" (click)="SelectComType(comTypeAdEmail.id, 2, false)" (keydown.enter)="SelectComType(comTypeAdEmail.id, 2, false)" (onSelectionChange)="SelectComType(comTypeAdEmail.id, 2, false)">{{comTypeAdEmail.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="mmc_flex__item">
              <mat-form-field appearance="outline">
                <mat-label>{{SelectedEmailTypeName}}</mat-label>
                <input type="email" (input)="EmailAdressChanged($event.target.value)" matInput placeholder="abc@dep.com.au" calss="form-control" formControlName="PersonalEmail_1" required autocomplete="off">
                <mat-error *ngIf="clientSaveForm.controls.PersonalEmail_1.errors !== null && clientSaveForm.controls.PersonalEmail_1.touched">{{'MYPROFILE.Please enter valid'| optimotranslate}} {{SelectedEmailTypeName|lowercase}}.</mat-error>
              </mat-form-field>
            </div>
            <div class="mmc_flex__item--inherit add-more">
              <div class="img-upload mat-button" mat-button tabindex="0" (click)="AddEmail=1" (keydown.enter)="AddEmail=1">
                <i class="material-icons">add</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="AddPhone === 1 || AddEmail === 1">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <div class="mmc_flex--container contact-detail" *ngIf="AddPhone === 1">
            <div class="mmc_flex__item--inherit drop-area">
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{'MYPROFILE.Phone'| optimotranslate}}</mat-label>
                <mat-select placeholder="{{SelectedAdditionalPhoneTypeName}}">
                  <mat-option *ngFor="let comTypeAdPhone of CommunicationTypesPhone" (click)="SelectComType(comTypeAdPhone.id, 1, true)" (keydown.enter)="SelectComType(comTypeAdPhone.id, 1, true)" (onSelectionChange)="SelectComType(comTypeAdPhone.id, 1, true)">{{comTypeAdPhone.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="mmc_flex__item">
              <mat-form-field appearance="outline">
                <mat-label>{{SelectedAdditionalPhoneTypeName}}</mat-label>
                <input matInput placeholder="+61 0000 0000" calss="form-control" formControlName="PersonalPhoneNumber_2" autocomplete="off">
                <mat-error *ngIf="clientSaveForm.controls.PersonalPhoneNumber_2.errors !== null && clientSaveForm.controls.PersonalPhoneNumber_2.touched">Please enter valid {{SelectedAdditionalPhoneTypeName | lowercase}} number.</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <div class="mmc_flex--container contact-detail" *ngIf="AddEmail === 1">
            <div class="mmc_flex__item--inherit drop-area">
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{'MYPROFILE.Email'| optimotranslate}}</mat-label>
                <mat-select placeholder="{{SelectedAdditionalEmailTypeName}}">
                  <mat-option *ngFor="let comTypeAdEmail of CommunicationTypesEmail" (click)="SelectComType(comTypeAdEmail.id, 2, true)" (keydown.enter)="SelectComType(comTypeAdEmail.id, 2, true)" (onSelectionChange)="SelectComType(comTypeAdEmail.id, 2, true)">{{comTypeAdEmail.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="mmc_flex__item">
              <mat-form-field appearance="outline">
                <mat-label>{{SelectedAdditionalEmailTypeName}}</mat-label>
                <input type="email" matInput placeholder="" calss="form-control" formControlName="PersonalEmail_2" autocomplete="off">
                <mat-error *ngIf="clientSaveForm.controls.PersonalEmail_2.errors !== null && clientSaveForm.controls.PersonalEmail_2.touched">Please enter valid {{SelectedAdditionalEmailTypeName|lowercase}}.</mat-error>

              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <style>
        .check-icon-green {
          color: #3d9b47;
          padding-right: 10px;
        }

        .check-icon-red {
          color: #dc3545;
          padding-right: 10px;
        }
      </style>

      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <mat-form-field appearance="outline">
            <mat-label>{{'SIGNUP.Username'| optimotranslate}}</mat-label>
            <input matInput placeholder="" calss="form-control" formControlName="UserName" required autocomplete="off">
            <mat-error *ngIf="clientSaveForm.get('UserName').errors?.maxlength">{{'SIGNUP.Username should not exceed 200 characters'| optimotranslate}}.</mat-error>
            <mat-hint></mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <mat-form-field appearance="outline">
            <mat-label>{{'SIGNUP.Password'| optimotranslate}}</mat-label>
            <input matInput placeholder="" type="password" calss="form-control" formControlName="Password" required ([ngModel])="PasswordVal" (input)="passwordStrength($event)" autocomplete="new-password">
            <mat-error *ngIf="clientSaveForm.controls.Password.errors && clientSaveForm.controls.Password.touched">{{'SIGNUP.Invalid Password'| optimotranslate}}.</mat-error>
          </mat-form-field>

          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="!isPasswordValid && clientSaveForm.controls.Password.dirty">
            <div *ngIf="pwPolicy?.EnforceComplexPassword">
              <div class="mmc_flex--container sub-section-title">
                <div class="mmc_flex__item--inherit">{{'SIGNUP.Minimum Password Requirements'| optimotranslate}}</div>
              </div>
              <div class="mmc_flex--container mmc_fd--col">
                <div class="mmc_flex__item--inherit mmc_flex__item--vcenter suggestion-label" *ngIf="pwPolicy?.EnforceMinimumPasswordLength">
                  <div *ngIf="isValidCharactersMinimum">
                    <i class="material-icons check-icon check-icon-green">done</i>
                  </div>
                  <div *ngIf="!isValidCharactersMinimum">
                    <i class="material-icons check-icon check-icon-red">clear</i>
                  </div>
                  <span>{{pwPolicy?.PasswordLength}} {{'CHANGEPASSWORD.Characters minimum'| optimotranslate}}</span>
                </div>

                <div class="mmc_flex__item--inherit mmc_flex__item--vcenter suggestion-label" *ngIf="pwPolicy?.EnforceLowercase">
                  <div *ngIf="isValidOneLowercase">
                    <i class="material-icons check-icon check-icon-green">done</i>
                  </div>
                  <div *ngIf="!isValidOneLowercase">
                    <i class="material-icons check-icon check-icon-red">clear</i>
                  </div>
                  <span>{{'CHANGEPASSWORD.One lowercase'| optimotranslate}}</span>
                </div>

                <div class="mmc_flex__item--inherit mmc_flex__item--vcenter suggestion-label" *ngIf="pwPolicy?.EnforceUppercase">
                  <div *ngIf="isValidOneUppercase">
                    <i class="material-icons check-icon check-icon-green">done</i>
                  </div>
                  <div *ngIf="!isValidOneUppercase">
                    <i class="material-icons check-icon check-icon-red">clear</i>
                  </div>
                  <span>{{'CHANGEPASSWORD.One uppercase'| optimotranslate}}</span>
                </div>

                <div class="mmc_flex__item--inherit mmc_flex__item--vcenter suggestion-label" *ngIf="pwPolicy?.EnforceDigit">
                  <div *ngIf="isValidOneDigit">
                    <i class="material-icons check-icon check-icon-green">done</i>
                  </div>
                  <div *ngIf="!isValidOneDigit">
                    <i class="material-icons check-icon check-icon-red">clear</i>
                  </div>
                  <span>{{'CHANGEPASSWORD.One digit'| optimotranslate}}</span>
                </div>

                <div class="mmc_flex__item--inherit mmc_flex__item--vcenter suggestion-label" *ngIf="pwPolicy?.EnforceSpecialCharacters">
                  <div *ngIf="isValidOneSpecialCharacter">
                    <i class="material-icons check-icon check-icon-green">done</i>
                  </div>
                  <div *ngIf="!isValidOneSpecialCharacter">
                    <i class="material-icons check-icon check-icon-red">clear</i>
                  </div>
                  <span>{{'CHANGEPASSWORD.One special character'| optimotranslate}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <mat-form-field appearance="outline">
            <mat-label>{{'CHANGEPASSWORD.Confirm Password'| optimotranslate}}</mat-label>
            <input matInput placeholder="" type="password" calss="form-control" formControlName="ConfirmPassword" required autocomplete="off">
            <mat-error *ngIf="clientSaveForm.controls.ConfirmPassword.errors !== null && clientSaveForm.controls.ConfirmPassword.touched">{{'SIGNUP.New Password and Confirm Password should match'| optimotranslate}}.</mat-error>
          </mat-form-field>
        </div>
      </div>
    </section>

    <section [ngClass]="loadingStyle ? 'effect-show' : ''">
      <div class="font__bold title">
        <div class="name">
          <div class="name__inner pw-txt-h3_small pw-text-semi-bold">{{'MYPROFILE.Address'| optimotranslate}}</div>
        </div>
      </div>
      <div class="individual-additinal1" *ngIf="showAddressSearchBar">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <mat-form-field appearance="outline" class="address-lookup">
              <mat-label>{{'MYPROFILE.Address'| optimotranslate}}</mat-label>
              <span matPrefix><i class="material-icons">person_pin_circle &nbsp;</i></span>
              <input id="input_address"  autocomplete="off" type="text" matInput placeholder="Start typing your address" aria-label="Number"  [ngModel]="inputSearchString" (ngModelChange)="findAddress($event)" formControlName="myControl" [matAutocomplete]="auto">
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let address of AddressList.AddressDetails" [value]="address.Address" (click)="GetFormattedAddress(address.Address)" (keydown.enter)="GetFormattedAddress(address.Address)" (onSelectionChange)="GetFormattedAddress(address.Address)">
                  {{address.Address}}
                </mat-option>
                <mat-option *ngIf="isNoResultFound">{{'MYPROFILE.No results found'| optimotranslate}}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div class="address-loader ie9up" *ngIf="QASLoader">
              <mat-spinner [strokeWidth]="5" [diameter]="50"></mat-spinner>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <mat-form-field appearance="outline">
              <mat-label>{{ this.selectedLanguage.toUpperCase() === 'EN' ? appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine1?.Label : ('SIGNUP.Address Line1' | optimotranslate) }}{{appData.ClientRegistrationConfig.EnableManualAddress && (appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine1?.IsMandatory == "1" )?"*":""}}</mat-label>
            <input matInput maxlength="100" [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine1?.Value  calss="form-control" formControlName="Address_Line1" [(ngModel)]="Address_Line1" >
            <mat-hint></mat-hint>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ this.selectedLanguage.toUpperCase() === 'EN' ? appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine2?.Label : ('SIGNUP.Address Line2' | optimotranslate) }}{{appData.ClientRegistrationConfig.EnableManualAddress && appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine2?.IsMandatory == "1"?"*":""}}</mat-label>
            <input matInput maxlength="100" [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine2?.Value calss="form-control" formControlName="Address_Line2" [(ngModel)]="Address_Line2" >
            <mat-hint></mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ this.selectedLanguage.toUpperCase() === 'EN' ? appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine3?.Label : ('SIGNUP.Address Line3' | optimotranslate) }}{{appData.ClientRegistrationConfig.EnableManualAddress && appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine3?.IsMandatory == "1"?"*":""}}</mat-label>
            <input matInput maxlength="100" [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine3?.Value  calss="form-control" formControlName="Address_Line3" [(ngModel)]="Address_Line3">
            <mat-hint></mat-hint>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ this.selectedLanguage.toUpperCase() === 'EN' ? appData?.ClientRegistrationConfig?.Placeholder?.Address?.City?.Label : ('SIGNUP.City' | optimotranslate) }}{{appData.ClientRegistrationConfig.EnableManualAddress && appData?.ClientRegistrationConfig?.Placeholder?.Address?.City?.IsMandatory == "1"?"*":""}}</mat-label>
            <input matInput maxlength="100" [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.City?.Value calss="form-control" formControlName="Address_City" [(ngModel)]="Address_City">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <mat-form-field appearance="outline">
            <mat-label class="profile-before">{{ this.selectedLanguage.toUpperCase() === 'EN' ? appData?.ClientRegistrationConfig?.Placeholder?.Address?.State?.Label : ('SIGNUP.State' | optimotranslate) }}{{appData.ClientRegistrationConfig.EnableManualAddress && appData?.ClientRegistrationConfig?.Placeholder?.Address?.State?.IsMandatory == "1"?"*":""}}</mat-label>
            <input matInput maxlength="10" [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.State?.Value calss="form-control" formControlName="Address_CountyCode" [(ngModel)]="Address_CountyCode">
            <mat-hint></mat-hint>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <mat-form-field appearance="outline">
            <mat-label>{{'CHECKOUTINFO.Post Code'| optimotranslate}}{{appData.ClientRegistrationConfig.EnableManualAddress && appData?.ClientRegistrationConfig?.Placeholder?.Address?.PostCode?.IsMandatory == "1"?"*":""}}</mat-label>
            <input matInput maxlength="50" [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.PostCode?.Value calss="form-control" formControlName="Address_PostCode" [(ngModel)]="Address_PostCode">
            <mat-hint></mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngIf="!showAddressSearchBar">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <mat-form-field appearance="outline">
            <mat-label>{{'CHECKOUTINFO.Country'| optimotranslate}}{{appData.ClientRegistrationConfig.EnableManualAddress &&
              appData?.ClientRegistrationConfig?.Placeholder?.Address?.Country?.IsMandatory ==
              "1"?"*":""}}</mat-label>
            <input type="text" aria-label="Number" matInput [formControl]="country" [matAutocomplete]="auto">
            <mat-icon matSuffix (click)="handleMatAutoComplete()">keyboard_arrow_down</mat-icon>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let option of filteredCountries | async" [value]="option" (click)="SelectCountry(option.id)"
              (keydown.enter)="SelectCountry(option.id)" (onSelectionChange)="SelectCountry(option.id)">
              <div style="display: flex;">
                <span [innerHTML]="option?.name"></span>
              </div>
                <span></span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngIf="showAddressSearchBar">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <mat-form-field appearance="outline">
            <mat-label>{{'CHECKOUTINFO.Country'| optimotranslate}}{{appData.ClientRegistrationConfig.EnableManualAddress && appData?.ClientRegistrationConfig?.Placeholder?.Address?.Country?.IsMandatory == "1"?"*":""}}</mat-label>
            <input matInput maxlength="100" [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.Country?.Value calss="form-control" formControlName="Address_Country" [(ngModel)]="Address_Country">
            <mat-hint></mat-hint>
          </mat-form-field>
        </div>

      </div>
    </section>


    <!--Reqular Booking access Request-->
    <section *ngIf="requestToAccessReqularBookingHeaderText && enableWebPortalAccess">
      <div class="font__bold title">
        <div class="name">
          <div class="name__inner pw-txt-h3_small pw-text-semi-bold">{{ this.selectedLanguage.toUpperCase() === 'EN' ? requestToAccessReqularBookingHeaderText : ('SIGNUP.Regular Booking Access' | optimotranslate) }}</div>
        </div>
      </div>

      <div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="pw-txt-b1">
                <mat-checkbox class="pw-txt-b1" formControlName="Request_To_Begular_BookingAccess"  (change)="RegularBookingCheckboxCheck($event)" [(ngModel)]="requestToRegularBookingAccess">{{ this.selectedLanguage.toUpperCase() === 'EN' ? requestToAccessReqularBookingAcceptText : ('SIGNUP.I would like to have access to regular booking portal' | optimotranslate) }}</mat-checkbox>
              <!--<input type="checkbox" formControlName="Request_To_Begular_BookingAccess" [(ngModel)]="requestToRegularBookingAccess" />--> <!--<span class="pl-2">{{requestToAccessReqularBookingAcceptText}}</span>-->
            </div>
          </div>
        </div>

      </div>


      <div class="individual-additinal1" *ngIf="requestToRegularBookingAccess">
        <div class="row ">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="mmc_flex--container  mmc_fd--col">
              <div class="mmc_flex__item--inherit font__semibold pw-txt-h4 pw-txt-bold pt-3">
                {{ this.selectedLanguage.toUpperCase() === 'EN' ? reqularBookingAccessRequestHeaderText : ('SIGNUP.Request access to create regular booking online' | optimotranslate) }}
              </div>
              <div class="mmc_flex__item font__semibold pw-txt-b1 pt-3">
                {{ this.selectedLanguage.toUpperCase() === 'EN' ? reqularBookingAccessRequestSubHeaderText : ('SIGNUP.Provide more details on the activities you wish to conduct along with your preferred venue' | optimotranslate) }}
              </div>

              <div class="mmc_flex__item font__semibold mt-2 pt-3">
                <mat-form-field class="request-access-txtarea"  [ngClass]="{'input-error': clientSaveForm.get('Request_To_Regular_Booking_Access_Note').errors &&
                clientSaveForm.get('Request_To_Regular_Booking_Access_Note').hasError('required')}" >
                  <textarea matInput placeholder="" ([ngModel])="requestToRegularBookingAccessNote" formControlName="Request_To_Regular_Booking_Access_Note" aria-label="request-access-txtarea"></textarea>
                </mat-form-field>
              </div>


            </div>
          </div>
        </div>


      </div>
    </section>

    <div class="font__bold title main-view-div" style="display: none;">
      <div class="name">
        <div class="name__inner pw-txt-h3_small pw-text-semi-bold">Organisation Logo</div>
      </div>
    </div>

      <!--imageupload-->
      <div class="dp-upload-wrapper upload-profile user-image-pro mmc_flex__item--vcenter mmc_flex__item--hcenter" *ngIf="enableProfileImageUpload">
          <!--Uploaded image-->
          <div class="row  profile-image-row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="mmc_flex--container user-profile user-image-pro mmc_flex__item--vcenter mmc_flex__item--hcenter">

                      <div class="mmc_flex__item--inherit mmc_flex__item--vcenter">
                          <div class="profile-image" *ngIf="!profileImage">
                              <img alt="profile_image" class="img-circle" height="80" src="../dist/assets/images/user-icon.png" width="80">
                          </div>
                          <div class="profile-image" *ngIf="profileImage">
                              <img alt="profile_image" class="img-circle" height="80" src={{profileImage}} width="80">
                          </div>
                      </div>
                      <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter ml-5">
                          <div class="mmc_flex--container mmc_fd--col">

                            <div class="mmc_flex__item" *ngIf="profileImage">
                                <div class="d-flex"><span class="pw-txt-s2 link-txt" (click)="profileImage=''" (keydown.enter)="profileImage=''">{{'MYPROFILE.Remove Image'| optimotranslate}}</span></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

          <!--Profile image uploading section-->
          <div class="document-upload" [ngClass]="{'disable-div' : profileImage}">
              <h1 class="pw-txt-h3 upload-doc-heading mmc_txt-align--center wp-mb-20 logo-header-upload">{{ this.selectedLanguage.toUpperCase() === 'EN' ? profileImageHeaderTextForCorporateClients : ('SIGNUP.Upload your company logo (maximum file size 3MB, resolution 2*2)' | optimotranslate) }}</h1>
              <div class="file-upload--card">
                  <input id="profileImageupload" type="file" (change)="onFileChangedForProfileImage($event)" #fileInput style="display:none" #fileInputTagforProfileImage>
                  <file-drop class="file-drop" headertext="" (onFileDrop)="droppedForProfileImage($event)" showBrowseBtn="false" >
                      <div class="mmc_flex__item--vcenter" onclick="$('#profileImageupload').trigger('click'); return false;">
                          <div class="mmc_flex--container">
                              <div class="mmc_flex__item--vcenter upload-icon mcc-file-upload">
                                  <i class="material-icons pw-ico-color material-change-icon upload-icon-image">insert_drive_file</i>
                              </div>
                          </div>
                      </div>
                      <div class="mmc_flex__item--vcenter doc-up-text--mobile d-mobile" *ngIf="!profileImage">
                          <span class="link-primary" onclick="$('#profileImageupload').trigger('click'); return false;">{{'MYPROFILE.Select a file from your device'| optimotranslate}}</span>
                      </div>
                      <div class="mmc_flex__item--vcenter doc-up-text--desktop h-mobile main-drag-div">
                          <span class="pw-txt-b1 pr-2 pw-text-color__tertiary font-family drag-text">{{'MYPROFILE.Drag your files here or'| optimotranslate}}</span>
                          <span class="link-primary font-family drag-link-text" onclick="$('#profileImageupload').trigger('click'); return false;">{{'MYPROFILE.Select a file from your device'| optimotranslate}}</span>
                      </div>
                  </file-drop>
              </div>
          </div>
      </div>


    <!--Reqular Booking access Request-->
    <!--Additional Details-->
    <div *ngIf="IsEnableToCreateAdditionalDetailsSectionForCorporateClients">

      <div class="mmc_flex--container sub-section-title">
        <div class="mmc_flex__item--inherit font__semibold pw-txt-h3_small pw-text-semi-bold">
          {{'MYPROFILE.Additional Detail'| optimotranslate}}
        </div>
        <div class="mmc_flex__item">
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="past-booking-details booking-document-upload gray-bg" id="targetFileBtn">
            <div *ngIf="priceConcessionQuestionText">

              <div class="pw-txt-b1 upload-doc-heading">{{ this.selectedLanguage.toUpperCase() === 'EN' ? PriceConcessionQuestionText : ('SIGNUP.Please select what category best describes your organisation (Note: Incorporated Organisations must hold a Certificate of Incorporation. Not for profit Organisations must provide proof of not for profit status.)*' | optimotranslate) }}</div>

              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">

                  <mat-form-field appearance="outline">
                    <mat-label></mat-label>
                    <mat-select placeholder="" ([ngModel])="additionalDetailNote" formControlName="Additional_Detail_Note" aria-label="Additional Detail Note">
                      <mat-option *ngFor="let PriceConcessionAnswerOption of PriceConcessionAnswerOptions" [value]="PriceConcessionAnswerOption.Key" (click)="additionalDetailNote=PriceConcessionAnswerOption.Key" (keydown.enter)="additionalDetailNote=PriceConcessionAnswerOption.Key" (onSelectionChange)="additionalDetailNote=PriceConcessionAnswerOption.Key" matTooltip="{{PriceConcessionAnswerOption.Value}}"
                      [matTooltipPosition]="'above'">{{PriceConcessionAnswerOption.Value}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>
              </div>

              <!--<div class="d-block my-3 ml-5">
              <mat-select placeholder="" ([ngModel])="additionalDetailNote" formControlName="Additional_Detail_Note">
                <mat-option *ngFor="let PriceConcessionAnswerOption of PriceConcessionAnswerOptions" [value]="PriceConcessionAnswerOption.Key" (click)="additionalDetailNote=PriceConcessionAnswerOption.Key">{{PriceConcessionAnswerOption.Value}}</mat-option>
              </mat-select>
            </div>-->
            </div>

            <div class="" *ngIf="IsEnableToCreateAdditionalDocumentSectionForCorporateClients">
              <h1 class="pw-txt-b1 upload-doc-heading" [innerHTML]="(this.selectedLanguage.toUpperCase() === 'EN') ? ClientFileUploadDescription : ('SIGNUP.Upload certificates regarding your organisation (Maximum file size 3MB)' | optimotranslate)"></h1>
              <div class="file-upload--card">
                <input id="fileuploadCl" type="file" (change)="onFileChangedCl($event)" #fileInput style="display:none" #fileInputTag>
                <file-drop class="file-drop" headertext="" (onFileDrop)="droppedCl($event)" showBrowseBtn="true" browseBtnLabel="Select a file from your computer">
                  <div class="mmc_flex__item--vcenter" onclick="$('#fileuploadCl').trigger('click'); return false;">
                    <div class="mmc_flex--container">
                      <div class="mmc_flex__item--vcenter upload-icon mcc-file-upload">
                        <i class="material-icons pw-ico-color">insert_drive_file</i>
                      </div>
                    </div>
                  </div>
                  <div class="mmc_flex__item--vcenter doc-up-text--mobile d-mobile">
                    <span class="link-primary" onclick="$('#fileuploadCl').trigger('click'); return false;">{{'MYPROFILE.Select a file from your device'| optimotranslate}}</span>
                  </div>
                  <div class="mmc_flex__item--vcenter doc-up-text--desktop h-mobile">
                    <span class="pw-txt-b1 pr-2 pw-text-color__tertiary font-family">{{'MYPROFILE.Drag your files here or'| optimotranslate}}</span>
                    <span class="link-primary font-family" onclick="$('#fileuploadCl').trigger('click'); return false;">{{'MYPROFILE.Select a file from your device'| optimotranslate}}</span>
                  </div>
                </file-drop>
              </div>
              <div class="uploaded-file-list--card" *ngIf="additionalDetail.documents.length>0">

                <div>
                  <div class="file-list-row" *ngFor="let item of additionalDetail.documents; let i=index">
                    <div class="mmc_flex--container">
                      <div class="mmc_flex__item--inherit icon-col">
                        <span class="material-icons">file_copy</span>
                      </div>
                      <div class="mmc_flex__item label-col">
                        <div class="mmc_flex--container mmc_fd--col">
                          <span class="font__normal font-family">{{ item?.name }}</span>
                          <span class="font__small font__gray font-family">{{item?.fileSize}}</span>
                        </div>
                      </div>
                      <div class="mmc_flex__item--inherit download-col">
                        <a [href]="get64Stringdata(item)" download="{{item?.name}}">
                          <span class="material-icons font__gray">cloud_download</span>
                        </a>
                      </div>
                      <div class="mmc_flex__item--inherit download-col">
                        <a><i class="material-icons font__gray" (click)="removeFile(i)" (keydown.enter)="removeFile(i)">close</i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--<button class="btn-cancel-booking " mat-button><span class="icon-p2-delete pr5"></span><span class="section-subheading bold">Request cancellation</span></button>-->
            </div>

          </div>
        </div>
      </div>
    </div>


    <div class="privacy-policy pw-txt-b1" *ngIf="isPublicPortal">
      <mat-checkbox class="pw-txt-b1" [checked]="isReceivedMailChecked" (change)="isReceivedMailChecked=!isReceivedMailChecked"></mat-checkbox>
      <span class="mat-checkbox-txt" [innerHtml]="(this.selectedLanguage.toUpperCase() === 'EN') ? bookingTermAndCondition.bookingTermAndConditionMessage : ('SIGNUP.Id like to receive news and promotional information from IMG Events and its official sponsors and partners via email' | optimotranslate)"></span>
      <br>
      <mat-checkbox class="pw-txt-b1" [checked]="isChecked" (change)="onChange($event)"></mat-checkbox>
      <span class="mat-checkbox-txt" [innerHtml]="(this.selectedLanguage.toUpperCase() === 'EN') ? bookingTermAndCondition.termsAndConditionContents.signUpContent : ('SIGNUP.I agree to the IMG Terms of Use, Purchase Policy and Privacy Policy' | optimotranslate)"></span>
    </div>
    <div class="privacy-policy pw-txt-b1" *ngIf="!isPublicPortal">
      <mat-checkbox class="pw-txt-b1" [checked]="isChecked" (change)="onChange($event)"><div [innerHTML]="PrivacyPolicyLink"></div></mat-checkbox>
    </div>
    <div class="recaptcha mmc_flex--container" *ngIf="isLoad && tabIndex==0">
      <ngx-recaptcha2 #captchaElem [siteKey]="siteKey"
                      [size]="size"
                      [hl]="selectedLanguage"
                      [theme]="theme"
                      [type]="type"
                      (expire)="handleExpire()"
                      (load)="handleLoad()"
                      (success)="handleSuccess($event)"
                      formControlName="recaptcha" id="123">
      </ngx-recaptcha2>
    </div>

    <div>
      <div class="mmc_flex--container btn-content">
        <div class="mmc_flex__item--vcenter"  *ngIf="!isPublicPortal">
          <i class="material-icons">
            chevron_left
          </i>
          <a class="pw-txt-h4" href="javascript:void(0);" (click)="Redirect($event,1,CurrentUrl)" (keydown.enter)="Redirect($event,1,CurrentUrl)" *ngIf="isFromBooking=='1' || CurrentUrl == 'fromBookingDetail'">BOOKING DETAILS </a>
          <a class="pw-txt-h4" href="javascript:void(0);" (click)="Redirect($event,2)" (keydown.enter)="Redirect($event,2)" *ngIf="isFromBooking!='1' && CurrentUrl != 'fromBookingDetail'">VENUES</a>
        </div>
        <div class="mmc_flex__item--vcenter">
          <div>
            <button type="submit" class="btn__card btn-continue main mmc_flex__item--vcenter" tabindex="0"
                    [disabled]="isDisableClientSaveBtn || isChecked==false || isCaptchaVerified==false ||(IsEnableManualAddress==false && (QASLoader || addressLoaded== false))"
                    [ngClass]="{'disable-div' : isChecked==false || isCaptchaVerified==false || (IsEnableManualAddress==false && (QASLoader || addressLoaded== false))}" mat-button>
                    {{'SIGNIN.Continue'| optimotranslate |uppercase}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
