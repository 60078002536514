import { environment } from 'src/environments/environment';
import { FacilitySummary } from './facility-summary';
import { BookingFacility } from './booking-facility';
import { BespokePackage } from './bespoke-package';
import { Item } from '../item/item';
import { ItemPriceGroup } from '../item/item-price-group';
import { Configuration } from './configuration';
import { CalenderTimeslot, Timeslot } from './timeslots';
import { Utility } from 'src/modules/utility';
import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';
import { PriceType } from '../enum';


export class FacilityAdaptor {

    //var appsetting = environment.AppSetting as AppSetting;

    //--------------------------------------------------------------------------------------------------------------------------------------------------------
    //Populate Facility Summary
    //--------------------------------------------------------------------------------------------------------------------------------------------------------
    static PopulateFacilitySummary(facilities: FacilitySummary[], items: Item[], packageClassId: number, customerConcessionId: number, minimumCapacityCount?: number) {


        facilities.forEach((facility) => {

            let minP: ItemPriceGroup;
            let maxP: ItemPriceGroup;
            let concessionId = customerConcessionId;
            //debugger;
            var removeItemList = [];
            facility.items.forEach((item, i) => {

                let searchedItem = items.find(i => i.id == item.id);
                let isHaveSearchedCapacity = this.isItemHaveMaximumCapacity(searchedItem, facility, minimumCapacityCount);
                if (!isHaveSearchedCapacity) {
                    removeItemList.push(item);
                }
                if (searchedItem != undefined && isHaveSearchedCapacity) {
                    //overwrite the facility item from item serach item
                    item.itemAssets = (searchedItem.itemAssets);
                    item.salesCategories = (searchedItem.salesCategories);
                    item.defaultPriceConcessionID = (searchedItem.defaultPriceConcessionID);
                    item.availableFrom = searchedItem.availableFrom;
                    item.availableTo = searchedItem.availableTo;
                    item.allocateToAllAssets = searchedItem.allocateToAllAssets;
                    item.itemPriceGroups = searchedItem.itemPriceGroups;

                    item.name = searchedItem.name;
                    item.question = searchedItem.question;
                    item.allowImmediateConfirmedBookings = searchedItem.allowImmediateConfirmedBookings;
                    item.userDefinedFields = searchedItem.userDefinedFields;

                    //set default price concession
                    //if (concessionId == 0 && environment.ImmediateConfirmation.DefaultPriceConsessionId>0)// item.allowImmediateConfirmedBookings)
                    //{
                    //    concessionId = environment.ImmediateConfirmation.DefaultPriceConsessionId;


                    //}

                    concessionId = this.getBookingPriceConcession(concessionId, false);

                    var index = item.itemPriceGroups.findIndex(ipg => ipg.concessionId == concessionId);
                    //set  items prices
                    var res = this.populateItemDetail(item, searchedItem.itemPriceGroups, packageClassId, concessionId);
                    if (res) {
                        minP = res.minPF;
                        maxP = res.maxPF;

                        //check matching price group
                        if ((minP || maxP)
                            && ((facility.item == undefined || facility.item.id == undefined || facility.item.id == "")
                                || (facility.item.allocateToAllAssets == true && item.allocateToAllAssets == false)
                                || (index >= 0 && facility.itemPrice && facility.itemPrice.concessionId != concessionId)
                                || ((!facility.configuration) || !facility.configuration.internetDefault)
                            )
                        ) {
                            //Price concession validation
                            if (!environment.IsAllowMultiplePriceConsession || concessionId == 0 || index >= 0) {
                                facility.item = item; //set default item for booking //facility.package = defaultPackage; //set default package for booking
                                facility.configuration = this.getConfiguration(item, facility);

                                facility.itemPrice = minP == undefined ? maxP : minP;
                                
                                if(maxP){
                                    if (!facility.maxPrice || !facility.maxPrice.priceIncludingTax || maxP.priceIncludingTax >facility.maxPrice.priceIncludingTax) {
                                        facility.maxPrice = maxP;
                                    }
                                }
                                if(minP){
                                    if (!facility.minPrice || !facility.minPrice.priceIncludingTax || minP.priceIncludingTax < facility.minPrice.priceIncludingTax) {
                                        facility.minPrice = minP;
                                    }

                                }
                            }

                        }
                    }


                }
            });

            if (removeItemList.length > 0) {
                removeItemList.forEach(itm => {
                    let index = facility.items.findIndex(i => i == itm);
                    if (index >= 0) {
                        facility.items.splice(index, 1);
                    }
                });
            }
        });
    }

    static isItemHaveMaximumCapacity(item: Item, facility: FacilitySummary, minimumCapacityCount: number) {
        let confg: Configuration; // find matching configuration for that booking item
        if (item && item.itemAssets != undefined) {
            let itmAsset = item.itemAssets.find(a => a.asset.id == facility.id);
            if (itmAsset) {
                if (itmAsset.configuration != undefined && itmAsset.configuration.id != null) {
                    confg = facility.configurations.find(c => c.id == itmAsset.configuration.id);
                    if (confg && minimumCapacityCount != 0 && confg.capacity < minimumCapacityCount) {
                        return false;
                    }
                }
            }
        }
        return true;
    }
    //pricing
    static getBookingPriceConcession(clientConcessionsId: number, isImmediateBooking: boolean): number {
        var appsetting = environment.AppSetting as AppSetting;
        if (clientConcessionsId > 0)
            return clientConcessionsId;
        else if (appsetting.BookingConfig.ImmediateConfirmation && appsetting.BookingConfig.ImmediateConfirmation.Allow && isImmediateBooking)
            return appsetting.BookingConfig.ImmediateConfirmation.DefaultPriceConsessionId;
        else if (appsetting.BookingConfig.DefaultPriceConsessionId)
            return appsetting.BookingConfig.DefaultPriceConsessionId;
        //else if (environment.ImmediateConfirmation.DefaultPriceConsessionId) return environment.ImmediateConfirmation.DefaultPriceConsessionId;
        else return 0;
    }
    //When find out min max price concession for facilityy items, item will be emppty
    //if this is using for calculatiing actual price, item will be pass, if user not logged in and price display option is 1,
    //item min max concession will be used to calculate prices for the range
    static getMinMaxPriceConcession(concessionsId: number, packageClassId: number, item: Item = null): any {
        let minPriceConcession = 0;
        let maxPriceConcession = 0;
        if (concessionsId > 0)//customer concession or default concession for immediate booking
        {
            minPriceConcession = maxPriceConcession = concessionsId;
        }
        else if (environment.PriceDisplayOption == 2) {
            minPriceConcession = environment.PriceMinConsessionId;
            maxPriceConcession = environment.PriceMaxConsessionId;
        } else if (environment.PriceDisplayOption == 3) {
            let pc = environment.DefaultPackageClassId;
            if (packageClassId && packageClassId > 0)
                pc = packageClassId;
            let selectedPackageClass = environment.PackageClasses.find(a => a.Id == pc);
            if (selectedPackageClass) {
                minPriceConcession = selectedPackageClass.PriceMinConsessionId;
                maxPriceConcession = selectedPackageClass.PriceMaxConsessionId;
            } else {
                return;//Data issue
            }
        } else if (item != null) {
            minPriceConcession = item.itemMinPrice.concessionId;
            maxPriceConcession = item.itemMaxPrice.concessionId;
        }
        return { minPriceConcession: minPriceConcession, maxPriceConcession: maxPriceConcession }
    }

    static getMinMaxPriceConcessionForUpsell(concessionsId: number, packageClassId: number): any {
        let minPriceConcession = 0;
        let maxPriceConcession = 0;
        
        var _appSettings = environment.AppSetting as AppSetting;
        var _upsell =   _appSettings.BookingConfig.UpsellPrice;
        
        if (concessionsId > 0)//customer concession or default concession for immediate booking
        {
            minPriceConcession = maxPriceConcession = concessionsId;
        }
        else if (_upsell.PriceDisplayOption == 2) {
            minPriceConcession = _upsell.PriceMinConsessionId;
            maxPriceConcession = _upsell.PriceMaxConsessionId;
        } else if (_upsell.PriceDisplayOption == 3) {
            let pc = environment.DefaultPackageClassId;
            if (packageClassId && packageClassId > 0)
                pc = packageClassId;
            let selectedPackageClass = _upsell.PackageClases.find(a => a.Id == pc);
            if (selectedPackageClass) {
                minPriceConcession = selectedPackageClass.PriceMinConsessionId;
                maxPriceConcession = selectedPackageClass.PriceMaxConsessionId;
            } else {
                return;//Data issue
            }
        }
        return { minPriceConcession: minPriceConcession, maxPriceConcession: maxPriceConcession }
    }

    static populateItemDetail(item: Item, itemPriceGroups: ItemPriceGroup[], packageClassId: number = 0, concessionsId: number = 0): any {

        if (itemPriceGroups != undefined) {
            //filter for hourly price type
            if (environment.PriceTypeId && environment.PriceTypeId > 0) {
                item.itemPriceGroups = [];
                itemPriceGroups.forEach(ip => {
                    if (ip.priceTypeId == environment.PriceTypeId) {
                        item.itemPriceGroups.push(ip);
                    }
                });
            }

            //1: min and max price,
            //2: defined price concession(PriceMinConsessionId, PriceMaxConsessionId)
            //3: package class wise min max price concession

            let _minP: ItemPriceGroup, _maxP: ItemPriceGroup;
            let res = FacilityAdaptor.getMinMaxPriceConcession(concessionsId, packageClassId);
            let minPriceConcession = res.minPriceConcession;
            let maxPriceConcession = res.maxPriceConcession;
            //item.itemPriceGroups.forEach(pr =>
            if (item.itemPriceGroups && item.itemPriceGroups.length > 0) {
                for (var i = 0; i < item.itemPriceGroups.length; i++) {
                    var pr = item.itemPriceGroups[i];
                    if (minPriceConcession == 0 || pr.concessionId == minPriceConcession) {
                        if (_minP == undefined || pr.priceIncludingTax < _minP.priceIncludingTax) {
                            _minP = pr;
                        }
                    }
                    if (maxPriceConcession == 0 || pr.concessionId == maxPriceConcession) {
                        if (_maxP == undefined || pr.priceIncludingTax > _maxP.priceIncludingTax) {
                            _maxP = pr;
                        }
                    }
                }
                //);
                //no restriction in price concession
                if (environment.IsAllowMultiplePriceConsession && _minP == undefined && _maxP == undefined && item.itemPriceGroups.length > 0) {
                    _minP = item.itemPriceGroups[0];
                }
                //set price group for booking Item
                item.itemMinPrice = _minP ? _minP : _maxP;
                item.itemMaxPrice = _maxP ? _maxP : _minP;
                item.itemPriceGroups = itemPriceGroups;

                let minPF: ItemPriceGroup, maxPF: ItemPriceGroup
                if (_maxP && (maxPF == undefined || _maxP.priceIncludingTax > maxPF.priceIncludingTax))
                    maxPF = _maxP;

                if (_minP && (minPF == undefined || _minP.priceIncludingTax < minPF.priceIncludingTax))
                    minPF = _minP;

                return { maxPF: maxPF, minPF: minPF }
            }

            //

        }
    }
    /*
    static populateItemDetail(item: Item, facility: FacilitySummary, itemPriceGroups: ItemPriceGroup[], minP?: ItemPriceGroup, maxP?: ItemPriceGroup, concessions?: any[]) {

        if (itemPriceGroups != undefined) {
            if (environment.PriceDisplayOption == 3) {
                this.populatePackageClassWiseItemPrice(item, facility, environment.DefaultPackageClassId, concessions)
            } else {
                //filter for hourly price type
                item.itemPriceGroups = [];
                if (environment.PriceTypeId) {
                    itemPriceGroups.forEach(ip => {
                        if (ip.priceTypeId == environment.PriceTypeId) {
                            item.itemPriceGroups.push(ip);
                        }
                    });
                }

                item.itemPriceGroups.forEach(pr => {
                    //1:min and max price,
                    //2: defined price concession(PriceMinConsessionId, PriceMaxConsessionId)
                    if (environment.PriceDisplayOption == 1) {
                        if (minP == undefined || pr.priceIncludingTax < minP.priceIncludingTax) {
                            minP = pr;
                        }
                        if (maxP == undefined || pr.priceIncludingTax > maxP.priceIncludingTax) {
                            maxP = pr;
                        }
                    } else if (environment.PriceDisplayOption == 2) {
                        if (environment.PriceMinConsessionId == pr.concessionId) {
                            if (minP == undefined || pr.priceIncludingTax < minP.priceIncludingTax) {
                                minP = pr;
                            }
                        }
                        if (environment.PriceMaxConsessionId == pr.concessionId) {
                            if (maxP == undefined || pr.priceIncludingTax > maxP.priceIncludingTax) {
                                maxP = pr;
                            }
                        }
                    }
                }
                );
                if (minP == undefined && maxP == undefined && item.itemPriceGroups.length > 0) {
                    minP = item.itemPriceGroups[0];
                }
                //set price group for booking Item
                facility.itemPrice = minP == undefined ? maxP : minP;
                facility.minPrice = minP == undefined ? maxP : minP;
                facility.maxPrice = maxP == undefined ? minP : maxP;
                item.itemPriceGroups = itemPriceGroups;
            }
            //
        }
    }
    static populatePackageClassWiseItemPrice(item: Item, facility: FacilitySummary, packageClass: number, concessions: any[] = []) {
        let selectedPackageClass = environment.PackageClasses.find(a => a.Id == packageClass);
        if (concessions.length == 0) {
            if (selectedPackageClass.PriceMinConsessionId != "") {
                concessions.push(selectedPackageClass.PriceMinConsessionId)
            }

            if (selectedPackageClass.PriceMaxConsessionId != "") {
                concessions.push(selectedPackageClass.PriceMaxConsessionId)
            }
        }

        if ((concessions == undefined || concessions.length == 0) || item.itemPriceGroups == undefined) return;
        let minConcessionId = 0;
        let maxConcessionId = 0;
        let displayPriceRange: boolean = true;
        if (concessions.length > 0) {
            if (concessions.length == 1) {
                minConcessionId = concessions[0];
                displayPriceRange = false;
            } else {
                minConcessionId = concessions[0];
                maxConcessionId = concessions[1];
            }
        }

        item.itemMinPrice = new ItemPriceGroup();
        item.itemMaxPrice = new ItemPriceGroup();

        //item.calculatedMinPrice = new ItemPriceGroup();
        //item.calculatedMaxPrice = new ItemPriceGroup();

        let minConcessionPricegroups = [];
        let maxConcessionPricegroups = [];
        minConcessionPricegroups = item.itemPriceGroups.filter(x => x.concessionId == minConcessionId);
        maxConcessionPricegroups = item.itemPriceGroups.filter(x => x.concessionId == maxConcessionId);

        if (minConcessionPricegroups != undefined && minConcessionPricegroups.length > 0) {
            minConcessionPricegroups = minConcessionPricegroups.sort((a, b) => a.priceTypeId - b.priceTypeId);
            let isContinue = true;
            minConcessionPricegroups.forEach(function (pr) {
                if (isContinue && pr.priceTypeId == 2 && pr.priceIncludingTax != 0 && !isNaN(pr.priceIncludingTax)) {
                    item.itemMinPrice = pr;

                    isContinue = false;
                }
                if (isContinue && pr.priceTypeId == 4 && pr.priceIncludingTax != 0 && !isNaN(pr.priceIncludingTax)) {
                    item.itemMinPrice = pr;

                    isContinue = false;
                }
                if (isContinue && pr.priceTypeId == 3 && pr.priceIncludingTax != 0 && !isNaN(pr.priceIncludingTax)) {
                    item.itemMinPrice = pr;

                    isContinue = false;
                }
                if (isContinue && pr.priceTypeId == 5 && pr.priceIncludingTax != 0 && !isNaN(pr.priceIncludingTax)) {
                    item.itemMinPrice = pr;

                    isContinue = false;
                }

            });
        } else {
            item.itemMinPrice = null;
        }

        if (maxConcessionPricegroups != undefined && maxConcessionPricegroups.length > 0) {
            maxConcessionPricegroups = maxConcessionPricegroups.sort((a, b) => a.priceTypeId - b.priceTypeId);
            let isContinue = true;
            maxConcessionPricegroups.forEach(function (pr) {

                if (isContinue && pr.priceTypeId == 2 && pr.priceIncludingTax != 0 && !isNaN(pr.priceIncludingTax)) {
                    item.itemMaxPrice = pr;

                    isContinue = false;
                }
                if (isContinue && pr.priceTypeId == 4 && pr.priceIncludingTax != 0 && !isNaN(pr.priceIncludingTax)) {
                    item.itemMaxPrice = pr;

                    isContinue = false;
                }
                if (isContinue && pr.priceTypeId == 3 && pr.priceIncludingTax != 0 && !isNaN(pr.priceIncludingTax)) {
                    item.itemMaxPrice = pr;

                    isContinue = false;
                }
                if (isContinue && pr.priceTypeId == 5 && pr.priceIncludingTax != 0 && !isNaN(pr.priceIncludingTax)) {
                    item.itemMaxPrice = pr;

                    isContinue = false;
                }
            });
        } else {
            item.itemMaxPrice = null;
        }

        if (((item.itemMinPrice == undefined && item.itemMaxPrice == undefined) || (item.itemMinPrice == null && item.itemMaxPrice == null)) && item.itemPriceGroups.length > 0) {
            item.itemMinPrice = item.itemPriceGroups[0];
        }

        if (facility.item.id == item.id) {
            facility.minPrice = Object.assign({}, item.itemMinPrice);
            facility.maxPrice = Object.assign({},item.itemMaxPrice);
        }
    }
    */

    static isPriceConcessionAvailable(facilitySummaries: FacilitySummary[], selectedClass) {
        let isHasValidMinPriceConcession = true;
        let isHasValidMaxPriceConcession = true;

        let selectedPackageClass = environment.PackageClasses.find(a => a.Id == selectedClass);

        if (facilitySummaries.length > 0) {
            facilitySummaries.forEach(function (fc) {
                if (fc.item != undefined && fc.item.itemPriceGroups != null && fc.item.itemPriceGroups.length > 0) {
                    let min = fc.item.itemPriceGroups.find(pr => pr.concessionId == selectedPackageClass.PriceMinConsessionId);
                    if (min == undefined) {
                        isHasValidMinPriceConcession = false;
                    }
                    let max = fc.item.itemPriceGroups.find(pr => pr.concessionId == selectedPackageClass.PriceMaxConsessionId);
                    if (max == undefined) {
                        isHasValidMaxPriceConcession = false;
                    }
                } else {
                    isHasValidMinPriceConcession = false;
                    isHasValidMaxPriceConcession = false;
                }
                if (fc.upsellSummaries != undefined && fc.upsellSummaries.length > 0) {
                    fc.upsellSummaries.forEach(function (us) {
                        if (us.itemPriceGroups != null && us.itemPriceGroups.length > 0) {
                            let min = us.itemPriceGroups.find(pr => pr.concessionId == selectedPackageClass.PriceMinConsessionId);
                            if (min == undefined) {
                                isHasValidMinPriceConcession = false;
                            }
                            let max = us.itemPriceGroups.find(pr => pr.concessionId == selectedPackageClass.PriceMaxConsessionId);
                            if (max == undefined) {
                                isHasValidMaxPriceConcession = false;
                            }
                        } else {
                            isHasValidMinPriceConcession = false;
                            isHasValidMaxPriceConcession = false;
                        }
                    });
                }
            });
            return {
                isHasValidMinPriceConcession: isHasValidMinPriceConcession,
                isHasValidMaxPriceConcession: isHasValidMaxPriceConcession,
                PriceMinConsessionId: selectedPackageClass.PriceMinConsessionId,
                PriceMaxConsessionId: selectedPackageClass.PriceMaxConsessionId,
            };

            // return (isHasValidMinPriceConcession || isHasValidMaxPriceConcession);
        }
    }
    //--------------------------------------------------------------------------------------------------------------------------------------------------------
    //Configuration
    //--------------------------------------------------------------------------------------------------------------------------------------------------------
    static getFacilityConfigurations(facilitySummary: FacilitySummary, selectedItem: Item) {

        if (selectedItem.itemAssets != undefined) {
            let itemAssets = selectedItem.itemAssets.find(a => a.asset.id == facilitySummary.id);
            if (itemAssets != undefined && itemAssets.configuration != undefined) {
                let cns = facilitySummary.configurations.find(a => a.id == itemAssets.configuration.id);
                if (cns != undefined) {
                    let configarations = [];
                    configarations.push(cns);
                    return configarations;
                }
            }
            return facilitySummary.configurations;
        }

    }

    static getConfiguration(item: Item, facility: FacilitySummary) {
        let confg: Configuration; // find matching configuration for that booking item
        if (item.itemAssets != undefined) {
            let itmAsset = item.itemAssets.find(a => a.asset.id == facility.id);
            if (itmAsset) {
                if (itmAsset.configuration != undefined && itmAsset.configuration.id != null)
                    confg = facility.configurations.find(c => c.id == itmAsset.configuration.id);
            }
        }
        if (confg == undefined || confg.id == undefined || confg.id == 0) {
            //map internet default configuration
            confg = facility.configurations.find(c => c.internetDefault);
            if (confg == undefined) confg = facility.configurations.find(c => c.default); //map default configuration

        }
        if (confg != undefined && confg.id) {
            //facility.configuration = confg;
            return confg;
        }
    }

    static getAdapterSelectedConfiguration(facility: FacilitySummary) {
        if (facility.selectedConfiguration != undefined) {
            return facility.selectedConfiguration;
        }
        return facility.configuration;
    }

    //--------------------------------------------------------------------------------------------------------------------------------------------------------
    /*
    static TimeslotPopulate(unavailableSlots: Timeslot[], bumpIn?: number, bumpOut?: number, isShowSetupTimeInCalendar?: boolean): CalenderTimeslot[] {
        let timelineEntries: CalenderTimeslot[] = [];

        if (unavailableSlots) {
            unavailableSlots.forEach((slot) => {
                // if (!slot.preliminary) {
                let t = new CalenderTimeslot();
                let dtS = slot.startTime;

                if (bumpOut != undefined && bumpOut != 0 && dtS != undefined && isShowSetupTimeInCalendar) {
                    dtS.setMinutes(dtS.getMinutes() - bumpOut);
                }
                let dtE = slot.endTime;

                if (bumpIn != undefined && bumpIn != 0 && dtE != undefined && isShowSetupTimeInCalendar) {
                    dtE.setMinutes(dtE.getMinutes() + bumpIn);
                }
                let minS = dtS.getMinutes();
                let minE = dtE.getMinutes();
                if (minS == 59) {
                    dtS.setMinutes(0);
                    dtS.setSeconds(0);
                    dtS.setHours(dtS.getHours() + 1);
                }
                t.start = (dtS.getHours() + dtS.getMinutes() / 60);
                t.startTime = dtS;

                if (minE == 59) {
                    if (dtE.getHours() == 23) {
                        t.end = 24.0;
                        dtE.setMinutes(0);
                        dtE.setSeconds(0);
                        dtE.setHours(dtE.getHours() + 1);
                    } else {
                        t.end = (dtE.getHours() + dtE.getMinutes() / 60);
                    }
                } else {
                    t.end = (dtE.getHours() + dtE.getMinutes() / 60);
                }
                t.isBumpInBumOut = false;
                t.isSelectedTimeSlot = false;
                t.endTime = dtE;
                timelineEntries.push(t);
                // }
            });
        }



        return timelineEntries;
    }
    */
    //Populate Calender slot
    //--------------------------------------------------------------------------------------------------------------------------------------------------------
    static round59MinToHour(dtS: Date) {
        if (dtS.getMinutes() == 59) {
            dtS.setMinutes(0);
            dtS.setSeconds(0);
            dtS.setHours(dtS.getHours() + 1);
        }
    }
   static populateTimeslot(unavailableSlots: Timeslot[], date: Date, facility?: FacilitySummary): CalenderTimeslot[] {
      var appsetting = environment.AppSetting as AppSetting;
      var displayPreliminarySlotAsUnavailble = appsetting.BookingConfig.CheckAvailability.DisplayPreliminarySlotAsUnavailble;
      let timelineEntries: CalenderTimeslot[] = [];
      let openingTime: Date;
      let closingTime: Date;
      if (facility) {
        openingTime = facility.openingTime ? Utility.appendTimePart(date, Utility.convertISOToDate(facility.openingTime), true) : undefined;
        closingTime = facility.closingTime ? Utility.appendTimePart(date, Utility.convertISOToDate(facility.closingTime), true) : undefined;
      }
      if (unavailableSlots) {
        unavailableSlots.forEach((slot) => {
          if (!slot.preliminary || (slot.preliminary && displayPreliminarySlotAsUnavailble)) {
            // if (slot.startTime.getDate() < slot.endTime.getDate()) {
                // not the date the whole date need to be compared
            // if (slot.startTime < slot.endTime) {
            //   if (slot.endTime.getDate() != date.getDate()) {
            if (Utility.convertToDate(slot.endTime) != Utility.convertToDate(date)) { // check is both date string without time (yyyy-MM-dd)
                let setEndDate = new Date(date.getTime());
                if(closingTime){
                  setEndDate.setHours(closingTime.getHours());
                  setEndDate.setMinutes(closingTime.getMinutes());
                  setEndDate.setSeconds(closingTime.getSeconds());
                }else{
                  setEndDate.setHours(23);
                  setEndDate.setMinutes(59);
                  setEndDate.setSeconds(0);
                }
                slot.endTime = setEndDate;
              }

              if (Utility.convertToDate(slot.startTime) != Utility.convertToDate(date)) {
                let setStartDate = new Date(date.getTime());
                if(openingTime){
                  setStartDate.setHours(openingTime.getHours());
                  setStartDate.setMinutes(openingTime.getMinutes());
                  setStartDate.setSeconds(openingTime.getSeconds());
                }else{
                  setStartDate.setHours(0);
                  setStartDate.setMinutes(0);
                  setStartDate.setSeconds(0);
                }
                slot.startTime = setStartDate;
              }
            // }
            timelineEntries.push(FacilityAdaptor.populateCalenderTimeslot(slot.startTime, slot.endTime, slot.preliminary));
          }
        });
      }
      return timelineEntries;
    }
    static getTimeLinePosition(dtS: Date): number {
        if (dtS.getHours() == 23 && dtS.getMinutes() == 59) {
            return 24;
        }
        FacilityAdaptor.round59MinToHour(dtS);
        return (dtS.getHours() + dtS.getMinutes() / 60);
    }
    static populateCalenderTimeslot(start: Date, end: Date, preliminary?: boolean): CalenderTimeslot {
        let t = new CalenderTimeslot();
        let dtS = start;
        let dtE = end

        t.start = FacilityAdaptor.getTimeLinePosition(dtS);
        t.startTime = dtS;

        t.end = FacilityAdaptor.getTimeLinePosition(dtE);
        t.endTime = dtE;
        t.preliminary = preliminary;

        return t;
    }
    static populateAvailableTimeslots(facilities: FacilitySummary[], searchDate: Date, isMultipleDays: boolean = false) {

        facilities.forEach(f => {
            if (f.openingTime) {
                let dt = new Date(searchDate.getFullYear(), searchDate.getMonth(), searchDate.getDate());
                let dtE = Utility.appendTimePart(searchDate, Utility.convertISOToDate(f.openingTime), true);
                let ots = FacilityAdaptor.populateCalenderTimeslot(dt, dtE);
                ots.isUnAvailableDueToOperationalHours = true;
                f.calenderTimeslots.push(ots);
            }

            if (f.closingTime) {
                let dt = new Date(searchDate.getFullYear(), searchDate.getMonth(), searchDate.getDate(), 23, 59);
                let dtS = Utility.appendTimePart(searchDate, Utility.convertISOToDate(f.closingTime), true);
                let cts = FacilityAdaptor.populateCalenderTimeslot(dtS, dt);
                cts.isUnAvailableDueToOperationalHours = true;
                f.calenderTimeslots.push(cts);
            }

            let timeConsiderUpto = 0;
            let startTime = new Date(searchDate.getFullYear(), searchDate.getMonth(), searchDate.getDate());
            let timeNow = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;

            if (searchDate.getFullYear() == timeNow.getFullYear() && searchDate.getMonth() == timeNow.getMonth() && searchDate.getDate() == timeNow.getDate()) {
                timeConsiderUpto = (timeNow.getHours() + timeNow.getMinutes() / 60);
                //startTime = timeNow;
                startTime.setHours(timeNow.getHours());
                startTime.setMinutes(timeNow.getMinutes());
            }
            let sortArr;
            if (isMultipleDays) {
                // the search date shoulb be in between the slot start and end then its for the serchdate slot
              sortArr = f.calenderTimeslots.filter(slot => new Date(slot.startTime.toDateString()) <= new Date(searchDate.toDateString()) && new Date(slot.endTime.toDateString()) >= new Date(searchDate.toDateString()));
              sortArr = sortArr.sort((a, b) => (a.start < b.start) ? -1 : a.start > b.start ? 1 : 0);
            } else {
              sortArr = f.calenderTimeslots.sort((a, b) => a.start < b.start ? -1 : a.start > b.start ? 1 : 0);
            }
            sortArr.forEach(t => {
                if (!t.isSelectedTimeSlot) {
                    // if unavailable for multiple days have to skip and get the timeconsiderUpto from this slot
                    if (t.start > timeConsiderUpto) {
                        f.availableTimeslots.push({
                            start: timeConsiderUpto,
                            end: t.start,
                            startTime: startTime,
                            endTime: t.startTime,
                            availableStartTime: null,
                            availableEndTime: null,
                            isUnAvailableDueToOperationalHours: false,
                            isBumpInBumpOut: false,
                            isSelectedTimeSlot: false
                        });
                        timeConsiderUpto = t.end
                        startTime = t.endTime;

                    } else if (t.end > timeConsiderUpto) {
                        timeConsiderUpto = t.end;
                        startTime = t.endTime;
                    }
                }
            });
            if (timeConsiderUpto < 24 && ((timeConsiderUpto - 23) + 59 / 60) != timeConsiderUpto) {
                let endDt = new Date(searchDate.getFullYear(), searchDate.getMonth(), searchDate.getDate());
                endDt.setHours(23);
                endDt.setMinutes(59);
                endDt.setSeconds(0);
                f.availableTimeslots.push(
                    {
                        start: timeConsiderUpto,
                        startTime: startTime,
                        end: 24.0,
                        endTime: endDt,
                        availableStartTime: null,
                        availableEndTime: null,
                        isUnAvailableDueToOperationalHours: false,
                        isBumpInBumpOut: false,
                        isSelectedTimeSlot: false
                    });
            }
            f.calenderTimeslots = [].concat(f.calenderTimeslots);
            f.availableTimeslots = [].concat(f.availableTimeslots);

        });
    }

    static populateSelectedTimeslot(facilitySummary: FacilitySummary, selectedStart: Date, selectedEnd: Date, configuration: Configuration = null) {
        let config = configuration;
        if (config == undefined)
            config = facilitySummary.configuration;

        facilitySummary.calenderTimeslots = facilitySummary.calenderTimeslots.filter(x => !x.isSelectedTimeSlot);
        let slt = FacilityAdaptor.populateCalenderTimeslot(selectedStart, selectedEnd);
        slt.isSelectedTimeSlot = true;
        facilitySummary.calenderTimeslots.push(slt);

        if (config && config.setupTime && config.setupTime > 0) {
            let selectedBumpIn = new Date(selectedStart);
            selectedBumpIn.setMinutes(selectedBumpIn.getMinutes() - config.setupTime);
            if (selectedStart.getDate() != selectedBumpIn.getDate()) {
                selectedBumpIn = new Date(selectedStart);
                selectedBumpIn.setHours(0, 0, 0, 0);
            }
            let bmp = FacilityAdaptor.populateCalenderTimeslot(selectedBumpIn, selectedStart);
            bmp.isBumpInBumpOut = true;
            bmp.isSelectedTimeSlot = true;
            facilitySummary.calenderTimeslots.push(bmp);
        }
        if (config && config.teardownTime && config.teardownTime > 0) {
            let selectedBumpOut = new Date(selectedEnd);
            selectedBumpOut.setMinutes(selectedBumpOut.getMinutes() + config.teardownTime);
            if (selectedEnd.getDate() != selectedBumpOut.getDate()) {
                selectedBumpOut = new Date(selectedEnd);
                selectedBumpOut.setHours(23, 59, 0, 0);
            }
            let bmp = FacilityAdaptor.populateCalenderTimeslot(selectedEnd, selectedBumpOut);
            bmp.isBumpInBumpOut = true;
            bmp.isSelectedTimeSlot = true;
            facilitySummary.calenderTimeslots.push(bmp);

            
        }

        // let fcArr = [];
        // fcArr.push(facilitySummary);

        // fcArr.forEach(x=>{
        //     x.calendarTimeslotOriginal = [];
        //     x.calenderTimeslots.forEach(val => x.calendarTimeslotOriginal.push(Object.assign({}, val)));
        // });

        facilitySummary.calendarTimeslotOriginal = [];
        facilitySummary.calendarTimeslotOriginal = [...facilitySummary.calenderTimeslots]
    }

    //--------------------------------------------------------------------------------------------------------------------------------------------------------
    //Item
    //--------------------------------------------------------------------------------------------------------------------------------------------------------

    static changeFacilityItem(facilitySummary: FacilitySummary, item: Item, config: Configuration = null) {
        facilitySummary.item = item;

        if (config && config.id && config != null) {
            facilitySummary.configuration = facilitySummary.configurations.find(c => c.id == config.id);
        }
        //set default configuration
        if (!config || !config.id || config == null || !facilitySummary.configuration || facilitySummary.configuration == undefined) {
            facilitySummary.configuration = FacilityAdaptor.getConfiguration(item, facilitySummary);
        }

        if (item.itemMinPrice != undefined)
            facilitySummary.minPrice = Object.assign({}, item.itemMinPrice);
        else
            facilitySummary.minPrice = null;

        if (item.itemMaxPrice != undefined)
            facilitySummary.maxPrice = Object.assign({}, item.itemMaxPrice);
        else
            facilitySummary.maxPrice = null;
    }


    static getItemPrefix(val: string) {
        let prifix = "PrivateBooking";

        if (val == "cateringitem" || val == "productitem" || val == "serviceitem" || val == "wineitem") {
            prifix = "Booking";
        }
        return prifix;
    }

    static isQuestionAnswerd(question) {
        if ((question.type == "textQuestion" || question.type == "booleanQuestion" || question.type == "singleChoiceQuestion")
            && (question.answeredText !== undefined && question.answeredText != null && question.answeredText != "")) {
            return true;
        } else if (question.type == "multipleChoiceQuestion") {
            if (question.answerChoices && question.answerChoices.length > 0) {
                let answerd = false;
                question.answerChoices.forEach((answ, indx) => {
                    if ((question.quantityRequired == true && answ.Quantity) || ((!question.quantityRequired) && answ.isSelectedAnswer)) {
                        answerd = true
                    }
                });
                return answerd;
            } else {
                return false;
            }
        }
        else {
            return false;
        }

    }
    static getMatchedPackages(facilities, packages) {
        let matchingPackages = []
        if (facilities && facilities.length > 0)
            facilities.forEach(itm => {
                packages.forEach(p => {
                    let index = matchingPackages.findIndex(selectedP => selectedP.id == p.id);
                  if (itm.item && itm.item.salesCategories && itm.item.salesCategories.length) {
                    if (p.salesCategories.findIndex(ps => itm.item.salesCategories.findIndex(i => i.id == ps) >= 0) >= 0) {
                      if (index <= 0)
                        matchingPackages.push(p);
                    } else if (index > 0) {
                      matchingPackages.splice(index, 1);
                    }
                  }
                })

            });
        return matchingPackages;
    }

    static getItemQuantity(_item: Item,attendees){
        let _minPriceTypeId = _item.itemMinPrice.priceTypeId;
        let _maxPriceTypeId = _item.itemMaxPrice.priceTypeId;

        let _itemsPerCover = _item.CoversPerItem;

        let _qty = 1;

        // both min max is covers
        if (_minPriceTypeId == _maxPriceTypeId && _minPriceTypeId == PriceType.Covers) {
            //if items per cover avilble devide else all attendees
            _qty = _itemsPerCover ? (attendees / _itemsPerCover) : attendees;
        }

        //multiple with covers and session covers given priority
        // else if(_minPriceTypeId != _maxPriceTypeId && (_minPriceTypeId == PriceType.Session || _maxPriceTypeId == PriceType.Session) 
        //     && (_minPriceTypeId == PriceType.Covers || _maxPriceTypeId == PriceType.Covers) ){
        //         _qty = _itemsPerCover >0 ? attendees * _itemsPerCover : attendees;
        // }

        // Priority order of Price Type
        // 1 session
        // 2 timebased price types(hourly, daily halfday)=> these are considered as same type
        // 3 cover

        // all other scenarios covers have low priority
        else{
            _qty = 1;
        }

        return _qty;
    }
}

