import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from "rxjs";
import { HttpResponse } from '@angular/common/http';
import { map } from "rxjs/operators";

import { Deserializer, Serializer } from 'json-api-format';
import { BaseService } from '../services/base/base.service';

import { environment } from '../../environments/environment'

//Models
import { Login } from '../models/login/login';
import { Contact } from '../models/client/contact';
import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';
import { ClientRegistration } from 'src/modules/models/settings/casual-portal/client-registration-config';

import { QASAddress } from '../models/client/qas-address';
import { Addresses, AddressDetail } from '../models/client/Address';
import { ReferenceData } from '../models/reference-data';
import { Address, Client } from '../models/client/client';
import { CustomerAdditionalDetail } from 'src/modules/models/client/customer-additional-detail';
import { IndividualClient } from '../models/client/Individual-client';;
import { CorporateClient } from '../models/client/corporate-client';;
import { ChangePassword } from 'src/modules/models/client/change-password';
import { CommunicationType } from '../models/client/communication-type';
import { Title } from '../models/client/title';
import { ClientEmailModel } from 'src/modules/models/client/client-email-model';
import { Attendee } from '../models/booking/Attendee';
import { Country } from '../models/public-web/customer/country';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private _baseService: BaseService,

  ) {

  }

  countries$ = new BehaviorSubject<Country[]>([]);

  setCountryValues() {
    this.GetCountries().subscribe((values) => {
      if (values) {
        this.countries$.next(values);
      }
    })
  }

  getCountryValues() {
    return this.countries$.asObservable();
  }

  /****| GET |****/

  //getClientTiltles(): any {

  //    return this._baseService.InternalWebAPIGET("api/customer/invoke?id=108");

  //}
  validateGuestEmail(email): Observable<any> {
    return this._baseService.Internal_WebAPI_GET("api/Customer/ValidateGuestMail?email=" + email + "&disableSpinner").pipe(
      map((response: any) => {
        return response.body.data;
      })
    );
  }
  GetCountries(): Observable<Country[]> {
    return this._baseService.Internal_WebAPI_GET("api/Customer/Countries").pipe(
      map((response: any) => {
        return response.body;
      })
    );
  }
  getClientAttendees(clientId): Observable<Attendee[]> {
    return this._baseService.Internal_WebAPI_GET("api/Customer/client/" + clientId + "/attendees").pipe(
      map((response: any) => {
        return response.body.data;
      })
    );
  }
  getClientTiltles(): Observable<Title[]> {

    return this._baseService.InternalWebAPIGET("api/customer/invoke?id=108").pipe(
      map((response: HttpResponse<any>) => {
        var JsonData = JSON.parse(response["data"]);
        var json = new Deserializer().deserialize(JsonData);


        var lst: Title[] = [];
        json.forEach((c, i) => {
          lst.push((c as Title));
        });
        return lst;
      }));

  }


  CommunicationTypes(): Observable<CommunicationType[]> {

    return this._baseService.Internal_WebAPI_GET("api/customer/invoke?id=107&" + "fields=Name,Format").pipe(
      map((response: HttpResponse<any>) => {
        var JsonData = JSON.parse(response.body["data"]);
        var json = new Deserializer().deserialize(JsonData);

        return json;
      }));
  }


  getClientRegistrationConfig(): Observable<AppSetting> {
    return this._baseService.InternalWebAPIGET("api/system/app-settings");
  }

  SendCustomerRegisterConfirmation(email: string, customerId: string): Observable<any> {
    return this._baseService.InternalWebAPIGET("api/customer/SendCustomerRegisterConfirmation?email=" + email + "&contactid=" + customerId).pipe(
      map((response) => {
        return response;
      })
    );
  }

  AdditionalDetail(customerAdditionalDetail: CustomerAdditionalDetail): Observable<any> {
    return this._baseService.InternalWebAPIPOST("api/Customer/additional-detail", customerAdditionalDetail).pipe(
      map((response) => {
        return response;
      })
    );
  }
  GetindividualClient(ClientID: string) {
    return this._baseService.Internal_WebAPI_GET("api/customer/invoke?id=112&" + "clientId=" + ClientID + "&include=" + "client,mailingAddress,communicationMethods,invoiceAddress").pipe(map((response: HttpResponse<any>) => {
      var JsonData = JSON.parse(response.body["data"]);
      var json = (new Deserializer().deserialize(JsonData));
      return json;
    }));


  }

  // GetCoporateClient(ClientID: string) {
  //     return this._baseService.WebAPIGet("api/V4.1/customers/contacts/" + ClientID + "?include=client,mailingAddress,communicationMethods,invoiceAddress,ClientCommunicationMethods").pipe(map(response => {
  //         var json = (new Deserializer().deserialize(response));
  //         console.log("GetCoporateClient() --> Starting.....");
  //         console.log(json);
  //
  //         console.log("GetCoporateClient() --> Ended.....");
  //         return json;
  //     }));


  //}

  SelectContact(contactId?: string): Observable<Contact> {

    return this._baseService.Internal_WebAPI_GET("api/customer/invoke?id=109" + (contactId? "&contactId=" + contactId :'') + "&include=" + "client,mailingAddress,communicationMethods,invoiceAddress,ClientCommunicationMethods").pipe(map((response: HttpResponse<any>) => {


      var JsonData = JSON.parse(response.body["data"]);
      var json = new Deserializer().deserialize(JsonData);

      var cnt = new Contact();
      cnt.firstName = json.firstName;
      cnt.lastName = json.lastName;
      cnt.userName = json.userName;
      cnt.id = json.id;
      cnt.type = json.type;
      cnt.ref = json.ref;
      cnt.title = json.title;
      cnt.titleId = json.titleId;
      cnt.communicationMethods = json.communicationMethods;
      cnt.invoiceAddress = json.invoiceAddress;
      cnt.mailingAddress = json.mailingAddress;
      cnt.deliveryAddress = json.deliveryAddress;
      cnt.active = json.active;
      cnt.defaultVenue = json.defaultVenue;
      cnt.accountManager = json.accountManager;
      cnt.isPrimaryContact = json.isPrimaryContact;
      if (json.communicationMethods != undefined && json.communicationMethods.length > 0) {
        json.communicationMethods.forEach(function (communicationMethod) {
          if (communicationMethod.communicationType.toLowerCase().indexOf("email") > -1) {
            cnt.invoiceEmailAddress = communicationMethod.value;
          }
        });
      }

      if (json.profilePicture != undefined)
        //cnt.profilePicture = environment.WebApiUrl + json.profilePicture;
        cnt.profilePicture = json.profilePicture;
      if (json.client != undefined) {



        if (json.client.type != undefined) {
          if (json.client.type == "corporateClient") {
            cnt.client = new CorporateClient();
            (cnt.client as CorporateClient).companyRegistrationNo = json.client.companyRegistrationNo;
            (cnt.client as CorporateClient).companyName = json.client.companyName;
            (cnt.client as CorporateClient).legalName = json.client.legalName;
            (cnt.client as CorporateClient).bookingTypes = json.client.bookingTypes;
          }
          else {
            cnt.client = new IndividualClient();
            (cnt.client as IndividualClient).firstName = json.client.firstName;
            (cnt.client as IndividualClient).lastName = json.client.lastName;
            (cnt.client as IndividualClient).bookingTypes = json.client.bookingTypes;
          }
          cnt.client.type = json.client.type;
          cnt.client.communicationMethods = json.client.communicationMethods;
          cnt.client.accountManager = json.client.accountManager;
          cnt.client.defaultVenue = json.client.defaultVenue;
          cnt.client.clientCategoryId = json.client.clientCategoryId;
          cnt.client.clientTypeId = json.client.clientTypeId;
          cnt.client.address = json.client.address;
          cnt.client.id = json.client.id;
          cnt.client.clientRef = json.client.clientRef;
          cnt.client.profilePicture = json.client.profilePicture;
          cnt.client.paymentTerm = json.client.paymentTerm;

        }

        if (json.client.clientName != undefined) {
          cnt.client.clientName = json.client.clientName;
        }
      }
      return cnt;
    }));
  }

  SelectClientDetails(): Observable<any> {
    //return this._baseService.WebAPIGet("api/V4.1/customers/clients/" + clientId + "?include=documents,notes").pipe(map(response => {
    return this._baseService.Internal_WebAPI_GET("api/customer/invoke?id=110&include=" + "documents,notes").pipe(map((response: HttpResponse<any>) => {
      var JsonData = JSON.parse(response.body["data"]);
      var json = (new Deserializer().deserialize(JsonData));
      return json;
    }));
  }


  SelectInternalUser(ContactId): Observable<any> {
    return this._baseService.InternalWebAPIGET("api/customer/internaluser?Id=" + ContactId).pipe(
      map((response) => {
        var JsonData = JSON.parse(response["data"]);
        var json = (new Deserializer().deserialize(JsonData));
        return json;
      })
    );
  }


  GetPasswordPolicies(contactId: string): Observable<any> {
    return this._baseService.InternalWebAPIGET("api/system/GetPasswordPolicies?contactId=" + contactId).pipe(
      map((response) => {
        return response;
      })
    );
  }

  GetAnonymousPasswordPolicies(): Observable<any> {
    return this._baseService.InternalWebAPIGET("api/system/GetAnonymousPasswordPolicies").pipe(
      map((response) => {
        return response;
      })
    );
  }

  GetSession(): Observable<Contact> {
    return this._baseService.InternalWebAPIGET("api/customer/session").pipe(
      map((response:any) => {
        if (response && response.data) {
          var JsonData = JSON.parse(response.data);
          var json = new Deserializer().deserialize(JsonData);
          let cnt = this.populateContact(json);
          return cnt;
        }
      })
    );
  }



  /****| POST |  this._store.dispatch(new StoreAssets(this.SelectedList)); ****/

  DeleteSession(): Observable<any> {
    let url = `api/customer/session`;
    return this._baseService.Internal_WebAPI_DELETE(url).pipe(map((b: any) => {
      environment.LoggedInUser = '';
      return true;
    }));
  }

  clientLogin(postdata: Login): Observable<Contact> {
    // this.clientLoginOtherApp(postdata)

    let loginJson = JSON.stringify(postdata);
    return this._baseService.Internal_WebAPI_POST("api/customer/login?include=client,CommunicationMethods", loginJson).pipe(
      map((response: any) => {
        var JsonData = JSON.parse(response.body.data);
        var json = new Deserializer().deserialize(JsonData);
        let cnt = this.populateContact(json);
        return cnt;


      })
    )
  }

  populateContact(json) {
    let cnt = new Contact();

    cnt.ref = json.ref;
    cnt.firstName = json.firstName;
    cnt.lastName = json.lastName;
    cnt.name = json.firstName + json.lastName;
    cnt.communicationMethods = json.communicationMethods;
    cnt.address = this.getfullAddress(json.invoiceAddress);

    if (json.communicationMethods != undefined && json.communicationMethods.length > 0) {
      let _cm = json.communicationMethods;

      //get customer mobile number
      let mobileCM = _cm.find(function (communicationMethod) {
        return communicationMethod.communicationType.toLowerCase().includes("mobile") && communicationMethod.value != "" && communicationMethod.value != undefined;
      });
      if (mobileCM) {
        cnt.tel = mobileCM.value;
      } else {

        let officeCM = _cm.find(function (communicationMethod) {
          return communicationMethod.communicationType.toLowerCase().includes("office phone") && communicationMethod.value != "" && communicationMethod.value != undefined;
        });

        if (officeCM) {
          cnt.tel = officeCM.value;
        } else {

          let HomeCM = _cm.find(function (communicationMethod) {
            return communicationMethod.communicationType.toLowerCase().includes("home phone") && communicationMethod.value != "" && communicationMethod.value != undefined;
          });
          if (HomeCM) {
            cnt.tel = HomeCM.value;
          }

        }
      }


      //get customer email
      let personalEmail = _cm.find(function (communicationMethod) {
        return communicationMethod.communicationType.toLowerCase().includes("personal email") && communicationMethod.value != "" && communicationMethod.value != undefined;
      });
      if (personalEmail) {
        cnt.invoiceEmailAddress = personalEmail.value;
        cnt.email = personalEmail.value;
      } else {
        let officeEmail = _cm.find(function (communicationMethod) {
          return communicationMethod.communicationType.toLowerCase().includes("office email") && communicationMethod.value != "" && communicationMethod.value != undefined;
        });
        if (officeEmail) {
          cnt.invoiceEmailAddress = officeEmail.value;
          cnt.email = officeEmail.value;
        } else {
          let email = _cm.find(function (communicationMethod) {
            return communicationMethod.communicationType.toLowerCase().includes("email") && communicationMethod.value != "" && communicationMethod.value != undefined;
          });
          if (email) {
            cnt.invoiceEmailAddress = email.value;
            cnt.email = email.value;
          }
        }
      }
    }
    cnt.userName = json.userName == undefined ? cnt.invoiceEmailAddress : json.userName;

    cnt.id = json.id;
    cnt.type = json.type;
    //if (json.profilePicture != undefined)
    //    cnt.profilePicture = environment.WebApiUrl + json.profilePicture;
    if (json.client != undefined) {
      cnt.client = new Client();
      cnt.client.id = json.client.id;
      cnt.client.clientRef = json.client.clientRef;
      cnt.client.clientType = json.client.clientType;
      cnt.client.clientTypeId = json.client.clientTypeId;
      cnt.client.clientCategoryId = json.client.clientCategoryId;
      if (json.client.profilePicture != undefined)
        cnt.client.profilePicture = json.client.profilePicture;
      if (json.client.type != undefined) {
        cnt.client.type = json.client.type;
      }
      if (json.client.priceConcession != undefined) {
        cnt.client.priceConcessionId = json.client.priceConcession.id;
      }

      if (json.client.paymentTerm != undefined) {
        cnt.client.paymentTerm = json.client.paymentTerm;
      }


      if (json.client.clientName != undefined) {
        cnt.client.clientName = json.client.clientName;
      }
    }

    cnt.bookingTypeList = json.bookingTypes ? json.bookingTypes : json.client.bookingTypes;
    cnt.clientVenueIds = json.client.clientVenues;

    if (json.mailingAddress) {
      cnt.mailingAddress = new Address();
      cnt.mailingAddress.id = json.mailingAddress.id;
      cnt.mailingAddress.address1 = json.mailingAddress.address1;
      cnt.mailingAddress.address2 = json.mailingAddress.address2;
      cnt.mailingAddress.address3 = json.mailingAddress.address3;
      cnt.mailingAddress.city = json.mailingAddress.city;
      cnt.mailingAddress.countryID = json.mailingAddress.countryID;
      cnt.mailingAddress.country = json.mailingAddress.country;
      cnt.mailingAddress.stateCode = json.mailingAddress.stateCode;
      cnt.mailingAddress.postCode = json.mailingAddress.postCode;
      cnt.mailingAddress.state = json.mailingAddress.county;
    }
    if (json.deliveryAddress) {
      cnt.deliveryAddress = new Address();
      cnt.deliveryAddress.id = json.deliveryAddress.id;
      cnt.deliveryAddress.address1 = json.deliveryAddress.address1;
      cnt.deliveryAddress.address2 = json.deliveryAddress.address2;
      cnt.deliveryAddress.address3 = json.deliveryAddress.address3;
      cnt.deliveryAddress.city = json.deliveryAddress.city;
      cnt.deliveryAddress.countryID = json.deliveryAddress.countryID;
      cnt.deliveryAddress.country = json.deliveryAddress.country;
      cnt.deliveryAddress.stateCode = json.deliveryAddress.stateCode;
      cnt.deliveryAddress.postCode = json.deliveryAddress.postCode;
      cnt.deliveryAddress.state = json.deliveryAddress.county;
    }
    if (json.preferredLanguage) {
      cnt.preferredLanguageID = json.preferredLanguage.id;
    }
    cnt.profilePicture =  json.profilePicture ? json.profilePicture : "";

    //map type
    return cnt;
  }
  getfullAddress(address: Address) {
    var adrs;
    if (address.address1 != null) {
      adrs = address.address1 + ",";
    }
    if (address.address2 != null) {
      adrs += address.address2 + ",";
    }
    if (address.city != null) {
      adrs += address.city + ",";
    }
    if (address.postCode != null) {
      adrs += address.postCode + ",";
    }
    if (address.county != null) {
      adrs += address.county;
    }
    return adrs as any;

  }

  clientLoginOtherApp(postdata: Login) {
    let loginJson = JSON.stringify(postdata);
    let urls = [];

    let _casualPortalUrl = environment.CasualBookingPortalUrl.toLowerCase();
    let _regularPortalUrl = environment.RegularBookingPortalUrl.toLowerCase().replace('booking/regular/', '').replace('booking/regular', '');
    let _wastePortalUrl = environment.WasteBookingPortalUrl.toLowerCase().replace('booking/wastemanagement/', '').replace('booking/wastemanagement', '');
    let _paymentPortal = environment.PaymentPortalUrl.toLowerCase();

    if (window.location.href.toLowerCase().startsWith(_casualPortalUrl)) {
      if (_regularPortalUrl != _casualPortalUrl)
        urls.push(_regularPortalUrl);

      if (_wastePortalUrl != _casualPortalUrl && _wastePortalUrl != _regularPortalUrl)
        urls.push(_wastePortalUrl)

    } else if (window.location.href.toLowerCase().startsWith(environment.RegularBookingPortalUrl.toLowerCase())) {
      if (_casualPortalUrl != _regularPortalUrl)
        urls.push(_casualPortalUrl);
      if (_wastePortalUrl != _regularPortalUrl && _wastePortalUrl != _casualPortalUrl)
        urls.push(_wastePortalUrl)
    } else if (window.location.href.toLowerCase().startsWith(environment.WasteBookingPortalUrl.toLowerCase())) {
      if (_casualPortalUrl != _wastePortalUrl)
        urls.push(_casualPortalUrl);
      if (_regularPortalUrl != _wastePortalUrl && _regularPortalUrl != _casualPortalUrl)
        urls.push(_regularPortalUrl);
    }

    urls.forEach(u => {
      if (u && u.trim().length>0) {
        this._baseService.Internal_WebAPI_POST_FullUrl(u + "api/customer/login?include=client,CommunicationMethods", loginJson).pipe(
          map((response: any) => {
            // console.log(response);
          })
        ).subscribe(x => { });
      }
    });

  }

  clientLoginOtherInternalPortal(id: number) {
       let urls = [];
       let _casualPortalInternalUrl = environment.CasualPortalInternalUrl.toLowerCase().replace('booking/casual/', '').replace('booking/casual', '');
       let _regularPortalInternalUrl = environment.RegularPortalInternalUrl.toLowerCase().replace('booking/regular/', '').replace('booking/regular', '');
       let _casualPortalUrl = environment.CasualBookingPortalUrl.toLowerCase().replace('booking/casual/', '').replace('booking/casual', '');
       let _regularPortalUrl = environment.RegularBookingPortalUrl.toLowerCase().replace('booking/regular/', '').replace('booking/regular', '');
       if (!window.location.href.toLowerCase().startsWith(_casualPortalInternalUrl)) {
           if (urls.length == 0 || !(urls ?.includes(_casualPortalUrl)))
               urls.push(_casualPortalUrl);
       } else if (!window.location.href.toLowerCase().startsWith(_regularPortalInternalUrl)) {
           if (urls.length == 0 || !(urls ?.includes(_regularPortalUrl)))
               urls.push(_regularPortalUrl);
       }
       urls.forEach(u => {
           this._baseService.InternalWebAPIGET_FullUrl(u + "/api/customer/clientLoginOtherInternalPortal?Id=" + id).pipe(
               map((response: any) => {
                   console.log(response, ",curent=" + window.location.href, ",u=" + u);
               })
           ).subscribe(x => { });
       });
   }  

  clientSave(postdata: any): Observable<CorporateClient> {    //corporate client save -> calling from Corporate Client Setup
    //let endPoint = `api/V4.1/customers/clients?include=contacts`;
    var json = new Serializer().serialize(postdata)
    // return this._baseService.WebAPIPOST(endPoint, json).pipe(map(response => {
    //     var corporateClient = new CorporateClient();
    //     corporateClient = (new Deserializer().deserialize(response));
    //     return corporateClient;
    // }));
    return this._baseService.Internal_WebAPI_POST("api/customer/clients?include=contacts", json).pipe(
      map((response: any) => {
        var corporateClient = new CorporateClient();
        if (response.body.isError == true) {
          throw new Error(JSON.stringify(response.body));
        }
        corporateClient = (new Deserializer().deserialize(response.body.data));
        return corporateClient;
      }));
  }

  individualClientSave(postdata: IndividualClient): Observable<IndividualClient> { //individual client save -> calling from individual Client Setup
    if (postdata.titleId == 0) {
      postdata.titleId = undefined;
      //postdata.title = undefined;
    }
    var json = new Serializer().serialize(postdata)
    // return this._baseService.WebAPIPOST("api/V4.1/customers/clients?include=Contacts", json).pipe(map(response => {
    //     var corporateClient = new Contact();
    //     corporateClient = (new Deserializer().deserialize(response));
    //     return corporateClient;
    // }));
    return this._baseService.Internal_WebAPI_POST("api/customer/clients?include=contacts", json).pipe(
      map((response: any) => {
        var corporateClient = new Contact();
        if (response.body.isError == true) {
          throw new Error(JSON.stringify(response.body));
        }
        else {
          corporateClient = (new Deserializer().deserialize(response.body.data));
          return corporateClient;
        }
      }));
  }

  // getClientDetails(clientID: string): Observable<Client> {
  //   return this._baseService.InternalWebAPIGET("api/customer/client/" + clientID).pipe(map((response: any) => {
  //     return response.data
  //   }))
  // }

  getQASAddress(postData: QASAddress): Observable<Addresses> {
    return this._baseService.InternalWebAPIPOST("api/customer/address-list", postData);
  }

  ChangePassword(changepassword: ChangePassword): Observable<any> {
    return this._baseService.InternalWebAPIPOST("api/system/ChangePassword", changepassword).pipe(
      map((response) => {
        return response;
      })
    );
  }

  sendForgetPasswordEmail(email: ClientEmailModel): Observable<any> {
    return this._baseService.InternalWebAPIPOST("api/customer/request-new-password", email);
  }


  /****| PATCH |****/


  ClientPatch(postdata: any): Observable<any> {  //currently using Direct patch from customer service
    var json = new Serializer().serialize(postdata)
    if (!postdata.id) {
      postdata.id = postdata.tid;

    }
    return this._baseService.Internal_WebAPI_PATCH("api/customer/clientpatch?" + "&id=" + postdata.id, json);
  }

  patchPostContact(contact: any) { //* ClientPatch() get success (200) --> Updating Contact Patch  using MVC */
    var json = new Serializer().serialize(contact)
    return this._baseService.Internal_WebAPI_PATCH("api/customer/customer-patch/", json);
  }




  /****| DELETE |****/


  CommunicationMethodDelete(id: string, contactid: string): Observable<any> {
    //let endPoint = `api/V4.1/customers/contacts/${contactid}/Communication-methods/${id}`;
    //return this._baseService.WebAPIDELETEResponse(endPoint);
    //return this._baseService.WebAPIDELETEResponse("api/customer/deletemethod?" + "&clientId=" + id + "&contactId=" + contactid);
    return this._baseService.InternalWebAPIPOST("api/customer/deletemethod?" + "&clientId=" + id + "&contactId=" + contactid, true).pipe(
      map((response) => {
        return response;
      })
    );

  }



  communication_method_delete(id: string, clientId: string): Observable<any> {

    return this._baseService.InternalWebAPIPOST("api/customer/communication-method-detele?" + "&clientId=" + clientId + "&contactId=" + id, true).pipe(
      map((response) => {
        return response;
      })
    );

  }





  // temp  need to remove

  UpdateClient(Customer: any) {


    let result = this._baseService.Internal_WebAPI_POST("api/customer/customer-update", Customer);

    return result;
  }

  GetContactDetails() {

    var json = '';
    // return this._baseService.Internal_WebAPI_POST("api/common/invoke?id=14&clientId=" + clientId, json).pipe(
    return this._baseService.Internal_WebAPI_POST("api/common/invoke?id=14", json).pipe(
      map((response: any) => {
        if (response.body.data) {
          var JsonData = JSON.parse(response.body.data);
          var result = new Deserializer().deserialize(JsonData);
        }
        return result;
      })
    )

  }

  GetBookingTypes(): any {

    return this._baseService.Internal_WebAPI_GET("api/regular-bookings/bookingTypes").pipe(
      map((response: any) => {
        if (response.body) {
          return response.body;
        }
      })
    )
  }
}
