<mat-expansion-panel [expanded]="true" [disabled]="true">
  <mat-expansion-panel-header  class="mat-header">
    <opt-question-header [dates]="dates" [isMandatory]="bookingQues?.question?.mandatory"
      [questionNumber]="questionNumber" [questionText]="bookingQues?.question?.questionText"
      (popselectedDates)="RecveselectedDates($event)"></opt-question-header>
  </mat-expansion-panel-header>
  <div class="mmc_flex--container fd-col">
    <!-- <mat-form-field class="" *ngIf="!isAdvanced">
        <textarea id="{{bookingQues?.id}}" matInput placeholder="" (input)="inputChanged($event)"
          [(ngModel)]="bookingQues.answer"></textarea>
      </mat-form-field> -->
    <div class="mmc_flex__item m1 mr-3 p25" style="height: 45px;" *ngIf="isAnswerDate || isAnswerDateAndTime">
      <mat-form-field appearance="outline" class="pw-txt-s3" style="height: 40px;">
        <mat-label style="margin-top: -15px;">{{"QUESTIONPOPUP.Date" | optimotranslate}}</mat-label>
        <input matInput style="position: absolute;top: 0px;" [matDatepicker]="picker" [value]="bookingQues.date"
          (focus)="picker.open()" name="date" (dateChange)="dateChanged($event,bookingQues.date)" readonly>
        <!-- <mat-datepicker-toggle matSuffix [for]="picker" style="position: relative;
        right: 0px;
        top: -10px;
        font-size: 16px;" ></mat-datepicker-toggle> -->
        <mat-icon (click)="picker.open()" style="position: relative;right: 3px;top: -20px;" matSuffix>event</mat-icon>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="mmc_flex__item m1 mr-3 p25" style="height: 45px;" *ngIf="isAnswerTime || isAnswerDateAndTime">

      <mat-form-field appearance="outline" class="pw-txt-s3 time-picker"  style="height: 40px;" #hoverStartMenuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="startmenu">
        <mat-label style="margin-top: -15px;">{{"QUESTIONPOPUP.Time" | optimotranslate}}</mat-label>
        <input matInput placeholder="{{'QUESTIONPOPUP.Time' | optimotranslate | uppercase}}" style="position: absolute;top: 0px;"
          value="{{bookingQues.tempTime | dateFormat:'time' }}" readonly>
        <mat-icon style="position: relative;right: 3px;top: -20px;" matSuffix>access_time</mat-icon>
      </mat-form-field>
      <timepicker>
      </timepicker>
      <mat-menu #startmenu="matMenu" class="time-popup" [overlapTrigger]="false">
        <div class="mmc_flex--container" (mouseleave)="closeTimeMenu()" (click)="$event.stopPropagation();"
          (keydown.tab)="$event.stopPropagation();">
          <timepicker [(ngModel)]="bookingQues.tempTime " (ngModelChange)="timeChanged($event)">
          </timepicker>
          <!-- <div class="mmc_flex__item">
            <div class="mmc_flex--container">
              <div class="mmc_flex__item">

            </div>
          </div> -->
        </div>
      </mat-menu>
    </div>

    <div class="mmc_flex__item m1 mr-3"
      [ngClass]=" isAnswerTime && isAnswerDate  ? 'p50' : !isAnswerTime && isAnswerDate ? 'p75' : 'p75'">
      <button mat-stroked-button (click)="clearDate($event)" style="margin-left: 5px;
      margin-top: 2px;
      height: 34px;">{{"QUESTIONPOPUP.Clear" | optimotranslate}}</button>
    </div>

  </div>
</mat-expansion-panel>
<div class="wp_flex--container flex-column question-list " class="mt-4 conditional-question">
  <div class="wp_flex__item--inherit wp_flex__item--vcenter">
    <opt-question class="w-100" [isMandatory]="false" [questionNumber]="1"
      [questions]="bookingQues.conditionalBookingQuestions" [answeredQuestionIds]="answeredQuestionIds"
      (questionAnswerOutput)="conditionalQuestionAnswerChange($event)"></opt-question>
  </div>
</div>
