<main role="main">
<div class="body-wrapper booking-cart" #top>
    <opt-sub-header></opt-sub-header>
    <div class="container">
        <div class="booking-snipper">
            <div class="snipper booking-cart-header">
                <mat-horizontal-stepper [linear]="isLinear" #stepper [selectedIndex]="selectedIndex">
                    <mat-step [stepControl]="firstFormGroup" [completed]="true">
                        <form [formGroup]="firstFormGroup">
                            <ng-template matStepLabel>
                                <div class="text-center">
                                    <div class="activity-title">Choose Your Venues</div>
                                    <span class="number">1</span>
                                </div>
                            </ng-template>
                        </form>
                    </mat-step>

                    <mat-step [stepControl]="secondFormGroup" [completed]="((cart$|async)?.packageFilter != null && (cart$|async)?.packageFilter.EventName !='undefined' ) || ((cart$|async)?.booking.bookingReference !='undefined' )?true:false">
                        <form [formGroup]="secondFormGroup">
                            <ng-template matStepLabel>
                                <div class="text-center">
                                    <div class="activity-title">Booking Details</div>
                                    <span class="number">2</span>
                                </div>
                            </ng-template>
                            <div *ngIf="displayPriceChange" class="row">
                                <div class="col-md-12 alert success--btn pw-text-medium ng-star-inserted">Price Concession Applied</div>                              
                            </div>
                            <opt-mmc-cart (navigated)="setTabIndex($event)" [showQuestionPop]="showQuestionPop"></opt-mmc-cart>
                        </form>
                    </mat-step>

                    <mat-step [completed]="(cart$|async)?.contact?.id ?true:false">
                        <ng-template matStepLabel>
                            <div class="text-center">
                                <div class="activity-title">Customer Details</div>
                                <span class="number">3</span>
                            </div>
                        </ng-template>

                        <div *ngIf="displayLogin">
                            <app-login [isFromBooking]="1" (LoggedIn)="setTabIndex($event)" (LoadSignUp)="loadSignUp($event)"></app-login>
                        </div>
                        <div *ngIf="!displayLogin && !displayPriceChange" class="bookingthrounghReg">
                            <mat-horizontal-stepper>
                                <mat-step>
                                    <div class="regSec">
                                        <div class="regSec__title">
                                            <div class="regSec__title--main main wp-mb-10">{{'SIGNUP.Join Now'| optimotranslate}}</div>
                                            <div class="regSec__title--sub sub">{{'SIGNUP.Please choose your customer type to proceed with the registration.'| optimotranslate}}</div>
                                        </div>

                                        <div class="regSec__body">
                                            <div class="regSec__body--customer customer">
                                                <div class="name">{{'SIGNUP.Are you a Corporate customer?'| optimotranslate}}<span></span></div>
                                                <button class="regSec__body--customer-cor" mat-button matStepperNext type="button" tabindex="0" (click)="openTab(0)" (keydown.enter)="openTab(0)" >
                                                    <div class="mmc_flex--container rectangle">
                                                        <div class="mmc_flex__item--vcenter outer-wrapper">
                                                            <div class="inner-wrapper">
                                                                <span class="tab-img mmc icon-users"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div>
                                            <div class="regSec__body--customer customer">
                                                <div class="name">{{'SIGNUP.Are you an Individual customer?'| optimotranslate}}<span></span></div>
                                                <button class="regSec__body--customer-indi" mat-button matStepperNext type="button" tabindex="0" (click)="openTab(1)" (keydown.enter)="openTab(1)">
                                                    <div class="mmc_flex--container rectangle">
                                                        <div class="mmc_flex__item--vcenter outer-wrapper">
                                                            <div class="inner-wrapper">
                                                                <span class="tab-img mmc icon-user-silhouette"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </mat-step>

                                <mat-step>
                                    <mat-tab-group [selectedIndex]="selectedCusIndex" (selectedTabChange)="tabChange($event)">
                                        <mat-tab>
                                            <ng-template mat-tab-label>
                                                <span class="tab-text">{{'SIGNUP.Corporate Customer'| optimotranslate}}</span>
                                                <span class="tab-img mmc icon-users"></span>
                                            </ng-template>
                                            <opt-corporate (navigated)="setTabIndex($event)" [isFromBooking]="1" *ngIf="isCoparateTab" [tabIndex]="tabIndex"></opt-corporate>

                                        </mat-tab>
                                        <mat-tab>
                                            <ng-template mat-tab-label>
                                                <span class="tab-text">{{'SIGNUP.Individual Customer'| optimotranslate}}</span>
                                                <span class="tab-img mmc icon-user-silhouette"></span>
                                            </ng-template>
                                            <opt-individual (navigated)="setTabIndex($event)" [isFromBooking]="1" *ngIf="isIndividualTab" [tabIndex]="tabIndex"></opt-individual>
                                        </mat-tab>
                                    </mat-tab-group>
                                </mat-step>
                            </mat-horizontal-stepper>
                        </div>
                    </mat-step>

                    <mat-step [completed]="isCompleted" class="rmv-border">
                        <ng-template matStepLabel>
                            <div class="text-center">
                                <div *ngIf="!isAllImmediateConfirmed" class="activity-title">Enquiry Submitted</div>
                                <!--<div *ngIf="isAllImmediateConfirmed && displayPriceChange" class="activity-title">Review Booking</div>
                                <div *ngIf="isAllImmediateConfirmed && continuePayment" class="activity-title">Make Payment</div>-->
                                <div *ngIf="isAllImmediateConfirmed" class="activity-title">Review & Make Payment</div>
                                <span class="number">4</span>
                            </div>
                        </ng-template>
                        <div *ngIf="isAllImmediateConfirmed && displayPriceChange && !displayPaymentComplete && !enquieryCompleted">
                            <div *ngIf="!continuePayment" class="col-md-12 alert success--btn pw-text-medium ng-star-inserted">Price Concession Applied  </div>
                            <opt-mmc-cart *ngIf="!continuePayment" (navigated)="setTabIndex($event)" [showQuestionPop]="false"></opt-mmc-cart>
                        </div>
                        <opt-mmc-cart *ngIf="showCart && !displayPaymentComplete && !enquieryCompleted" (navigated)="setTabIndex($event)" [showQuestionPop]="false"></opt-mmc-cart>
                        <div *ngIf="(!isAllImmediateConfirmed && !displayPaymentComplete && !showCart) || enquieryCompleted"><opt-mmc-enquiry></opt-mmc-enquiry></div>
                        <div *ngIf="isAllImmediateConfirmed && displayPaymentComplete">
                            <div class="mmc_enquiry">
                                <div class="mmc_card">
                                    <div class="mmc_flex--container top-line">
                                        <div class="mmc_flex__item--inherit icon-left">
                                            <i class="pw-ico-color__tertiary icon-verified"></i>
                                        </div>
                                        <h6 class="mmc_flex__item--vcenter font__h6 font__semibold enquiry-content pw-txt-h3 pw-text-semi-bold">
                                            Booking completed
                                        </h6>
                                        <div class="mmc_flex__item--inherit icon-right">
                                            <div mat-button class="material-icons" tabindex="0" onClick="window.print()">local_printshop</div>
                                        </div>
                                    </div>

                                    <div class="mmc_flex--container seco-line">
                                        <h3 class="mmc_flex__item font__xlarge font__bold pw-txt-h3_small pw-text-semi-bold">
                                            {{(cart$|async)?.contact?.title}} {{(cart$|async)?.contact?.firstName}} {{(cart$|async)?.contact?.lastName}}
                                        </h3>
                                        <div class="mmc_flex__item font__small font__gray pw-txt-b3" *ngIf="( cart$|async)?.contact.type == 'corporateClient'">
                                            {{(cart$|async)?.contact?.client.clientName}}
                                        </div>
                                    </div>

                                    <div class="mmc_flex--container seco-line--colored">
                                        <h4 class="mmc_flex__item font__nt-available font__h4 font-family font__bold pw-text-bold">
                                            {{(cart$|async)?.booking.bookingReference}}
                                        </h4>
                                        <div class="mmc_flex__item font__small font__semibold pw-txt-b3">
                                            Booking enquiry reference number
                                        </div>
                                    </div>

                                    <div class="mmc_flex--container bottom-line font__normal">
                                        <div class="mmc_flex__item bottom-line--topic">
                                            <div class="mmc_flex--container">
                                                <div class="mmc_flex__item--vcenter para pw-txt-b1 confirm-footer-mesg"><span class="confirm-footer-mesg-txt">Thank you for your booking.  Please keep a record of your booking reference number</span></div>
                                                <div class="mmc_flex__item line">
                                                    <hr />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <iframe [src]="paymentUrl"  *ngIf="paymentUrl" #paymentFrame style="width:100%;min-height:340px; height:auto; border:none;"></iframe>
                    </mat-step>
                </mat-horizontal-stepper>
            </div>

            <!--<div *ngIf="((loader$|async)?.isLoading_bookingSave)">
                <opt-mmc-loader></opt-mmc-loader>
            </div>-->
        </div>
        <!--<ng-template #spinner>
            <div class="container" style="min-height:400px">
                <opt-mmc-loader></opt-mmc-loader>
            </div>
        </ng-template>-->
    </div>
</div>
</main>