<div class="flex-container">
    <div class="header-container">
        <div class="header-text-container">
            <span class="heading">{{data.heading}}</span>
        </div>
        <div class="close-btn">
            <mat-icon (click)="cancelClick()">close</mat-icon>
        </div>
    </div>
    <div class="body-container">
        <div [innerHTML]="data.message"></div>
    </div>
    <div class="footer-container">
        <button type="submit" class="confitrmation-cancel-btn" (click)="cancelClick()">{{data.noText}}</button>
        <button type="submit" class="confitrmation-btn" (click)="navigateClick()">{{data.yesText}}</button>
    </div>
</div>