import { Action } from "@ngrx/store";
import {
  EventDate,
  TimeSlot,
  Event,
} from "src/modules/public-web/booking-application/models/BookingEvent";
import { Block, PriceBand } from "src/modules/public-web/booking-application/models/CommonDTOs";
import { BlockDetail, SeatDetail } from "src/modules/public-web/booking-application/models/ConfigurationDetail";
import { ZOOM } from "src/modules/models/public-web/enum";
import { BAMode, IDForLocator } from "./configuration.reducer";
import { Package } from "src/modules/models/public-web/Package";
import { EventSeat } from "src/modules/models/public-web/booking/eventSeatingConfiguration";

export const SET_SELECTED_EVENT = "SET_SELECTED_EVENT";
export const SET_EVENTDATE = "SET_EVENTDATE";
export const LOAD_EVENT = "LOAD_EVENTS";
export const LOAD_EVENT_FAIL = "LOAD_EVENTS_FAIL";
export const LOAD_EVENT_SUCCESS = "LOAD_EVENT_SUCCESS";
export const LOAD_EVENT_DATE = "LOAD_EVENTS_DATES";
export const LOAD_EVENT_DATE_FAIL = "LOAD_EVENTS_DATES_FAIL";
export const LOAD_EVENT_DATE_Success = "LOAD_EVENTS_DATES_Success";
export const LOAD_TIMESLOT = "LOAD_TIMESLOT";
export const LOAD_TIMESLOT_FAIL = "LOAD_TIMESLOT_FAIL";
export const LOAD_TIMESLOT_Success = "LOAD_TIMESLOT_Success";
export const LOAD_BOOKING_FOR_TIMESLOTS = "LOAD_BOOKING_FOR_TIMESLOTS";
export const SET_SELECTED_SEATS = "SET_SELECTED_SEATS";
export const SELECT_SEAT = "SELECT_SEAT";
export const UPDATE_SEAT = "UPDATE_SEAT";
export const REMOVE_SEAT = "REMOVE_SEAT";
export const RESET_SEAT = "RESET_SEAT";
export const SELECT_BLOCK = "SELECT_BLOCK";
export const UPDATE_BLOCK = "UPDATE_BLOCK";
export const REMOVE_BLOCK = "REMOVE_BLOCK";
export const RESET_BLOCK = "RESET_BLOCK";
export const REMOVE_SEAT_AFTER_PATCH = "REMOVE_SEAT_AFTER_PATCH";
export const SET_SELECTED_PACKAGEID = "SET_SELECTED_PACKAGEID";
export const SET_SELECTEDLOCATION = "SET_SELECTEDLOCATION";


// export const SEAT_HIGH_LIGHT_VISIBLE = "SEAT_HIGH_LIGHT_VISIBLE";

export const SWITCH_MINI_MAP = "SWITCH_MINI_MAP";
export const RESET = "RESET";
export const SWITCH_MAP_SEAT_VIEW = "SWITCH_MAP_SEAT_VIEW";
export const SWITCH_ZOOM = "SWITCH_ZOOM uP OR dOWN";
export const SET_ZOOMLEVEL = "SET_ZOOMLEVEL";
export const SET_INTIALTRANSFORM = "SET_INTIALTRANSFORM";

export const SET_FILTER_PRICE_BAND = "SET_FILTER_PRICE_BAND";
export const SET_FILTER_PRICE_RANGE = "SET_FILTER_PRICE_RANGE";
export const SET_FILTER_SEAT_TYPE = "SET_FILTER_SEAT_TYPE";
export const SET_SELECTED_PRICE_BAND = "SET_SELECTED_PRICE_BAND";

export const SET_EVENT_PACKAGES = "SET_EVENT_PACKAGES";
export const RESET_EVENT_PACKAGES = "RESET_EVENT_PACKAGES";

export const SWITCH_BA_MODE = "SWITCH_BA_MODE";

export const RESET_CONFIGURATION_STATE = "RESET_CONFIGURATION_STATE";

//#region Filter Actions

export class SwitchBAMode implements Action {
  readonly type = SWITCH_BA_MODE;
  constructor(public payload: BAMode) { }
}
export class SetFilterSeatType implements Action {
  readonly type = SET_FILTER_SEAT_TYPE;
  constructor(public payload: string[]) { }
}

export class SetFilterPriceBand implements Action {
  readonly type = SET_FILTER_PRICE_BAND;
  constructor(public payload: PriceBand[]) { }
}
export class SetFilterPriceRange implements Action {
  readonly type = SET_FILTER_PRICE_RANGE;
  constructor(public payload: { startPrice: number, endPrice: number }) { }
}
export class SetSelectedPriceBand implements Action {
  readonly type = SET_SELECTED_PRICE_BAND;
  constructor(public payload: PriceBand[]) { }
}
//#endregion

//#region event packages
export class SetEventPackages implements Action {
  readonly type = SET_EVENT_PACKAGES;
  constructor(public payload: Package[]) { }
}
export class ResetEventPackages implements Action {
  readonly type = RESET_EVENT_PACKAGES;
}
//#endregion

//#region Venue Map Configuration
export class SwitchMiniMap implements Action {
  readonly type = SWITCH_MINI_MAP;
  constructor(public payload: boolean) { }
}
export class ResetVenueMap implements Action {
  readonly type = RESET;
  constructor(public payload: boolean) {
  }
}
export class SwitchZoomLevel implements Action {
  readonly type = SWITCH_ZOOM;
  constructor(public payload: ZOOM) { }
}
export class SetZoomValue implements Action {
  readonly type = SET_ZOOMLEVEL;
  constructor(public payload: number) { }
}
export class SetInitialTransform implements Action {
  readonly type = SET_INTIALTRANSFORM;
  constructor(public payload: string) { }
}
export class SetSelectedPackage implements Action {
  readonly type = SET_SELECTED_PACKAGEID;
  constructor(public payload: string) { }
}
export class SetSelectedForLocator implements Action {
  readonly type = SET_SELECTEDLOCATION;
  constructor(public payload: IDForLocator) { }
}

export class SwitchMapSeatView implements Action {
  readonly type = SWITCH_MAP_SEAT_VIEW;
  constructor(public payload: boolean) { }
}
//#endregion

//#region common Action
// export class SeatHighlightVisible implements Action {
//   readonly type = SEAT_HIGH_LIGHT_VISIBLE;
//   constructor(public payload: Boolean) { }
// }
export class SetSelectedSeats implements Action {
  readonly type = SET_SELECTED_SEATS;
  constructor(public payload: SeatDetail[]) { }
}
export class SelectSeat implements Action {
  readonly type = SELECT_SEAT;
  constructor(public payload: SeatDetail) { }
}
export class UpdateSeat implements Action {
  readonly type = UPDATE_SEAT;
  constructor(public payload: SeatDetail) { }
}
export class RemoveSeat implements Action {
  readonly type = REMOVE_SEAT;
  constructor(public payload: string) { }
}
export class ResetSeat implements Action {
  readonly type = RESET_SEAT;
}
export class SelectBlock implements Action {
  readonly type = SELECT_BLOCK;
  constructor(public payload: BlockDetail) { }
}
export class UpdateBlock implements Action {
  readonly type = UPDATE_BLOCK;
  constructor(public payload: BlockDetail) { }
}
export class RemoveBlock implements Action {
  readonly type = REMOVE_BLOCK;
  constructor(public payload: string) { }
}
export class ResetBlock implements Action {
  readonly type = RESET_BLOCK;
}
export class RemovedSeatsAfterPatch implements Action {
  readonly type = REMOVE_SEAT_AFTER_PATCH;
  constructor(public payload: EventSeat[]) { }
}
export class SetEvent implements Action {
  readonly type = SET_SELECTED_EVENT;
  constructor(public payload: Event) { }
}
export class SetEventDate implements Action {
  readonly type = SET_EVENTDATE;
  constructor(public payload: EventDate) { }
}
export class LoadEvent implements Action {
  readonly type = LOAD_EVENT;
}
export class LoadEventFail implements Action {
  readonly type = LOAD_EVENT_FAIL;
  constructor(public payload: any) { }
}
export class LoadEventSuccess implements Action {
  readonly type = LOAD_EVENT_SUCCESS;
  constructor(public payload: Event) { }
}
export class LoadEventDate implements Action {
  readonly type = LOAD_EVENT_DATE;
}
export class LoadEventDateFail implements Action {
  readonly type = LOAD_EVENT_DATE_FAIL;
  constructor(public payload: any) { }
}
export class LoadEventDateSuccess implements Action {
  readonly type = LOAD_EVENT_DATE_Success;
  constructor(public payload: EventDate) { }
}
export class LoadTimeslot implements Action {
  readonly type = LOAD_TIMESLOT;
}

export class LoadTimeslotFail implements Action {
  readonly type = LOAD_TIMESLOT_FAIL;
  constructor(public payload: any) { }
}

export class LoadTimeslotSuccess implements Action {
  readonly type = LOAD_TIMESLOT_Success;
  constructor(public payload: TimeSlot[]) { }
}

export class ReSetConfigurationState implements Action {
  readonly type = RESET_CONFIGURATION_STATE;
}

export class LoadBookingsForTimeSlots implements Action {
  readonly type = LOAD_BOOKING_FOR_TIMESLOTS;
}
//#endregion

export type ConfigurationAction =
  SetFilterPriceBand |
  UpdateSeat |
  SetFilterPriceRange | SetFilterSeatType
  | SetSelectedPriceBand
  | SetEventPackages
  | ResetEventPackages
  | SwitchMapSeatView
  | SwitchZoomLevel
  | SetZoomValue
  | SetInitialTransform
  | SetSelectedPackage
  | SetSelectedForLocator
  | SwitchMiniMap
  | ResetVenueMap
  // | SeatHighlightVisible
  | SetEvent
  | SetSelectedSeats
  | SelectSeat
  | RemoveSeat
  | ResetSeat
  | SelectBlock
  | UpdateBlock
  | RemoveBlock
  | ResetBlock
  | RemovedSeatsAfterPatch
  | SetEventDate
  | LoadEvent
  | LoadEventFail
  | LoadEventSuccess
  | LoadEventDate
  | LoadEventDateFail
  | SwitchBAMode
  | LoadEventDateSuccess
  | ReSetConfigurationState
  | LoadTimeslotSuccess
  | LoadTimeslotFail
  | LoadTimeslot
  | LoadBookingsForTimeSlots;
