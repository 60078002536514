import { BaseResource } from '../../../base-resource';
import { PriceGroup } from '../../../booking/price-group';
import { Allocation } from 'src/modules/models/regular-portal/booking/save-booking/allocations';
import { Asset } from '../../../booking/asset';
import { RecurrenceOption } from 'src/modules/models/regular-portal/recurrence-option';
import { DeliveryMethod } from 'src/modules/models/booking/booking';
export class BookingItem extends BaseResource {
    constructor() {
        super();
       //this.item = new Item();
       this.type = "BookingItem";

    }

    name: string;
    quantity: number;
    covers: number;
    startTime: string;
    endTime: string;
    item: Item;
    upsell: boolean;
    bookingItemAssociations: BookingItemAssociation[];
    recurrenceOption: RecurrenceOption;
    recurrent: boolean;
    adhoc: boolean;
    recurrentBookingItems: BookingItem[];
    attendees: number;
    allocations: Allocation;
    deliveryMethod:DeliveryMethod;
}
export class Item extends BaseResource {
    constructor() {
        super();
    }

}
export class BookingItemAssociation extends BaseResource {
    constructor() {
        super();
        this.type = "BookingItemAssociation";
        this.asset = new Asset();
    }

    allocatedQuantity: number;
    startTime: string
    endTime: string;
    asset: Asset;
    primaryBookingSpaceItem: any;
}
