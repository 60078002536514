import { Injectable } from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router'
import { Observable } from 'rxjs'
import { ICart } from '../store/cart';
import { environment} from '../../environments/environment';
import { AppSetting } from '../models/settings/casual-portal/app-setting';
import { Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate {
    isLoggedIn: boolean;
    cart$: Observable<ICart>;
    appSetting: AppSetting;

    constructor(private router: Router, private store: Store<any>) {
        this.cart$ = this.store.select("cart");
         this.cart$.subscribe((state) => {
             this.isLoggedIn =
                 state.contact != null &&
                     state.contact.firstName != null &&
                     state.contact.firstName != ""
                     ? true
                     : false;
         });
        this.appSetting = environment.AppSetting as AppSetting;
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean | Promise<boolean> {
        if (environment.LoggedInUser == '' && (this.appSetting.ClientLoginIsRequired && !this.isLoggedIn)) {
            window.open(
                environment.ApiUrl + "#/client/login",
                "_self"
            );
            return false;
        }
        else
            return true;
    }
}
