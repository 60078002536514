import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ChangePassword } from 'src/modules/models/client/change-password';

import { CustomerService } from 'src/modules/services/customer.service';

import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { SuccessMessageComponent } from 'src/modules/shared/success-message/success-message.component'

import { Store, ICart } from 'src/modules/store/index';
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'opt-change-password',
    templateUrl: './change-password.component.html'
})

export class ChangePasswordComponent implements OnInit {
    cpForm: FormGroup;
    cart$: Observable<ICart>;
    cart_subscriber: any;
    contactId: string = "";
    username: string = "";
    pwPolicy: any;
    showValidationMessage: boolean = false;
    validationMessage: string = "";
    newPassword: string = "";
    currentPassword: string = "";
    confirmPassword: string = "";
    isValidEnforceComplexPassword: boolean;
    isValidCharactersMinimum: boolean;
    isValidOneLowercase: boolean;
    isValidOneUppercase: boolean;
    isValidOneDigit: boolean;
    isValidOneSpecialCharacter: boolean;
    isDisabledSubmit: boolean = true;
    ConfirmationMsg: string;

    constructor(private _customerService: CustomerService, private store: Store<any>, private router: Router,
        private route: ActivatedRoute, private formBuilder: FormBuilder, public snackBar: MatSnackBar,private translate: TranslateService) {
        this.cart$ = this.store.select('cart');
    }

    ngOnInit() {
        this.cpForm = this.formBuilder.group({
            currentPassword: ['', Validators.required],
            newPassword: ['', Validators.required],
            confirmPassword: ['', Validators.required],
        });

        this.cart_subscriber = this.cart$.subscribe(cart => {
            if (cart && cart.contact && cart.contact.id && cart.contact.id != null && cart.contact.id != "") {
                this.contactId = cart.contact.id;
                this.username = cart.contact.userName;

            }

        });

        if (this.contactId) {
            let passwordPolicyResult = this._customerService.GetPasswordPolicies(this.contactId);

            passwordPolicyResult.subscribe(policy => {
                if (!policy.IsError) {
                    this.pwPolicy = policy.Data;
                } else {
                    this.snackBar.openFromComponent(AlertMessageComponent, {
                        duration: 3000,
                        verticalPosition: 'top',
                        data: [policy.ErrorMessage]
                    });
                }
            }, err => { console.log('Error', err); });
        } else {
            this.router.navigate(['/client/login']);
        }
    }

    ngOnDestroy() {
        if (this.cart_subscriber)
            this.cart_subscriber.unsubscribe();
    }

    onSubmit() {
        if (!this.isDisabledSubmit) {
            this.showValidationMessage = false;
            this.isDisabledSubmit = true;


            if (this.validatePassword(this.cpForm.controls.newPassword.value) && this.cpForm.controls.currentPassword.value != "" && this.cpForm.controls.newPassword.value != "" && this.cpForm.controls.confirmPassword.value != "") {
                if (this.cpForm.controls.newPassword.value == this.cpForm.controls.confirmPassword.value) {
                    let changePassword = new ChangePassword();
                    changePassword.entityTypeId = 6;
                    changePassword.referenceEntityId = +this.contactId;
                    changePassword.existingPassword = this.cpForm.controls.currentPassword.value;
                    changePassword.newPassword = this.cpForm.controls.newPassword.value;
                    changePassword.userName = this.username;
                    let message ="Password Updated Successfully";
                    this.translate.get("CHANGEPASSWORD." + message).subscribe((res: string) => {
                      message = message.replace(message, res);
                    });
                    let changePasswordResult = this._customerService.ChangePassword(changePassword);
                    changePasswordResult.subscribe(result => {
                        if (!result.IsError) {
                            this.snackBar.openFromComponent(SuccessMessageComponent, {
                                data: [message],
                                duration: 4000,
                                verticalPosition: 'top',
                            });
                            setTimeout(() => {
                                // if (environment.PortalType === '4') {
                                //     const url = environment.PublicWebPortalUrl;
                                //     window.open(url, "_self");
                                // }
                                // else {
                                //     this.router.navigate(['/facility']);
                                // }
                                this.router.navigate(['/client/login']);
                            }, 3000);
                        }
                        // else if (result.ErrorCode == 400) {
                        //this.ConfirmationMsg = result.Errors[0].Detail;
                        //this.showErrorMessage(4);
                        //this.isDisabledSubmit = false;

                        // }
                        else {
                            this.showErrorMessage(1);
                            this.isDisabledSubmit = false;
                        }
                    },
                        err => {
                            this.showErrorMessage(1);
                            this.isDisabledSubmit = false;
                        }
                    );
                } else this.showErrorMessage(2);
            }
        }
    }

    showErrorMessage(messageId: number) {
        this.showValidationMessage = true;
        var message = {
            1: "The current password you have entered is incorrect",
            2: "New password and confirm password should match",
            3: "Password not strong enough",
            4: this.ConfirmationMsg,
            5: "Cannot use previous password as new password"

        };
        this.validationMessage = message[messageId];
    }

    focusOutFunction(val) {
        if ((!this.validatePassword(this.newPassword)) && !this.showValidationMessage && this.newPassword.length > 0) {
            this.showErrorMessage(3);
        }
        else if (this.newPassword.length > 0 && this.newPassword == this.currentPassword) {
            this.showErrorMessage(5);
            this.isDisabledSubmit = true;
        }

    }

    focusOutFunctionCp(val) {
        if (this.confirmPassword.length > 0 && this.newPassword == this.currentPassword) {
            this.showErrorMessage(5);
            this.isDisabledSubmit = true;
        }
        else if ((!this.validatePassword(this.newPassword)) && !this.showValidationMessage && this.newPassword.length > 0) {

            this.showErrorMessage(3);

        }
        else if (this.confirmPassword.length > 0 && this.newPassword != this.confirmPassword && this.newPassword.length > 0) {

            this.showErrorMessage(2);
        }
    }



    validatePassword(password): boolean {
        var isValid = true;

        if (this.pwPolicy != null) {
            if (this.pwPolicy.EnforceComplexPassword) {
                var enforceComplexPasswordValue = true;

                if (this.pwPolicy.EnforceDigit) {
                    var EnforceDigitValidation = new RegExp(".*[0-9].*");
                    if (EnforceDigitValidation.test(password)) {
                        this.isValidOneDigit = true;
                    }
                    else {
                        isValid = false;
                        enforceComplexPasswordValue = false;
                        this.isValidOneDigit = false;
                    }
                }

                if (this.pwPolicy.EnforceMinimumPasswordLength) {
                    if (password && password != null && password.length >= this.pwPolicy.PasswordLength) {
                        this.isValidCharactersMinimum = true;
                    }
                    else {
                        isValid = false;
                        enforceComplexPasswordValue = false;
                        this.isValidCharactersMinimum = false;
                    }
                }

                if (this.pwPolicy.EnforceLowercase) {
                    if ((/[a-z]/.test(password))) {
                        this.isValidOneLowercase = true;
                    }
                    else {
                        isValid = false;
                        enforceComplexPasswordValue = false;
                        this.isValidOneLowercase = false;
                    }
                }

                if (this.pwPolicy.EnforceUppercase) {
                    if ((/[A-Z]/.test(password))) {
                        this.isValidOneUppercase = true;
                    }
                    else {
                        isValid = false;
                        enforceComplexPasswordValue = false;
                        this.isValidOneUppercase = false;
                    }
                }

                if (this.pwPolicy.EnforceSpecialCharacters) {
                    if ((/[!@@#$%^&*(),.?"`~:;\[\]'/{}|\\<>+=._-]/.test(password))) {
                        this.isValidOneSpecialCharacter = true;
                    }
                    else {
                        isValid = false;
                        enforceComplexPasswordValue = false;
                        this.isValidOneSpecialCharacter = false;
                    }
                }

                if (this.pwPolicy.EnforceSpecialConditions) {
                    let thisComponent = this;
                    this.pwPolicy.SpecialConditionMappedInfo.forEach(function (item) {
                        if (item.Value != null && item.Value != "" && password.toLowerCase().includes(item.Value.toLowerCase())) {
                            thisComponent.showValidationMessage = true;
                            thisComponent.validationMessage = item.Key + " should not match with password";
                            isValid = false;
                        }
                    });
                }
                this.isValidEnforceComplexPassword = enforceComplexPasswordValue;
            }
        }
        return isValid;
    }

    passwordChange(obj) {
        if (this.newPassword.length > 0 && this.newPassword == this.currentPassword) {
            this.showErrorMessage(5);
            this.isDisabledSubmit = true;
        }
        else if (this.confirmPassword.length > 0 && this.newPassword != this.confirmPassword) {
            this.showErrorMessage(2);
        }
        else this.showValidationMessage = false;
        this.isDisabledSubmit = (!this.validatePassword(this.newPassword) || this.newPassword != this.confirmPassword);
    }

    confirmPasswordChange(obj) {
        if (this.newPassword.length > 0 && this.newPassword == this.currentPassword) {
            this.showErrorMessage(5);
            this.isDisabledSubmit = true;
        }
        else if (this.newPassword.substring(0, this.confirmPassword.length) == this.confirmPassword) {
            this.showValidationMessage = false;
        } else this.showErrorMessage(2);
        this.isDisabledSubmit = (!this.validatePassword(this.newPassword) || this.newPassword != this.confirmPassword || this.newPassword == this.currentPassword);
    }
}
