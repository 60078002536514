import { HttpErrorResponse } from "@angular/common/http";
import { Error } from "./models/api-result";
import { Message } from "./models/settings/casual-portal/app-setting";

export class Utility {

  static convertDateToTime(dt: Date) {
    const timeString = dt.toLocaleTimeString();
    var hours = dt.getHours();
    var minutes = dt.getMinutes();
    const isPM = timeString.match('PM')
    if (isPM && hours > 12) {
      hours -= 12;
    }
    return hours + ':' + this.leftPad(minutes, 2) + " " + (isPM ? 'PM' : 'AM')
  }
  static leftPad(number, targetLength) {
    let output = number + '';
    while (output.length < targetLength) {
      output = '0' + output;
    }
    return output;
  }

  static index = -1;
  static getIndex() {
    this.index = this.index - 1;
    return this.index;
  }
  static convertToISO(dt: Date) {
    //2018-04-23T10:26:00
    if (dt) {
      var month = (dt.getMonth() + 1) < 10 ? ('0' + (dt.getMonth() + 1)) : (dt.getMonth() + 1);
      return dt.getFullYear() + '-' + this.generateTwoDigitNo(dt.getMonth() + 1) + '-' + this.generateTwoDigitNo(dt.getDate()) + 'T' + this.generateTwoDigitNo(dt.getHours()) + ':' + this.generateTwoDigitNo(dt.getMinutes()) + ':' + this.generateTwoDigitNo(dt.getSeconds());
    } else {
      return null;
    }
  }

  static convertToDate(dt: Date) {
    //2018-04-23
    var month = (dt.getMonth() + 1) < 10 ? ('0' + (dt.getMonth() + 1)) : (dt.getMonth() + 1);
    return dt.getFullYear() + '-' + this.generateTwoDigitNo(dt.getMonth() + 1) + '-' + this.generateTwoDigitNo(dt.getDate());
  }

  static convertISOToDate(dt: any) {
    if (!(dt instanceof Date)) {
      if (dt != undefined) {
        var arr = dt.split(/[-T:]/);
        return new Date(+arr[0], (+arr[1]) - 1, +arr[2], +arr[3], +arr[4], 0);
      }
      else return undefined;
    }


    else return dt;
    //else return this.getClosestHalfHour(dt);
  }

  static isIsoFormat(str) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(str)) return false;
    //const d = new Date(str);
    //return d instanceof Date && d.toISOString()===str;
    return true;
  }

  static getClosestHalfHour(d) {
    d.setMilliseconds(0);
    d.setSeconds(0);
    var minutes = d.getMinutes();
    var neededMin;

    if (minutes == 0) {
      neededMin = 0;
    }
    else if (0 < minutes && minutes <= 30) {
      neededMin = 30 - minutes;
    }
    else {
      neededMin = 60 - minutes;
    }
    d.setMinutes(d.getMinutes() + neededMin)
    return d;
  }

  static setMinTime(dt) {
    dt.setMinutes(dt.getMinutes() + 1);
    return this.getClosestHalfHour(dt);
  }
  static appendTimePart(currentDt: Date, timeObject: Date, isNewObject: boolean = false) {
    if (currentDt) {
      var dt = isNewObject ? new Date(currentDt.getTime()) : currentDt;
      if (dt) {
        dt.setHours(timeObject.getHours());
        dt.setMinutes(timeObject.getMinutes());
        dt.setSeconds(0);
      }
      return dt;
    }
    return null;
  }




  static changeDatePart(currentDt: Date, newDt: Date) {
    if (currentDt) {
      var hr = currentDt.getHours();
      var min = currentDt.getMinutes();
      currentDt = newDt;
      currentDt.setHours(hr);
      currentDt.setMinutes(min);
      currentDt.setSeconds(0);
    }
    return currentDt;
  }

  static roundToMinDuration(dt: Date, MinimumBookingDuration: number, isEndTime = false, isStartTime = false): any {
    var isDateChange: Boolean = false;
    dt.setSeconds(0);
    var hr = dt.getHours();
    var min = dt.getMinutes();

    if (!(hr == 23 && min == 59)) {
      var totMin = hr * 60 + min
      var mxMin = 23 * 60 + 59;

      var minBookingDuration = MinimumBookingDuration;
      if (mxMin >= totMin && (min % MinimumBookingDuration) > 0) {
        var roundMin = (parseInt((min / minBookingDuration).toString()) + 1) * minBookingDuration;
        dt.setMinutes(roundMin);
        isDateChange = true;
      }
    }

    return { newDate: dt, isDateChanged: isDateChange };
  }

  static urlHistorty: string[] = [];

  static sortParams(link) {
    link = decodeURIComponent(link);
    let queryParams = link.split('?')[1];
    let params;
    let pair = null;
    let data = {};

    if (queryParams) params = queryParams.split('&');

    if (params && params.length > 0) {
      params.forEach((d) => {
        pair = d.split('=');
        data[`${pair[0]}`] = pair[1];
      });
    }
    return data;
  }

  static toCamel(o) {
    var newO, origKey, newKey, value;
    let thisPage = this;
    if (o instanceof Array) {
      return o.map(function (value) {
        if (typeof value === "object") {
          value = thisPage.toCamel(value);
        }
        return value;
      })
    }
    else {
      newO = {};
      for (origKey in o) {
        if (o.hasOwnProperty(origKey)) {
          newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
          value = o[origKey];
          if (value instanceof Array || (value !== null && value.constructor === Object)) {
            value = thisPage.toCamel(value);
          }
          newO[newKey] = value;
        }
      }
    }
    return newO;
  }

  private static generateTwoDigitNo(no: number): string {
    return no < 10 ? ('0' + no) : no.toString().substring(0, 2);
  }

  static calculateFileSize(bytes: number): string {
    if (bytes) {
      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes == 0) return 'n/a';
      var i = +(Math.floor(Math.log(bytes) / Math.log(1024)));
      if (i == 0) return bytes + ' ' + sizes[i];
      return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
    }
  }

  static sentenceCase(address) {
    //console.log(address);
    if (address != '') {
      return address.replace(/\w\S*/g, (letter) => {
        return letter.charAt(0).toUpperCase() + letter.substr(1).toLowerCase();
      })
    }
    return;
  }
  static paginate(
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = 10,
    maxPages: number = 10
  ) {
    let totalPages = Math.ceil(totalItems / pageSize);

    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= maxPages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        startPage = 1;
        endPage = maxPages;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        startPage = totalPages - maxPages + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }

    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }
  static isPriceUndefined(node) {
    if (node != undefined && node.data != undefined) {
      if (node.data.items.length == 0 || node.data.items == undefined) {
        return false;
      }
      return true;
    };
    return true;
  }
  static showErrorMessage(response) {
    if (!response.body || response.status > 400) {
      let messageDetail = "something went wrong please try again";
      //this.showErrorMessage = true;

      return { messageDetail, showErrorMessage: true };
    }
    return { showErrorMessage: false };
  }


  static displaySidepanel(node, parentId) {
    if (!node.selectable) {
      return true;
    } else {

      if (node.parentId == parentId) {
        return true;
      }

      return false;
    }
    return false;
  }


  static GetErrorMessageToBeDisplayed(apiResponse: HttpErrorResponse, customError: string, apiMessages: Message[]): string[] {
    let _errorMessageToReturn = ''
    let _errorListToReturn: string[] = []
    if (!apiMessages) {
      apiMessages = [];
    }

    if (customError) {
      _errorMessageToReturn = customError;
    } else {
      _errorMessageToReturn = 'Error Occured, Please Contact Support'
    }

    if (apiResponse && apiResponse.error) {
      let _errors = apiResponse.error.Errors as Error[];
      let _meta = apiResponse.error.Meta;

      if (_errors) {
        _errors.forEach((error) => {
          let _apiMessage = apiMessages.find(x => x.APICode.toLowerCase() == error.Code.toLowerCase());

          if (_apiMessage) {
            let _message = _apiMessage.Value;
            let _matchBindings = this.getSubstringsByDelimiters(_message, '#', '#');

            _matchBindings.forEach(match => {
              if (match) {
                var value = _meta.AdditionalDetails[match];
                if (value) {
                  let _matchwithDelimitter = '#' + match + '#';
                  _message = _message.replace(_matchwithDelimitter, value);
                }
              }
            });
            _errorListToReturn.push(_message);
          }
        });
      }
    }

    if (_errorListToReturn.length == 0) {
      _errorListToReturn.push(_errorMessageToReturn);
    }
    return _errorListToReturn;
  }

  static getSubstringsByDelimiters(str, open, close) {
    let _subStringList: string[] = [];

    let dels = [];
    for (let i = 0; i < str.length; i++) {
      if (str[i] == open && dels.length == 0) {
        dels.push(i);
      }
      else if ((str[i] == close) && (dels.length > 0)) {
        let pos = dels[dels.length - 1];
        dels = [];
        let len = i - 1 - pos;
        let ans;
        if (pos < len) {
          ans = str.substring(pos + 1, len + 1);
        }
        else {
          ans = str.substring(pos + 1, len + pos + 1);
        }
        _subStringList.push(ans);
      }
    }

    return _subStringList;
  }

}