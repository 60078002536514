<div class="mmc_enquiry">
    <div class="mmc_card">
        <div class="mmc_flex--container top-line">
            <div class="mmc_flex__item--inherit icon-left">
                <i class="pw-ico-color__tertiary icon-verified"></i>
            </div>
            <h3 class="mmc_flex__item--vcenter font__h6 font__semibold enquiry-content pw-txt-h3 pw-text-semi-bold">
                Booking enquiry submitted
            </h3>
            <div class="mmc_flex__item--inherit icon-right">
                <div mat-button class="material-icons" onClick="window.print()" keydown.enter="window.print()" role="button" tabindex="0">local_printshop</div>
            </div>
        </div>

        <div class="mmc_flex--container seco-line">
            <h3 class="mmc_flex__item font__xlarge font__bold pw-txt-h3_small pw-text-semi-bold">
                {{(cart$|async)?.contact?.title}} {{(cart$|async)?.contact?.firstName}} {{(cart$|async)?.contact?.lastName}}
            </h3>
            <div class="mmc_flex__item font__small font__gray pw-txt-b3" *ngIf="( cart$|async)?.contact?.type == 'corporateClient'">
                {{(cart$|async)?.contact?.client.clientName}}
            </div>
        </div>

        <div class="mmc_flex--container seco-line--colored">
            <h4 class="mmc_flex__item font__nt-available font__h4 font-family font__bold pw-text-bold">
                {{(cart$|async)?.booking.bookingReference}}
            </h4>
            <div class="mmc_flex__item font__small font__semibold pw-txt-b3">
                Booking enquiry reference number
            </div>
        </div>

        <div class="mmc_flex--container bottom-line font__normal">
            <div class="mmc_flex__item bottom-line--topic">
                <div class="mmc_flex--container">
                    <div class="mmc_flex__item--vcenter para pw-txt-b1">{{submitEnquiryMessage}}</div>
                    <div class="mmc_flex__item line">
                        <hr />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>