import { IPackageDeliveryMethod } from "../../store/public-web/public-web-reducers";
import { BaseResource } from "../base-resource";
import { AttendeeCaptureProfile } from "../booking/Attendee";
import { Item } from "../item/item";
import { AnswerChoices, Question } from "../item/question";
import { BookingQuestion } from "../regular-portal/booking/question/booking-question";
import { PackageDate, PackageSession } from "./PackageSession";
import { PublicEvent } from "./PublicEvent";

export class Document extends BaseResource {
  name: string;
  documentFileURL: string;

  initialize(d) {
    this.id = d.id;
    this.name = d.name;
    this.documentFileURL = d.documentFileURL;
    return this;
  }
}
export class LinkedPackageType extends BaseResource {
  adultRequired: boolean;
  adult: boolean;
  name: string;
  id: string;
  maximumNumberOfChildren: number;

  initialize(d) {
    this.id = d.id;
    this.name = d.name;
    this.adult = d.adult;
    this.adultRequired = d.adultRequired;
    this.maximumNumberOfChildren = !!d.maximumNumberOfChildren
      ? d.maximumNumberOfChildren
      : 0;
    return this;
  }
}
export class PackageDurationRange extends BaseResource {
  id: string;
  label: string;
  available: number;
  sessions: number;
  packageDates: PackageDate[];
  initialize(d, pd = []) {
    this.id = d.id;
    this.label = d.label;
    this.available = d.available;
    this.sessions = d.sessions;
    this.packageDates = pd;
    return this;
  }
}

export class Package extends BaseResource {
  quantity: number;
  name: string;
  packageSessions: PackageSession[];
  nameOnReport: string;
  timelineDescription: string;
  packageRef: string;
  active: boolean;
  publicEvent: PublicEvent;
  businessAreaId: number;
  businessArea: string;
  packageClassId: number;
  packageClass: string;
  bespokePackage: boolean;
  attendees: number;
  minimumAttendees: number;
  maximumAttendees: number;
  fixedPriceExcludingTax: number;
  variablePriceExcludingTax: number;
  fixedPriceIncludingTax: number;
  variablePriceIncludingTax: number;
  packageQuestions: Question[];
  margin: number;
  salesCategories: any[];
  linkedPackages: Package[];
  linkedPackageType: LinkedPackageType;
  packageDates: PackageDate[];
  priceTypeId: number;
  priceType: string;
  packageGroup: string;
  packageGroupId: string;
  taxId: number;
  packageItems: any[];
  packageDocuments: Document[];
  taxCode: string;
  packageDeliveryMethods: IPackageDeliveryMethod[];
  webDescription: string;
  longDescription: string;
  shortDescription: string;
  bookingQuestions: BookingQuestion[];
  availableForEnquiriesOnly: boolean;
  isBooked: boolean = false;
  packageDurationRanges: PackageDurationRange[];
  primaryPackage: Package;
  singleDayPackage: boolean;
  maxChildrenPerAdult: number;
  packageClientCategories: any[];
  attendeeCaptureProfile: AttendeeCaptureProfile;
  availability: number;
  upsellItem: Item[] = [];
  constructor() {
    super();
    this.type = "PublicPackage";
  }
  initialize(d) {
    this.singleDayPackage = d.singleDayPackage
    this.packageClientCategories = d.packageClientCategories;
    this.quantity = 0;
    this.attendeeCaptureProfile = d.attendeeCaptureProfile;
    this.maxChildrenPerAdult = d.MaxChildrenPerAdult;
    this.packageItems = d.packageItems
    this.id = d.id;
    this.name = d.name;
    this.timelineDescription = d.timelineDescription;
    this.packageDates = d.packageDates;
    this.primaryPackage = d.primaryPackage;
    this.packageSessions =
      d.packageSessions &&
      d.packageSessions.map((x) => new PackageSession().initialize(x));
    this.packageDurationRanges =
      d.packageDurationRanges &&
      d.packageDurationRanges.map((x) =>
        new PackageDurationRange().initialize(
          x,
          !!this.packageDates
            ? this.packageDates.filter(
              (p) =>
                p.packageDurationRange && p.packageDurationRange.id == x.id
            )
            : []
        )
      );
    this.packageQuestions = [];
    this.minimumAttendees = d.minimumAttendees;
    this.maximumAttendees = d.maximumAttendees;
    this.attendees = d.attendees;
    this.variablePriceExcludingTax = d.variablePriceExcludingTax;
    this.variablePriceIncludingTax = d.variablePriceIncludingTax;
    if (d.packageQuestions && d.packageQuestions.length > 0) {
      d.packageQuestions.forEach((c) => {
        let q = c as Question;
        q.answerChoices = [];
        this.packageQuestions.push(q);
      });
    }
    this.linkedPackages =
      d.linkedPackages &&
      d.linkedPackages.map((x) => {
        x.linkedPackages = undefined;
        return new Package().initialize(x);
      });
    // if(this.linkedPackages && this.linkedPackages.length > 0) {
    //   this.linkedPackages.forEach(x => {x.linkedPackages = []})
    // }
    this.linkedPackageType =
      d.linkedPackageType &&
      new LinkedPackageType().initialize(d.linkedPackageType);
    this.packageDocuments =
      d.packageDocuments &&
      d.packageDocuments.map((x) => new Document().initialize(x));
    if (d.packageDeliveryMethods && d.packageDeliveryMethods.length > 0) {
      this.packageDeliveryMethods = [];
      d.packageDeliveryMethods.forEach((dm) => {
        if (dm.deliveryMethod) {
          let packageDeliveryMethod: IPackageDeliveryMethod = {
            id: dm.id,
            deliveryMethodId: dm.deliveryMethod.id,
            name: dm.deliveryMethod.name,
          };
          this.packageDeliveryMethods.push(packageDeliveryMethod);
        }
      });
    }
    this.webDescription = d.webDescription;
    this.longDescription = d.longDescription;
    this.shortDescription = d.shortDescription ? d.shortDescription : '';
    this.packageGroup = d.packageGroup;
    this.packageGroupId = d.packageGroupId;
    this.upsellItem = [];
    if(d.publicEvent){
      let event = new PublicEvent();
      event.id = String(d.publicEvent?.id);
      event.type = 'PublicEvent';
      event.eventCategoryId = d.publicEvent?.eventCategoryId ? d.publicEvent?.eventCategoryId : 0;
      event.hasSeating = d.publicEvent?.hasSeating ? d.publicEvent?.hasSeating : false;
      event.shortDescription = d.publicEvent?.shortWebDescription ? d.publicEvent?.shortWebDescription : '';
      event.eventStartTime = new Date(this.packageSessions[0].packageDate.eventDate.startTime);
      event.eventDates = [];
      // if(d.publicEvent?.eventDates?.length > 0)
      // event.eventStartTime = d.publicEvent?.eventDates[0].startTime;
      // if(!event.eventStartTime){
      //   event.eventStartTime =d.packageDates[0].eventDate.startTime;
      // }
      event.name = d.publicEvent?.name;
      event.venueName = d.publicEvent?.venueName;
      this.publicEvent = event;
    }
    return this;
  }
  get getTotalPrice() {
    return this.variablePriceIncludingTax * this.quantity;
  }
}
