import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

import { Observable } from "rxjs";
import { CurrencyFormat } from "src/modules/currency-format";
import { WmCommonService } from "src/modules/services/waste-management/wm-common.service";

import { AppSetting, MenuSettings } from 'src/modules/models/settings/casual-portal/app-setting';

// Store
import { ClearCart, ICart, SetContact, SetInternalUserData, SignOut, Store } from '../../store/index';
import { isCardExpired } from 'src/modules/store/cart-reducer';
import { BookingType, Contact } from '../../models/client/contact';
import { ActivatedRoute, Router } from '@angular/router';
import { Utility } from 'src/modules/utility';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../base.component';
import { UserDataState } from '../../store/waste-management';

import {
  ClearAllBookingPackagesAction,
  ClearSelectedCustomerAction,
  GetCollectionLocationsSuccessAction,
  GetInternalUserDataAction,
  GetInternalUserDataResetAction,
  GetPreviousWasteBookingsAction, GetPreviousWasteBookingsResetAction,
  GetSavedBookingResetAction,
  GetUpComingWasteBookingsAction, GetUpComingWasteBookingsResetAction,
  RemoveAddressAction,
  ResetInternalUserDataAction,
  SelectCustomerAction,
  WMIsInternalUserPortalAction,
  WMSignOutAction,
} from "../../store/waste-management/actions";
import {
  selectBookingPackageState,
  selectIsInternalUserPortal,
  selectUserDataState,
  // selectSuburbs
} from "../../store/waste-management/selectors";
import * as PBSelector from '../../store/public-web/public-web-selectors';
import * as LanguageSelector from '../../store/public-web/select-language/language-selector';
import { IPBReducer, IPublicWebSession } from "../../store/public-web/public-web-reducers";
import { WmCustomer } from "src/modules/models/waste-management/wm-customer";
import { DeleteConfirmationComponent } from "src/modules/shared/delete-confirmation/delete-confirmation.component";
import { Deserializer } from "json-api-format/json_api";
import { WmUtility } from "src/modules/waste-management/wm-utility";
import { WmAddress } from "src/modules/models/waste-management/wm-address";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { CustomerService } from 'src/modules/services/customer.service';
import { WmWasteTypeApiService } from 'src/modules/services/waste-management/wm-waste-type-api-service';

import { RegularBookingSettings } from 'src/modules/models/settings/regular-portal/regular-booking-setting';

import { RegualrBookingService } from 'src/modules/services/regular-booking.service';
import { ClientSearchComponent } from 'src/modules/client-search/client-search/client-search.component';
import { AddEditClientComponent } from 'src/modules/client-search/add-edit-client/add-edit-client.component';
import { UserData } from 'src/modules/models/login/userData';
import { BookingService } from 'src/modules/services/booking.service';
import { Booking } from 'src/modules/models/booking/booking';
import { SuccessMessageComponent } from '../success-message/success-message.component';
import { AlertMessageComponent } from '../alert-message/alert-message.component';
import { FacilitySearchLoaded } from 'src/modules/store/loading/actions';
import { BookingStatus } from 'src/modules/models/booking/booking-status';
import { SystemService } from "src/modules/services/system.service";
import { PublicBookingService } from 'src/modules/services/public-web/public-booking.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { SetFacilitySearchData } from 'src/modules/store/loading/actions';
import { WasteBookingSettings } from 'src/modules/models/settings/waste-booking/waste-booking-setting';
import { BOOKING_CART, PAGE_TYPE } from 'src/modules/models/public-web/enum';
import { ResetBlock, ResetSeat } from 'src/modules/store/booking-application/Configuration.action';
import { PauseBookingSession, ResetPackages, ResetState } from 'src/modules/store/public-web/public-web-actions';
import { PublicBookingSettings } from 'src/modules/models/settings/public-booking/public-booking-setting';
import { PBUtility } from 'src/modules/public-web/pb-utility';
import { BookingCart } from 'src/modules/public-web/components/layout/models/bookingCart.model';
import { BookingPackages } from 'src/modules/models/booking/booking-packages';
import { SystemOption } from 'src/modules/models/public-web/SystemOption';


@Component({
  selector: 'opt-sub-header',
  templateUrl: './sub-header.component.html'
})
export class SubHeaderComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  ApiUrl: string;
  RegularBookingPortalUrl: string;
  WasteBookingPortalUrl: string;
  PublicBookingPortalUrl: string;
  CasualBookingPortalUrl: string;
  PortalType: string;
  cart$: Observable<ICart>;
  minAmount: number;
  maxAmount: number;
  pwMaxAmount: number;
  customer_type: string;
  totalCount: number;
  cart_subscriber: any;
  packageClassId: number;
  showHomeIcon: boolean = true;
  isAllImmediateConfirmed: boolean;
  systemDefaultPriceConsessionId: number;
  isSystemAllowedImmediateConfirmation: boolean;
  isLoggedIn: boolean;
  customerDefaultPriceConsessionId: number;
  RegularVenueHire: number = 0;
  isDisplayCart: boolean = true;
  storeBookingIds: any[];
  CustomerDetails: any;
  isInternalUser: boolean;
  // waste collection Portal
  wmCartCount: number = 0;
  WMTotalCartAmount: number;
  isWmCustomerServiceProcess = false;
  wmInternalUser$: Observable<UserDataState>;
  wmCustomerDetails: WmCustomer;
  isWasteBookingPortal = false;
  wasteBookingData: WasteBookingSettings;
  publicConfig$: Observable<IPBReducer>;
  BOOKING_CART = BOOKING_CART;
  isPublicBookingPortal = false;
  IsInternalUserPortal: boolean;
  selectedLanguage: string = "EN";
  isDisplayRegularHireLink: boolean = false;
  isDisplayCasualHireLink: boolean = false;
  languages: any = [];
  appSetting: AppSetting;
  booking: Booking;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('mySelect') mySelect;
  emptyUserIconImgUrl: string;
  homeBaseUrl: string;
  bookingType: BOOKING_CART;
  isPendingChanges: any;
  editBookingButtonConfiguration = (environment.PublicBookingSetting as PublicBookingSettings).editBookingButtonConfiguration;
  bookingPackages: BookingPackages[];
  isBooking: boolean = false;
  publicWebSession$: Observable<IPublicWebSession>;
  bookingSession: SystemOption;
  constructor(
    private systemService: SystemService,
    private store: Store<any>,
    private router: Router,
    private currencyPipe: CurrencyFormat,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private _wmWasteTypeApiService: WmWasteTypeApiService,
    private _regualrbookingService: RegualrBookingService,
    private wmCommonService: WmCommonService,
    private customerService: CustomerService,
    private translate: TranslateService,
    private bookingService: PublicBookingService,
    private _bookingService: BookingService,
    private snackBar: MatSnackBar,
  ) {
    super();
    this.showHomeIcon = window.location.hash != "#/facility";
    this.systemDefaultPriceConsessionId = 0;
    this.customerDefaultPriceConsessionId = 0;
    this.isSystemAllowedImmediateConfirmation = false;
    this.cart$ = this.store.select("cart"); //.subscribe((state => this.cart = state))
    this.ApiUrl = environment.ApiUrl;
    if (window["ShowSessionOut"] == "1") {
      window["ShowSessionOut"] = "";
      // this._snackBar.open("Session Timeout", "", {
      //    duration: 2000,
      //});
    }

    this.wmInternalUser$ = this.store.select(selectUserDataState);
    this.publicConfig$ = this.store.select("PBReducer");
    this.publicWebSession$ = this.store.select(PBSelector.selectSessionTimers);
    this.appSetting = environment.AppSetting as AppSetting;
  }

  ngOnDestroy() {
    if (this.cart_subscriber) this.cart_subscriber.unsubscribe();
  }

  ngOnInit() {
    this.wasteBookingData = (environment.WasteBookingSetting as WasteBookingSettings);
    this.RegularBookingPortalUrl = environment.RegularBookingPortalUrl;
    this.WasteBookingPortalUrl = environment.WasteBookingPortalUrl;
    this.PublicBookingPortalUrl = environment.PublicWebPortalUrl;
    this.CasualBookingPortalUrl = environment.CasualBookingPortalUrl;
    this.isInternalUser = environment.IsInternalUser;
    this.PortalType = environment.PortalType;
    this.isSystemAllowedImmediateConfirmation =
      environment.ImmediateConfirmation.Allow;
    this.systemDefaultPriceConsessionId =
      environment.ImmediateConfirmation.DefaultPriceConsessionId;
      
    this.emptyUserIconImgUrl = "../dist/assets/images/user-icon.png";
    this.homeBaseUrl = environment.HomeBaseUrl;

    this.store.select(LanguageSelector.getLanguages).safeSubscribe(this, storedLanguages => {
      this.languages = !!storedLanguages ? storedLanguages : [];
    });

    this.selectedLanguage = !!this.translate.currentLang ? this.translate.currentLang.toUpperCase() : '';
    this.translate.onLangChange.safeSubscribe(this, (event: LangChangeEvent) => {
      this.selectedLanguage = !!this.translate.currentLang ? this.translate.currentLang.toUpperCase() : '';
    });

    if (this.isInternalUser) {
      this.getInternalUserData();
    }

    this.cart_subscriber = this.cart$.subscribe(state => {
      //console.log("subheader cart subscription")
      if ((environment.BookingExpiryInMinutes && environment.BookingExpiryInMinutes != 0) && state.LastUpdatedTime != null && isCardExpired(state.LastUpdatedTime)) {
        this.store.dispatch(new ClearCart());
      }
      this.populateCartDetails(state);
      //let _bookingType = state.contact.bookingTypeList.filter(x => x.id == "1");
      //if (_bookingType.length > 0) {
      //    this.RegularBookingPortalUrl = environment.RegularBookingPortalUrl;
      //} else {
      //    this.RegularBookingPortalUrl = environment.CasualBookingPortalUrl;
      //}
      this.isAllImmediateConfirmed = state.isAllImmediateConfirmed;
      this.isLoggedIn = (state.contact != null && state.contact.firstName != null && state.contact.firstName != "") ? true : false;
      this.customerDefaultPriceConsessionId = (this.isLoggedIn) ? state.contact.client.priceConcessionId : 0;
      this.totalCount = 0;
      let thisPage = this;


      let minAmount = 0;
      let maxAmount = 0;
      this.packageClassId = (state.packageFilter != null && state.packageFilter != undefined) ? state.packageFilter.PackageClass : 0;
      if (state.addedFacilites != undefined && state.addedFacilites.length > 0) {
        state.addedFacilites.forEach(function (fc) {

          minAmount += (fc.item.calculatedMinPrice && fc.item.calculatedMinPrice.priceIncludingTax) ? fc.item.calculatedMinPrice!.priceIncludingTax :
            ((fc.item.calculatedMaxPrice && fc.item.calculatedMaxPrice.priceIncludingTax) ? fc.item.calculatedMaxPrice!.priceIncludingTax : 0);
          maxAmount += (fc.item.calculatedMaxPrice && fc.item.calculatedMaxPrice.priceIncludingTax) ? fc.item.calculatedMaxPrice!.priceIncludingTax :
            (fc.item.calculatedMinPrice && fc.item.calculatedMinPrice.priceIncludingTax) ? fc.item.calculatedMinPrice!.priceIncludingTax : 0;

          if (fc.upsellSummaries && fc.upsellSummaries.length > 0) {
            fc.upsellSummaries.forEach(function (fcUpsell) {
              minAmount += (fcUpsell.minPrice && fcUpsell.minPrice.priceIncludingTax) ? fcUpsell.minPrice!.priceIncludingTax :
                ((fcUpsell.maxPrice && fcUpsell.maxPrice.priceIncludingTax) ? fcUpsell.maxPrice!.priceIncludingTax : 0);
              maxAmount += (fcUpsell.maxPrice && fcUpsell.maxPrice.priceIncludingTax) ? fcUpsell.maxPrice!.priceIncludingTax :
                (fcUpsell.minPrice && fcUpsell.minPrice.priceIncludingTax) ? fcUpsell.minPrice!.priceIncludingTax : 0;
              thisPage.totalCount++;
            })
          }
          thisPage.totalCount++;
        })
      }

      this.minAmount = parseFloat(this.currencyPipe.transform(minAmount, true));
      this.maxAmount = parseFloat(this.currencyPipe.transform(maxAmount, true));
    });

    if(this.PortalType ==='4'){
      this.isPublicBookingPortal = true;
      this.publicConfig$.subscribe((config) => {
        this.pwMaxAmount = 0;
        this.bookingType = config.bookingMode;
        if (this.isPendingChanges === undefined) {
          if (this.bookingType == this.BOOKING_CART.NEW_BOOKING) {
            this.isPendingChanges = true;
          } else if (this.bookingType == this.BOOKING_CART.EDIT_BOOKING && config.booking?.dueAmount != config.freezedBooking?.dueAmount) {
            this.isPendingChanges = true;
          } else {
            this.isPendingChanges = false;
          }
        }

        if (
          config.booking &&
          config.booking.bookingPackages &&
          config.booking.bookingPackages.length > 0
        ) {
          this.bookingPackages = config.booking.bookingPackages;
          this.pwMaxAmount = config.booking.grossAmount;
          this.isBooking = true;
        } else {
          this.bookingPackages = [];
          this.isBooking = false;
        }
      });

      this.publicWebSession$.subscribe((config) => {
        if (config.bookingSession) {
          this.bookingSession = config.bookingSession;
        }
      });
      if (!((this.bookingType === BOOKING_CART.EDIT_BOOKING && this.editBookingButtonConfiguration.continueShoppingEditBooking.visible)) && !(this.isBooking && this.isPendingChanges)) {
        this.store.dispatch(new ResetPackages());
        this.store.dispatch(new ResetState());
        this.store.dispatch(new ResetSeat());
        this.store.dispatch(new ResetBlock());
      }
    } 
    else if (this.PortalType === '5') {
      this.isWasteBookingPortal = true;

      this._wmWasteTypeApiService.getCollectionLocations().safeSubscribe(this, data => {
        this.store.dispatch(new GetCollectionLocationsSuccessAction(data));
      }
      );

      // const cacheRefreshTimeInMins = this.wasteBookingData.bookingSetting.addressDataCacheTimeInMinutes ? this.wasteBookingData.bookingSetting.addressDataCacheTimeInMinutes : 10080;
      // this.store.select(selectSuburbs).safeSubscribe(this, (res) => {
      //   if (res) {
      //     const lastUpdatedTime = res.updatedDate;
      //     if (lastUpdatedTime) {
      //       const newTime = new Date(new Date().setMinutes(new Date(lastUpdatedTime).getMinutes() + cacheRefreshTimeInMins));
      //       if (new Date().getTime() >= newTime.getTime()) {
      //         this.store.dispatch(new GetStreetsSuburbsAction());
      //       }
      //     } else {
      //       this.store.dispatch(new GetStreetsSuburbsAction());
      //     }
      //   }
      // });

      this.store.select(selectIsInternalUserPortal).safeSubscribe(this, (res) => {
        this.isInternalUser = res;
      });
      if (this.compareWasteUrl(environment.WasteBookingInternalPortalUrl, window.location.href)) {
        this.store.dispatch(new SignOut());
        this.CustomerDetails = null;
        if (!this.isInternalUser) {
          this.store.dispatch(new ClearAllBookingPackagesAction());
          this.store.dispatch(new GetSavedBookingResetAction());
        }
        this.store.dispatch(new WMIsInternalUserPortalAction(true));
      } else if (this.compareWasteUrl(environment.WasteBookingPortalUrl, window.location.href)) {
        if (this.isInternalUser) {
          this.store.dispatch(new ClearAllBookingPackagesAction());
          this.store.dispatch(new GetSavedBookingResetAction());
          this.store.dispatch(new ResetInternalUserDataAction());
        }
        this.store.dispatch(new WMIsInternalUserPortalAction(false));
      }

      if (this.isInternalUser) {
        this.route.queryParamMap.subscribe(params => {
          if (params.get('From') === 'CB') {
            this.store.dispatch(new GetInternalUserDataResetAction());
            this.store.dispatch(new GetInternalUserDataAction());
            const contactId = params.get('contactId');
            this.customerService.SelectContact(contactId).subscribe(data => {
              if (data != null) {
              //  console.log(data);

                const wmCustomer = new WmCustomer();
                wmCustomer.clientId = +data.client.id;
                wmCustomer.name = data.client.clientName;
                wmCustomer.tel = data.communicationMethods[0].value;
                wmCustomer.address = this.getAddress(data.invoiceAddress);
                wmCustomer.email = data.invoiceEmailAddress;
                wmCustomer.contactId = +data.id;
                wmCustomer.client = data.client;

                this.CustomerDetails = wmCustomer;
                if (this.CustomerDetails) {
                  this.store.dispatch(new ClearSelectedCustomerAction());
                  this.store.dispatch(new GetPreviousWasteBookingsResetAction());
                  this.store.dispatch(new GetUpComingWasteBookingsResetAction());
                  this.store.dispatch(new SelectCustomerAction(this.CustomerDetails));
                  this.wmCommonService.setDefaultPageIndex(1);
                }
              }
            });

          }

        });
      }

      this.store.select('WMReducer').safeSubscribe(this, (res) => {
        if (res) {
          if (res.bookingPackageState) {
            this.wmCartCount = res.bookingPackageState.bookingPackageData.length;
            if (res.bookingPackageState.totalAmount >= 0) {
              this.WMTotalCartAmount = res.bookingPackageState.totalAmount;
            }
          } if (res.selectedCustomer) {
            this.CustomerDetails = res.selectedCustomer;
          }
        }
      });

      this.wmInternalUser$.safeSubscribe(this, (res) => {
        if (res && res.userData) {
          this.isWmCustomerServiceProcess = true;
        } else {
          this.isWmCustomerServiceProcess = false;
        }
      });
    }
  }

  populateCartDetails(state) {
    if (state.contact && state.contact.id) {

      /* RB linked to be disabled when:
      there are no booking types available
      there are only casual or waste booking types are avaialable
      */

      let _bookingTypeList;

      if (state.contact && state.contact.bookingTypeList) {
        _bookingTypeList = state.contact.bookingTypeList;
      } else if (state.contact && state.contact.client && state.contact.client.bookingTypes) {
        _bookingTypeList = state.contact.client.bookingTypes;
      }


      var regularBookingDetails = (environment.RegularBookingSetting as RegularBookingSettings)
      const bookingtypes = regularBookingDetails?.booking?.bookingTypes;

      if (_bookingTypeList && _bookingTypeList.length > 0) {

        //let _notCasualorWaste = _bookingTypeList.filter(e => Number(e.id) != 1 );
        let _RBAllowed = _bookingTypeList.filter(e => Number(e.id) == Number(bookingtypes.map(bk => bk.id).find(bk => Number(e.id) == Number(bk))));
        this.RegularVenueHire = (_RBAllowed?.length) ? 1 : 0;

      } else {
        this.RegularVenueHire = 0;
      }

      if (environment.PortalType != "" && state.addedFacilites) {
        this.isDisplayCart = ((environment.PortalType == "2" || environment.PortalType == "3") && state.addedFacilites.length == 0) ? false : true;
      }
      if (this.CustomerDetails == null && state.contact.id) {
        this.CustomerDetails = state.contact;
      }


      if (this.isInternalUser && state.InternalUserData != null && state.InternalUserData.userId != undefined) {

        /*Displaying casual hire link for
        internal users based on contact
        selected and url is setup*/
        if (environment.CasualPortalInternalUrl != '' && environment.CasualPortalInternalUrl != undefined) {
          this.isDisplayCasualHireLink = true;
        }
        /*Displaying regualr hire link for
         internal users based on contact selected,
         url is setup and user right available*/
        if (environment.RegularPortalInternalUrl != '' && environment.RegularPortalInternalUrl != undefined && this.RegularVenueHire == 1) {
          this.isDisplayRegularHireLink = true;
        }
      }

      if (!this.isInternalUser && this.RegularBookingPortalUrl != '' && this.RegularBookingPortalUrl != undefined && this.RegularVenueHire == 1) {
        this.isDisplayRegularHireLink = true;
      }

    }



    // Displaying casual hire link for external users regardless of contact selected or not
    if (!this.isInternalUser && this.CasualBookingPortalUrl != '' && this.CasualBookingPortalUrl != undefined) {
      this.isDisplayCasualHireLink = true;
    }

  }


  myBooking(isIntlUser: boolean) {
    var ul;
    if (isIntlUser) {
      //if (environment.PortalType == "3") {
      ul = environment.ApiUrl + '/Booking/CasualInternalPortal#/bookings';
      //} else {
      //  ul = environment.CasualPortalInternalUrl + '#/bookings';
      //}

    }
    else {
      ul = environment.ApiUrl + '#/bookings';
    }
    window.open(ul, "_self");
  }

  myprofile() {
    if (this.getMenuVisibility('MyProfile'))
      this.router.navigate(["/my-profile"]);
  }

  cartClick() {
    if(this.PortalType === "4"){
      window.open(
        environment.PublicWebPortalUrl +
        "#/booking-cart",
        "_self"
      );
    }else if (this.PortalType === "5") {
      this.store
        .select(selectBookingPackageState)
        .safeSubscribe(this, (data) => {
          const bookings = data.bookingPackageData;
          if (bookings && bookings.length > 0 && bookings[0].bookingId) {
            this.router.navigate(["/wm-booking-cart", bookings[0].bookingId]);
          } else {
            this.router.navigate(["/wm-booking-cart"]);
          }
        });
    } else {
      this.router.navigate(["/booking-cart"]);
    }
  }

  SignIn() {
    if (this.isLoggedIn) {
      if (this.getMenuVisibility('MyProfile'))
        this.router.navigate(['/my-profile']);
    } else {
      let url = "";
      if (environment.PortalType == "3") {
        url = environment.ApiUrl + '#/client/login?regularBooking=1';
      } else if (environment.PortalType == "5") {
        url = environment.ApiUrl + '#/client/login?wasteBooking=1';
      } else {
        url = environment.ApiUrl + '#/client/login';
      }
      window.open(url, '_self');
    }
  }
  signOut() {
    this.RegularVenueHire = 0;
    environment.LoggedInUser = '';
    this.store.dispatch(new SignOut());

    if (this.appSetting.FacilityPageSetting.FacilitySearchFilterRememberOption && this.appSetting.FacilityPageSetting.FacilitySearchFilterRememberOption==2) {
      this.store.dispatch(new SetFacilitySearchData(null));
    }

    if (this.isInternalUser) {
      if (window.location.href.includes('CasualInternalPortal')) {
        const logout = environment.ApiUrl + '/Booking/Logout?portaltype=7';
        window.open(logout, '_self');
      } else if (window.location.href.includes('RegularInternalPortal')) {
        const logout = environment.ApiUrl + '/Booking/Logout?portaltype=8';
        window.open(logout, '_self');
      }
    } else {
      if (this.appSetting.AnyPortalSignOutRedirectToSignIn) {
        this.router.navigate(['/client/login']);
      }
      else if (environment.PortalType == "3") {
        var ul = environment.ApiUrl + "#/client/login?regularBooking=1";
        window.open(ul, "_self");
      }
      /* else if (environment.PortalType != "5")
      {
          window.location.href = this.CasualBookingPortalUrl + "#/facility";
      }*/
      else if (environment.PortalType === "5") {
        this.wmCommonService.setUserLogStatus(true);
        this.store.dispatch(new SignOut());
        this.store.dispatch(new WMSignOutAction());
        if (this.isWmCustomerServiceProcess) {
          const logout = environment.ApiUrl + "/Booking/Logout";
          window.open(logout, "_self");
        } else {
          this.router.navigate(["/"]);
        }
      } else if (window.location.href.toLowerCase().endsWith('#/bookings') || window.location.href.toLowerCase().endsWith('#/my-profile') || window.location.href.toLowerCase().endsWith('#/change-password')) {
        if (environment.PortalType === "4") {
          this.store.dispatch(new ResetState());
          this.store.dispatch(new ResetSeat());
          this.store.dispatch(new ResetBlock());
          this.customerService.DeleteSession().subscribe();
          window.open(
            environment.ApiUrl + "#/client/login?DirectLoginFromPublicWeb=1",
            "_self"
          );
        }
        else {
          this.router.navigate(['/client/login']);
        }
      }
      else {
        window.location.href = this.CasualBookingPortalUrl + '#/facility';
      }
    }


  }

  testClick() {
    var cn = new Contact();
    cn.firstName = "AAAAA";
    //this.store.dispatch(new SetContact(cn));
    this.router.navigate(['/facility-detail']);

  }
  changePassword() {
    this.router.navigate(['/change-password']);
  }
  venueSearch() {
    this.store.dispatch(new SetFacilitySearchData(null));
    let isIntlUser = this.isInternalUser;
    if (this.totalCount > 0) {
      let dialogRef = this.dialog.open(DeleteConfirmationComponent, {
        data: {
          message: 'Starting a New Casual Hire will clear all the items in the Cart. Please confirm to proceed',
          yesText: 'Confirm',
          noText: 'Cancel'
        },
        panelClass: ['custom-dialog-container', 'w30modal'],
        height: 'auto',
        width: '30%'
      });
      const sub = dialogRef.componentInstance.onAdd.subscribe((data: any) => {
        if (data == true) {

          let  _immediateBookingFailedStatus = (environment.AppSetting as AppSetting).BookingConfig && (environment.AppSetting as AppSetting).BookingConfig.ImmediateBookingFailedStatus.toString() ? true : false;

          if(this.booking && this.booking.id && _immediateBookingFailedStatus){
            //make the already existing booking expire
            this.expireBooking(this.booking.id);

          }
          this.store.dispatch(new ClearCart());// clear cart

          if (isIntlUser) {
            window.location.href = environment.CasualPortalInternalUrl + '#/facility';
          }
          else {
            window.location.href = this.CasualBookingPortalUrl + '#/facility';
          }
        }
      });
    } else {
        this.store.dispatch(new ClearCart());// clear cart
          if (isIntlUser) {
            window.location.href = environment.CasualPortalInternalUrl + '#/facility';
          }
          else {
            window.location.href = this.CasualBookingPortalUrl + '#/facility';
          }
    }
  }


  linkClick(event) {
    //  this.store.dispatch(new FacilitySearchLoading());

    Utility.urlHistorty.push(this.router.url);

  }

  NewBooking() {

    var url;
    if (this.isInternalUser) {
      url = environment.RegularPortalInternalUrl;
    }
    else {
      url = environment.RegularBookingPortalUrl;
    }


    if (url.endsWith('/')) {
      url = url.slice(0, url.length - 1)
    }
    url = (url + '#/home?isNewBooking=1');
    window.open(url, "_self")
  }

  WmGoToHomePage() {

    if (environment.PortalType == "5") {
      let home = environment.WasteBookingPortalUrl;
      if (this.isWmCustomerServiceProcess) {
        home = environment.WasteBookingInternalPortalUrl;
      }
      window.location.href = home;
    }
    else if (environment.PortalType == "3") {
      var url = environment.RegularBookingPortalUrl;
      window.open(url, "_self")
    }
    else {
      var url = environment.CasualBookingPortalUrl;
      window.open(url, "_self")
    }
  }

  removeCustomer() {
    this.CustomerDetails = null; // make cutomer details null

    this.isDisplayCasualHireLink = false;
    this.isDisplayRegularHireLink = false;

    //remove the customer details from cart first else on disptch customer details reloads
    this.store.dispatch(new SetContact(this.CustomerDetails, null, null, null, null, null));
    this.customerService.DeleteSession().subscribe(); // remove contact details from MVC level
    this.store.dispatch(new SelectCustomerAction(null));
    this.store.dispatch(new RemoveAddressAction());
    this.store.dispatch(new GetUpComingWasteBookingsResetAction());
    this.store.dispatch(new GetPreviousWasteBookingsResetAction());
    this.wmCommonService.setUserLogStatus(true);
    this.store.dispatch(new ClearAllBookingPackagesAction());

    if (window.location.href.includes('wm-booking-cart')) {
      this.wmGoToHomePage();
    }
  }



  wmGoToHomePage() {

    if (environment.PortalType == "5") {
      let home = environment.WasteBookingPortalUrl;
      if (this.isWmCustomerServiceProcess) {
        home = environment.WasteBookingInternalPortalUrl;
      }
      window.location.href = home;
    }
    else if (environment.PortalType == "3") {
      var url = environment.RegularBookingPortalUrl;
      window.open(url, "_self")
    }
    else {
      var url = environment.CasualBookingPortalUrl;
      window.open(url, "_self")
    }
  }


  clientSearch() {
    const dialogRef = this.dialog.open(ClientSearchComponent, {
      panelClass: ['wm-client-search-popup'],
      height: '85%',
      width: '100vw',
      data: {
        portalType: environment.PortalType
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {



        if (this.isWasteBookingPortal) {

          const wmCustomer = new WmCustomer();
          wmCustomer.clientId = +result.clientId;
          wmCustomer.name = result.name;
          wmCustomer.tel = result.communicationMethods[0].value;
          wmCustomer.address = result.address;
          wmCustomer.email = result.email;
          wmCustomer.contactId = +result.contactId;
          wmCustomer.client = result.client;
          this.CustomerDetails = wmCustomer;
          if (this.CustomerDetails) {
            this.store.dispatch(new GetPreviousWasteBookingsAction(this.CustomerDetails.contactId, 1));
            this.store.dispatch(new GetUpComingWasteBookingsAction(this.CustomerDetails.contactId, 1));
            this.store.dispatch(new SelectCustomerAction(this.CustomerDetails));
            this.wmCommonService.setDefaultPageIndex(1);

          }
        } else {

          this.CustomerDetails = result;
          this.CustomerDetails.id = result.contactId;
          this.CustomerDetails.communicationMethods = result.communicationMethods;
          if(result.client && result.client.clientVenues && result.client.clientVenues.length>0){
            this.CustomerDetails.clientVenueIds=result.client.clientVenues;
          }

          this.customerService.SelectInternalUser(this.CustomerDetails.id).subscribe((data) => {
            let _redirectFullUrl = environment.ApiUrl + '/Booking/CasualInternalPortal#/bookings';
            
            this.CustomerDetails.firstName = data.firstName;
            this.CustomerDetails.lastName = data.lastName;

            this.store.dispatch(new SetContact(this.CustomerDetails, null, null, null, null, null));
            this.customerService.clientLoginOtherInternalPortal(this.CustomerDetails.id);
          },
            err => {
              console.log(err);
            });


        }

      }
    });
  }



  addOrEditClient(customer?) {
    if (this.isWasteBookingPortal) {
      let data;
      if (customer) {
        // Edit customer
        data = {
          type: "EDIT CUSTOMER",
          cusId: customer.clientId, // using client id for individual clients
        };
      } else {
        // new customer
        data = {
          type: "ADD CUSTOMER",
        };
      }
      const addClientDialogRef = this.dialog.open(AddEditClientComponent, {
        panelClass: ["wm-client-search-popup"],
        height: "90%",
        width: "100vw",
        data: {
          data: data,
          portalType: environment.PortalType
        }
      });
      addClientDialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const addedCustomerDetails = new WmCustomer();
          addedCustomerDetails.tel =
            result.clientDetails.communicationMethods[0].value;
          addedCustomerDetails.name = result.clientDetails.clientName;
          addedCustomerDetails.email =
            result.contactDetails.attributes.invoiceEmailAddress;
          addedCustomerDetails.clientId = result.savedClientId;
          addedCustomerDetails.contactId = result.contactDetails.id;
          addedCustomerDetails.client = result.clientDetails;
          addedCustomerDetails.address = this.getAddress(result.clientDetails.contactAddresses[0]);
          this.CustomerDetails = addedCustomerDetails;
          if (this.CustomerDetails) {
            this.store.dispatch(new GetPreviousWasteBookingsAction(this.CustomerDetails.contactId, 1));
            this.store.dispatch(new GetUpComingWasteBookingsAction(this.CustomerDetails.contactId, 1));
            this.store.dispatch(new SelectCustomerAction(this.CustomerDetails));
            this.wmCommonService.setDefaultPageIndex(1);
          }
        }
      });
    }
  }

  getAddress(address) {
    if (address != null) {
      const addrs = new WmAddress();
      addrs.Address1 = address.address1;
      addrs.Address2 = address.address2;
      addrs.StateCode = address.stateCode;
      addrs.City = address.city;
      addrs.State = address.state || address.county;
      addrs.PostCode = address.postCode;
      return WmUtility.conCatAddress(addrs);
    }
  }

  RedirectToPortal() {
    var url = "";
    if (environment.PortalType == "3") {
      if (this.isInternalUser) { // regular portal internal user
      url = environment.RegularPortalInternalUrl;
      }else{                     // regular portal external user
      url = environment.RegularBookingPortalUrl;
      }
    } else if (environment.PortalType == "5") {
      if (this.isInternalUser) { // waste portal internal user
        url = environment.WasteBookingInternalPortalUrl;
      } else {                   // waste portal external user
        url = environment.WasteBookingPortalUrl;
      }
    } else if (environment.PortalType == "4") {
      if(this.homeBaseUrl.trim()) { // redirect to the IMG home page
        window.location.href = this.homeBaseUrl;
      }
      else {
        url = environment.PublicWebPortalUrl;
      }
    } else {
      if (this.isInternalUser) { //casual portal internal user
        url = environment.CasualPortalInternalUrl;
      } else {                   //casual portal external user
        url = environment.CasualBookingPortalUrl;
      }
    }
    window.open(url, "_self")
  }

  redirectToDashboard() {
    const url = environment.OptimoHomeUrl;
    window.open(url, "_self");
  }

  WasteBooking() {
    const url = environment.WasteBookingPortalUrl;
    window.open(url, "_self");
  }
  PublicBooking() {
    const url = environment.PublicWebPortalUrl;
    window.open(url, "_self");
  }

  compareWasteUrl(enUrl, pageUrl) {
    return (
      enUrl
        .toLowerCase()
        .substring(enUrl.toLowerCase().lastIndexOf("/") + 1) ===
      pageUrl
        .toLowerCase()
        .split("#")[0]
        .substring(pageUrl.toLowerCase().split("#")[0].lastIndexOf("/") + 1)
    );
  }

  getInternalUserData() {
    this._regualrbookingService.getInternalUserData().safeSubscribe(this, result => {
      if (result != undefined) {
        //console.log("result gettings session data of internal user=>> ", result);
        var internalUser = new UserData();
        internalUser.userId = result.userId;
        internalUser.firstName = result.firstName
        internalUser.lastName = result.lastName
        internalUser.contactEmail = result.contactEmail
        internalUser.contactId = result.contactId;
        internalUser.userImageUrl = result.userImageUrl;
        this.store.dispatch(new SetInternalUserData(internalUser));
      }
    });
  }

  switchLanguage() {
    //Call below line whenever language is changed
    this.bookingService.saveSelectedLanguage(this.selectedLanguage).safeSubscribe(this, () => { this.translate.use(this.selectedLanguage.toLowerCase()); });
  }

  getMenuName(id, defaultValue) {
    let menu: MenuSettings = this.appSetting.MenuSettings ? this.appSetting.MenuSettings.find(menu => menu.Id == id) : null;

    if (menu)
      if (menu.Value != null && menu.Value != '')
        return 'NAVBAR.' + menu.Value;
      else
        return 'NAVBAR.' + defaultValue;
    else
      return 'NAVBAR.' + defaultValue;
  }

  getMenuVisibility(id) {
    let menu: MenuSettings = this.appSetting.MenuSettings ? this.appSetting.MenuSettings.find(menu => menu.Id == id) : null;

    if (menu)
      return !(menu.Disabled)
    else
      return true;
  }

  @HostListener('window:scroll', [])
  scrollHandler() {
    // this.trigger.closeMenu();
    // this.mySelect.close();
  }

  expireBooking(bookingiId){
      let _immediateBookingFailedStatus = (environment.AppSetting as AppSetting).BookingConfig.ImmediateBookingFailedStatus.toString();

      if (_immediateBookingFailedStatus && Number(_immediateBookingFailedStatus) != 0) {
          var _bk = new Booking();
          _bk.id = bookingiId;
          _bk.bookingStatus = new BookingStatus();
          _bk.bookingStatus.id = (environment.AppSetting as AppSetting).BookingConfig.ImmediateBookingFailedStatus.toString();
          _bk.type = "booking";
          _bk.isExpired = true;

          this._bookingService.BookingPatch(bookingiId, _bk).subscribe(result => {
              if (!result.body.isError) {
                  console.log("booking expire successfull");
              }

              // this.store.dispatch(new FacilitySearchLoaded());
          });
      }
  }
}
