<div class="container">
    <div class="main_search">
        <div class="mmc_flex--container">
            <div class="mmc_flex__item--inherit facility-search-input-cage" [ngClass]="isEnableAvailableFacilitiesOption ? 'isfacilityinput' : 'input_cage'">
                <!--<mat-form-field class="example-full-width remove_underline">
                    <mat-placeholder class="pw-txt-h4">Search for venue, suburbs</mat-placeholder>
                    <input [(ngModel)]="searchstring" matInput value="Search Venue, Facility or Location" class="mat-font--change search-input" (keyup.enter)="onEnter()" (ngModelChange)="modelChanged($event)" name="facilityText">
                </mat-form-field>-->
                <div class="mmc_flex--container facility-search-input">
                    <div class="mmc_flex__item">
                        <mat-form-field class="example-full-width remove_underline">
                            <mat-placeholder class="pw-txt-h4 search-input-before">{{facilitySearchTextPlaceHolder}}</mat-placeholder>
                            <input [(ngModel)]="searchstring" matInput value="Search Venue, Facility or Location" class="mat-font--change search-input" (keyup.enter)="onEnter()" (ngModelChange)="modelChanged($event)" name="facilityText"  type="text" role="textbox"> 
                        </mat-form-field>
                    </div>
                    <div class="mmc_flex__item--inherit mmc_flex__item--vcenter">
                        <i class="material-icons" [matMenuTriggerFor]="searchmenu">info_outline</i>
                        <mat-menu #searchmenu="matMenu" class="search-info-panel">
                            <div class="pw-txt-h3_small pw-text-bold wp-mb-10">Search tips:</div>
                            <ul>
                                <li>Name of venue</li>
                                <li>Location or suburb</li>
                                <li>Type of facilities (eg: sports ground, indoor, outdoor, halls), Type of activity (eg: netball, squash, private function, wedding, major event, personal training etc.)</li>
                                <li>Facilities with specific attributes, equipment and services (projector, catering, floodlights etc. )</li>
                            </ul>
                        </mat-menu>
                    </div>
                </div>
            </div>

            <div class="mmc_flex__item filter_cage">
                <div class="mmc_flex--container">
                    <div class="mmc_flex__item--inherit  pax" style="display:none;">
                        <form class="example-form">
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="Pax" value="10">
                            </mat-form-field>
                        </form>
                    </div>

                    <div class="mmc_flex__item--inherit pax check-box" *ngIf="isEnableAvailableFacilitiesOption">
                        <div class="mmc_flex--container" style="align-items:center">
                            <mat-checkbox [(ngModel)]="showAvailableFacilities" (change)="getAvailableFacility($event)">
                                <span class="check-text">Available facilities</span>
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="mmc_flex__item--inherit date remove-table-border">
                        <mat-form-field class="remove_underline">
                            <mat-placeholder class="placeholder pw-txt-b2">Date</mat-placeholder>
                            <input class="pw-txt-b1 pw-text-semi-bold date-small-font" matInput [matDatepicker]="picker" (focus)="picker.open()" [min]="minDate" placeholder="" [(ngModel)]="date" (ngModelChange)="dateChanged()" readonly>
                            <mat-datepicker-toggle class="pw-ico-color__tertiary" matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="mmc_flex__item--inherit time">
                        <mat-form-field class="example-full-width" [matMenuTriggerFor]="timemenu">
                            <mat-placeholder class="placeholder pw-txt-b2">Time</mat-placeholder>
                            <input class="pw-txt-b1 pw-text-semi-bold" matInput placeholder="" value={{timelable}} readonly>
                            <mat-icon class="pw-ico-color__tertiary" matSuffix>access_time</mat-icon>
                        </mat-form-field>
                        <mat-menu yPosition="below" xPosition="before" #timemenu="matMenu" class="time-popup" [overlapTrigger]="false">
                            <div class="mmc_flex--container radio-container mb-3" (click)="$event.stopPropagation();" (keydown.tab)="$event.stopPropagation();" tabindex="0">
                                <div class="mmc_flex__item">
                                    <mat-radio-group class="mmc_flex__item--hcenter mmc_fd--col pw-txt-b2" [(ngModel)]="timeSelection" aria-label="Select an option">
                                        <mat-radio-button value="Any">Any Time</mat-radio-button>
                                        <mat-radio-button value="Range">Specific Time</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>

                            <div class="mmc_flex--container time-container" (click)="$event.stopPropagation();" (keydown.tab)="$event.stopPropagation();" *ngIf="timeSelection=='Range'" tabindex="0">
                                <div class="mmc_flex__item add-padding table-no-border">
                                    <div class="mmc_flex--container">
                                        <div class="mmc_flex__item--vcenter clock">
                                            <i class="material-icons">access_time</i>
                                        </div>
                                        <div class="mmc_flex__item">
                                            <mat-placeholder class="placeholder pw-txt-b2">START TIME</mat-placeholder>
                                            <timepicker [(ngModel)]="StartTime" [min]="minStartDate" [max]="maxStartDate"
                                                        (ngModelChange)="startTimeChanged()"
                                                        (change)="startChange($event)"
                                                        (isValid)="isValidStartF($event)"></timepicker>
                                            <pre *ngIf="!isValidStart" class="alert alert-danger">Invalid time format</pre>
                                        </div>
                                    </div>
                                </div>
                                <div class="mmc_flex__item table-no-border">
                                    <div class="mmc_flex--container">
                                        <div class="mmc_flex__item--vcenter clock">
                                            <i class="material-icons">access_time</i>
                                        </div>
                                        <div class="mmc_flex__item">
                                            <mat-placeholder class="placeholder pw-txt-b2">END TIME</mat-placeholder>
                                            <timepicker [min]="minEndDate" [max]="maxEndDate" (isValid)="isValidEndF($event)"
                                                        [(ngModel)]="EndTime"
                                                        (ngModelChange)="endTimeChanged()"
                                                        (change)="endChange($event)"></timepicker>
                                            <pre *ngIf="!isValidEnd" class="alert alert-danger">Invalid time format</pre>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mmc_flex--container btn-content">
                                <div class="btn-date-picker btn__card main mmc_flex__item--vcenter" (click)="settimelable()" (keydown.enter)="settimelable()" [ngClass]="{'disable-div' : !isValidStart || !isValidEnd}" tabindex="0">
                                    APPLY
                                </div>
                            </div>
                        </mat-menu>
                    </div>

                    <div class="mmc_flex__item--inherit pax filter">
                        <div class="mmc_flex--container search-filter" mat-button [matMenuTriggerFor]="menufilter"  #advSearchMenuTrigger="matMenuTrigger" tabindex="0">
                            <div class="mmc_flex__item">
                                <i class="mr-0 mmc icon-filter-filled-tool-symbol icn-aminity pw-ico-color__quinary"></i>
                            </div>
                            <div class="mmc_flex__item pw-text-trans__upper pw-text-color__quinary pw-txt-b2_small">FILTER</div>
                        </div>
                        <mat-menu #menufilter="matMenu" class="filter--menu" tabindex="0">
                          <div class="mmc_flex--container" (click)="$event.stopPropagation();" (keydown.enter)="$event.stopPropagation();" tabindex="0">
                            <div class="mmc_flex__item" *ngIf="attendeesFilterSetting && attendeesFilterSetting.Visible">
                              <mat-form-field class="example-full-width">
                                <input matInput placeholder="{{attendeesFilterSetting.Label}}" [(ngModel)]="advancedfilterAtenndence" value="ENTER HERE" type="number" min="1" onkeypress="return ((event.charCode > 48 && event.charCode <= 57) || (event.charCode == 48 && this.value !=''))" autocomplete="off">
                              </mat-form-field>
                            </div>

                            <!-- Venue Filter -->
                            <div class="mmc_flex__item venue-type" *ngIf="venueTypesListSettings && venueTypesListSettings.Visible">
                                <mat-form-field>
                                  <mat-select multiple #VenuesTypeDropDOwn [ngModel]="advanceSelectedVenuesType" (ngModelChange)="advanceSelectedVenuesType" placeholder="{{venueTypesListSettings.Label}}">                    
                                    
                                    <!-- <input (keydown)="handleInput($event)" class="filter-search-facility" type="text" matInput  > -->
                                    <!-- <mat-form-field class="example-full-width remove_underline">
                                        <mat-placeholder class="pw-txt-h4 search-input-before">{{facilitySearchTextPlaceHolder}}</mat-placeholder>
                                        <input class="filter-search-facility" placeholder="Search Venues..." matInput [formControl]="searchFacilityListForDropdown" (keyup.enter)="onEnter()" type="text" role="textbox"> 
                                    </mat-form-field> -->

                                          <mat-option (onSelectionChange)="checkforselect($event,'advanceSelectedVenuesType')" style="color: black" *ngFor="let fv of venueTypeDropdown" [value]="fv?.Id" matTooltip="{{fv?.Name}}"
                                          [matTooltipPosition]="'above'">
                                              {{fv?.Name}}
                                          </mat-option>

                                          <div class="filter-apply-btn-wrapper">
                                            <button class="apply-btn btn__card main" mat-flat-button (click)="closeSelect()"> Apply </button>
                                          </div>

</mat-select>
                                </mat-form-field>
                            </div>


                            <div class="mmc_flex__item" *ngIf="facilityTypeFilterSetting && facilityTypeFilterSetting.Visible">
                             <mat-form-field>
                               <mat-select panelClass="venue-category-select-panel" [(ngModel)]="advanceSelectedFacilityType" placeholder="{{facilityTypeFilterSetting.Label}}">
                                 <mat-option value="0">Any</mat-option>
                                 <mat-option style="color: black" *ngFor="let ft of facilityTypeDropdown" [value]="ft?.id">
                                    {{ft?.name}}
                                 </mat-option>
                               </mat-select>
                             </mat-form-field>
                            </div>
                            <div class="mmc_flex__item" *ngIf="facilityListFilterSetting && facilityListFilterSetting.Visible">
                              <mat-form-field>
                                <mat-select panelClass="venue-select-panel" multiple [ngModel]="advanceSelectedFacility" (ngModelChange)="advanceSelectedFacility" placeholder="{{facilityListFilterSetting.Label}}">
                                  <!-- <mat-option (onSelectionChange)="checkforselect($event,'advanceSelectedFacility')" value="{{advanceSelectedFacilityValue}}">Any</mat-option> -->
                                  
                                    <!-- <input (keydown)="handleInput($event)" class="filter-search-facility" type="text" matInput  > -->
                                    <div class="filter-search-wrapper">
                                        <mat-form-field class="example-full-width remove_underline">
                                            <!-- <mat-placeholder class="pw-txt-h4 search-input-before">{{facilitySearchTextPlaceHolder}}</mat-placeholder> -->
                                            <input class="filter-search-facility" placeholder="Search Venues..." matInput [formControl]="searchFacilityListForDropdown"   (keydown.space)="$event.stopPropagation()" (keyup.enter)="onEnter()" type="text" role="textbox"> 
                                        </mat-form-field>
                                    </div>
                                    <mat-option (onSelectionChange)="checkforselect($event,'advanceSelectedFacility')" style="color: black" *ngFor="let fc of FacilityListForDropdown" [value]="fc?.Id" matTooltip={{fc?.Name}}>
                                        {{fc?.Name}}
                                    </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="mmc_flex__item btn-section">
                              <button class="btn login__submit font__large font__regular font__upper" (click)="advancedfilterSearch()" (keydown.enter)="advancedfilterSearch()" tabindex="0">
                                proceed
                              </button>
                            </div>
                          </div>
                        </mat-menu>
                    </div>

                    <div class="mmc_flex__item--inherit pax filter">
                        <div class="mmc_flex--container search-filter" mat-button (click)="clear()" (keydown.enter)="clear()" tabindex="0">
                            <div class="mmc_flex__item">
                                <i class="mr-0 mmc icon-clear icn-aminity pw-ico-color__quinary"></i>
                            </div>
                            <div class="mmc_flex__item pw-text-trans__upper pw-text-color__quinary pw-txt-b2_small">CLEAR</div>
                        </div>
                    </div>

                    <div class="mmc_flex__item--inherit btn--cage search-btn-component" (click)="searchClick()" (keydown.enter)="searchClick()" tabindex="0">
                        <div class="mmc_flex--container">
                            <div class="mmc_flex__item--inherit mmc_flex__item--vcenter btn--search" role="button">
                                <i class="material-icons">search</i>
                                <span class="btn-text pw-txt-h3_small pw-text-semi-bold">Search</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- this form facility-search.component.html -->
<div class="body-search">
    <!--<div class="container body-search-container" *ngIf="!((loader$|async).isLoading_facilitySearch); else spinner">-->
        <div class="container body-search-container">
            <div class="row">
                <div *ngIf="packageName" class="col col-md-12 col-sm-12 col-xs-12 col-lg-12" style="background-color: #efe6b3; border-radius: 3px;margin-top: 5px;">
                    <div style="padding:1rem;">
                        <p>Venues are filtered for the event  <B>{{packageName}}</B></p>
                    </div>
                </div>
            </div>

            <div class="mmc_flex--container count-cage">
                <div class="mmc_flex__item no-venu search-relative">
                    <h2 class="pw-txt-h2 pw-text-semi-bold pw-text-color__quinary txtnew">{{recordCount}} out of {{totalRecordCount}} <span class="pw-txt-h2 pw-text-semi-bold pw-text-color__quinary txtnew txt-venue">venue(s) </span>  <span class="pw-text-color__primary found header-search-title">found.</span></h2>
                    <div class="confirmation" *ngIf="recordCount>0">
                        <span class="font__nt-available">
                            <i class="material-icons pw-ico-color__quinary">comment</i>
                        </span>
                            <span class="font__small pw-txt-b2 pw-text-color__tertiary"  [innerHtml]="FacilityScreenMainDescription">
                        </span>
                    </div>
                </div>
                <div class="mmc_flex__item--vcenter sortby hide-in-cog" *ngIf="searchFacilities?.length>0">
                    <span class="pw-txt-b2 pw-text-color__tertiary pw-text-semi-bold sortby">Sort by</span>
                    <span class="filter">
                        <button mat-button (click)="toggleSort()" (keydown.enter)="toggleSort()" tabindex="0" role="button">
                            <i class={{sortingiconname}}></i>
                            <span>{{sortingLable}}</span>
                        </button>
                    </span>
                </div>
            </div>

            <div class="three-tab-main-container">
                <div class="mmc_flex__item--vcenter sortby" *ngIf="showMapView  || isCalendarViewEnable">
                    <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="view" aria-label="Map view">
                        <mat-button-toggle  *ngIf="showMapView" value="1"  class="map-toggale map-icon-word" matTooltip="Map View" (change)="toggleView($event.value)">
                            <mat-icon>location_on</mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle value="2" class="map-toggale list-icon-word" matTooltip="List View" (change)="toggleView($event.value)" aria-label="List view">
                            <mat-icon>format_list_bulleted</mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle *ngIf="isCalendarViewEnable" value="3" class="map-toggale cal-icon-word" matTooltip="Calendar View" (change)="toggleView($event.value)">
                            <mat-icon>calendar_today</mat-icon>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </div>
            <div *ngIf="view=='2'">
                <ul style="list-style-type:none; padding-left: 0;">
                    <li *ngFor="let facility of searchFacilities;let i = index; trackBy: trackerFunction">
                        <div class='mmc_card search--result'>
                            <div class="mmc_flex--container mobile-card">
                                <div class="mmc_flex__item image-cage">
                                    <a *ngIf="timelable=='Any'" href="#/facility-detail?Time=Any&&AssetId={{facility.id}}&&Date={{date | date:'medium'}}&&Attendees={{advancedfilterAtenndence}}" tabindex="0" aria-label="facility detail">
                                        <img class="img" src="{{facility.url}}" (error)="errorHandler($event)" alt="{{facility.name}}"/>
                                        <div class="overlay"></div>
                                    </a>
                                    <a *ngIf="timelable!='Any'" href="#/facility-detail?StartDate={{getSerchbaleDatefomTime(StartTime)}}&&EndDate={{getSerchbaleDatefomTime(EndTime)}}&&AssetId={{facility.id}}&&Attendees={{advancedfilterAtenndence}}" tabindex="0" aria-label="facility detail">
                                        <img class="img" src="{{facility.url}}" (error)="errorHandler($event)" alt="{{facility.name}}" />
                                        <div class="overlay"></div>
                                    </a>
                                </div>
                                <div class="mmc_flex__item content">
                                    <div class='item_name'>
                                        <div class="" *ngIf="timelable!='Any'"><a class="pw-txt-h3_small--link pw-text-color__primary pw-text-semi-bold" href="#/facility-detail?StartDate={{getSerchbaleDatefomTime(StartTime)}}&&EndDate={{getSerchbaleDatefomTime(EndTime)}}&&AssetId={{facility.id}}&&Attendees={{advancedfilterAtenndence}}">{{facility.name}}</a></div>
                                        <div class="" *ngIf="timelable=='Any'"><a class="pw-txt-h3_small--link pw-text-color__primary pw-text-semi-bold" href="#/facility-detail?Time=Any&&AssetId={{facility.id}}&&Date={{date | date:'medium'}}&&Attendees={{advancedfilterAtenndence}}">{{facility.name}}</a></div>
                                    </div>
                                    <div class='item_address pw-text-color__secondary' *ngIf="facility.directions">
                                        <div class="mmc_flex--container ">
                                            <div class="mmc_flex__item--inherit location_icon pw-txt-s2 pw-txt-lh-1">
                                                <i class="material-icons">location_on</i>
                                            </div>
                                            <div class="mmc_flex__item--vcenter pw-txt-s2 pw-txt-lh-1">{{facility.directions}}</div>
                                        </div>
                                    </div>
                                    <div class='item_details'>
                                        <div class="facility-short-description" id="{{facility.id}}-preview">
                                            <span class="pw-txt-b1 pw-text-color__tertiary description-text" [innerHtml]="facility.shortDescription"></span>
                                            <span class="read-more-link" id="{{facility.id}}-linkshow" role="link" style="color:#0275d8" *ngIf="facility.webDescription?.length > textLimit" tabindex="0" (click)="toggelReadMore(facility,facility.id)" (keydown.enter)="toggelReadMore(facility,facility.id)">Read more</span>
                                            <!--<span *ngIf="facility.webDescription?.length > textLimit">.....</span>-->
                                        </div>
                                        <div class="facility-long-description"  id="{{facility.id}}-expand" style="display:none">
                                            <span class="pw-txt-b1 pw-text-color__tertiary description-text" [innerHtml]="facility.description"></span>
                                            <span class="show-less-link" id="{{facility.id}}-linkhide" role="link" style="display:none;color:#0275d8" *ngIf="facility.webDescription?.length > textLimit" tabindex="0" (click)="toggelReadMore(facility,facility.id)" (keydown.enter)="toggelReadMore(facility,facility.id)">Show less</span>
                                        </div>


                                    </div>

                                    <div class="mmc_flex--container aminity-icons--cage ">
                                        <div class="mmc_flex__item aminity-icons">
                                            <div class="icon-list">
                                                <img matTooltip="{{amminity.name}}" *ngFor="let amminity of facility.amenities" src={{amminity.icon}} width="15" height="15" padding-right="10" alt="{{amminity.name}}">

                                            </div>
                                        </div>
                                        <div class="mmc_flex__item--inherit " (click)="openTimePopup(facility)" (keydown.enter)="openTimePopup(facility)" role="button" tabindex="0">
                                            <div class="mmc_flex--container available-cage" [ngClass]="{'fully-available': facility.getAvailabilityLabel()=='FULLY AVAILABLE', 'partially-available': facility.getAvailabilityLabel()=='PARTIALLY AVAILABLE'}" role="button">
                                                <div class="mmc_flex__item">
                                                    <div class="icn"><i class="mmc icon-users icn-aminity"></i></div>
                                                    <div class="pax">{{getConfigurationData(facility)?.capacity}}</div>
                                                </div>
                                                <div class="mmc_flex__item link mmc_flex__item--vcenter">{{facility.getAvailabilityLabel()}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mmc_flex__item--vcenter butn__cage">
                                    <div class="price" *ngIf="!isConfigurationEnabled">
                                        <div class="total__amount pw-txt-h3_small pw-text-bold price-justify" *ngIf="facility.isPriceRange()">{{facility.minPrice?.priceIncludingTax | CurrencyFormat}}<span class="pw-txt-b3 pw-text-color__tertiary pw-text-medium pr-1">/{{facility.minPrice?.priceType}}</span> - {{facility.maxPrice?.priceIncludingTax | CurrencyFormat}}<span class="pw-txt-b3 pw-text-color__tertiary pw-text-medium pr-1">/{{facility.maxPrice?.priceType}}</span></div>
                                        <div class="total__amount pw-txt-h3_small pw-text-bold price-justify" *ngIf="!facility.isPriceRange() && facility.getPrice()">{{facility.getPrice()?.priceIncludingTax | CurrencyFormat}}<span class="pw-txt-b3 pw-text-color__tertiary pw-text-medium pr-1">/{{facility.getPrice()?.priceType}}</span></div>
                                        <div class="total__amount pw-txt-s2 pw-text-color__tertiary pw-text-medium" *ngIf="!facility.getPrice()">Prices are not defined</div>
                                    </div>
                                    <div class="price" *ngIf="isConfigurationEnabled && facility.item">
                                        <div class="total__amount pw-txt-h3_small pw-text-bold price-justify" *ngIf="facility.isItemPriceRange()">{{facility.item?.itemMinPrice?.priceIncludingTax | CurrencyFormat}}<span class="pw-txt-b3 pw-text-color__tertiary pw-text-medium pr-1">/{{facility.item?.itemMinPrice?.priceType}}</span> - {{facility.item?.itemMaxPrice?.priceIncludingTax | CurrencyFormat}}<span class="pw-txt-b3 pw-text-color__tertiary pw-text-medium pr-1">/{{facility.item?.itemMaxPrice?.priceType}}</span></div>
                                        <div class="total__amount pw-txt-h3_small pw-text-bold price-justify" *ngIf="!facility.isItemPriceRange() && facility.getItemPrice()">{{facility.getItemPrice()?.priceIncludingTax | CurrencyFormat}}<span class="pw-txt-b3 pw-text-color__tertiary pw-text-medium pr-1">/{{facility.getItemPrice()?.priceType}}</span></div>
                                        <div class="total__amount pw-txt-s2 pw-text-color__tertiary pw-text-medium" *ngIf="!facility.getItemPrice()">Prices are not defined</div>
                                    </div>

                                    <div class="discount_cage" style="display:none;">
                                        <div class="lbl_discount"></div>
                                    </div>
                                    <div class='btn__card main mmc_flex__item--vcenter' (click)="bookingEnquiry(facility)" [ngStyle]="bookingDetails?.id ? {'cursor': 'not-allowed'} : {}" [ngClass]="{'disable-div' : bookingDetails?.id && !enableBookButton(facility)}">
                                        <div  [ngStyle]="bookingDetails?.id ? {'pointer-events': 'none'} : {}">ADD TO BOOKING</div>
                                    </div>
                                    <div class='mmc_flex__item--hcenter mt-2 hide-in-cog' *ngIf="showItemsAvailability(facility)" (click)="toggleAlternativeItems(facility)"><a class="pw-txt-s2 pw-txt-h3_small--link"> {{(facility?.isDefultExpandAlternativeConfiguration) ?'HIDE ALTERNATIVES' : 'VIEW ALTERNATIVES'}}</a></div>
                                </div>
                            </div>

                            <div class="alternative-list mt-3" *ngIf="showItemsAvailability(facility)">
                                <mat-accordion multi="all">
                                    <mat-expansion-panel [expanded]="facility.isDefultExpandAlternativeConfiguration" (click)="toggleAlternativeItems(facility)" (keydown.enter)="toggleAlternativeItems(facility)" tabindex="0">
                                        <mat-expansion-panel-header collapsedHeight="65px" expandedHeight="65px">
                                            <div class="mmc_flex--container header-container">
                                                <div class="mmc_flex__item mmc_flex__item--vcenter">
                                                    <div class="pw-txt-b3 pw-text-color__primary">
                                                        <!--Selected Option: {{facility.item?.name}} - <span class="more-options-text">{{facility.items.length - 1}} more options available</span>-->
                                                        <span>Selected Option:</span> {{facility.item?.name}}
                                                    </div>
                                                </div>

                                                <div class="mmc_flex__item mmc_flex__item--vcenter more-options-text-wrapper">

                                                    <div class="pw-txt-b3 more-options-text">
                                                        {{facility.items.length - 1}} more option(s) available
                                                    </div>
                                                </div>
                                            </div>

                                            <!--</div>-->
                                        </mat-expansion-panel-header>
                                        <div class="mmc_expansion__body--row-header d-block" (click)="$event.stopPropagation();" (keydown.enter)="$event.stopPropagation();" tabindex="0">

                                            <mat-radio-group class="alternative-radio-group" aria-label="Select an option">

                                                <div class="mmc_flex--container body-container" *ngFor="let itm of facility.items">

                                                    <div class="mmc_flex__item mmc_flex__item--vcenter alternative-radio">
                                                        <mat-radio-button (change)="changeFacilityItem(itm ,facility)" [checked]="itm.id == facility.item?.id" [value]="itm.id" tabindex="0">{{itm?.name}}</mat-radio-button>
                                                    </div>
                                                    <div class="mmc_flex__item mmc_flex__item--vcenter f-end text-right">
                                                        <div class="pw-txt-h4 pw-text-color__primary">
                                                            <div class="total__amount pw-txt-h4 pw-text-color__primary pw-text-semi-bold" *ngIf="isUnitPriceRange(itm)">{{itm.itemMinPrice?.priceIncludingTax | CurrencyFormat}} - {{itm.itemMaxPrice?.priceIncludingTax | CurrencyFormat}}</div>
                                                            <div class="total__amount pw-txt-s2 pw-text-color__tertiary pw-text-medium" *ngIf="isUnitPriceRange(itm)">{{itm.itemMinPrice?.priceType}}: - {{itm.itemMaxPrice?.priceType}}</div>
                                                            <div class="total__amount pw-txt-h4 pw-text-color__primary pw-text-semi-bold" *ngIf="!isUnitPriceRange(itm) && itm.itemMinPrice ">{{itm.itemMinPrice?.priceIncludingTax | CurrencyFormat}}</div>
                                                            <div class="total__amount pw-txt-s2 pw-text-color__tertiary pw-text-medium" *ngIf="!isUnitPriceRange(itm) && itm.itemMinPrice">{{itm.itemMinPrice?.priceType}}</div>
                                                            <div class="total__amount pw-txt-s2 pw-text-color__tertiary pw-text-medium" *ngIf="!itm.itemMinPrice">Prices are not defined</div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </mat-radio-group>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                            </div>



                        </div>
                    </li>
                </ul>
            </div>

            <div *ngIf="view=='1'  && showMapView">
                <agm-map [latitude]="lat" [style.height.px]="500" [style.width.px]="1000"
                         [longitude]="lng"
                         [zoom]="zoom"
                         [disableDefaultUI]="false"
                         [zoomControl]="false"
                         class="search-map"
                         (mapClick)="mapClick($event)">
                    <agm-marker *ngFor="let facility of searchFacilities; let i = index"
                                [latitude]="facility.latitude"
                                [longitude]="facility.longitude"
                                [title]="facility.name" [agmFitBounds]="true"
                                (markerClick)=markerClick(iw,facility)>

                        <agm-info-window #iw>
                            <div class='mmc_card search--result'>
                                <div class="mmc_flex--container mobile-card">
                                    <div class="mmc_flex__item image-cage">
                                        <a *ngIf="timelable=='Any'" href="#/facility-detail?Time=Any&&AssetId={{facility.id}}&&Date={{date | date:'medium'}}&&Attendees={{advancedfilterAtenndence}}" tabindex="0" aria-label="facility detail">
                                            <img class="img" src="{{facility.url}}" (error)="errorHandler($event)" alt="{{facility.name}}" />
                                            <div class="overlay"></div>
                                        </a>
                                        <a *ngIf="timelable!='Any'" href="#/facility-detail?StartDate={{getSerchbaleDatefomTime(StartTime)}}&&EndDate={{getSerchbaleDatefomTime(EndTime)}}&&AssetId={{facility.id}}&&Attendees={{advancedfilterAtenndence}}" tabindex="0" aria-label="facility detail">
                                            <img class="img" src="{{facility.url}}" (error)="errorHandler($event)" alt="{{facility.name}}" />
                                            <div class="overlay"></div>
                                        </a>
                                    </div>
                                    <div class="mmc_flex__item content">
                                        <div class='item_name'>
                                            <h1 class="font__h6" *ngIf="timelable!='Any'"><a href="#/facility-detail?StartDate={{getSerchbaleDatefomTime(StartTime)}}&&EndDate={{getSerchbaleDatefomTime(EndTime)}}&&AssetId={{facility.id}}&&Attendees={{advancedfilterAtenndence}}">{{facility.name}}</a></h1>
                                            <h1 class="font__h6" *ngIf="timelable=='Any'"><a href="#/facility-detail?Time=Any&&AssetId={{facility.id}}&&Date={{date | date:'medium'}}&&Attendees={{advancedfilterAtenndence}}">{{facility.name}}</a></h1>
                                        </div>
                                        <div class='item_address opt-txt__caption' *ngIf="facility.directions">
                                            <div class="mmc_flex--container ">
                                                <div class="mmc_flex__item--inherit location_icon">
                                                    <i class="material-icons">location_on</i>
                                                </div>
                                                <div class="mmc_flex__item--vcenter">{{facility.directions}}</div>
                                            </div>
                                        </div>
                                        <div class='item_details'>
                                            <div id="{{facility.id}}-preview">
                                                <div class="pw-txt-b1 pw-text-color__tertiary" [innerHtml]="facility.shortDescription" ></div>
                                                <span *ngIf="facility.webDescription?.length > textLimit">.....</span>
                                            </div>
                                            <!-- {{facility.isFullDescription}} -->
                                            <div id="{{facility.id}}-expand" style="display:none">
                                                <div class="pw-txt-b1 pw-text-color__tertiary" [innerHtml]="facility.description" *ngIf="facility.isFullDescription"></div>
                                            </div>
                                            <label id="{{facility.id}}-linkshow" style="color:#0275d8" *ngIf="facility.webDescription?.length > textLimit" (click)="toggelReadMore(facility,facility.id)" (keydown.enter)="toggelReadMore(facility,facility.id)"  tabindex="0">Read more</label>
                                            <label id="{{facility.id}}-linkhide" style="display:none;color:#0275d8" *ngIf="facility.webDescription?.length > textLimit" (click)="toggelReadMore(facility,facility.id)" (keydown.enter)="toggelReadMore(facility,facility.id)" tabindex="0">Show less</label>
                                        </div>

                                        <div class="mmc_flex--container aminity-icons--cage ">
                                            <div class="mmc_flex__item aminity-icons">
                                                <div class="icon-list">
                                                    <img matTooltip="{{amminity.name}}" *ngFor="let amminity of facility.amenities" src={{amminity.icon}} width="15" height="15" padding-right="10" alt="{{amminity.name}}">
                                                </div>
                                            </div>
                                            <div class="mmc_flex__item--inherit " role="button" tabindex="0">
                                                <div class="mmc_flex--container available-cage" [ngClass]="{'fully-available': facility.getAvailabilityLabel()=='FULLY AVAILABLE', 'partially-available': facility.getAvailabilityLabel()=='PARTIALLY AVAILABLE'}" role="button">
                                                    <div class="mmc_flex__item">
                                                        <div class="icn"><i class="mmc icon-users icn-aminity"></i></div>
                                                        <div class="pax">{{getConfigurationData(facility)?.capacity}}</div>
                                                    </div>
                                                    <div class="mmc_flex__item link mmc_flex__item--vcenter" (click)="openTimePopup(facility)" (keydown.enter)="openTimePopup(facility)" tabindex="0">{{facility.getAvailabilityLabel()}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mmc_flex__item--vcenter butn__cage">
                                        <!--<div class="price">
                                            <div class="total__amount" *ngIf="facility.isPriceRange()">{{facility.minPrice?.priceIncludingTax | CurrencyFormat}} - {{facility.maxPrice?.priceIncludingTax | CurrencyFormat}}</div>
                                            <div class="total__amount" *ngIf="!facility.isPriceRange()">{{facility.minPrice?.priceIncludingTax | CurrencyFormat}}</div>
                                        </div>-->
                                        <div class="price" *ngIf="!isConfigurationEnabled">
                                            <div class="total__amount pw-txt-h3_small pw-text-bold" *ngIf="facility.isPriceRange()">{{facility.minPrice?.priceIncludingTax | CurrencyFormat}}<span class="pw-txt-b3 pw-text-color__tertiary pw-text-medium pr-1">/{{facility.minPrice?.priceType}}</span> - {{facility.maxPrice?.priceIncludingTax | CurrencyFormat}}<span class="pw-txt-b3 pw-text-color__tertiary pw-text-medium pr-1">/{{facility.maxPrice?.priceType}}</span></div>
                                            <div class="total__amount pw-txt-h3_small pw-text-bold" *ngIf="!facility.isPriceRange() && facility.getPrice()">{{facility.getPrice()?.priceIncludingTax | CurrencyFormat}}<span class="pw-txt-b3 pw-text-color__tertiary pw-text-medium pr-1">/{{facility.getPrice()?.priceType}}</span></div>
                                            <div class="total__amount pw-txt-s2 pw-text-color__tertiary pw-text-medium" *ngIf="!facility.getPrice()">Prices are not defined</div>
                                        </div>
                                        <div class="price" *ngIf="isConfigurationEnabled && facility.item">
                                            <div class="total__amount  pw-txt-h3_small pw-text-bold" *ngIf="facility.isItemPriceRange()">{{facility.item?.itemMinPrice?.priceIncludingTax | CurrencyFormat}}<span class="pw-txt-b3 pw-text-color__tertiary pw-text-medium pr-1">/{{facility.item?.itemMinPrice?.priceType}}</span> - {{facility.item?.itemMaxPrice?.priceIncludingTax | CurrencyFormat}}<span class="pw-txt-b3 pw-text-color__tertiary pw-text-medium pr-1">/{{facility.item?.itemMaxPrice?.priceType}}</span></div>
                                            <div class="total__amount pw-txt-h3_small pw-text-bold" *ngIf="!facility.isItemPriceRange() && facility.getItemPrice()">{{facility.getItemPrice()?.priceIncludingTax | CurrencyFormat}}<span class="pw-txt-b3 pw-text-color__tertiary pw-text-medium pr-1">/{{facility.getItemPrice()?.priceType}}</span></div>
                                            <div class="total__amount pw-txt-s2 pw-text-color__tertiary pw-text-medium" *ngIf="!facility.getItemPrice()">Prices are not defined</div>
                                        </div>
                                        <div class="discount_cage" style="display:none;">
                                            <div class="lbl_discount"></div>
                                        </div>
                                        <div (click)="bookingEnquiry(facility)" class='btn__card main mmc_flex__item--vcenter' [ngStyle]="bookingDetails?.id ? {'cursor': 'not-allowed'} : {}">
                                            <div *ngIf="enableBookButton(facility)" [ngStyle]="bookingDetails?.id ? {'pointer-events': 'none'} : {}">ADD TO BOOKING</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </agm-info-window>
                    </agm-marker>
                </agm-map>
            </div>

            <div class="cal-view" *ngIf="view=='3' && isCalendarViewEnable">
                <app-calendar-tab-view [facilities]="searchFacilities"
                [bookingDate]="date" (selectedBookingData)="selectBookingFacility($event)"
                [bookingPopUpClosedFacility]="bookingPopUpClosedFacility"
                [cartAddedFacilities]="addedFacilites"
                (removeSelectedFacility)="removeSelectedFacilityFromCart($event)"
                (selectTimeLineColor)="selectTimeLineColor($event)"
                (faciltySearch)="onsearch($event)"></app-calendar-tab-view>
            </div>

            <div class="d-flex legend-pagi-wrapper" [ngClass]="{'cal-view-open': view=='3' && isCalendarViewEnable}">
                <div class="availability-legend-wrapper" *ngIf="searchFacilities?.length && view=='3' && isCalendarViewEnable" [ngClass]="{'cal-view-open': view=='3' && isCalendarViewEnable}">
                    <fieldset class="availabiblity-legend">
                        <legend class="pw-txt-s2">Colour key:</legend>
                        <div class="mmc_flex--container key-container">
                            <div class="mmc_flex__item key-item" *ngFor="let timeLineColor of timeLineColors;">
                                <div class="key-color"  [ngStyle]="{'backgroundColor':timeLineColor?.Value}"></div>
                                <div class="pw-txt-s2" style="margin: 5px">{{timeLineColor?.Name}}</div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                
                <div class="ml-auto search-pagi" *ngIf="searchFacilities?.length && view!=='1'" [ngClass]="{'cal-view-open': view=='3' && isCalendarViewEnable}">
                  <mat-paginator #facilityPaginator
                                 [length]="recordCount"
                                 [pageSize]="pageSize"
                                 [pageSizeOptions]="pageSizeOptions"
                                 [pageIndex]="pagenumber"
                                 (page)="pageclickevent($event)">
                  </mat-paginator>
              </div>
              
            </div>

            <!--<div *ngIf="((loader$|async).isLoading_bookingSave)">
                <opt-mmc-loader></opt-mmc-loader>
            </div>-->
        </div>

   <!--<ng-template #spinner>
        <div class="container" style="min-height:400px">
            <opt-mmc-loader></opt-mmc-loader>
        </div>
    </ng-template>-->
</div>

<style type="text/css">
    .main_search .form-control .is-invalid {
        border-color: #dc3545;
    }
</style>
