import { jsonIgnore } from "json-ignore";
import { BaseResource } from "../base-resource";
import { Item } from "./item/item";
import { PackageDurationRange } from "./Package";

export class PackageDate extends BaseResource {
  id: string;
  description: string;
  eventDate: EventDate
  @jsonIgnore() items: { packageID: string, item: Item, bookingItemID: string}[];
  @jsonIgnore() selectedItem: Item;
  packageDurationRange: PackageDurationRange;
}

export class PackageSession extends BaseResource {
  totalPriceIncludingTax: number;
  totalPriceExcludingTax: number;
  // packagePriceGroup: PackagePriceGroup;
  packageDate: PackageDate;
  startTime: Date;
  endTime: Date;
  availability: number;
  capacity: number;
  salesChannelId: number;
  salesChannel: string;
  initialize(d) {
    this.id = d.id;
    this.salesChannel = d.salesChannel;
    this.salesChannelId = d.salesChannelId;
    this.capacity = d.capacity;
    this.packageDate = d.packageDate
    this.availability = d.availability;
    this.endTime = new Date(d.endTime);
    this.startTime = new Date(d.startTime);
    this.totalPriceExcludingTax = d.totalPriceExcludingTax;
    this.totalPriceIncludingTax = d.totalPriceIncludingTax;

    return this;
  }
}

export class EventDate extends BaseResource {
  id: string;
  date: string | null;
  startTime: string | null;
  endTime: string | null;
  description: string;
  insertedUser: string;
  updatedUser: string;
  updatedTime: string | null;
}
