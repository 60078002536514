import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ViewportScroller } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

import { RegularBookingSettings, BookingTypes } from 'src/modules/models/settings/regular-portal/regular-booking-setting';
import { Addresses } from 'src/modules/models/client/Address';
import { QASAddress } from 'src/modules/models/client/qas-address';
import { IndividualClient } from 'src/modules/models/client/Individual-client';
import { Contact, BookingType} from 'src/modules/models/client/contact';
import { CommunicationType } from 'src/modules/models/client/communication-type';
import { ReferenceData } from 'src/modules/models/client/referenceData';
import { Address, CommunicationMethod } from 'src/modules/models/client/client';
import { CommunicationTypeValidation } from 'src/modules/models/settings/casual-portal/client-registration-config';
import { environment } from 'src/environments/environment';

import { CustomerService } from 'src/modules/services/customer.service';
import { SystemConfig } from 'src/modules/system-configuration';
import { ManageContactsComponent } from 'src/modules/booking-portal/modals/manage-contacts/manage-contacts.component';
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { SuccessMessageComponent } from 'src/modules/shared/success-message/success-message.component';

import { Store, ICart, SetContact } from 'src/modules/store/index';
import { ILoadingStatus } from 'src/modules/store/loading/status-store';
import { LoadingStarted, LoadingCompleted } from 'src/modules/store/loading/actions';
import { Utility } from 'src/modules/utility';


import { Observable ,  Subject } from "rxjs";



import { CustomerAdditionalDetail } from 'src/modules/models/client/customer-additional-detail';
import { Note } from 'src/modules/models/client/note';
import { AdHocDocument } from 'src/modules/models/booking/adhoc-document';
import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { DomSanitizer } from '@angular/platform-browser';
import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';
import { el } from 'date-fns/locale';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { FormControlValidatorDirective } from '../../../form-control-validator.directive';
import * as LanguageSelector from '../../../store/public-web/select-language/language-selector';
import { Country } from '../../../models/public-web/customer/country';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
    selector: 'customer-profile',
    templateUrl: './customer-profile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CustomerProfileComponent implements OnInit {
    cart$: Observable<ICart>;
    cart_subscriber: any;
    loader$: Observable<ILoadingStatus>;
    cid: string;
    clientid: string;
    clientRef: string;
    contact: Contact ;
    contactCopy: Contact;
    contactChangesList: Array<CommunicationMethod> = [];
    contactComTypeNewList: Array<CommunicationMethod> = [];
    modalChangeList: Array<any> = [];
    isCommTypeChange: boolean = false;
    customerType: string;
    customerDetails: string = "Profile";
    showCoparateDetails: boolean = false;
    showIndividualDetails: boolean = true;
    titles: Array<ReferenceData>;
    countries: Country[] = [];
    countrySearch: FormControl;
    filteredCountries: Observable<Country[]>;
    communicationTypes: Array<CommunicationType>;
    communicationTypesPhone: Array<CommunicationType>;
    communicationTypesEmail: Array<CommunicationType>;
    officePhoneType: string;
    officeEmailType: string;
    companyEmail: CommunicationMethod = new CommunicationMethod();
    companyPhone: CommunicationMethod = new CommunicationMethod();
    phoneNewID: number = -1;
    removedCommunicationMethods: string[] = [];
    removedClientCommunicationMethods: string[] = [];
    nameValidation: RegExp;
    companyPhoneValidation: RegExp;
    addressList: Addresses;
    qasLoader: boolean;
    addressLoaded = false;
    addressChanged: Subject<string> = new Subject<string>();
    isNoResultFound: boolean = false;
    isChanged: boolean = false;
    @ViewChild('formProfile') public formProfile: NgForm;
    inputSearchString: string = "";
    communicationTypeValidation: CommunicationTypeValidation[] = [];
    placholderSampleNumber: string;
    defaultCountryName: string;
    clientBookingTypes: BookingType[] = [];
    clientAccessGrantBookingTypes: any[] = [];
    allbookingTypeList: any[] = [];
    priceConcessionQuestionText: string;
    additionalDetail: CustomerAdditionalDetail;
    additionalDetailNote: string;
    public files: UploadFile[] = [];
    public fileSelector: ElementRef;
    @ViewChild('fileInputTag') fileInputTagVariable: ElementRef; status: boolean = false;
    @ViewChild(MatAutocompleteTrigger) matAutocomplete: MatAutocompleteTrigger;
    isAdditionalDetailChange: boolean;
    clientDocuments: any[] = [];
    webAPiBaseUrl: string;
    copyNotes: any;
    IsEnableToUpdateAdditionalDetailsSectionForIndividualClients: boolean = false;
    IsEnableToUpdateAdditionalDetailsSectionForCorporateClients: boolean = false;
    IsEnableToUpdateAdditionalDocumentSectionForIndividualClients: boolean = false;
    IsEnableToUpdateAdditionalDocumentSectionForCorporateClients: boolean = false;


    requestToAccessReqularBookingHeaderText: string = "";
    requestToAccessReqularBookingAcceptText: string = "";
    reqularBookingAccessRequestHeaderText: string = "";
    reqularBookingAccessRequestSubHeaderText: string = "";
    profileImageHeaderTextForCorporateClients: string = "";
    profileImageHeaderTextForIndividualClients: string = "";
    reqularBookingAccessRequestReviewText: string = "";
    reqularBookingAccessRequestCompleteText: string = "";
    requestToRegularBookingAccess: boolean = false;
    requestToRegularBookingAccessNote: string ;
    requestToRegularBookingAccessCount: number = 0;
    regularVenueHireEnabledCount: number = 0;
    @ViewChild('fileInputTagforProfileImage') fileInputTagforProfileImage: ElementRef;
    profileImage: any;
    isProfileImageRemoved: boolean = false;
    enableProfileImageUpload: boolean;
    enableWebPortalAccess: boolean;
    showAddressSearchBar: boolean = true;
    IsEnableManualAddress: boolean = false;
    appData: AppSetting;
    docSize: number;
    ClientFileUploadDescription: any;
    PriceConcessionAnswerOptions: any[] = [];
    showAdditionalNote: boolean = true;
    bookingTypesNames: BookingType[] = [];

    //
    formGroup: FormGroup;
    firstName: any;
    lastName: string;
    firstNamePlaceholder: any;
    lasttNamePlaceholder: any;
    copformGroup: FormGroup;
    selectedLanguage ;


    constructor(private store: Store<any>, private _customerService: CustomerService, public snackBar: MatSnackBar,
        private _sysConfig: SystemConfig, private cdr: ChangeDetectorRef, private vps: ViewportScroller, private sanitizer: DomSanitizer, private dialog: MatDialog  ) {
        this.titles = new Array<ReferenceData>();
        this.addressList = new Addresses();
        this.communicationTypes = new Array<CommunicationType>();
        this.communicationTypesPhone = new Array<CommunicationType>();
        this.communicationTypesEmail = new Array<CommunicationType>();
        this.loader$ = this.store.select('loader');
        this.addressChanged.pipe(debounceTime(1000)).subscribe(model => this.loadAddress(model));
        this.defaultCountryName = environment.DefaultCountryName;
        if (environment.PriceConcessionQuestionText && environment.PriceConcessionQuestionText != "") {
            this.priceConcessionQuestionText = environment.PriceConcessionQuestionText;
        }
        this.IsEnableToUpdateAdditionalDetailsSectionForIndividualClients = environment.IsEnableToUpdateAdditionalDetailsSectionForIndividualClients;
        this.IsEnableToUpdateAdditionalDetailsSectionForCorporateClients = environment.IsEnableToUpdateAdditionalDetailsSectionForCorporateClients;
        this.IsEnableToUpdateAdditionalDocumentSectionForIndividualClients = environment.IsEnableToUpdateAdditionalDocumentSectionForIndividualClients;
        this.IsEnableToUpdateAdditionalDocumentSectionForCorporateClients = environment.IsEnableToUpdateAdditionalDocumentSectionForCorporateClients;


        this.requestToAccessReqularBookingHeaderText = environment.RequestToAccessReqularBookingHeaderText;
        this.requestToAccessReqularBookingAcceptText = environment.RequestToAccessReqularBookingAcceptText;
        this.reqularBookingAccessRequestHeaderText = environment.ReqularBookingAccessRequestHeaderText;
        this.reqularBookingAccessRequestSubHeaderText = environment.ReqularBookingAccessRequestSubHeaderText;
        this.reqularBookingAccessRequestReviewText = environment.ReqularBookingAccessRequestReviewText;
        this.reqularBookingAccessRequestCompleteText = environment.ReqularBookingAccessRequestCompleteText;
        this.profileImageHeaderTextForCorporateClients = environment.ProfileImageHeaderTextForCorporateClients;
        this.profileImageHeaderTextForIndividualClients = environment.ProfileImageHeaderTextForIndividualClients;
        this.enableProfileImageUpload = environment.EnableProfileImageUpload;
        this.enableWebPortalAccess = environment.EnableWebPortalAccess;
        this.additionalDetail = new CustomerAdditionalDetail();
        this.additionalDetail.note = new Note();
        this.additionalDetail.note.plainText = "";
        this.additionalDetail.documents = [];
        this.additionalDetail.removedDocumentIds = [];
        this.clientDocuments = [];
        this.webAPiBaseUrl = environment.WebApiUrl;
        var appsettings = environment.AppSetting as AppSetting;
        this.nameValidation = new RegExp(appsettings.ClientRegistrationConfig.NameValidation);
        this.appData = (environment.AppSetting as AppSetting);
        this.countrySearch = new FormControl('', [this.appData.ClientRegistrationConfig.Placeholder.Address.Country && this.appData.ClientRegistrationConfig.Placeholder.Address.Country.IsMandatory == "1" ? Validators.required : null,
            ].filter(validator => validator !== null));

        if (environment.ExternalIntegrationIdForAddress == 0 || environment.ExternalIntegrationIdForAddress == undefined) {
            this.showAddressSearchBar = false
        }

        this.IsEnableManualAddress = this.appData.ClientRegistrationConfig.EnableManualAddress;
        this.docSize = this.appData.ClientRegistrationConfig.MaximumDocUploadSize ? this.appData.ClientRegistrationConfig.MaximumDocUploadSize : 3;
        this.ClientFileUploadDescription = this.sanitizer.bypassSecurityTrustHtml(this.appData.ClientRegistrationConfig.ClientFileUploadDescription);
        this.PriceConcessionAnswerOptions = environment.PriceConcessionAnswerOptions ? environment.PriceConcessionAnswerOptions : [];
        if (this.PriceConcessionAnswerOptions.length > 0) {
            this.PriceConcessionAnswerOptions.forEach((PriceConcessionAnswerText) => {
                if (PriceConcessionAnswerText.PriceConcessionId) {
                    this.showAdditionalNote = false;
                }

            });
        }
        this.cart$ = this.store.select('cart');
        this.cart_subscriber = this.cart$.subscribe(state => {
            this.cid = state.contact.id;
            if (state.contact && state.contact.client) {
                this.customerType = state.contact.client.type;
                this.clientid = state.contact.client.id;
                this.clientRef = state.contact.client.clientRef;
            }
            //this.contact = state.contact;
      });
        this.store.select(LanguageSelector.getSelectedLanguage).subscribe( storedLanguage => {
          this.selectedLanguage = !!storedLanguage ? storedLanguage : '';
        });
        this._customerService.setCountryValues();



    }

    setupForm(){

        if(this.IsEnableManualAddress && (this.appData && this.appData.ClientRegistrationConfig && this.appData.ClientRegistrationConfig.Placeholder && this.appData.ClientRegistrationConfig.Placeholder.Address)){

            let _addressconfiguration = this.appData.ClientRegistrationConfig.Placeholder.Address;
            // if ((environment.ExternalIntegrationIdForAddress != undefined && environment.ExternalIntegrationIdForAddress != 0 ) && _enableManualAddress) {
            //     //showAddressSearchBar is true when search integration is configured properly
            //     this.showAddressSearchBar = true
            // }
                // add validators based on config
            this.formGroup = new FormGroup({
                AddressLine1: new FormControl(this.contact && this.contact.invoiceAddress && this.contact.invoiceAddress.address1 ? this.contact.invoiceAddress.address1 : '', [
                    _addressconfiguration.AddressLine1 && _addressconfiguration.AddressLine1.IsMandatory == "1" ? Validators.required : null,
                //   Validators.minLength(),
                ].filter(validator => validator !== null)),
                AddressLine2: new FormControl(this.contact && this.contact.invoiceAddress && this.contact.invoiceAddress.address2 ? this.contact.invoiceAddress.address2 : '', [
                    _addressconfiguration.AddressLine2 && _addressconfiguration.AddressLine2.IsMandatory == "1" ? Validators.required : null,
                ].filter(validator => validator !== null)),
                AddressLine3: new FormControl(this.contact && this.contact.invoiceAddress && this.contact.invoiceAddress.address3 ? this.contact.invoiceAddress.address3 : '', [
                    _addressconfiguration.AddressLine3 && _addressconfiguration.AddressLine3.IsMandatory == "1" ? Validators.required : null,
                ].filter(validator => validator !== null)),
                City: new FormControl(this.contact && this.contact.invoiceAddress && this.contact.invoiceAddress.city ? this.contact.invoiceAddress.city : '', [
                    _addressconfiguration.City && _addressconfiguration.City.IsMandatory == "1" ? Validators.required : null,
                ].filter(validator => validator !== null)),
                Country: new FormControl(this.contact && this.contact.invoiceAddress && this.contact.invoiceAddress.country ? this.contact.invoiceAddress.country : '', [
                    _addressconfiguration.Country && _addressconfiguration.Country.IsMandatory == "1" ? Validators.required : null,
                ].filter(validator => validator !== null)),
                PostCode: new FormControl(this.contact && this.contact.invoiceAddress && this.contact.invoiceAddress.postCode ? this.contact.invoiceAddress.postCode : '', [
                    _addressconfiguration.PostCode && _addressconfiguration.PostCode.IsMandatory == "1" ? Validators.required : null,
                ].filter(validator => validator !== null)),
                State: new FormControl(this.contact && this.contact.invoiceAddress && this.contact.invoiceAddress.county ? this.contact.invoiceAddress.county : '', [
                    _addressconfiguration.State && _addressconfiguration.State.IsMandatory == "1" ? Validators.required : null,
                ].filter(validator => validator !== null))
                // Add other fields and their respective FormControl instances with validators
            });
        }


        let _corporateConfiguration = this.showCoparateDetails ? this.appData.ClientRegistrationConfig.Placeholder.Corporate : null;

        if(this.showCoparateDetails && this.appData && this.appData.ClientRegistrationConfig && this.appData.ClientRegistrationConfig.Placeholder && this.appData.ClientRegistrationConfig.Placeholder.Corporate){
            let _corporateConfiguration = this.appData.ClientRegistrationConfig.Placeholder.Corporate;

            this.copformGroup = new FormGroup({
                CompanyName: new FormControl(this.contact && this.contact.client && this.contact.client.companyName ? this.contact.client.companyName : '', [
                    _corporateConfiguration.CompanyName && _corporateConfiguration.CompanyName.IsMandatory == "1" ? Validators.required : null,
                //   Validators.minLength(),
                ].filter(validator => validator !== null)),
                CompanyPhoneNumber: new FormControl(this.companyPhone && this.companyPhone.value ? this.companyPhone.value : '', [
                    _corporateConfiguration.CompanyPhoneNumber && _corporateConfiguration.CompanyPhoneNumber.IsMandatory == "1" ? Validators.required : null,
                ].filter(validator => validator !== null)),
                CompanyEmail: new FormControl(this.companyEmail && this.companyEmail.value ? this.companyEmail.value : '', [
                    _corporateConfiguration.CompanyEmail && _corporateConfiguration.CompanyEmail.IsMandatory == "1" ? Validators.required : null,
                ].filter(validator => validator !== null)),
                FirstName: new FormControl(this.contact && this.contact.firstName ? this.contact.firstName : '', [
                    _corporateConfiguration.FirstName && _corporateConfiguration.FirstName.IsMandatory == "1" ? Validators.required : null, Validators.pattern(this.nameValidation)
                ].filter(validator => validator !== null)),
                LastName: new FormControl(this.contact && this.contact.lastName ? this.contact.lastName : '', [
                    _corporateConfiguration.LastName && _corporateConfiguration.LastName.IsMandatory == "1" ? Validators.required : null, Validators.pattern(this.nameValidation)
                ].filter(validator => validator !== null)),
            })
            
            this.firstName = _corporateConfiguration.FirstName.Label ; 
            this.lastName = _corporateConfiguration.LastName.Label  ; 
            this.firstNamePlaceholder =  _corporateConfiguration.FirstName.Value ; 
            this.lasttNamePlaceholder =  _corporateConfiguration.LastName.Value ; 
            
            if(_corporateConfiguration.FirstName.IsMandatory == "1"){
                this.firstName += "*";
                this.firstNamePlaceholder += "*";
            }

            if(_corporateConfiguration.LastName.IsMandatory == "1"){
                this.lastName += "*";
                this.lasttNamePlaceholder += "*";
            }
        }

        // individual form doesnt have config
        //first and last name always required
        else if(this.showIndividualDetails){

            this.copformGroup = new FormGroup({
                FirstName: new FormControl(this.contact && this.contact.firstName ? this.contact.firstName : '', [Validators.required, Validators.pattern(this.nameValidation)].filter(validator => validator !== null)),
                LastName: new FormControl(this.contact && this.contact.lastName ? this.contact.lastName : '', [Validators.required, Validators.pattern(this.nameValidation)].filter(validator => validator !== null))
            })

            this.firstName = "First Name *" ; 
            this.lastName = "Last Name *" ; 

            this.firstNamePlaceholder =  "James *" ; 
            this.lasttNamePlaceholder =  "Smith *" ; 
        }


    }

    openManageContacts(): void {
        const dialogRef = this.dialog.open(ManageContactsComponent, {
            data: {primaryContact: this.contact},
            panelClass: ['w80modal', 'dialog-container-common'],
            width: '250px'
        });
    }

    ngOnInit() {

        this.loadTitles();
        this._customerService.getCountryValues().subscribe(values => {
            if (values.length > 0) {
                this.countries = values;
            }
        })
        this._sysConfig.loadAppConfiguration().then(() => {
            this.loadCommunicationTypes();
        });
        this.filteredCountries = this.countrySearch.valueChanges.pipe(
            startWith<string | Country>(''),
            map(value => (typeof value === "string" ? value : value.name)),
            map(name => (name ? this._filter(name) : this.countries))
          );
        this.isChanged = false;
    }

    ngOnDestroy() {
        if (this.cart_subscriber)
            this.cart_subscriber.unsubscribe();
        this.addressChanged.unsubscribe();
    }

    ngAfterViewInit() {
        this.isChanged = false;
        this.cdr.markForCheck();
        this.cdr.detectChanges();
    }

    async loadContact() {

        //    this.store.dispatch(new LoadingStarted());

          //  if (this.contact.invoiceAddress == undefined)
            //    this.contact.invoiceAddress = new Address();


            if (this.customerType.toLowerCase() == "corporateclient") {
                this.customerDetails = "Customer";
                this.showCoparateDetails = true;
                this.showIndividualDetails = false;
            } else if (this.customerType.toLowerCase() == "individualclient") {
                this.showCoparateDetails = false;
                this.showIndividualDetails = true;
            }

            this._customerService.SelectContact().subscribe(data => {
                if (data != null)
                {
                    console.log(data);
                    //this.contact = JSON.parse(JSON.stringify(data));
                    //this.contact.firstName = 'asdsadsa';
                    //this.contact.invoiceAddress = new Address();
                    //this.contact.invoiceAddress = Object.assign({}, data.invoiceAddress);
                    //this.contact.communicationMethods = [];
                    //this.contact.communicationMethods = Object.assign([], data.communicationMethods);
                    //this.contact.client = Object.assign({}, data.client);
                    this.contact = data;
                    this.contactCopy = JSON.parse(JSON.stringify(data));
                    this.setComMethodPatterns();

                    if (this.contact.bookingTypeList) {
                        this.clientBookingTypes = this.contact.bookingTypeList;

                    } else if (this.contact.client && this.contact.client.bookingTypes) {
                            this.clientBookingTypes = this.contact.client.bookingTypes;
                    }
                    if (this.contact.invoiceAddress.country && this.countrySearch) {
                        let ContactCountry = this.countries.find(c => c.name.toLowerCase() == this.contact.invoiceAddress.country.toLowerCase())
                        if (ContactCountry) {
                            this.countrySearch.setValue(ContactCountry);
                        }
                    }
                    this.setupForm();
                    

                    this.cdr.markForCheck();                    
                    this.cdr.detectChanges();
                    this.isChanged = false;
                }
                else {
                    this.openSnackBarError(["Customer Details not found"]);
                    this.isChanged = false;
                }
     }
   ,
                err => {
                    console.log("Customer Details Error : ", err);
                    this.isChanged = false;
                },
                () => {
                    //  this.store.dispatch(new LoadingCompleted());
                    this.isChanged = false;
                }
            );
            if ((this.showCoparateDetails && this.IsEnableToUpdateAdditionalDocumentSectionForCorporateClients) || ((!this.showCoparateDetails && this.IsEnableToUpdateAdditionalDocumentSectionForIndividualClients)) || this.requestToAccessReqularBookingHeaderText) {
                this.loadCustomerDocuments();
            }



        this.getBookingTypes(this.filterClientRestrictedBookingTypes.bind(this));


    }


    setComMethodPatterns(){
        if (this.contact.client && this.contact.client.communicationMethods) {
            this.contact.client.communicationMethods.forEach(cm => {
                if (this.companyPhone.id == undefined) {
                    if (cm.communicationTypeID == this.officePhoneType) {
                        this.companyPhone = cm;
                        this.companyPhone.pattern = this.mobileNumberValidation(this.companyPhone.communicationTypeID);
                    }

                }

                if (this.companyEmail.id == undefined) {
                    if (cm.communicationTypeID == this.officeEmailType) {
                        this.companyEmail = cm;
                    }
                }

            });
        }

        if (this.contact.communicationMethods) {
            this.contact.communicationMethods.forEach(cm => {
                var cmType = this.communicationTypesEmail.find(ct => ct.id == cm.communicationTypeID);
                if (cmType != undefined) cm.format = cmType.format;
                else {
                    cmType = this.communicationTypesPhone.find(ct => ct.id == cm.communicationTypeID);
                    if (cmType != undefined) {
                        cm.format = cmType.format;
                        cm.pattern = this.mobileNumberValidation(cm.communicationTypeID);
                    }
                }
            });
        }
    }
    getBookingTypes(callbackOnSuccess){
        this._customerService.GetBookingTypes().subscribe(data => {
            if(data){
            this.allbookingTypeList = data;
            callbackOnSuccess();
            }
        });
    }

    filterClientRestrictedBookingTypes(){
        const _restrictedBookingTypeList = this.allbookingTypeList.filter(bookingType => bookingType.isRestricted);
        if (this.allbookingTypeList && this.allbookingTypeList.length && this.clientBookingTypes.length){
            this.clientBookingTypes.forEach((clientBookingType) => {
                const filteredBookingType = _restrictedBookingTypeList.find(bookingType => bookingType.id === clientBookingType.id && bookingType.name);
                if(filteredBookingType){
                    this.clientAccessGrantBookingTypes.push(filteredBookingType);
                }
            });
        }
        const restrictedBookings = this.allbookingTypeList.filter(bookingType => bookingType.isRestricted).filter((element) => !this.clientAccessGrantBookingTypes.find(({ id }) => element.id === id));
        this.requestToRegularBookingAccess = restrictedBookings.length ? true : false;
    }


    loadCustomerDocuments() {
        this.additionalDetail.documents = [];
        this.additionalDetail.removedDocumentIds = [];
        this.clientDocuments = [];
        this.isAdditionalDetailChange = false;
        this._customerService.SelectClientDetails().subscribe(data => {

            if (data) {

                this.clientDocuments = data.documents;
                if (data.notes && data.notes.length > 0) {
                    this.additionalDetail.note = data.notes[0];
                }
                if (this.additionalDetail.note) {
                    var customerNotes = this.additionalDetail.note.plainText.split("Client Note - ");
                    if (customerNotes && customerNotes.length > 0) {
                        var addtionalDetailFullNote = customerNotes.find(cn => cn.includes("Additional Note Created At"))
                        if (addtionalDetailFullNote && addtionalDetailFullNote.length>0) {
                            this.additionalDetailNote = addtionalDetailFullNote.substring(49);
                        }
                        var regularBookingAccessNote = customerNotes.filter(cn => cn.includes("Reqular Booking Note"))
                        // if (regularBookingAccessNote && regularBookingAccessNote.length>0) {
                        //     this.requestToRegularBookingAccessCount = regularBookingAccessNote.length;
                        //     this.requestToRegularBookingAccess = true;

                        // }
                    }
                }
                if (this.additionalDetailNote)
                    this.copyNotes = JSON.parse(JSON.stringify(this.additionalDetailNote));
                this.isAdditionalDetailChange = false;

            }
        },
            err => {
                console.log("Customer Documents Error : ", err);
            }
        );
    }
    loadTitles() {
        this._customerService.getClientTiltles().subscribe(data => {
            if (data != null) {
                this.titles = data;
            }
        });
    }

    loadCommunicationTypes() {
        this._customerService.CommunicationTypes().subscribe(data => {
            if (data != null) {
                for (let comType of data) {
                    if (comType.format == "1") {
                        if (this.officePhoneType == undefined)
                            this.officePhoneType = comType.id;
                        this.communicationTypesPhone.push(comType);
                    }
                    else if (comType.format == "2") {
                        if (this.officeEmailType == undefined)
                            this.officeEmailType = comType.id;
                        this.communicationTypesEmail.push(comType);
                    }
                    else {
                        this.communicationTypes.push(comType);
                    }
                }
            }

            this.companyPhoneValidation = this.mobileNumberValidation("1");
            this.loadContact();
            this.isChanged = false;
        },
            err => { this.isChanged = false; }
        );
    }

    valueChange(event, com?) {
        if (com) {
            if (typeof com === 'object') {
                let ind = this.contact.communicationMethods.filter(x => { return x.id == com.id });
                if (parseInt(ind[0].id) < 0) {
                    let newListIndex = this.contactComTypeNewList.findIndex(x => x.id == com.id);
                    if (com.value == "") {
                        this.contactComTypeNewList.push(com);
                    } else {
                        if (newListIndex != -1) {
                            this.contactComTypeNewList.splice(newListIndex, 1);
                        }
                    }
                } else {
                    let changeListIndex = this.contactChangesList.findIndex(x => x.id == com.id);
                    let copiedContact = this.contactCopy.communicationMethods.filter(x => { return x.id == com.id });
                    if (changeListIndex != -1) {
                        if (copiedContact[0].value != this.contactChangesList[changeListIndex].value) {
                            this.contactChangesList[changeListIndex] = com;
                        } else {
                            this.contactChangesList.splice(changeListIndex, 1);
                        }
                    } else {
                        this.contactChangesList.push(com)
                    }
                }
                this.checkComminicationTypeChange();
            } else {
                let modelChangeListIndex = this.modalChangeList.findIndex((x) => {
                    return (com in x);
                });
                if (this.contactCopy[com] != event) {
                    if (modelChangeListIndex != -1) {
                        this.modalChangeList[modelChangeListIndex] = { [com]: event };
                    } else {
                        this.modalChangeList.push({ [com]: event })
                    }
                } else {
                    this.modalChangeList.splice(modelChangeListIndex, 1);
                }
                if (this.modalChangeList.length > 0) this.isChanged = true;
                else this.isChanged = false;
            }

        } else {
            if (!this.isChanged) { this.isChanged = true; }
        }
    }

    additionDetailsChange() {

        if (!(this.copyNotes) && (!this.additionalDetailNote || this.additionalDetailNote == "")) {
            this.isAdditionalDetailChange = false;
        } else if (this.copyNotes && this.additionalDetailNote == this.copyNotes.plainText) {
            this.isAdditionalDetailChange = false;
        } else {
            this.isAdditionalDetailChange = true;
        }
    }
    checkComminicationTypeChange() {
        let existChange: boolean = false;
        let hasEmptyNewListItem: boolean = false;
        let isChange: boolean = false;

        if (this.contactComTypeNewList.length > 0) {
            this.isCommTypeChange = false;
        }
        else if ((this.contactChangesList.length > 0 && this.contactComTypeNewList.length == 0) && (this.contact.communicationMethods.length != this.contactCopy.communicationMethods.length)) {
            this.isCommTypeChange = true;
        } else if (this.contactChangesList.length > 0 && this.contactComTypeNewList.length == 0) {
            this.isCommTypeChange = true;
        } else if ((this.contactChangesList.length == 0 && this.contactComTypeNewList.length == 0) && (this.contact.communicationMethods.length != this.contactCopy.communicationMethods.length)) {
            this.isCommTypeChange = true;
        } else if (this.contactChangesList.length == 0 && this.contactComTypeNewList.length == 0) {
            this.isCommTypeChange = false;
        }
    }

    mobileNumberValidation(selectedPhoneTypeId) {
        var pattern: RegExp;
        var sampleValueArray = [];
        this.communicationTypeValidation = environment.CommunicationTypeValidation;

        if (this.communicationTypeValidation.length > 0) {
            this.communicationTypeValidation.forEach(ctv => {
                if (ctv.Rule != undefined && ctv.Rule.length > 0 && ctv.CommunicationTypeId != undefined && ctv.CommunicationTypeId != "") {
                    var ids = ctv.CommunicationTypeId.split(",");
                    if (ids != undefined && ids.length > 0 && ids.indexOf(selectedPhoneTypeId.toString()) > -1) {
                        ctv.Rule.forEach(rule => {
                            if (environment.DefaultCountryId == rule.countryId) {
                                pattern = new RegExp(rule.Value);
                                if (rule.SampleNumber != "") {
                                    sampleValueArray.push(rule.SampleNumber);
                                }
                                //if (ctv.Rule.length > 1) {

                                //var resultSampleValue = ctv.Rule.map(e => {
                                //    return e.SampleNumber;
                                //}).join(', ');
                                //this.placholderSampleNumber = resultSampleValue;

                                /// }
                                //} else {
                                //    if (rule.SampleNumber != "") {
                                //        this.placholderSampleNumber = rule.SampleNumber;
                                //    }
                                //}
                                //if (ctv.SampleNumber != '') {
                                //    this.placholderSampleNumber = ctv.SampleNumber;
                                //}

                            }
                        });

                        if (pattern == undefined || pattern == null) {
                            var selectedRule = ctv.Rule.find(a => a.countryId == "");
                            pattern = new RegExp(selectedRule.Value);
                            // this.placholderSampleNumber = selectedRule.SampleNumber;
                            //this.placholderSampleNumber = ctv.SampleNumber;
                        }
                    }
                }
            });
            this.placholderSampleNumber = sampleValueArray.join(', ');
        }
        return pattern;
    }

    cancel() {
        this.clientAccessGrantBookingTypes= [];
        this.vps.scrollToAnchor("pageTop");
        this.loadContact();
        this.isCommTypeChange = false;
        this.contactChangesList = [];
        this.contactComTypeNewList = [];
        this.modalChangeList = [];
    }

    setTitles(title) {
        this.contact.titleId = title.id;
        this.contact.title = title.name;
        this.isChanged=true;
    }

    SelectCountry(SelectedCountryId: string) {
        let selectedCountry = this.countries.find(c => c.id == SelectedCountryId);
        if (selectedCountry) {
          this.formGroup.controls.Country.setValue(selectedCountry.name);
          this.contact.invoiceAddress.countryID = +selectedCountry.id;
          this.contact.invoiceAddress.country = selectedCountry.name;
          this.countrySearch.setValue(selectedCountry);
          this.isChanged = true;
        }
    }
    
    private _filter(value: string): Country[] {
        let filterValue = ''
        if(value.length > 0) {
          filterValue = value.toLowerCase();
          return this.countries.filter(country => country.name.toLowerCase().startsWith(filterValue));
        } else {
          return this.countries;
        }
    }
    
    handleMatAutoComplete(){
        if(this.matAutocomplete.autocomplete._isOpen){
          setTimeout(() => {
            this.matAutocomplete.closePanel()
          }, 0)
        }
    }
    
    displayFn(country?: any): string | undefined {
        return country ? country.name : undefined;
    } 

    setCommunicationMethod(comm: CommunicationMethod, commtype: CommunicationType) {
        comm.communicationTypeID = commtype.id;
        comm.communicationType = commtype.name;
        comm.pattern = this.mobileNumberValidation(commtype.id);
    }

    addPhone() {
        let cm = new CommunicationMethod();
        cm.id = this.phoneNewID.toString();
        cm.format = "1";
        cm.communicationTypeID = this.communicationTypesPhone[0].id;
        cm.communicationType = this.communicationTypesPhone[0].name;
        this.phoneNewID--;
        if (cm.communicationTypeID)
            cm.pattern = this.mobileNumberValidation(cm.communicationTypeID);
        this.contact.communicationMethods.push(cm);
        if (this.customerType && this.customerType.toLowerCase() == "individualclient") {
            this.contact.client.communicationMethods.push(cm);
        }
        // this.isChanged = true;
    }

    addEmail() {
        let cm = new CommunicationMethod();
        cm.id = this.phoneNewID.toString();
        cm.format = "2";
        cm.communicationTypeID = this.communicationTypesEmail[0].id;
        cm.communicationType = this.communicationTypesEmail[0].name;
        this.phoneNewID--;
        this.contact.communicationMethods.push(cm)
        this.isChanged = true;
        if (this.customerType && this.customerType.toLowerCase() == "individualclient") {
            this.contact.client.communicationMethods.push(cm);
        }

    }

    filterItemsOfType(type) {
        if (this.contact.communicationMethods)
            return this.contact.communicationMethods.filter(x => x.format == type);
    }

    isNewCommunicationMethod(val: string) {
        return (val.length > 0) ? (val.charAt(0) == "-") ? true : false : true;
    }

    removeCommunication(comm: CommunicationMethod) {
        if (comm) {
            if (!comm.id.startsWith('-'))
            {
                this.removedCommunicationMethods.push(comm.id);

                if (this.customerType && this.customerType.toLowerCase() == "individualclient") {

                    let cccid = null;
                    let cmvalue = this.contact.client.communicationMethods;
                    cmvalue.forEach(function (cmethod) {
                        if (comm.value == cmethod.value) {
                            cccid = cmethod.id;
                        }
                    });

                    this.removedClientCommunicationMethods.push(cccid);

                }
            }



            //remove from customer level
            this.contact.communicationMethods = this.contact.communicationMethods.filter(c => c.id != comm.id);

            //remove from client level
            this.contact.client.communicationMethods = this.contact.client.communicationMethods.filter(c => c.id != comm.id);

            if (parseInt(comm.id) < 0) {
                let newListIndex = this.contactComTypeNewList.findIndex(x => x.id == comm.id);
                if (newListIndex != -1) {
                    this.contactComTypeNewList.splice(newListIndex, 1);
                }
            } else {
                let changeIndex = this.contactChangesList.findIndex(x => x.id == comm.id);
                if (changeIndex == -1) {
                    this.contactChangesList.push(comm);
                }
            }

        }
        this.checkComminicationTypeChange();
    }



    getQASAddress(isFormattedAddress: boolean, QASID: string, searchText: string) {
        if (searchText.trim() == "") {
            this.qasLoader = false;
            this.addressList = new Addresses();
        }
        this.qasLoader = (isFormattedAddress || searchText.trim() != "");
        let qasAddressParam = new QASAddress();
        qasAddressParam.isFormattedAddress = isFormattedAddress;
        qasAddressParam.QASId = QASID;
        qasAddressParam.SearchText = searchText.trim();
        return this._customerService.getQASAddress(qasAddressParam);
    }

    findAddress(value) {
        this.addressChanged.next(value);
    }

    loadAddress(searchText: string) {
        this.addressList = new Addresses();
        if (searchText) {
            if (searchText.trim() != null) {
                this.getQASAddress(false, '', searchText).subscribe(data => {
                    this.isNoResultFound = false;
                    if (data != null && data.AddressDetails != null && data.AddressDetails.length > 0) {
                        this.addressList = data;
                    }
                    else {
                        this.isNoResultFound = true;
                        this.addressList = new Addresses();
                    }
                    this.qasLoader = false;
                });
            } else {
                this.qasLoader = false;
                this.addressList = new Addresses();
            }
        }



    }

    getFormattedAddress(address: string) {
        if (this.addressList.AddressDetails.length > 0)
            this.addressLoaded = false;

        this.qasLoader = true;
        let selectedAddress = this.addressList.AddressDetails.find(f => f.Address == address);
        let postCode = selectedAddress.Postcode;

        this.getQASAddress(true, selectedAddress.QASID, '').subscribe(data => {
            this.addressList = new Addresses();
            if (data != null && data.AddressDetails != null && data.AddressDetails.length > 0) {
                this.contact.invoiceAddress.country = (data.AddressDetails[0].Country == '') ? this.defaultCountryName : Utility.sentenceCase(data.AddressDetails[0].Country);
                this.contact.invoiceAddress.address1 = Utility.sentenceCase(data.AddressDetails[0].AddressLine1);
                this.contact.invoiceAddress.address2 = (data.AddressDetails[0].AddressLine2 != null) ? Utility.sentenceCase(data.AddressDetails[0].AddressLine2) : ' ';
                this.contact.invoiceAddress.address3 = (data.AddressDetails[0].AddressLine3 != null) ? Utility.sentenceCase(data.AddressDetails[0].AddressLine3) : ' ';
                this.contact.invoiceAddress.city = Utility.sentenceCase(data.AddressDetails[0].Locality);
                this.contact.invoiceAddress.county = data.AddressDetails[0].StateCode;
                this.contact.invoiceAddress.postCode = data.AddressDetails[0].Postcode;;
                this.contact.deliveryAddress = this.contact.invoiceAddress;
                // console.log("Delivaery" + this.contact.deliveryAddress)
                this.contact.mailingAddress = this.contact.invoiceAddress;
                if (this.showIndividualDetails && !this.showCoparateDetails) {
                    if (this.contact && this.contact.client) {
                        let _id = "-1";
                        if (this.contact.client.address) {
                            _id = this.contact.client.address.id;
                        }
                            this.contact.client.address = Object.assign({}, this.contact.invoiceAddress);
                            this.contact.client.address.id = _id;


                    }
                }
                this.addressLoaded = true;
                this.qasLoader = false;
                this.isChanged = true;
            }
        });
        this.addressList = new Addresses();
    }


    updateCustomer(Postdata) {
        if (this.isChanged || this.isCommTypeChange) {
            if (!this.formProfile.valid ||  !this.formGroup.valid || !this.copformGroup.valid) return;

            this.isChanged = false;
            this.isCommTypeChange= false;
            //  this.store.dispatch(new LoadingStarted());
            if (this.profileImage) {
                this.contact.client.profilePicture = this.profileImage
            }
            else if (this.profileImage == "" && this.isProfileImageRemoved) {
                this.contact.client.profilePicture = "";
            }
            if (this.customerType && this.customerType.toLowerCase() == "individualclient") {
                let cln = this.contact.client as IndividualClient;
                cln.firstName = this.contact.firstName;
                cln.lastName = this.contact.lastName;
                cln.titleId = this.contact.titleId;
                cln.clientName = this.contact.firstName+ ' '+this.contact.lastName;
                if (this.contact && this.contact.client) {
                    let _id = "-1";
                    if (this.contact.client.address) {
                        _id = this.contact.client.address.id;
                        this.contact.client.address = Object.assign({}, this.contact.invoiceAddress);
                        this.contact.client.address.id = _id;
                    }

                }
            }


            if (this.showCoparateDetails || this.profileImage || this.isProfileImageRemoved) {
                this._customerService.ClientPatch(this.contact.client).subscribe(res => {
                    if (res.status == "200")
                        this.patchContact();
                    else {
                        this.openSnackBarError(['Error occurred while processing your request']);
                        //    this.store.dispatch(new LoadingCompleted());
                        this.isChanged = true;
                    }
                }, err => {
                    this.openSnackBarError([err.message]);
                    //     this.store.dispatch(new LoadingCompleted());
                    this.isChanged = true;
                })
            } else {
                this.patchContact();
            }
        } else {
            if ((this.showCoparateDetails && this.IsEnableToUpdateAdditionalDetailsSectionForCorporateClients) || ((!this.showCoparateDetails && this.IsEnableToUpdateAdditionalDetailsSectionForIndividualClients))) {
                this.updateAdditionalDetails();
            }
        }
    }

    updateAdditionalDetails() {
        var thisPage = this;
        // additional details save
        if (this.copyNotes != this.additionalDetailNote || (this.additionalDetail.documents.length > 0) || this.requestToRegularBookingAccessNote || this.additionalDetail.removedDocumentIds.length >0) {
            this.additionalDetail.note.entityTypeID = "5";
            this.additionalDetail.contactId = this.contact.id;
            this.additionalDetail.contactRef = this.contact.ref;
            this.additionalDetail.clientId = this.contact.client.id;
            this.additionalDetail.clientRef = this.clientRef;
            this.additionalDetail.isUpdate = true;

            var customerNotes = this.additionalDetail.note.plainText.split("Client Note - ");
            if (this.copyNotes != this.additionalDetailNote) {

                var addtionalDetailFullNote = (customerNotes && customerNotes.length > 0) ? customerNotes.find(cn => cn.includes("Additional Note Created At")) : "";
                if (addtionalDetailFullNote) {
                    this.additionalDetail.note.plainText=this.additionalDetail.note.plainText.replace(addtionalDetailFullNote, ("Additional Note Created At : " + Utility.convertToISO(new Date()) + " " + this.additionalDetailNote));
                }
                else
                {
                    this.additionalDetail.note.plainText += "Client Note - Additional Note Created At : " + Utility.convertToISO(new Date()) + " " + this.additionalDetailNote;
                }
            }
            if (this.requestToRegularBookingAccessNote) {
                this.additionalDetail.note.plainText += "Client Note - Reqular Booking Note - " + (this.requestToRegularBookingAccessCount + 1) + " Created At: " + Utility.convertToISO(new Date()) + " " + this.requestToRegularBookingAccessNote;
                this.additionalDetail.sendNotificationForRegularBookingAccess = true;
                this.requestToRegularBookingAccessNote = "";
            }

            if (this.additionalDetailNote)
                this.copyNotes = JSON.parse(JSON.stringify(this.additionalDetailNote));
            this.additionalDetail.sendAdditionalDetailNotificationMail = ((this.additionalDetailNote != undefined && this.additionalDetailNote != "") || this.additionalDetail.documents.length > 0)
            // need to map additional notes
            let clientAdditionalDetailSaveResult = this._customerService.AdditionalDetail(this.additionalDetail);
            clientAdditionalDetailSaveResult.subscribe(data => {
                if (data.isError) {
                    let errorData = [];
                    if (data.errors != null && data.errors.length > 0) {
                        data.errors.forEach((error, i) => {
                            errorData.push(error.detail);
                        });
                        thisPage.snackBar.openFromComponent(AlertMessageComponent, {
                            duration: 3000,
                            verticalPosition: 'top',
                            data: errorData,
                        });
                    }
                } else {
                    this.openSnackBarSuccess(["Additional details has been updated successfully"]);
                    thisPage.loadCustomerDocuments();
                }
            },
                err => {
                    console.log(err);
                });
        }
    }
    patchContact() {
        let result = this._customerService.patchPostContact(this.contact);
        var thisPage = this;

        result.subscribe(res => {
            //   this.store.dispatch(new LoadingCompleted());

            if (res.body.IsError) {
                this.openSnackBarError([res.body.Errors[0].Detail]);
                console.error("Customer profile patch failed : ", res.body.Errors);
                this.isChanged = true;
            }
            else {
                // this.store.dispatch(new LoadingCompleted());
                this.removedCommunicationMethods.forEach(cm => {
                    this._customerService.CommunicationMethodDelete(cm, this.cid).subscribe(() => { });
                });
                this.removedClientCommunicationMethods.forEach(cccid => {

                    this._customerService.communication_method_delete(cccid, this.contact.client.id).subscribe(() => { });
                });

                if ((this.showCoparateDetails && this.IsEnableToUpdateAdditionalDetailsSectionForCorporateClients) || ((!this.showCoparateDetails && this.IsEnableToUpdateAdditionalDetailsSectionForIndividualClients))) {

                    this.updateAdditionalDetails();
                }
                this._customerService.GetindividualClient(this.cid).subscribe(data => {
                    if (data != null) {
                        this.contact.id = data.id;

                        this.contact.ref = data.ref;
                        this.contact.firstName = data.firstName;
                        this.contact.lastName = data.lastName;
                        this.contact.userName = data.userName;
                        if (data.communicationMethods != undefined && data.communicationMethods.length > 0) {
                            this.contact.communicationMethods=data.communicationMethods;
                            this.setComMethodPatterns();
                            data.communicationMethods.forEach(function (communicationMethod) {
                                if (communicationMethod.communicationType.toLowerCase().indexOf("email") > -1) {
                                    thisPage.contact.invoiceEmailAddress = communicationMethod.value;
                                }
                            });
                        }
                        this.contact.bookingTypeList = data.bookingTypeList ? data.bookingTypes : data.client.bookingTypes;

                        if (data.client != undefined) {
                            this.contact.client.id = data.client.id;
                            this.contact.client.clientName = data.client.clientName;
                            if(data.client.profilePicture){
                                this.contact.profilePicture = data.client.profilePicture;
                            }
                            // this.contact.profilePicture = data.client.profilePicture ? data.client.profilePicture : "";
                            this.contact.client.clientRef = data.client.clientRef;
                        }



                        this.contact.dateRegistered = new Date();
                        this.store.dispatch(new SetContact(this.contact, null, null, null, null, null));
                        this.store.complete();
                        // if profile image changed refreshing the page
                        if (this.profileImage) {
                            setTimeout(() => {
                                window.location.reload();
                            }, 3000);
                        }

                    }
                    else {
                        this.isChanged = true;
                        this.openSnackBarError(["Customer details has updated. To continue, please login again"]);
                    }
                },
                    err => {
                        this.openSnackBarError([err.msg]);
                        //    this.store.dispatch(new LoadingCompleted());
                        this.isChanged = true;
                    },
                    () => {
                        //  this.store.dispatch(new LoadingCompleted());
                    }
                );

                this.openSnackBarSuccess(["Your profile has been updated successfully"]);
            }
        });
    }

    openSnackBarSuccess(message) {
        this.snackBar.openFromComponent(SuccessMessageComponent, {
            data: message,
            duration: 4000,
            verticalPosition: 'top',
        });
    }

    openSnackBarError(message) {
        this.snackBar.openFromComponent(AlertMessageComponent, {
            data: message,
            duration: 4000,
            verticalPosition: 'top',
        });
    }

    //#region File upload

    public dropped(event: UploadEvent) {
        this.files = event.files;
        let snack = this.snackBar;


        for (const droppedFile of event.files) {
            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    if (this.checkFileExtesion(file.name)) {

                        if (file.size < this.docSize * 1048576) {
                            var myReader: FileReader = new FileReader();
                            myReader.onloadend = (e) => {
                                let aHD = new AdHocDocument();
                                aHD.name = file.name;
                                aHD.fileSize = Utility.calculateFileSize(file.size);

                                let stringdata = myReader.result.toString().split(',');
                                aHD.documentFile = stringdata[stringdata.length - 1];
                                aHD.file = file;
                                aHD.documentFileOriginal = myReader.result.toString().replace(stringdata[stringdata.length - 1], "");
                                this.additionalDetail.documents.push(aHD);
                                this.cdr.detectChanges();
                            };
                            myReader.readAsDataURL(file);
                        }
                        else {
                            snack.openFromComponent(AlertMessageComponent, {
                                duration: 3000,
                                verticalPosition: 'top',
                                data: ["Your request could not be processed. You have attempted to upload a document larger than " + this.docSize + "MB in size"]
                            });
                        }
                    }
                    else {
                        snack.openFromComponent(AlertMessageComponent, {
                            duration: 3000,
                            verticalPosition: 'top',
                            data: ["Your request could not be processed. You have uploaded an invalid document type. Only files with the following extensions are allowed:  .doc, .docx, .pdf, .txt, .xls, .xlsx, .jpeg, .jpg, .png"]
                        });
                    }
                });
            } else {
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
            }
        }
    }

    onFileChanged(event) {

        if (event.target.files && event.target.files.length > 0 && this.checkFileExtesion(event.target.files[0].name)) {

            if (event.target.files[0].size < this.docSize * 1048576) {
                var myReader: FileReader = new FileReader();
                myReader.onloadend = (e) => {
                    let aHD = new AdHocDocument();
                    aHD.file = event.target.files[0];
                    aHD.name = event.target.files[0].name;
                    aHD.fileSize = Utility.calculateFileSize(event.target.files[0].size);
                    let stringdata = myReader.result.toString().split(',');
                    aHD.documentFile = stringdata[stringdata.length - 1];
                    aHD.documentFileOriginal = myReader.result.toString().replace(stringdata[stringdata.length - 1], "");
                    this.additionalDetail.documents.push(aHD);
                    this.fileInputTagVariable.nativeElement.value = "";
                    this.cdr.detectChanges();
                };
                myReader.readAsDataURL(event.target.files[0]);
            }
            else {
                this.snackBar.openFromComponent(AlertMessageComponent, {
                    duration: 3000,
                    verticalPosition: 'top',
                    data: ["Your request could not be processed. You have attempted to upload a document larger than " + this.docSize + "MB in size"]
                });
            }
        }
        else {
            this.snackBar.openFromComponent(AlertMessageComponent, {
                duration: 3000,
                verticalPosition: 'top',
                data: ["Your request could not be processed. You have uploaded an invalid document type. Only files with the following extensions are allowed:  .doc, .docx, .pdf, .txt, .xls, .xlsx, .jpeg, .jpg, .png"]
            });
        }
    }

    removeFile(index) {
        this.additionalDetail.documents.splice(index, 1);
    }

    removeAddedFile(document) {
        if (!document.isDelete) {
            this.additionalDetail.removedDocumentIds.push(document.id);
            document.isDelete = true;
        }
    }

    checkFileExtesion(name: string) {
        let supportTypes = ["doc", "docx", "pdf", "txt", "xls", "xlsx", "jpeg", "jpg", "png"];
        let fileExtensionArray = name.split('.');
        let fileExtension = fileExtensionArray[fileExtensionArray.length - 1];
        return (supportTypes.indexOf(fileExtension.toLowerCase()) !== -1);
    }

    get64Stringdata(file: AdHocDocument) {
        if (file && file.file && file.file.size) {
            return this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file.file));
        }
        else return null;
    }

    downloadDocument(name, path) {
        if (path != undefined && path != null) {
            let arr = path.split('/');
            let fileFullName = arr[arr.length - 1];
            let fileExtension = fileFullName.split('.');
            return name + "." + fileExtension[fileExtension.length - 1];
        }
    }
    //#endregion

    //#region profile image upload
    onFileChangedForProfileImage(event) {

        if (this.IsFileExtesionImage(event.target.files[0].name)) {

            if (event.target.files[0].size < this.docSize * 1048576) {
                var myReader: FileReader = new FileReader();
                myReader.onloadend = (e) => {
                    this.profileImage = myReader.result;
                    this.fileInputTagforProfileImage.nativeElement.value = "";
                    this.isChanged = true;
                     // Trigger Angular change detection after updating profileImage
                     this.cdr.detectChanges();
                };
                myReader.readAsDataURL(event.target.files[0]);
            }
            else {
                this.snackBar.openFromComponent(AlertMessageComponent, {
                    duration: 3000,
                    verticalPosition: 'top',
                    data: ["Your request could not be processed. You have attempted to upload a document larger than " + this.docSize + "MB in size"]
                });
            }
        }
        else {
            this.snackBar.openFromComponent(AlertMessageComponent, {
                duration: 3000,
                verticalPosition: 'top',
                data: ["Your request could not be processed. You have uploaded an invalid document type. Only files with the following extensions are allowed: .jpeg, .jpg, .png"]
            });
        }
    }

    public droppedForProfileImage(event: UploadEvent) {


        if (this.profileImage)
            return;
        this.files = event.files;
        let snack = this.snackBar;
        for (const droppedFile of event.files) {
            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    if (this.IsFileExtesionImage(file.name)) {

                        if (file.size < this.docSize * 1048576) {
                            var myReader: FileReader = new FileReader();
                            myReader.onloadend = (e) => {
                                this.profileImage = myReader.result;
                                this.isChanged = true;
                                this.cdr.detectChanges();
                            };
                            myReader.readAsDataURL(file);
                        }
                        else {
                            snack.openFromComponent(AlertMessageComponent, {
                                duration: 3000,
                                verticalPosition: 'top',
                                data: ["Your request could not be processed. You have attempted to upload a document larger than " + this.docSize + "MB in size"]
                            });
                        }
                    }
                    else {
                        snack.openFromComponent(AlertMessageComponent, {
                            duration: 3000,
                            verticalPosition: 'top',
                            data: ["Your request could not be processed. You have uploaded an invalid document type. Only files with the following extensions are allowed: .jpeg, .jpg, .png"]
                        });
                    }
                });
            } else {
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
            }
        }
    }

    IsFileExtesionImage(name: string) {
        let supportTypes = ["jpeg", "jpg", "png"];
        let fileExtensionArray = name.split('.');
        let fileExtension = fileExtensionArray[fileExtensionArray.length - 1];
        return (supportTypes.indexOf(fileExtension.toLowerCase()) !== -1);
    }

    removeProfileImage() {
        this.profileImage = "";
        this.contact.client.profilePicture = "";
        this.isChanged = true;
        this.isProfileImageRemoved = true;
    }
    //#endregion profile image upload

    newRegularBooking() {
        var url = environment.RegularBookingPortalUrl;
        if (url.endsWith('/')) {
            url = url.slice(0, url.length - 1)
        }
        url = (url + '#/home?isNewBooking=1');
        window.open(url, "_self")
    }

}
