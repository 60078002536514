import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";


@Pipe({
    name: 'optimotranslate',
    pure: false
})

export class OptimoTanslate implements PipeTransform {

    constructor(private translate: TranslateService) { }

    transform(value: string, ...args: any[]) {
        if (value != null) {
            let tranlastedValue = this.translate.instant(value)
            if (value !== tranlastedValue) {
                //to check whether sent value was added in the translation file (en.json or other language)
                return tranlastedValue;
            } else {
                if (tranlastedValue.includes('.'))
                    return tranlastedValue.split('.')[1];
                else
                    return tranlastedValue;
            }
        }else   
            return '';
    }
}