export class SystemOption {
  public id: string;
  public keyName: string;
  public module: string;
  public keyValue: number;
  public description: string;
  public dataType: string;
  public readOnly: boolean;
  public displayValue: any;
  public sessionStartDate: Date;
  public sessionEndDate: any;
  public isSessionExpired: boolean;
  public isSessionTimerStart: boolean;
  public sessionKey;
  public hours = "00";
  public minutes = "00";
  public seconds = "00";
  public bonusSessionTime = 5;
  public sessionExpended = false;
  public sessionEndTime: number;
  public timerPaused: boolean = false;
  public timerPauseTime: any = null;
  public popupShowed: boolean;
  constructor() {
    this.isSessionTimerStart = false;
    this.popupShowed = false;
    this.sessionEndTime = 0;
  }
  initialize(d) {
    this.id = d.id;
    this.keyName = d.keyName;
    this.dataType = d.dataType;
    this.isSessionTimerStart = false;
    this.bonusSessionTime = d.bonusSessionTime;
    if (this.dataType == "Integer") {
      this.keyValue = parseInt(d.keyValue, 10);
      this.displayValue = parseInt(d.displayValue, 10);
      // this.displayValue = 60;
      if (this.displayValue > 60) {
        this.hours = (this.displayValue / 60).toString();
        this.minutes = (this.displayValue % 60).toString();
      } else {
        this.minutes = this.displayValue.toString();
      }
    }
    else {
      this.displayValue = d.displayValue;
      this.keyValue = d.keyValue;
    }
    this.description = d.description;
    this.module = d.module;
    this.isSessionExpired = false;
    return this;
  }

}
