import {
  ConfirmModalType,
  MAT_TOGGLE_TYPE,
  MetricUnit,
  PropertyType,
  RULERTYPE,
  ShapeType,
  CONFIGTYPE,
  MIME_TYPE,
} from "./utils";
import { Facility } from "./FacilityAndOtherStructure";
import { VenueMap } from "./VenueMap";
import { Configuration } from "./Configuration";

export class ViewBox {
  constructor(
    public x: number,
    public y: number,
    public width: number,
    public height: number
  ) { }
}
export class Invoice {
  public total: number = 0;
  public tax: number = 0;
  public priceBands = [];
}
export class SidePanelDetail {
  facility: SidePanelData;
  otherStructure: SidePanelData;
  image: SidePanelData;

  constructor() {
    this.facility = new SidePanelData(true);
    this.otherStructure = new SidePanelData(false);
    this.image = new SidePanelData(false);
  }
}

export class SidePanelData {
  isSelected: boolean;
  height: number;

  constructor(isSelected: boolean) {
    this.isSelected = isSelected;
    this.height = 0;
  }
}

export class ConfirmModalData {
  title: string;
  bodyText: string;
  confirmButton: boolean;
  confirmButtonText: string;
  modalType: ConfirmModalType;
}

export class IntersectionPoint {
  constructor(
    public point: any,
    public shapeOne: any,
    public shapeTwo: any,
    public wallOne: any,
    public wallTwo: any
  ) { }
}

export class Setting {
  constructor(
    public isGridView: boolean,
    public tableTableSpace: number,
    public wallTableSpace: number,
    public createAndAllocateStructureScale: number,
    public venueMapScale: number,
    public tableAreaScale: number,
    public structureUnit: MetricUnit,
    public tableUnit: MetricUnit,
    public onProgress = false
  ) { }
}

export class SvgPoints {
  x: number;
  y: number;

  constructor() {
    this.x = 0;
    this.y = 0;
  }
}

class BBox {
  x: number;
  y: number;
  width: number;
  height: number;
}

export class Block {
  public staticId: string;
  constructor(
    public id?: any,
    public name?: any,
    public facilityID?: string,
    public facilityShapeID?: string,
    public type?: ShapeType,
    public pathArray?: PathPoint[],
    public seatsRow: SeatsRow[] = [],
    public ConfigType: CONFIGTYPE = undefined,
    public style = new Style("none", "black", 2, 1),
    public tables: AllocatedTable[] = [],
    public edited = true,
    public isDelete = false,
    public spectatorViewURL = '',
    public isPrivate?:boolean
    
  ) { }

  initialize(data: any, isPrivate:boolean=false, facilityId, rows = [], seats = [], tables = []) {
    if (!!data) {
      this.staticId = data.id;
      this.isPrivate = isPrivate;
      this.id = "block_" + data.id;
      if(data.spectatorViewURL)
        this.spectatorViewURL=data.spectatorViewURL;
      this.pathArray = !!data.layout ? JSON.parse(data.layout) : [];
      this.ConfigType = +data.configurationTypeId;
      this.facilityShapeID = data.layoutShapeId;
      this.facilityID = facilityId;
      this.name = data.name;
      this.seatsRow = !!rows
        ? rows.map((row) =>
          new SeatsRow().initialize(
            row,
            seats
              .filter((s) => s.eventRow?.id == row.id)
              .sort((a, b) => (+a.name < +b.name ? -1 : 1))
          )
        )
        : [];
      this.edited = false;
      this.tables =
        !!tables && tables.length > 0
          ? tables.map((d) => new AllocatedTable().initialize(d))
          : [];
    }
    return this;
  }
}

export class SeatsRow {
  staticId: any;

  constructor(
    public id?: any,
    public name?: string,
    public linePathPoint?: PathPoint[],
    public seats?: Seat[],
    public seatsRadius?: number,
    public curve?: number,
    public seatSpacing?: number,
    public isSeatLabelInAsc = true,
    public edited = true,
    public isDelete = false
  ) { }
  initialize(row: any, seats) {
    this.staticId = row.id
    this.id = "row_" + row.id;
    this.name = row.name;
    this.linePathPoint = JSON.parse(row.layout);
    this.curve = row.curve;
    this.seatsRadius = row.radius; //!!row.radius ? row.radius : 0;
    this.seatSpacing = row.seatSpace;
    this.isSeatLabelInAsc = row.nameOrderAsc;
    this.seats =
      seats.length > 0
        ? seats.map((seat) => new Seat().initialize(seat, this.seatsRadius))
        : [];
    this.edited = false;
    return this;
  }
}
export class Seat {
  staticId: any;
  allocated: boolean;
  constructor(
    public id?: any,
    public centerPoint = new MousePosition(),
    public sideStartPoint?: MousePosition,
    public sideEndPoint?: MousePosition,
    public radius = 0,
    public priceBand?: PriceBand,
    public style = new Style(),
    public name?: string,
    public edited = true,
    public isDelete = false,
    public isAccessible = false,
    public isUnavailable = false,
    public isReserved = false,
    public isFrozen = false,
    public note?: string
  ) { }
  initialize(seat: any, r) {
    this.staticId = seat.id
    this.id = "seat_" + seat.id;
    this.centerPoint.x = seat.xPosition;
    this.centerPoint.y = seat.yPosition;
    this.priceBand = new PriceBand().initialize(seat.priceBand);
    this.radius = r;
    this.allocated = seat.hasAllocation;
    this.name = seat.name;
    this.edited = false;
    this.isAccessible = seat.accessible ? seat.accessible : false;
    this.isUnavailable = seat.unavailable ? seat.unavailable : false;
    this.isReserved = seat.reserved ? seat.reserved : false;
    this.isFrozen = seat?.freeze ? seat.freeze : false;
    this.note = seat.note ? seat.note : '';
    return this;
  }
}
export class FormSeatsProperties {
  constructor(
    public priceBand: PriceBand,
    public radius: number,
    public curve: number,
    public seatSpacing: number,
    public isSeatLabelAsc: boolean,
    public rowStart: string
  ) { }
}

export class FormTableTypeProperties {
  constructor(
    public name: string,
    public seatCount: number,
    public priceBand: PriceBand,
    public shareType: string,
    public width: number,
    public length: number,
    public radius: number,
    public seatRadius: number
  ) { }
}

export class Shape {
  id: string;
  visibility = true;
  style: Style;
  type: ShapeType;
  walls?: Wall[];
  groupId?: string;
  nonSeatingArea?: NonSeatingArea[];
  pathArray?: PathPoint[];
  properties?: Property[];
  isShapeGrouped = false;
  isClockWise?: Boolean;
}
export class Label {
  id: number;
  x: number;
  y: number;
  width: number;
  height: number;
  fontSize: string;
  rotateAngle: number;
  color: string;
}

export class NonSeatingArea {
  constructor(
    public id: string,
    public shapeType: ShapeType,
    public pathArray?: PathPoint[]
  ) { }
}

export class Wall {
  constructor(
    public id: string,
    public pathArray: PathPoint[] = [],
    public style = new Style("none", "black", 3, 1),
    public startPoint = new SvgPoints(),
    public endPoint = new SvgPoints()
  ) { }
}

export class PathPoint {
  constructor(
    public command?: string,
    public x?: number,
    public y?: number,
    public x1?: number,
    public y1?: number,
    public x2?: number,
    public y2?: number,
    public style?: Style,
    public arcRadiusX?: number,
    public arcRadiusY?: number,
    public xAxisRotation?: number,
    public largeArcFlag?: number,
    public sweepFlag?: number
  ) { }
}

// @ts-ignore
export interface ImageCroppedEvent extends ImageCroppedEvent {
  opacity: number;
}

export class Property {
  constructor(
    public id: string,
    public type: PropertyType,
    public propertyID?: string,
    public lineStart?: SvgPoints,
    public lineEnd?: SvgPoints,
    public image?: Image,
    public pathArray?: PathPoint[],
    public transform?: TransformValue,
    // public transform2?: TransformValue,
    public previousTransform?: TransformValue,
    // public previousTransform2?: TransformValue,
    public isFlipped = false
  ) { }
}

export class Image {
  constructor(
    public id?: string,
    public initialPoint = new SvgPoints(),
    public rotate = 0,
    public opacity = 100,
    public width = 100,
    public height = 100,
    public location?: string
  ) { }
  initialize(d) {
    console.log(d);
    this.id = d.id;
    this.initialPoint.x = d.x;
    this.initialPoint.y = d.y;
    this.rotate = d.rotate;
    this.opacity = d.opacity;
    this.width = d.width;
    this.height = d.height;
    this.location = MIME_TYPE[d.commonImageType] + d.file;
    return this;
  }
}

export class TransformData {
  constructor(
    public scale = 0,
    public rotate = 0,
    public translate = new MousePosition()
  ) { }
}

export class TransformValue {
  constructor(
    public scale: { x: number; y: number },
    public rotate: { rotation: number; x: number; y: number },
    public translate: { x: number; y: number },
    public childTransform?: TransformValue
  ) { }
}

export class Style {
  constructor(
    public fill?: string,
    public stroke?: string,
    public strokeWidth?: number,
    public opacity?: number
  ) { }
}

export class TableType {
  constructor(
    public id?: string,
    public name?: string,
    public shape?: Shape,
    public seats?: Seat[],
    public shareType?: boolean,
    public attributes = new Attributes()
  ) { }
  initialize(data) {
    const tbTypeObg = !!data.layout && JSON.parse(data.layout);
    this.id = data.configurationObjectId || data.id;
    this.name = data.name;
    if (tbTypeObg) {
      this.shape = tbTypeObg.shape;
      this.seats = tbTypeObg.seats;
    }
    this.shareType = data.defaultShared;
    this.attributes.width = data.defaultWidth;
    this.attributes.length = data.defaultLength;
    return this;
  }
}

export class AllocatedTable {
  public id: string;
  public name: string;
  public priceBand: PriceBand;
  public tableType: TableType;
  public tableShape: Shape;
  public seats: Seat[];
  public rotatedAngle: number;
  public isColapsed = false;
  public BlockId: string;
  public editMultiple: boolean;
  public shapeID: string;
  public edited = true;
  public isDeleted = false;
  initialize(d) {
    const tbTypeObg = JSON.parse(d.layout);
    this.id = d.id;
    this.name = d.name;
    // this.priceBand = PRICE_BAND[0];
    this.tableType = new TableType().initialize(d.configurationObject);
    this.tableShape = tbTypeObg.tableShape;
    this.seats = tbTypeObg.seats;
    this.rotatedAngle = d.angle;
    this.edited = true;
    return this;
  }
}

export class MouseOverToggleMat {
  constructor(
    public toggleType: MAT_TOGGLE_TYPE,
    public mainElement: SVGElement,
    public togglePosition: MousePosition,
    public facilityId?: string,
    public configurationID?: string,
    public blockId?: string,
    public seatRowId?: string,
    public seatId?: string
  ) { }
}

export class ImageSrc {
  constructor(
    public id: string,
    public file: FileReader,
    public src: string | ArrayBuffer,
    public name = "Empty"
  ) { }
}
export class VenueImage {
  public id: string;
  public type: string;
  public file: string;
  public VenueId: string;
  public name: string;

  initialize(d) {
    this.id = d.id;
    this.type = d.venueImageType;
    this.file = MIME_TYPE[this.type] + d.file;
    this.VenueId = d.venueId;
    this.name = d.name;
    return this;
  }
}
export class MousePosition {
  x: number;
  y: number;

  constructor() {
    this.x = 0;
    this.y = 0;
  }
}

export class Attributes {
  constructor(
    public length = 1,
    public height = 1,
    public width = 1,
    public radius = 1,
    public seatRadius = 1
  ) { }
}

export class PriceBand {
  id: string;
  name: string;
  color: string;
  priceList: number[];
  selected: boolean;
  constructor() {
    this.color = "red";
  }
  initialize(d) {
    if (!d) {
      return;
    }
    this.id = d.id;
    this.name = d.name;
    this.color = d.color;
    this.priceList = [];
    this.selected = false;
    return this;
  }
}
export class HistoryState {
  shapes?: Shape[];
  venueMap?: VenueMap;
  configuration?: Configuration;
  table?: TableType;
  detectedShapeObjects?: any;
}
export class History {
  past: HistoryState[];
  present: HistoryState;
  future: HistoryState[];
}
