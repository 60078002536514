
<div class="wm-modals-container wm-custom-modal-wrapper">
  <div class="mmc-modal-header mmc_flex--container wm-header" >
    <div class="sub-header-left">
      <div class="_icon-carge">
        <span class="material-icons wm-client-search-icon">
          search
          </span>
      </div>
      <div class="left-header-title">
        <span>{{ WMModelTitle.CLIENT_SEARCH }}</span>
      </div>
    </div>
    <div class="sub-header-right">
       <div class="mmc_flex__item--inherit mmc_flex__item--vcenter close-icon-cage mmc_just-content-center mat-button">
        <button  mat-button (click)="close()" (keydown.enter)="close()" class="mat-button"><i class="material-icons">close</i></button>
      </div>
    </div>
  </div>
  <div class="wm-client-search-body">
      <iframe [src]="url" class="wm_search-add-client_iframe" style="height: 100vh;"></iframe>
  </div>

</div>
