import { Component, Inject, Input, Output, OnInit, EventEmitter, OnDestroy } from '@angular/core';
//import { MatDialog } from '@angular/material/dialog';

//import { GlobalBookingQuestionnaire, ItemBookingQuestionnaire, PackageBookingQuestionnaire } from 'src/modules/models/regular-portal/booking/question/booking-questionnaire';
//import { BookingQuestion } from 'src/modules/models/regular-portal/booking/question/booking-question';
import { Answer, AnswerChoice } from 'src/modules/models/regular-portal/booking/question/answer';
//import { Question } from 'src/modules/models/regular-portal/booking/question/question';
//import { Question as Questions } from 'src/modules/models/regular-portal/booking/question/question-patch/question';

import { BookingQuestion, BooleanQuestionAnswer } from 'src/modules/models/regular-portal/booking/question/booking-question';
@Component({
  selector: 'opt-boolean-question',
  templateUrl: './boolean-question.component.html',
})
export class BooleanQuestionComponent implements OnInit {
  @Input() bookingQues: BooleanQuestionAnswer;
  @Input() dates: any[];
  @Output() QuestionAnswerOutput = new EventEmitter();
  @Output() DateSelectedOutput = new EventEmitter();
  displayArrayData: any[] = [];
  @Input() answeredQuestionIds: string[];
  @Input() questionNumber: number;

  constructor() {
  }
  ngOnInit() {
    this.displayArrayData.push(this.dates)
    var thisComponent = this;
    // if (this.bookingQues.question.answer == undefined) {
    //if (this.bookingQues.answer == undefined) {
    //    this.bookingQues.answer = false;

    //}
    if (this.bookingQues.answer != undefined) {
      //if (this.dates != undefined && this.dates.length > 0) {
      //    this.dates.forEach(function (dt, i) {
      //        thisComponent.addOrRemoveQuestionID(dt.actualQuestionTobePatch.id, true);
      //    });
      //} else {
      thisComponent.addOrRemoveQuestionID(this.bookingQues.id, true);
      //  }
      if (!(this.dates != undefined && this.dates.length > 0))
        this.QuestionAnswerOutput.emit(this.bookingQues);
      this.bookingQues.conditionalBookingQuestions =
        this.checkConditionalQuestions(this.bookingQues.conditionalBookingQuestions)
    }
  }
  QuestionAnswerOutputs(val) {

  }
  selectionChanged(val) {
    var thisComponent = this;
    if (this.dates != undefined && this.dates.length > 0) {
      this.dates.forEach(function (dt, i) {
        dt.actualQuestionTobePatch.answer = val.value;
        //  thisComponent.addOrRemoveQuestionID(dt.actualQuestionTobePatch.id, true);
      });
    }
    //else {
    thisComponent.addOrRemoveQuestionID(this.bookingQues.id, true);
    //}
    this.bookingQues.conditionalBookingQuestions =
      this.checkConditionalQuestions(this.bookingQues.conditionalBookingQuestions)
    this.bookingQues.answeredTime = new (Date);
    if (!(this.dates != undefined && this.dates.length > 0))
      this.QuestionAnswerOutput.emit(this.bookingQues);

  }
  conditionalQuestionAnswerChange(val) {
    if(this.bookingQues.conditionalBookingQuestions && this.bookingQues.conditionalBookingQuestions.length > 0) {
      const i = this.bookingQues.conditionalBookingQuestions.findIndex(a => a.id == val.id && a.question.id === val.question.id);
      if(i !== -1)
        this.bookingQues.conditionalBookingQuestions[i] = val;
        this.bookingQues.answeredTime = new (Date);
      this.QuestionAnswerOutput.emit(this.bookingQues);
    }
  }
  checkConditionalQuestions(conditionalQues: BookingQuestion[]) {
    if (conditionalQues) {
      conditionalQues.forEach(bq => {
        bq.bookingConditionalQuestionAnswers.forEach(ansS => {
          if (ansS.answerYes === this.bookingQues.answer) {
            bq.canDisplayQuestion = true;
          } else {
            bq.canDisplayQuestion = false;
          }
        })
      })
    }
    return conditionalQues
  }
  addOrRemoveQuestionID(bookingId: string, isAnswered: boolean) {
    let index = this.answeredQuestionIds.findIndex(x => x == bookingId);
    if (index >= 0) {
      if (!isAnswered) {
        this.answeredQuestionIds.splice(index, 1)
      }
    } else {
      if (isAnswered) {
        this.answeredQuestionIds.push(bookingId);
      }
    }
  }


  RecveselectedDates(event) {
    this.displayArrayData.push(event);
    this.DateSelectedOutput.emit(event);
  }


}
