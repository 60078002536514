import { Component, Inject, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatSnackBar } from '@angular/material/snack-bar';

import { BookingCommonComponent } from 'src/modules/booking-portal/booking-common.component';

import { FacilitySummary } from 'src/modules/models/asset/facility-summary';
import { AssetService } from 'src/modules/services/asset.service';
import { Utility } from 'src/modules/utility';
import { Store, ICart, AddFacility, UpdateFacility, EditFacility, DeleteFacilityPrice, UpdateBookingPackage } from 'src/modules/store/index';

import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";

import { MomentDateAdapter } from 'src/modules/booking-portal/common/date-time-adapter/moment-date-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FacilityAdaptor } from 'src/modules/models/asset/facility-adaptor';
import { BookingAdaptor } from 'src/modules/models/booking/booking-adaptor';
import { CalenderTimeslot, Timeslot } from 'src/modules/models/asset/timeslots';
import { environment } from 'src/environments/environment';
import { Booking } from 'src/modules/models/booking/booking';
import { ItemService } from 'src/modules/services/item.service';
import { AlternativeOptionsComponent } from 'src/modules/booking-portal/booking-cart/edit-modal/alternative-options/alternative-options.component';
import { DateFormat } from 'src/modules/date-format';
import { UpsellSummary } from 'src/modules/models/item/item';
import { Configuration } from 'src/modules/models/asset/configuration';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { ItemPriceGroup } from 'src/modules/models/item/item-price-group';
import { AppSetting } from 'src/modules/models/settings/casual-portal/app-setting';
import { WarningMessageComponent } from 'src/modules/shared/warning-message/warning-message.component';
import { Action, ErrorType } from 'src/modules/models/enum';
import { UserDefinedFunction } from 'src/modules/models/system/user-defined-function';
import { PackageFilter } from 'src/modules/models/booking/common';
import { BespokePackage } from 'src/modules/models/asset/bespoke-package';
import { DeleteConfirmationComponent } from 'src/modules/shared/delete-confirmation/delete-confirmation.component';
import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { QuestionnaireComponent } from '../../modals/questionnaire/questionnaire.component';
import { PackageQuestionPopupHidden, PackageQuestionPopupShown } from 'src/modules/store/actions';
import { PackageService } from 'src/modules/services/package.service';
import { SuccessMessageComponent } from 'src/modules/shared/success-message/success-message.component';
import { FormControl } from '@angular/forms';

const moment = _rollupMoment || _moment;



export const MY_FORMATS = {
    parse: { dateInput: 'LL' },
    display: {
        dateInput: 'DD MMM YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'opt-edit-modal',
    templateUrl: './edit-modal.component.html',
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ]
})

export class EditModalComponent extends BookingCommonComponent implements OnInit, OnDestroy {
    imagepath: string = environment.ApiUrl + environment.publishFolder;

    date = moment();
    facilitySummary: FacilitySummary;
    booking = new Booking;
    startTime: Date;
    endTime: Date;

    selected = 'option1';

    //select-configuration
    configurationDisplayMode: any;
    isConfigurationEnabled: boolean;
    isCategoryAndEventEnabled: boolean;
    isCategoryAndEventMandatory: boolean;
    // time picker validation
    minDate: Date = new Date();
    maxDate: Date;
    minStartDate: Date;
    maxStartDate: Date;
    minEndDate: Date;
    maxEndDate: Date;
    isValidStart: boolean;
    isValidEnd: boolean;

    attendees: number;
    isAvailable: boolean = false;
    showUnavailableMessage: boolean = false;
    isAddFacility = false;
    //timeWithBumpIn: Date;
    //timeWithBumpOut: Date;
    isCheckAvailabilityTriggered: boolean = false;
    showUnavailableFutureDateMessage: boolean = false;
    IsDateChangeFromCalendar: boolean = true;
    showUnavailableFutureDateErrorMessage: string;
    showUnavailableOperationalMessage: boolean = false;
    selectedPackageClass: any;
    selectedClass: number;
    showBookingLeadDayValidationMessage: boolean = false;
    bookingLeadDayValidationMessage: any;
    isBookingAdded: boolean = false;
    isBookingLeadDaysError: boolean = false;

    displayBumpInOutNotification: boolean;

    timeLineColors: any;
    isSystemAllowedImmediateConfirmation: boolean;
    systemDefaultPriceConsessionId: number;

    facilitySummaries: FacilitySummary[] = [];

    availableSlots: CalenderTimeslot[] = [];
    panelOpenState: boolean;
    isEnableAutoAvailabilityCheck: boolean = false;
    @ViewChild('hoverStartMenuTrigger') starttrigger: MatMenuTrigger;
    @ViewChild('hoverEndMenuTrigger') endtrigger: MatMenuTrigger;
    isEnableTimeLineAdvancedMode: boolean;
    startChangedByTimeslotClick: number = 0;
    endChangedByTimeslotClick: number = 0;

    errorMessages: string[] = [];
    warningMessages: string[] = [];

    cartData: ICart;

    AppData: AppSetting;

    continueProcess: boolean;

    packageFilter: PackageFilter;
    selectedPackage: string;
    selectedPackageDetail: BespokePackage;
    defaultEventName: string;
    packageClasses: any[] = [];
    selectedBusinessArea: string
    packages: BespokePackage[] = [];
    packageDetail: BespokePackage;
    isValidPackage: boolean;
    packageSelected: BespokePackage;
    isContinueProcessOnpackageChange: boolean = true;
    isContinueProcessOnTimeChange: boolean = true;
    isStartTimeChanged: boolean;
    isShowQuestionsUpdate: boolean = false;

    attendeesSubs : Subscription;
    attendessControl = new FormControl();
    timechange: boolean = false;
    itemUnavailableMessages: string;

    filteredPackages: BespokePackage[] = [];

    constructor(public dialogRef: MatDialogRef<EditModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, private _assetService: AssetService,
        private _itemService: ItemService,private _packageService: PackageService, private store: Store<any>, private cdr: ChangeDetectorRef, public snackBar: MatSnackBar) {
        super(store, _itemService, snackBar);
        this.AppData = environment.AppSetting as AppSetting;
        this.maxDate = new Date(environment.LatestFutureBookingDate);

        this.timeLineColors = environment.CheckAvailability.TimeLineColors
        
        this.facilitySummary = data.facility;
        this.selectedPackageClass = data.selectedPackageClass;
        this.panelOpenState = this.AppData.BookingConfig.IsDefaultExpandAlternativeConfigurationForFacility;



        if (this.facilitySummary) {
            this.facilitySummary.timeslotLoaded = false;
            this.startTime = Utility.convertISOToDate(this.facilitySummary.startTime);
            this.endTime = Utility.convertISOToDate(this.facilitySummary.endTime);
            this.attendees = this.facilitySummary.attendees;

            this.displayBumpInOutNotification = environment.ConfigurationSelection.DisplayBumpInOutNotification;
            this.date = moment(this.facilitySummary.startTime);
        }


        this.booking = new Booking();
        if (data.isaddfacility != undefined || data.isaddfacility != null) {
            this.isAddFacility = data.isaddfacility;
        }

        this.isSystemAllowedImmediateConfirmation = environment.ImmediateConfirmation.Allow;
        this.systemDefaultPriceConsessionId = environment.ImmediateConfirmation.DefaultPriceConsessionId;

        this.initializeTime();
        this.checkAvailability();

        this.AppData = environment.AppSetting as AppSetting;

        this.attendeesSubs = this.attendessControl.valueChanges
        .debounceTime(1500)
        .subscribe(e => {
            let itm = this.getItem();// (this.facilitySummary.selectedItem ? this.facilitySummary.selectedItem : this.facilitySummary.item);
                    
            // onchanging number of attendees if price typee cover
            if((itm.itemMinPrice && itm.itemMinPrice.priceType.toLowerCase() == "covers" ) || (itm.itemMaxPrice && itm.itemMaxPrice.priceType.toLowerCase() == "covers")){
                this.facilitySummary.attendees = this.attendees;
                this.calculateItemPrices([this.facilitySummary], this.getFormattedDate(this.startTime), this.getFormattedDate(this.endTime));
        
            }
            });

        

    }
    ngAfterViewChecked() {
        //your code to update the model
        this.cdr.detectChanges();
    }
    ngOnInit() {
        super.ngOnInit();
        this.validateAvailability();

        this.cart$.safeSubscribe(this, state => {
            this.booking = state.booking;
            this.facilitySummaries = state.addedFacilites;
            this.cartData = state as ICart;
           
            this.packages = state.bespokePackages;
            if (state.packageFilter != undefined && state.packageFilter != null) {
                this.packageFilter = state.packageFilter;
                if (state.packageFilter.Package) {
                    this.selectedPackage = state.packageFilter.Package.toString();
                    this.selectedPackageDetail = state.packageFilter.PackageDetail;
                    console.log("state.packageFilter.PackageDetail", state.packageFilter.PackageDetail);
                }
                this.defaultEventName = state.packageFilter.EventName;
                if (state != undefined && state.packageFilter != undefined && state.packageFilter != null) {
                    this.packageClasses = state.packageFilter.FilteredPackageClasses;
                    //this.selectedPackageClass = this.packageClasses.find(a => a.Id == state.packageFilter.PackageClass);
                }

            }
            

            this.packageDetail = state.packageFilter.PackageDetail;

            if ((!state.addedFacilites) || (state.addedFacilites && state.addedFacilites.length == 0)) {
                this.validatePackageClass(state);
            }

            if (this.filteredPackages.length==0) {
                this.filteredPackages = this.selectPackageList();
            }
        });

        var leadDays = (environment.LeadDaysForBooking == null) ? 0 : environment.LeadDaysForBooking;
        if (environment.DisableDatePickerForLeadDays) {
            var currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + leadDays)
            this.minDate = currentDate;
        }

        this.configurationDisplayMode = environment.ConfigurationSelection.DisplayMode;
        this.isConfigurationEnabled = environment.ConfigurationSelection.Enabled;
        this.isEnableAutoAvailabilityCheck = environment.CheckAvailability.EnableAutoAvailabilityCheck;

        this.isCategoryAndEventEnabled = this.AppData.AddEditPopupPageSettings.EnableCategoryAndEventSelection;
        this.isCategoryAndEventMandatory = this.AppData.AddEditPopupPageSettings.MandatoryCategoryAndEventSelection;

        if (this.isConfigurationEnabled) this.getImagesforConfigurations();
        this.isEnableTimeLineAdvancedMode = environment.CheckAvailability.EnableTimeLineAdvancedMode;

        this.calculateItemPrices([this.facilitySummary], this.getFormattedDate(this.startTime), this.getFormattedDate(this.endTime));

    }

    //ngOnDestroy() {
    //    if (this.cart_subscriber)
    //        this.cart_subscriber.unsubscribe();
    //}

    public close() {
        this.dialogRef.close(this.facilitySummary);
    }

    add(val) {
        this.attendees = ((this.attendees + val) <= 0) ? 0 : this.attendees + val;
    }

    async refreshBooking(facility: FacilitySummary) {
        var thisPage = this;


        if (this.isBookable()) {

            let _validationOutPut;

            if (this.cartData.userDefinedFunctions && this.cartData.userDefinedFunctions.length > 0) {
                _validationOutPut = await this.CallCustomFunction(facility, Action.OnUpdateBookingItem, this.getPackageDetail())
            }

            if (!_validationOutPut || (_validationOutPut && _validationOutPut.ContinueProcess)) {
                facility.attendees = this.attendees;
                facility.startTime = this.getFormattedDate(this.startTime);
                facility.endTime = this.getFormattedDate(this.endTime);

                if (this.facilitySummary.selectedItem != undefined) {
                    FacilityAdaptor.changeFacilityItem(this.facilitySummary, this.facilitySummary.selectedItem, this.facilitySummary.selectedConfiguration);
                } else if (this.facilitySummary.selectedConfiguration) {
                    this.facilitySummary.configuration = this.facilitySummary.selectedConfiguration;
                }


                this.store.dispatch(new DeleteFacilityPrice(facility));
                if (environment.PriceDisplayOption == 3) {
                    //     FacilityAdaptor.populateItemPrice(facility, this.selectedClass);
                }


                this.store.dispatch(new EditFacility(facility));

                this.calculateItemPrices([facility], this.getFormattedDate(this.startTime), this.getFormattedDate(this.endTime));

                if (facility.upsellSummaries != undefined && facility.upsellSummaries.length > 0) {
                    let time = 0;
                    facility.upsellSummaries.forEach(function (us) {
                        if (us.minPrice == undefined || us.maxPrice == undefined || (us.minPrice.priceTypeId != 5 && us.minPrice.priceTypeId != 7 && us.maxPrice.priceTypeId != 5 && us.maxPrice.priceTypeId != 7)) {
                            time = time + 150;
                            setTimeout(function () { thisPage.calculateUpsellItemPrice(us, facility.startTime, facility.endTime); }, time);
                        }
                    });
                }

                this.updateQuestionPopupState();

                this.dialogRef.close(facility);


            }
        }
    }

    calculateUpsellItemPrice(upsell: UpsellSummary, startTime, endTime) {
        var thisPage = this;
        if (this.selectedPackageClass == undefined) return;

        upsell.minPrice = undefined;
        upsell.maxPrice = undefined;
        upsell.startTime = Utility.convertToISO(Utility.convertISOToDate(startTime));
        upsell.endTime = Utility.convertToISO(Utility.convertISOToDate(endTime));

        let packageClassId = (this.selectedPackageClass && !isNaN(this.selectedPackageClass)) ? this.selectedPackageClass : environment.DefaultPackageClassId;
        let concessionsId = FacilityAdaptor.getBookingPriceConcession(this.customerDefaultPriceConsessionId, this.isAllImmediateConfirmed);

        let res = FacilityAdaptor.getMinMaxPriceConcessionForUpsell(concessionsId, packageClassId);
        let minConcessionsId = res.minPriceConcession;
        let maxConcessionsId = res.maxPriceConcession;

        // for min price
        let count = 0
        this._itemService.CalculateItemPrice(upsell.id, minConcessionsId, upsell.qty, upsell.startTime, upsell.endTime)
            .subscribe((result) => {
                if (result.calculatedItemPriceGroups != undefined && result.calculatedItemPriceGroups.length > 0) {
                    result.calculatedItemPriceGroups.forEach((cip, i) => {
                        if (!(cip instanceof Array)) {
                            if (cip.priceIncludingTax != 0)
                                upsell.minPrice = cip;
                        }
                    });
                }

                count++;
                if (count == 2) thisPage.setUpsellPrices(upsell);
            },
                err => {
                    console.log("Error : calculateUpsellItemPrice - min  " + upsell.name, err);
                    count++;
                    if (count == 2) thisPage.setUpsellPrices(upsell);
                }
            );

        // for max price
        setTimeout(function () {
            thisPage._itemService.CalculateItemPrice(upsell.id, maxConcessionsId, upsell.qty, upsell.startTime, upsell.endTime)
                .subscribe((result) => {
                    if (result.calculatedItemPriceGroups != undefined && result.calculatedItemPriceGroups.length > 0) {
                        result.calculatedItemPriceGroups.forEach((cip, i) => {
                            if (!(cip instanceof Array)) {
                                if (cip.priceIncludingTax != 0)
                                    upsell.maxPrice = cip;
                            }
                        });
                    }

                    count++;
                    if (count == 2) thisPage.setUpsellPrices(upsell);
                },
                    err => {
                        console.log("Error : calculateUpsellItemPrice - max  " + upsell.name, err);
                        count++;
                        if (count == 2) thisPage.setUpsellPrices(upsell);
                    }
                );
        }, 80);
    }

    setUpsellPrices(upsell: UpsellSummary) {
        upsell.minPrice = (upsell.minPrice == undefined || upsell.minPrice == null) ? upsell.maxPrice : upsell.minPrice;
        upsell.maxPrice = (upsell.maxPrice == undefined || upsell.maxPrice == null) ? upsell.minPrice : upsell.maxPrice;
        this.store.dispatch(new UpdateFacility(this.facilitySummary));
    }

    checkAvailability() {
        var current = moment().startOf('day');
        this.showBookingLeadDayValidationMessage = false;
        this.showUnavailableFutureDateMessage = false;
        this.showUnavailableOperationalMessage = false;

        var selectedDate = this.date.toDate();
        selectedDate.setHours(0, 0, 0, 0);
        var futureDate = new Date(environment.LatestFutureBookingDate);
        futureDate.setHours(0, 0, 0);

        if (selectedDate > futureDate) {
            this.showUnavailableFutureDateMessage = true;
            this.showUnavailableFutureDateErrorMessage = environment.LatestFutureBookingDateErrorMessage.replace("#FacilityName#", this.facilitySummary.name);
            this.showUnavailableFutureDateErrorMessage = this.showUnavailableFutureDateErrorMessage.replace("#LatestFutureBookingDate#", moment(environment.LatestFutureBookingDate).format(environment.DateFormat.toUpperCase()).toString());
            this.isAvailable = false;
            return;
        }
        var daysCount = this.date.diff(current, 'days');
        var leadDays = (environment.LeadDaysForBooking == null) ? 0 : environment.LeadDaysForBooking;
        this.isBookingLeadDaysError = false;

        if (!(daysCount >= leadDays)) {
            this.bookingLeadDayValidationMessage = environment.BookingLeadDayValidationMessage.replace("#LeadDays#", leadDays.toString());
            this.showBookingLeadDayValidationMessage = true;
            this.isAvailable = false;
            this.isBookingLeadDaysError = true;
            return;
        }

        if (this.isValidStart && this.isValidEnd) {
            this.isCheckAvailabilityTriggered = true;
            this.validateAvailability();
        }

        if (this.isEnableAutoAvailabilityCheck) {
            this.calculateItemPrices([this.facilitySummary], this.getFormattedDate(this.startTime), this.getFormattedDate(this.endTime));
        }
    }
    getStartEndTime() {
        //let startTime = this.getFormattedDate(this.startTime);
        //let endTime = this.getFormattedDate(this.endTime);
        let timeWithBumpIn = new Date(this.startTime);
        let timeWithBumpOut = new Date(this.endTime);
        var cnf = this.getSelectedConfiguration();
        if (cnf) {
            if (cnf.setupTime && cnf.setupTime > 0) {
                timeWithBumpIn.setMinutes(timeWithBumpIn.getMinutes() - cnf.setupTime)
                //startTime = this.getFormattedDate(timeWithBumpIn);
            }
            if (cnf.teardownTime && cnf.teardownTime > 0) {
                timeWithBumpOut.setMinutes(timeWithBumpOut.getMinutes() + cnf.teardownTime)
                // endTime = this.getFormattedDate(timeWithBumpOut);
                //15181
                let maxEndTime = new Date(this.endTime);
                maxEndTime.setHours(24, 0, 0)
                if (Utility.convertToISO(timeWithBumpOut) == Utility.convertToISO(maxEndTime)) {
                    timeWithBumpOut = new Date((new Date(this.endTime)).setHours(23, 59, 0, 0))
                }
            }
        }
        return { start: timeWithBumpIn, end: timeWithBumpOut }
    }
    async validateAvailability() {
        if (this.showUnavailableOperationalMessage)
        {
            return;
        }


        if (this.facilitySummary.facilityTypeId && environment.BookingRestrictions && environment.BookingRestrictions.length > 0) {
            var FCMaxtime = environment.BookingRestrictions.find(ftm => ftm.Id == this.facilitySummary.facilityTypeId)
            if (FCMaxtime && FCMaxtime.MaxNoOfBookingMinutes) {
                var diff = (new Date(this.endTime).getTime() - new Date(this.startTime).getTime()) / 1000;
                diff /= 60;
                let totalBookingMins = Math.abs(Math.round(diff));
                if (totalBookingMins > FCMaxtime.MaxNoOfBookingMinutes) {
                    this.showUnavailableFutureDateMessage = true;
                    this.showUnavailableFutureDateErrorMessage = "You may book this venue for max " + (FCMaxtime.MaxNoOfBookingMinutes % 60 == 0 ? ((FCMaxtime.MaxNoOfBookingMinutes / 60) + " hours") : (FCMaxtime.MaxNoOfBookingMinutes + " minutes")) + ". Please adjust the duration of the booking to proceed. "
                    this.isAvailable = false;
                    return;
                }

            }

        }

      // let fcArr = [];
      // fcArr.push(this.facilitySummary);

      if (this.facilitySummary) {
          let startEndTime = this.getStartEndTime();
          let startTime = this.getFormattedDate(startEndTime.start);
          let endTime = this.getFormattedDate(startEndTime.end);
          var isBookingForNonOperationalHours = this.validateNonOperationalHours(startEndTime.start, startEndTime.end);


          if (!isBookingForNonOperationalHours) {
              let availablityResult = this._assetService.timeslotSearch(this.facilitySummary.id, startTime, endTime);

              availablityResult.subscribe(res => {

                    if (environment.CheckAvailability.DisplayPreliminarySlotAsUnavailble || res.length < 1 || res == undefined) {
                        this.isAvailable = (res.length < 1);
                        this.showUnavailableMessage = !(res.length < 1);
                    } else {
                        var bookedSlot = res.filter(re => re.preliminary == false || re.preliminary === undefined);
                        this.isAvailable = !(bookedSlot && bookedSlot.length > 0)
                        this.showUnavailableMessage = (bookedSlot && bookedSlot.length > 0);
                    }
                },
                    err => {
                        console.log("Error", err);
                        this.isAvailable = false;
                        this.showUnavailableMessage = true;
                    }
                );
            }


          // if (this.IsDateChangeFromCalendar) {
          //     let dayStart = new Date(startTime);
          //     dayStart.setHours(0);
          //     dayStart.setMinutes(0);
          //     dayStart.setSeconds(0);

          //     let dayEnd = new Date(startTime);
          //     dayEnd.setHours(23);
          //     dayEnd.setMinutes(59);
          //     dayEnd.setSeconds(0);

          //     let availablityTimeSlotResult = this._assetService.timeslotSearch(this.facilitySummary.id, this.getFormattedDate(dayStart), this.getFormattedDate(dayEnd));
          //     availablityTimeSlotResult.subscribe(async res => {
          //         this.facilitySummary.availableTimeslots = [];
          //         this.facilitySummary.calenderTimeslots = [];
          //         this.facilitySummary.timeslotLoaded = false;
          //         //validate on date change

                        // res.forEach(t => {
                        //     if (t.asset.id) {
                        //         var facility = fcArr.find(f => f.id == t.asset.id);
                        //         if (facility) {
                        //             if (!facility.calenderTimeslots) facility.calenderTimeslots = [];
                        //             facility.timeslotLoaded = true;
                        //             var arr: Timeslot[] = [];
                        //             arr.push(t);
                        //             var ts = FacilityAdaptor.populateTimeslot(arr, this.date.toDate(), facility);
                        //             if (ts) {
                        //                 ts.forEach(x => {
                        //                     facility.calenderTimeslots.push(x);
                        //                 });
                        //             }
                        //         }
                        //     }
                        // });

          //             res.forEach(t => {
          //                 if (t.asset.id) {
          //                     var facility = fcArr.find(f => f.id == t.asset.id);
          //                     if (facility) {
          //                         if (!facility.calenderTimeslots) facility.calenderTimeslots = [];
          //                         facility.timeslotLoaded = true;
          //                         var arr: Timeslot[] = [];
          //                         arr.push(t);
          //                         var ts = FacilityAdaptor.populateTimeslot(arr, this.date.toDate(),facility);
          //                         if (ts) {
          //                             ts.forEach(x => {
          //                                 facility.calenderTimeslots.push(x);
          //                              });
          //                         }
          //                     }
          //                 }
          //             });


          //             FacilityAdaptor.populateAvailableTimeslots(fcArr, dayStart);

                        // fcArr.forEach(x => {
                        //     x.calendarTimeslotOriginal = [];
                        //     x.calenderTimeslots.forEach(val => x.calendarTimeslotOriginal.push(Object.assign({}, val)));
                        // });

          //             fcArr.forEach(x=>{
          //                 x.calendarTimeslotOriginal = [];
          //                 x.calenderTimeslots.forEach(val => x.calendarTimeslotOriginal.push(Object.assign({}, val)));
          //             });


                          

          //                 let _validationOutPut = await this.CustomFunctionOnCalanderPopulate(fcArr);
          //             }

          //             //this.facilitySummary = Object.assign({}, fcArr[0]);
          //             this.facilitySummary.timeslotLoaded = true;
          //             this.IsDateChangeFromCalendar = false;
          //         }
          //     },
          //         err => {
          //             console.log("Error", err);
          //             this.showUnavailableMessage = true;
          //         }
          //     );
          // }
      }
    }

    validateNonOperationalHours(searchStartTime: Date, searchEndTime: Date) {
        var isBookingForNonOperationalHours = false;
        if (!environment.AllowToDoBookingForNonOperationalHours) {
            //let searchStartTime = new Date(this.date.year(), this.date.month(), this.date.date());
            //if (this.startTime)
            //    searchStartTime.setHours(this.startTime.getHours(), this.startTime.getMinutes(), this.startTime.getSeconds());

            //let searchEndTime = new Date(this.date.year(), this.date.month(), this.date.date());
            //if (this.endTime)
            //    searchEndTime.setHours(this.endTime.getHours(), this.endTime.getMinutes(), this.endTime.getSeconds());

            if (this.facilitySummary.openingTime) {
                let openingTime = Utility.changeDatePart(Utility.convertISOToDate(this.facilitySummary.openingTime), Utility.convertISOToDate(this.getFormattedDate(this.startTime)));
                if (searchStartTime < openingTime && searchStartTime.toTimeString() != openingTime.toTimeString()) {
                    this.showUnavailableOperationalMessage = true;
                    this.isAvailable = false;
                    isBookingForNonOperationalHours = true;
                    // return;
                }
            }
            if (this.facilitySummary.closingTime) {
                let closingTime = Utility.changeDatePart(Utility.convertISOToDate(this.facilitySummary.closingTime), Utility.convertISOToDate(this.getFormattedDate(this.endTime)));
                if (searchEndTime > closingTime && searchEndTime.toTimeString() != closingTime.toTimeString()) {
                    this.showUnavailableOperationalMessage = true;
                    isBookingForNonOperationalHours = true
                    this.isAvailable = false;
                    //  return;
                }
            }
        }
        if (!isBookingForNonOperationalHours)
            this.showUnavailableOperationalMessage = false;

        return isBookingForNonOperationalHours;
    }
    getFormattedDate(selected: Date) {
        let searchabledate = new Date(this.date.year(), this.date.month(), this.date.date());
        if (selected)
            searchabledate.setHours(selected.getHours(), selected.getMinutes(), selected.getSeconds());
        return Utility.convertToISO(searchabledate);
    }

    attendeesChanged(val) {
        if (new String(val).length > 5) {
            val = + String(val).slice(0, 5);
        }
    }

    displayBumpIn(date: Date) {
        let tempDate = new Date(date);
        tempDate.setMinutes(tempDate.getMinutes() - this.facilitySummary.configuration.setupTime)
        return tempDate
    }

    displayBumpOut(date: Date) {
        let tempDate = new Date(date);
        tempDate.setMinutes(tempDate.getMinutes() + this.facilitySummary.configuration.teardownTime)
        return tempDate
    }



    async addBooking(action : string) {


        if (this.isBookable()) {


            this.isBookingAdded = true;
            this.facilitySummary.startTime = this.getFormattedDate(this.startTime);
            this.facilitySummary.endTime = this.getFormattedDate(this.endTime);
            this.facilitySummary.attendees = (this.attendees == null || isNaN(this.attendees) || this.attendees == 0) ? 1 : this.attendees;

            if (this.facilitySummary.selectedItem != undefined) {
                FacilityAdaptor.changeFacilityItem(this.facilitySummary, this.facilitySummary.selectedItem, this.facilitySummary.selectedConfiguration);
            } else if (this.facilitySummary.selectedConfiguration) {
                this.facilitySummary.configuration = this.facilitySummary.selectedConfiguration;
            }
            //this.facilitySummary.configuration = FacilityAdaptor.getConfiguration(this.facilitySummary.item, this.facilitySummary);



            //validateOnAddToBooking

            let _validationOutPut;


            if (this.cartData.userDefinedFunctions && this.cartData.userDefinedFunctions.length > 0) {
                _validationOutPut = await this.CallCustomFunction(this.facilitySummary, Action.OnAddItemToBooking, this.getPackageDetail())
            }

            if (!_validationOutPut || (_validationOutPut && _validationOutPut.ContinueProcess)) {
                this.updateQuestionPopupState();

                //action 0:Add More Venues;  1: Book Now
                if(action == "0"){
                    let _deepClonefcs = _.cloneDeep(this.facilitySummary);
                    this.store.dispatch(new AddFacility(_deepClonefcs, action));
                }
                else if(action == "1"){
                    this.store.dispatch(new AddFacility(this.facilitySummary, action));
                }

                this.calculateItemPrices([this.facilitySummary], this.getFormattedDate(this.startTime), this.getFormattedDate(this.endTime));
                    
                var n = 0;
                //setTimeout(() => {
                //    n = n + 10;
                //    this.isBookingAdded = false;
              //    this.dialogRef.close(this.facilitySummary);
              //}, 2000);

              if(action == "0"){
                this.snackBar.openFromComponent(SuccessMessageComponent, {
                    data: "Cart Updated SuccessFully with " +  this.facilitySummary.name + ".",
                    duration: 5000,
                    verticalPosition: 'top',
                });
                // setTimeout(() => {
                //   n = n + 10;
                //   this.isBookingAdded = false;
                //   this.dialogRef.close();
                // }, 2000);
              }
              setTimeout(() => {
                n = n + 10;
                this.isBookingAdded = false;
                
                //action 0:Add More Venues;  1: Book Now
                this.dialogRef.close(action);
                
              }, 2000);
              }

        }
        return;
    }

    //#region time picker

    initializeTime(): void {
        this.minStartDate = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
        this.maxStartDate = new Date();
        this.maxStartDate.setHours(24, 0, 0);
        this.maxStartDate = moment(this.maxStartDate).add(environment.MinimumBookingDuration * -1, 'm').toDate();

        this.minEndDate = moment(this.startTime).add(environment.MinimumBookingDuration, 'm').toDate();
        this.maxEndDate = new Date();
        this.maxEndDate.setHours(24, 0, 0);
        this.dateChanged();
    }

    async dateChanged() {

        this.showBookingLeadDayValidationMessage = false;
        this.showUnavailableFutureDateMessage = false;
        this.isAvailable = false;
        this.showUnavailableMessage = false;
        this.IsDateChangeFromCalendar = true;
        this.startTime = Utility.changeDatePart(this.startTime, this.date.toDate());
        var dt = this.date.toDate();
        var today = new Date();



        if (!(dt.getFullYear() == today.getFullYear() && dt.getMonth() == today.getMonth() && dt.getDate() == today.getDate())) {
            dt.setHours(0, 0, 0);
            this.minStartDate = dt;
        } else {
            if (new Date(this.startTime) < new Date()) {
                this.startTime = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
            }
            else {
                let newStartTime = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
                if (this.startTime) {
                    newStartTime.setHours(this.startTime.getHours());
                    newStartTime.setMinutes(this.startTime.getMinutes());
                    this.startTime = newStartTime;
                }
            }
            this.minStartDate = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
        }

        this.maxStartDate = Utility.changeDatePart(new Date(this.maxStartDate), this.date.toDate());
        this.maxStartDate.setMinutes(this.maxStartDate.getMinutes() + 1);
        let newEndTime = moment(this.startTime).add(environment.MinimumBookingDuration, 'm').toDate();

        if (this.endTime) {
            newEndTime.setHours(this.endTime.getHours());
            newEndTime.setMinutes(this.endTime.getMinutes());
            this.endTime = newEndTime;
        }

        this.minEndDate = new Date(moment(this.startTime).add(environment.MinimumBookingDuration, 'm').toDate());
        this.maxEndDate = new Date(this.minEndDate);
        this.maxEndDate.setHours(24, 0, 0);

        var selectedDate = this.date.toDate();
        selectedDate.setHours(0, 0, 0, 0);
        var futureDate = new Date(environment.LatestFutureBookingDate);
        futureDate.setHours(0, 0, 0);

        if (selectedDate > futureDate) {
            this.showUnavailableFutureDateMessage = true;
            this.showUnavailableFutureDateErrorMessage = environment.LatestFutureBookingDateErrorMessage.replace("#FacilityName#", this.facilitySummary.name);
            this.showUnavailableFutureDateErrorMessage = this.showUnavailableFutureDateErrorMessage.replace("#LatestFutureBookingDate#", moment(environment.LatestFutureBookingDate).format(environment.DateFormat.toUpperCase()).toString());
            this.isAvailable = false;
        }
        if (this.isEnableAutoAvailabilityCheck) this.checkAvailability();

        if (this.cartData && this.cartData.userDefinedFunctions && this.cartData.userDefinedFunctions.length > 0) {
            let _validationOutPut = await this.CallCustomFunction(this.facilitySummary, Action.OnBookingItemDateChange, this.getPackageDetail());
            const tempFacilitySummary: FacilitySummary[] = [];
            this.facilitySummary.availableTimeslots = [];
            tempFacilitySummary.push(this.facilitySummary);
            FacilityAdaptor.populateAvailableTimeslots(tempFacilitySummary, selectedDate);
        }

        this.changetimeline();

        this.itemUnavailableMessages = !this.isItemAvailable ? "This item is not available for the selected Date" : undefined;
        
    }

    changetimeline()
    {
        let fcArr = [];
        fcArr.push(this.facilitySummary);
        
        if (this.IsDateChangeFromCalendar) {
            let dayStart = new Date(this.startTime);
            dayStart.setHours(0);
            dayStart.setMinutes(0);
            dayStart.setSeconds(0);

            let dayEnd = new Date(this.startTime);
            dayEnd.setHours(23);
            dayEnd.setMinutes(59);
            dayEnd.setSeconds(0);

            let availablityTimeSlotResult = this._assetService.timeslotSearch(this.facilitySummary.id, this.getFormattedDate(dayStart), this.getFormattedDate(dayEnd));
            availablityTimeSlotResult.subscribe(async res => {
                this.facilitySummary.availableTimeslots = [];
                this.facilitySummary.calenderTimeslots = [];
                this.facilitySummary.timeslotLoaded = false;
                //validate on date change

                if (this.isEnableTimeLineAdvancedMode)
                    this.drawSelectedTimeline();
                if (res) {

                    res.forEach(t => {
                        if (t.asset.id) {
                            var facility = fcArr.find(f => f.id == t.asset.id);
                            if (facility) {
                                if (!facility.calenderTimeslots) facility.calenderTimeslots = [];
                                facility.timeslotLoaded = true;
                                var arr: Timeslot[] = [];
                                arr.push(t);
                                var ts = FacilityAdaptor.populateTimeslot(arr, this.date.toDate(),facility);
                                if (ts) {
                                    ts.forEach(x => {
                                        facility.calenderTimeslots.push(x);
                                    });
                                }
                            }
                        }
                    });


                    FacilityAdaptor.populateAvailableTimeslots(fcArr, dayStart);
    
    
                    fcArr.forEach(x=>{
                        x.calendarTimeslotOriginal = [];
                        x.calenderTimeslots.forEach(val => x.calendarTimeslotOriginal.push(Object.assign({}, val)));
                    });
    
    
                    if (this.cartData.userDefinedFunctions && this.cartData.userDefinedFunctions.length > 0) {
    
                        let _validationOutPut = await this.CustomFunctionOnCalanderPopulate(fcArr);
                    }
    
                    //this.facilitySummary = Object.assign({}, fcArr[0]);
                    this.facilitySummary.timeslotLoaded = true;
                    this.IsDateChangeFromCalendar = false;
                }
            },
                err => {
                    console.log("Error", err);
                    this.showUnavailableMessage = true;
                }
            );
        }

    }

    startTimeChanged() {
        this.timechange = true;
        if (!this.isEnableAutoAvailabilityCheck)
            this.isAvailable = false;
        if (this.startTime != null) {
            if (this.startChangedByTimeslotClick == 0) {
                var dtObj = Utility.roundToMinDuration(this.startTime, environment.MinimumBookingDuration);
                if (dtObj.isDateChanged)
                    this.startTime = moment(dtObj.newDate).toDate();
            }
            else {
                this.startChangedByTimeslotClick--;
            }


            this.minEndDate = moment(this.startTime).add(environment.MinimumBookingDuration, 'm').toDate();

            if (moment(this.startTime) >= moment(this.endTime))
                this.endTime = moment(this.startTime).add(environment.MinimumBookingDuration, 'm').toDate();

            var dt = this.date.toDate();
            var today = new Date();
            if ((dt.getFullYear() == today.getFullYear() && dt.getMonth() == today.getMonth() && dt.getDate() == today.getDate())) {
                if (this.startTime < today) {
                    this.startTime = Utility.roundToMinDuration(new Date(), environment.MinimumBookingDuration).newDate;
                }
            }
            if (this.endTime.getFullYear() != this.startTime.getFullYear() ||
                this.endTime.getMonth() != this.startTime.getMonth() ||
                this.endTime.getDate() != this.startTime.getDate()) {
                this.endTime.setFullYear(this.startTime.getFullYear(), this.startTime.getMonth(), this.startTime.getDate());
            }
        }
        let startEndTime = this.getStartEndTime();
        this.validateNonOperationalHours(startEndTime.start, startEndTime.end);
        if (this.isEnableTimeLineAdvancedMode)
            this.drawSelectedTimeline();

        this.isStartTimeChanged = true;
        this.CallTimeChangeValidation();
    }

    endTimeChanged() {
        this.timechange = true;
        if (!this.isEnableAutoAvailabilityCheck)
            this.isAvailable = false;
        if (this.endTime != null) {
            if (this.endTime.getFullYear() == this.maxEndDate.getFullYear() &&
                this.endTime.getMonth() == this.maxEndDate.getMonth() &&
                this.endTime.getDate() == this.maxEndDate.getDate() &&
                this.endTime.getHours() == this.maxEndDate.getHours() &&
                this.endTime.getMinutes() == this.maxEndDate.getMinutes()) {
                this.endTime = moment(this.endTime).add(-1, 'm').toDate();
            } else {
                if (this.endChangedByTimeslotClick == 0) {
                    var dtObj = Utility.roundToMinDuration(this.endTime, environment.MinimumBookingDuration, true);
                    if (dtObj.isDateChanged)
                        this.endTime = moment(dtObj.newDate).toDate();


                } else {
                    this.endChangedByTimeslotClick--;
                }


                if (moment(this.startTime) >= moment(this.endTime))
                    this.endTime = moment(this.startTime).add(environment.MinimumBookingDuration, 'm').toDate();
            }            
        }
        if (this.isEnableTimeLineAdvancedMode)
            this.drawSelectedTimeline();


        let startEndTime = this.getStartEndTime();
        this.validateNonOperationalHours(startEndTime.start, startEndTime.end);

        this.isStartTimeChanged = false;
        this.CallTimeChangeValidation();

        return this.endTime;
    }

    closeTimeMenu() {
        if(this.timechange)
        {
            if (this.isEnableAutoAvailabilityCheck) {
                this.checkAvailability();
                this.closeEndTimePopup();
            } else {
                let startEndTime = this.getStartEndTime();
                this.validateNonOperationalHours(startEndTime.start, startEndTime.end);
            }
            
            let itm = (this.facilitySummary.selectedItem ? this.facilitySummary.selectedItem : this.facilitySummary.item);
                        
            // onchanging number of attendees if price typee cover
            if((itm.itemMinPrice && itm.itemMinPrice.priceType.toLowerCase() == "covers" ) || (itm.itemMaxPrice && itm.itemMaxPrice.priceType.toLowerCase() == "covers")){
                this.facilitySummary.attendees = this.attendees;
            }
            this.calculateItemPrices([this.facilitySummary], this.getFormattedDate(this.startTime), this.getFormattedDate(this.endTime));
            this.timechange = false;

        }
        else{
            this.closeEndTimePopup();
        }
    }

    //display recive message
    receiveConfigurationMessage(event) {

        let cnf = this.getSelectedConfiguration();
        let newCnf = this.facilitySummary.configurations.find(f => f.id == event);
        this.facilitySummary.selectedConfiguration = newCnf;
        if (cnf.setupTime != newCnf.setupTime || cnf.teardownTime != newCnf.teardownTime) {
            if (this.isEnableAutoAvailabilityCheck) {
                this.checkAvailability();
            }
            this.drawSelectedTimeline();
        }


    }

    // start time end time click event
    setStartEndTime(slot) {
        const startTimeSlot = Utility.convertISOToDate(slot.startTime);
        const endTimeSlot = Utility.convertISOToDate(slot.endTime);
        this.startChangedByTimeslotClick++;
        this.startTime = startTimeSlot;
        this.endChangedByTimeslotClick++;
        this.endTime = endTimeSlot;

        //time and date initialize
        // this.initializeTime();
        //this.isAvailable = true;
        //this.showUnavailableMessage = false;

        if (this.isEnableAutoAvailabilityCheck)

            this.checkAvailability();


    }

    openAlternativeOptions(): void {
        //console.log(facility);
        this.facilitySummary.startTime = this.getFormattedDate(this.startTime);
        this.facilitySummary.endTime = this.getFormattedDate(this.endTime);

        const dialogRef = this.dialog.open(AlternativeOptionsComponent, {
            data: { fc: this.facilitySummary },
            panelClass: ['w80modal', 'dialog-container-common'],
            width: '250px'
        });
    }

    getImagesforConfigurations() {
        let configurationWithNoImage = [];
        if (this.facilitySummary.configurations != undefined && this.facilitySummary.configurations.length > 0) {
            this.facilitySummary.configurations.forEach((c, i) => {
                let configuration = c as Configuration;
                if (configuration.configurationThumbnail == undefined && configuration.type != undefined) {
                    configurationWithNoImage.push({
                        id: configuration.id,
                        type: configuration.type,
                        configurationCategoryId: configuration.configurationCategoryId
                    });
                }
            });

            if (configurationWithNoImage.length > 0) {
                let result = this._assetService.getConfigurationImages(configurationWithNoImage);
                result.safeSubscribe(this, data => {
                    
                    data.forEach((c, i) => {
                        let index = this.facilitySummary.configurations.findIndex(x => x.id == c.id);
                        if (index > -1)
                            this.facilitySummary.configurations[index].configurationThumbnail = (c.isLocalUrl) ? environment.ApiUrl + c.url : c.url;
                    });
                });
            }

        }
    }


    closeStartTimePopup() {
        this.starttrigger.closeMenu()
    }

    closeEndTimePopup() {
        this.endtrigger.closeMenu()
    }
    setAvailableSlots(slots: CalenderTimeslot[]) {
        this.availableSlots = slots;
    }
    drawSelectedTimeline() {
        let searchdate = new Date(this.date.year(), this.date.month(), this.date.date());
        let selectedStart = Utility.appendTimePart(searchdate, Utility.convertISOToDate(this.startTime), true);
        let selectedEnd = Utility.appendTimePart(searchdate, Utility.convertISOToDate(this.endTime), true);
        FacilityAdaptor.populateSelectedTimeslot(this.facilitySummary, selectedStart, selectedEnd, this.getSelectedConfiguration());
    }



    //getFaaciltyItemPriceDisplay(facilitySummary) {

    //    if (facilitySummary.item != undefined && facilitySummary.item.calculatedMinPrice != undefined) {
    //        if (facilitySummary.item.calculatedMinPrice.priceIncludingTax == facilitySummary.item.calculatedMaxPrice.priceIncludingTax) {
    //        return true;
    //    } else {
    //            return false;
    //        }
    //    }
    //    return false;
    //}

    getFacilityName(facility) {
        if (facility.name != undefined && facility.item.name != undefined) {
            if (facility.name == facility.item.name) {
                return facility.item.name;
            }
            return (facility.name + " : " + facility.item.name);
        }
    }

    getTimeslotAvailabilityHeader() {
        var checkTimeSlots = this.availableSlots.length;
        if (checkTimeSlots == 0) {
            return 'No time slots are available';
        }
        return 'Available time slots';

    }

    getBumpInOutNotification() {

        if (this.displayBumpInOutNotification) {
            //undefined check
            var configuration = (this.facilitySummary.selectedConfiguration != undefined) ? (this.facilitySummary.selectedConfiguration) : (this.facilitySummary.configuration);
            var SetupTime = (configuration && configuration.setupTime != null) ? (configuration.setupTime) : 0;
            var teardownTime = (configuration && configuration.teardownTime != null) ? (configuration.teardownTime) : 0;
            //null check
            if (SetupTime == 0 && teardownTime == 0) {
                return;
            }
            if (SetupTime == 0 || teardownTime == 0) {
                var returnDisplayBumpInOutValue = (SetupTime == 0) ? ("This venue has a setup time of " + SetupTime + " minutes") : ("This venue has a  tear down time of " + teardownTime + " minutes");
                return returnDisplayBumpInOutValue;
            }
            if (SetupTime == teardownTime) {
                return ("This venue has a setup and tear down time of " + SetupTime + " minutes");
            }
            return ("This venue has a setup time of " + SetupTime + " minutes and tear down time of " + teardownTime + " minutes");

        }
        return;
    }
    isFacilityAndItemNameSame() {
        let _itemName = "";
        if (this.facilitySummary)
            if (this.getItem().name) {
                _itemName = this.getItem().name.trim();
            }
        return this.facilitySummary.name.trim() == _itemName;
        return false;
    }
    getItem() {
        if (this.facilitySummary) {
            var selectedItem = this.facilitySummary.selectedItem;
            if (selectedItem == undefined) {
                selectedItem = this.facilitySummary.item;
            }
            return selectedItem;
        }
        return;
    }
    getConfigurationData() {
        var selectedItem = this.getItem();
        if (selectedItem) {
            return FacilityAdaptor.getFacilityConfigurations(this.facilitySummary, selectedItem);
        }
    }

    getSelectedConfiguration() {
        if (this.facilitySummary) {
            return FacilityAdaptor.getAdapterSelectedConfiguration(this.facilitySummary);
        }
    }
    isBumpinOutIncluded() {
        var cnf = this.getSelectedConfiguration();
        return cnf.setupTime > 0 || cnf.teardownTime > 0
    }

    estimatedPriceRange(facility) {
        let itm = this.getItem();
        return BookingAdaptor.isRange(itm.calculatedMinPrice, itm.calculatedMaxPrice)
    }

    getEstimatedPrice(facility) {
        let itm = this.getItem();
        var prc = BookingAdaptor.getItemPrice(itm, 1);
        if (prc) {
            return prc.priceIncludingTax;
        }
    }

    isHaveEstimatedPrice(facility) {
        let price = this.getEstimatedPrice(facility);
        return (price > -1)
    }

    public attendeesIsValid() {
        try {
            var cnf = this.getSelectedConfiguration();
            if (cnf.capacity && cnf.capacity != null) {
                return (cnf.capacity >= this.attendees)
            }
        } catch (e) {
            return false;
        }
    }

    public attendeesCountIsValid() {
        try {
            return (this.attendees != null && this.attendees != 0 && (!isNaN(this.attendees)));
        } catch (e) {
            return false;
        }
    }

    isBookable() {
        return this.isAvailable && this.attendeesIsValid() && this.attendeesCountIsValid() && !this.isBookingLeadDaysError &&  this.isHaveEstimatedPrice(this.facilitySummary)&& this.isHavingValidMinTime() && this.isItemAvailable
    }

    showItemsAvailability(facility) {
        if (facility.items != undefined && this.isConfigurationEnabled == true) {
            if (facility.items.length > 1) {
                return true;
            }
        }
        return false;
    }
    isUnitPriceRange(item) {
        if (item == undefined) return false;

        item.itemMinPrice = (item.itemMinPrice) ? item.itemMinPrice : item.itemMaxPrice;
        item.itemMaxPrice = (item.itemMaxPrice) ? item.itemMaxPrice : item.itemMinPrice;
        if (item.itemMinPrice == undefined || item.itemMaxPrice == undefined)
            return false;

        var min = (item.itemMinPrice) ? item.itemMinPrice!.priceIncludingTax : 0;
        var max = (item.itemMaxPrice) ? item.itemMaxPrice!.priceIncludingTax : 0;

        var dp: DecimalPipe = new DecimalPipe('en');
        return dp.transform(min, '1.2-2') != dp.transform(max, '1.2-2');
    }

    async addToBookingAlternative(item) {
        this.facilitySummary.selectedItem = item;
        this.facilitySummary.selectedConfiguration = FacilityAdaptor.getConfiguration(this.facilitySummary.selectedItem, this.facilitySummary);
        this.calculateItemPrices([this.facilitySummary], this.getFormattedDate(this.startTime), this.getFormattedDate(this.endTime));

        let _validationOutPut;
        if (this.cartData && this.cartData.userDefinedFunctions && this.cartData.userDefinedFunctions.length > 0) {
            _validationOutPut = await this.CallCustomFunction(this.facilitySummary, Action.OnChangeItem, this.packageDetail)
        }

        this.itemUnavailableMessages = !this.isItemAvailable ? "This item is not available for the selected Date" : undefined;
      
        this.filteredPackages = this.selectPackageList();
    }

    async CallCustomFunction(facilitySummary, action, packageDetails) {
        let _validationOutPut;

        let _formData = {
            attendees: this.attendees,
            startTime: this.startTime,
            endTime: this.endTime,
            date: this.date,
            selectedConfiguration: this.facilitySummary.selectedConfiguration,
            selectedItem: this.facilitySummary.selectedItem,
            isStartTime: this.isStartTimeChanged
        }

        let _options = {

            preliminaryAsUnavailable: this.AppData.BookingConfig.CheckAvailability.DisplayPreliminarySlotAsUnavailble

        }


        if (this.cartData.userDefinedFunctions && this.cartData.userDefinedFunctions.length > 0) {

            let _validateFunction = new UserDefinedFunction;


            _validateFunction = this.cartData.userDefinedFunctions.find(x => (x.customFunctionAction.action == action))


            if (_validateFunction && _validateFunction.functionName && window[_validateFunction.functionName]) {

                _validationOutPut = await window[_validateFunction.functionName](facilitySummary, _formData, _options, packageDetails);

                this.errorMessages = [];
                this.warningMessages = [];

                if (_validationOutPut && _validationOutPut.Messages && _validationOutPut.Messages.length > 0) {

                    _validationOutPut.Messages.forEach(er => {
                        if (er.ErrorType == ErrorType.Error) {
                            this.errorMessages.push(er.Detail)
                        }
                        if (er.ErrorType == ErrorType.Warning) {
                            this.warningMessages.push(er.Detail)
                        }

                    });
                }

            }

            if (_validationOutPut && !_validationOutPut.ContinueProcess) {
                this.continueProcess = false;
            } else {
                this.continueProcess = true;
            }


            return _validationOutPut;

        }

    }

    async CustomFunctionOnCalanderPopulate(facilityArray) {
        let _validationOutPut;

        let _options = {

            preliminaryAsUnavailable: this.AppData.BookingConfig.CheckAvailability.DisplayPreliminarySlotAsUnavailble

        }

        let _formData = {
            attendees: this.attendees,
            startTime: this.startTime,
            endTime: this.endTime,
            date: this.date

        }

        if (this.cartData.userDefinedFunctions && this.cartData.userDefinedFunctions.length > 0) {
            let _validateFunction = this.cartData.userDefinedFunctions.find(x => (x.customFunctionAction.action == Action.OnPopulateUnavailableCalenderSlots))

            if (_validateFunction && _validateFunction.functionName && window[_validateFunction.functionName]) {

                _validationOutPut = await window[_validateFunction.functionName](facilityArray, _options, _formData);

            }
        }

        return _validationOutPut;

    }

    packageSelectorChange(selectedPackage: BespokePackage = null, attendees?: number) {

      if (selectedPackage) {
          this.packageFilter.PackageDetail = selectedPackage;
      }
      if (this.packageFilter && this.packageFilter.PackageDetail && this.packageFilter.PackageDetail.packageItems && this.packageFilter.PackageDetail.packageItems.length > 0) {
          let resultCal = this._packageService.CalculatePackagePrices(Utility.convertToISO(Utility.convertISOToDate(this.startTime)), Utility.convertToISO(Utility.convertISOToDate(this.endTime)), this.attendees, selectedPackage.id);
          resultCal.safeSubscribe(this,(result) => {
              if (result != null) {
                  let filter = this.getPackageFilter();
                  if (result.packageItems) {
                      filter.PackageDetail.packageItems = result.packageItems;
                      if (attendees != null) {
                          filter.Attendees = attendees;
                      }
                  }
                  this.store.dispatch(new UpdateBookingPackage(filter));
              }

          },
              err => {
                  console.log("Error : calculatePackagePrice ", err);
              }
          );
      }
      else {
          let filter = this.getPackageFilter();
          this.store.dispatch(new UpdateBookingPackage(filter));
          //this.showPackageIncludes();
      }

      this.CallPackageChangeValidation();

    }

    getPackageFilter() {
        let filter = this.packageFilter;
        filter.BussinessAreaId = +this.selectedBusinessArea;
        filter.PackageClass = this.selectedClass;
        filter.Package = +this.selectedPackage;
        //filter.PackageDetail = result.packageItems;
      //  filter.snackBar = this.snackBar;
        filter.EventName = this.defaultEventName;
        // filter.ledgerNumber = this.ledgerNumber;
        filter.StartTime = this.minDate;
        filter.EndTime = this.maxDate;
        //filter.Attendees = this.maxattendees;
        return filter;
    }

    selectPackageList() {
        let selectedFaclities = Object.assign([], this.facilitySummaries);

        if (!selectedFaclities || selectedFaclities.length == 0) {
            selectedFaclities = [this.facilitySummary];
        } else {
            selectedFaclities.push(this.facilitySummary);
        }

        let filterdPackages = [];
        let packageFilterdByClass = this.packages.filter(a => a.packageClassId == +this.selectedClass);

        if (packageFilterdByClass.length == 0) {
            this.selectedPackage = null;
            this.selectedPackageDetail = null;
            if (this.packageDetail) {
                this.packageFilter.PackageDetail = null;
                let _filter = this.getPackageFilter()
                this.store.dispatch(new UpdateBookingPackage(_filter));
            }
            return filterdPackages;
        }

        filterdPackages = this.getfilterPackagesBySalesCategory(packageFilterdByClass, selectedFaclities);

        // if (this.packageDetail) {
        //   let _pkgs = filterdPackages.filter(x => (x.id == this.packageDetail.id));

        //   if (!_pkgs || _pkgs.length == 0) {
        //     this.packageFilter.PackageDetail = null;
        //     let _filter = this.getPackageFilter()
        //     this.store.dispatch(new UpdateBookingPackage(_filter));
        //   }
        // }

        return filterdPackages;
    }



    async packageChange() {

        if (this.selectedPackage == "") {
            this.selectedPackage = null;
            this.selectedPackageDetail = null;
            this.isValidPackage = false;
            this.packageSelectorChange()
        } else {
            if (this.facilitySummaries && this.facilitySummaries.length > (this.isAddFacility ? 0 : 1)) {
                if (this.getPackageDetail() && this.getPackageDetail().id != this.selectedPackage) {
                    let dialogRef = this.dialog.open(DeleteConfirmationComponent, {
                        data: {
                            message: 'Please note that, if you change the event it will be applied to the other facilities in the booking cart. Do you want to proceed?',
                            yesText: 'YES',
                            noText: 'NO'
                        },
                        panelClass: ['custom-dialog-container', 'w30modal'],
                        height: 'auto',
                        width: '30%'
                    });

                    const sub = dialogRef.componentInstance.onAdd.subscribe((data: any) => {
                        if (data == true) { // yes click
                            this.isValidPackage = this.packageSeletedValidation();
                        } else {
                            let _prevSelectedPackage = this.getPackageDetail();
                            this.selectedPackage = _prevSelectedPackage ? _prevSelectedPackage.id : null;
                        }
                        this.questionValidation();

                    });
                } else {
                    this.isValidPackage = this.packageSeletedValidation();
                }
            } else {
                this.isValidPackage = this.packageSeletedValidation();
                this.questionValidation();

            }
        }
        //this.showPackageIncludes();
    }


    questionValidation() {
        if (this.packageFilter.PackageDetail && this.packageFilter.PackageDetail.question && this.packageFilter.PackageDetail.question.length > 0) {
            // this.store.dispatch(new QuestionPopupShown());
            this.isShowQuestionsUpdate = true;
        } else {
            // this.store.dispatch(new QuestionPopupHidden());
            this.isShowQuestionsUpdate = false;
        }
    }


    updateQuestionPopupState() {
        if (this.isShowQuestionsUpdate) {
            this.store.dispatch(new PackageQuestionPopupShown());
        } else {
            this.store.dispatch(new PackageQuestionPopupHidden());
        }
    }


    packageSeletedValidation() {
        let selectedFaclities = Object.assign([], this.facilitySummaries);

        if (!selectedFaclities || selectedFaclities.length == 0) {
            selectedFaclities = [this.facilitySummary];
        } else {
            selectedFaclities.push(this.facilitySummary);
        }

        if (this.selectedPackage == undefined || this.selectedPackage == null) {
            this.snackBar.openFromComponent(AlertMessageComponent, {
                duration: 3000,
                verticalPosition: 'top',
                data: ["Please select the event"]
            });
            return false;
        }


        let selectedPackage = this.packages.find(p => p.id == this.selectedPackage);
        if (!this.packageSelected || this.packageSelected.id != selectedPackage.id) {
            if (this.selectedPackageDetail != null) {
                if (this.selectedPackageDetail.id == selectedPackage.id) {
                    this.packageSelected = this.selectedPackageDetail;
                }
                else {
                    this.packageSelected = selectedPackage;
                }
            }
            else {
                this.packageSelected = selectedPackage;
            }

        }
        let isMatch = true;

        if (selectedPackage) {
            selectedFaclities.forEach(function (fc:FacilitySummary) {
                if (isMatch) {
                    let _item = fc.selectedItem != null ? fc.selectedItem :fc.item;
                    let index = selectedPackage.salesCategories.findIndex(sc => _item.salesCategories.findIndex(isc => isc.id == sc) >= 0);
                    if (!(index != undefined && index >= 0)) {
                        isMatch = false;
                    }
                }
            });
            if (!isMatch) {
                this.snackBar.openFromComponent(AlertMessageComponent, {
                    duration: 3000,
                    verticalPosition: 'top',
                    data: ["Selected event is not matched with selected facilities"]
                });
            } else {

                if (this.selectedPackageDetail == null) {
                    this.selectedPackageDetail = this.packages.find(p => p.id == this.selectedPackage);
                }
                this.packageSelectorChange(this.packageSelected);
            }
        } else {
            this.snackBar.openFromComponent(AlertMessageComponent, {
                duration: 3000,
                verticalPosition: 'top',
                data: ["Selected event is not matched with selected facilities"]
            });
            isMatch = false;
        }

        if (this.packageSelected.originalQuestion && this.packageSelected.originalQuestion != null) {
            this.packageSelected.question = this.packageSelected.originalQuestion.filter(pq => pq.bookingStatusId == environment.BookingStatus || (this.isAllImmediateConfirmed && String(environment.BookingApproveStatus) == pq.bookingStatusId));

            if (this.packageSelected.question.length > 0 && isMatch && this.answeredCurrentCount(this.packageSelected.question) == this.packageSelected.question.length) {
                this.packageFilter.PackageDetail = this.packageSelected;

            }
        }

        return isMatch;
    }

    isHavingValidMinTime() {
        let status = true;
        if (this.isCategoryAndEventEnabled) {
            if (this.isCategoryAndEventMandatory) {
                if ((+(this.selectedPackage ? this.selectedPackage : 0)) > 0) {
                    status = true;
                } else {
                    status = false;
                }

            } else {
                status = true;
            }
        } else {
            status = true;
        }

        if (!this.isContinueProcessOnpackageChange && !this.errorMessages) {
            status = false;
        }

        if (!this.isContinueProcessOnTimeChange && !this.errorMessages) {
            status = false;
        }

        return status;
    }

    async CallPackageChangeValidation() {
        let _validationOutPut;
        if (this.cartData.userDefinedFunctions && this.cartData.userDefinedFunctions.length > 0) {
            _validationOutPut = await this.CallCustomFunction(this.facilitySummary, Action.OnPackageChange, this.getPackageDetail())
        }

        if (_validationOutPut && _validationOutPut.ContinueProcess == false) {
            this.isContinueProcessOnpackageChange = false;
        } else {
            this.isContinueProcessOnpackageChange = true;
        }
    }

    getPackageDetail() {
        return this.packageFilter.Package > 0 ? this.packageFilter.PackageDetail : undefined;
    }

    async CallTimeChangeValidation() {
        let _validationOutPut;
        if (this.cartData.userDefinedFunctions && this.cartData.userDefinedFunctions.length > 0) {
            _validationOutPut = await this.CallCustomFunction(this.facilitySummary, Action.OnTimeChange, this.getPackageDetail())
        }

        if (_validationOutPut && _validationOutPut.ContinueProcess == false) {
            this.isContinueProcessOnTimeChange = false;
        } else {
            this.isContinueProcessOnTimeChange = true;
        }
    }

    validatePackageClass(state) {
        let matchingPackages = FacilityAdaptor.getMatchedPackages([this.facilitySummary], state.bespokePackages);
        if (matchingPackages.length > 0) {

            let distinctPC = matchingPackages.map(p => p.packageClassId).filter((age, index, arr) => arr.indexOf(age) == index);
            state.packageFilter.FilteredPackageClasses = environment.PackageClasses.filter(cls => cls.Id != "0" && distinctPC.indexOf(cls.Id) >= 0);
            if (!state.packageFilter.Package || matchingPackages.findIndex(selectedP => selectedP.id == state.packageFilter.Package) < 0) {
                state.packageFilter.PackageClass = matchingPackages[0].packageClassId;
                state.packageFilter.BussinessAreaId = matchingPackages[0].businessAreaId;
            }
        } else {
            state.packageFilter.Package = 0;
        }
    }


    answeredCurrentCount(question) {
        var count = 0;
        for (var q of question) {
            if ((q.type == "textQuestion" || q.type == "booleanQuestion" || q.type == "singleChoiceQuestion") && (q.answeredText != undefined && q.answeredText != null && q.answeredText != "")) {
                count++;
            } else if (q.type == "multipleChoiceQuestion") {
                var answeredCount = 0;
                if (q.answerChoices) {
                    q.answerChoices.forEach((answ, indx) => {
                        if (q.quantityRequired) {
                            if (answ.Quantity) answeredCount++;
                        } else {
                            if (answ.isSelectedAnswer) answeredCount++;
                        }
                    });
                    if (answeredCount > 0) count++;
                }
            }
        }
        return (question.length) - count;
    }


    getfilterPackagesBySalesCategory(packages: BespokePackage[], selectedFaclities: FacilitySummary[]) {
        let filterdPackages = [];
        if (packages.length) {
          packages.forEach(function (pk) {
            let isMatch = true;
            selectedFaclities.forEach(function (fc) {
                if (isMatch) {
                    if (pk.salesCategories != undefined) {
                        let _item;
                        // high priority for selected item in absence of selected item itme is chosen
                        if(fc.selectedItem &&  fc.selectedItem.salesCategories &&  fc.selectedItem.salesCategories.length){
                            _item = fc.selectedItem;
                        }
                        else if(fc.item &&  fc.item.salesCategories &&  fc.item.salesCategories.length){
                            _item = fc.item;
                        }
                        var index = pk.salesCategories.findIndex(sc => _item && _item.salesCategories.findIndex(isc => isc.id == sc) >= 0);
                        if (!(index != undefined && index >= 0)) {
                            isMatch = false;
                        }
                    } else isMatch = false;
                }
            });
            if (isMatch) {
                filterdPackages.push(pk);
            }
        });
        }


        return filterdPackages;
    }

    isPackageSelected(){
        return (this.selectedPackage == undefined || this.selectedPackage == null) ? false : true ;
    }

    get isItemAvailable(){

        let isAvailable=true;
        // high priority for selected item in absence of selected item itme is chosen
        if(this.facilitySummary){
            let _item = this.facilitySummary.selectedItem ? 
                                                    this.facilitySummary.selectedItem : this.facilitySummary.item ? 
                                                                                                            this.facilitySummary.item : null
            if(_item){
                isAvailable=( _item.availableTo &&  new Date(_item.availableTo) < new Date(this.endTime)) ? false: true; 
            }         

        }        
        return isAvailable;
    }
}
