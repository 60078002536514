import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import "jquery";
import "signalr";
import { Store } from '@ngrx/store';
import { CheckSeat, SetAllDeselectSeat, SetAllSeats, SetDeselectSeat, SetSeat } from '../../../store/public-web/public-web-actions';
declare var $: any;

@Injectable({
  providedIn: 'root'
})

export class SignalRService {
  connection: any;
  proxy: any;

  isConnectionStarted = false;
  isConnectionIdSent = false;
  isBookingIdSent = false;
  activeUser = '';
  connectionID = '';
  enablePublicWebConcurrencySignalR: boolean;
  constructor(private store: Store<any>) {
    this.enablePublicWebConcurrencySignalR = environment.EnablePublicWebConcurrencySignalR;
  }

  public connectSignalRHub(): void {
    let url = environment.NotificationProjectUrl;
    if (this.connectionID == '') {
      this.connection = $.hubConnection(url);
      this.proxy = this.connection.createHubProxy('BookingApplicationConcurrencyHub');

      this.proxy.on("sendSelectedSeats", (data) => {
        this.store.dispatch(new SetSeat(data));
      });

      this.proxy.on("getAlreadySelectedSeat", (data) => {
        this.store.dispatch(new CheckSeat(data));
      });


      this.proxy.on("getAllSeats", (data) => {
        this.store.dispatch(new SetAllSeats(data));
      });

      this.proxy.on("getAllDeSelectSeats", (data) => {
        this.store.dispatch(new SetAllDeselectSeat(data));
      });

      this.proxy.on("sendDeSelectedSeats", (data) => {
        this.store.dispatch(new SetDeselectSeat(data));
      });

      this.connection.start().done(() => {
        this.isConnectionStarted = true;
        this.connectionID = this.connection?.id;
        if (this.connectionID != '') {
          console.log('Connection established successfully...');
        }
      }).fail(() => {
        this.isConnectionStarted = false;
      });
    }
  }

  //seatNumber : sessionID_ConfigID_blockID_RowNumber_ColNumber
  public sendSeatDetails(userId: string, seatNumber: String) {
    if (this.connectionID != '') {
      this.proxy.invoke('RegisterSeatDetails', userId, this.connectionID, seatNumber)
        .done(() => {
        })
        .catch((error: any) => {
        });
    }
  }
  public changeUserName(userId: string){
    if (this.connectionID != '') {
      this.proxy.invoke('ChangeUserName', userId, this.connectionID)
      .done(() => {
      })
      .catch((error: any) => {
      });
    }
  }
  public registerUser(userId: string) {
    if (this.connectionID != '' && this.activeUser == '') {
      this.proxy.invoke('RegisterUser', userId, this.connectionID)
        .done(() => {
          this.activeUser = userId;
        })
        .catch((error: any) => {
        });
    }else{
      setTimeout(() => {
        if(this.enablePublicWebConcurrencySignalR) {
          this.connectSignalRHub();
          this.registerUser(userId);
        }
      }, 90000);
    }
  }
  getAllSeats(userID){
    if (this.connectionID != '') {
      this.proxy.invoke('GetAllSeats', this.connectionID, userID)
        .done(() => {
        })
        .catch((error: any) => {
        });
    }
  }
  deRegisterSeat(seatNumber: String) {
    if (this.connectionID != '') {
      this.proxy.invoke('DeRegisterSeat', seatNumber)
        .done(() => {
        })
        .catch((error: any) => {
        });
    }
  }
}
