import { Component, OnInit, ViewChild, Inject, OnDestroy, QueryList, HostListener, ViewChildren } from '@angular/core';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { Router, ActivatedRoute } from '@angular/router';
import { ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { DOCUMENT } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { BaseParam, Paging } from 'src/modules/models/base-param';

import { environment } from "src/environments/environment";
import { BookingFilter } from "src/modules/models/booking/booking-search/booking-filter";

import { WpMessageComponent } from "../../modals/wp-message/wp-message.component";
import { WpChangeRequestComponent } from "../../modals/wp-change-request/wp-change-request.component";
import { WpRequestAccessComponent } from "../../modals/wp-request-access/wp-request-access.component";
import { WpAlreadyAccessComponent } from "../../modals/wp-already-access/wp-already-access.component";

import { BookingService } from "src/modules/services/booking.service";
import { CustomerService } from "src/modules/services/customer.service";

import { AlertMessageComponent } from 'src/modules/shared/alert-message/alert-message.component';
import { Utility } from 'src/modules/utility';
import { Store, ICart, SignOut, LogError, SetContact } from 'src/modules/store/index';
import { IRegularBooking, LoadBooking, StoreAssets, SpinnerLoaded, SpinnerLoading, ClearStore } from 'src/modules/store/regular-booking/index';
import { Observable, Subscription } from "rxjs";
import { CartActionTypes, Redirect } from 'src/modules/store/actions'
//loader
import { ILoadingStatus } from 'src/modules/store/loading/status-store';
import { FacilitySearchLoading, FacilitySearchLoaded, LoadingCompleted } from 'src/modules/store/loading/actions'
import { Contact } from 'src/modules/models/client/contact';

import { ClearCart } from 'src/modules/Init';
import { ResetState, SetBookingType, SetEligibleDeliveryMethod, SetSelectedBooking } from "../../../store/public-web/public-web-actions";
import { BOOKING_CART, FILE_TYPE } from "src/modules/models/public-web/enum";
import { BaseComponent } from 'src/modules/shared/base.component';
import { PublicBookingService } from 'src/modules/services/public-web/public-booking.service';
import * as PBSelector from '../../../store/public-web/public-web-selectors';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DeliveryMethod } from 'src/modules/models/booking/booking';
import { IPBReducer, ISelectBooking } from '../../../store/public-web/public-web-reducers';
import { PublicBookingSettings } from 'src/modules/models/settings/public-booking/public-booking-setting';
import { NavigationConfirmationComponent } from 'src/modules/public-web/components/modals/navigation-confirmation/navigation-confirmation.component';
import { OptimoTanslate } from 'src/modules/shared/opt-translate/optimo-translate.pipe';

@Component({
  selector: "opt-booking-search",
  templateUrl: "./booking-search.component.html",
  encapsulation: ViewEncapsulation.None,
})

export class BookingSearchComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('pastBookingPaginator') paginator: MatPaginator;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('mySelect') mySelect;
  moment: any = moment;
  tabSelectedIndex: number = 0;
  // showLoader: boolean = true;
  cart$: Observable<ICart>;
  loader$: Observable<ILoadingStatus>;
  cart_subscriber: any;
  bookings: any = [];
  recordCount: number = 0;
  filter = new BookingFilter();
  pageSize = 5;
  pagenumber = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  sortBy: string = "BookingDate";
  ascending: boolean = false;
  bookingStates: any[];
  selectedBookingStates: number[] = [];
  isExpanded: boolean = true;
  isAdvance: boolean = false;
  isUpcomingBooking: boolean = true;
  selectedBookingId: number = 0;
  contactRef: string;
  clientRef: string;
  bsSubscrption: Subscription;
  sortOptions = [];
  contacts: Contact[] = [];
  clientId: string;
  filterByContact: string = '0';
  isBookingSearchByClient: boolean;
  PaymentPortalUrl: string = environment.PaymentPortalUrl;
  isBooking: boolean;
  publicConfig$: Observable<IPBReducer>;

  isInternalUser: boolean = environment.IsInternalUser;
  loggedInContactId: string = '';
  searchedBookingId: any;
  isPublicBookingPortal = false;
  isLoggedIn: boolean;

  constructor(
    private snackBar: MatSnackBar,
    private _customerService: CustomerService,
    private _rbstore: Store<IRegularBooking>,
    private store: Store<ICart>,
    private _loadingStore: Store<ILoadingStatus>,
    private _bookingService: BookingService,
    private _publicBookingService: PublicBookingService,
    @Inject(DOCUMENT) private document: Document,
    private dialog: MatDialog,
    private router: Router,
    private translate: TranslateService,
    private optimoTanslate: OptimoTanslate,
  ) {
    super();
    this.store.select(PBSelector.selectBookingId).subscribe(id => {
      this.searchedBookingId = id ? id : '';
    });
    this.bookingStates = environment.BookingStates;
    this.sortOptions = environment.SortOptions;
    this.isBookingSearchByClient = environment.IsBookingSearchByClient;
    this.filterByContact = "0";//this.contactRef;
    this.publicConfig$ = this.store.select("PBReducer");
    this.cart$ = this.store.select('cart');
    this._customerService.GetSession().safeSubscribe(this, (res) => {
      if (res != null) {
          this.store.dispatch(new SetContact(res, null, null, null, null, null));
      } else if (!this.isInternalUser) {
          this.router.navigate(['/client/login']);
      }
        this.cart_subscriber = this.cart$.safeSubscribe(this, state => {
        if ((state != undefined && state.contact != undefined) && state.contact.id) {
          if ((state != undefined && state.contact != undefined))//&& state.contact.id && state.contact.id != this.loggedInContactId
          {
            this.contactRef = state.contact.ref;
            if (state.contact.client) {
              this.clientRef = state.contact.client.clientRef;
              this.clientId = state.contact.client.id;
            }
            if (state.contact.id) {
              if (this.loggedInContactId == '' || state.contact.id != this.loggedInContactId) {
                this.loggedInContactId = state.contact.id;
                this.bookings = [];
                //external user or internal user and contact is selected
                if (!this.isInternalUser || state.contact.id) {
                  if (state.contact.id && this.isBookingSearchByClient) {
                    this.loadContacts();
                  } else {
                    this.bookingSearch();
                  }
                }

              }
            }
          }
        }

        else if ((state.contact == undefined || state.contact.id == undefined) && this.isInternalUser) {
          this.bookings = [];
          this.contacts = [];
          this.loggedInContactId = '';
          this.contactRef = null;
          this.clientRef = null;
          this.clientId = null;
          return { type: CartActionTypes.ERROR_LOG, payload: 'Internal User Loged in, No Contact...' }
        }

      });
    });


  }

  ngOnInit() {
    var currentTab = +localStorage.getItem("selectedTab");
    if (currentTab) {
      this.tabSelectedIndex = currentTab;
      this.changeMode(this.tabSelectedIndex == 0);
    }
    this.publicConfig$.subscribe((config) => {
      if (
        config.booking &&
        config.booking.bookingPackages &&
        config.booking.bookingPackages.length > 0
      ) {
        this.isBooking = true;
      } else {
        this.isBooking = false;
      }
    });
    //else {
    //    this.bookingSearch();
    // }

    if (environment.PortalType === '4') {
      this.isPublicBookingPortal = true;
    }
    this.translate.onLangChange.safeSubscribe(this, (event: LangChangeEvent) => {
      if (this.loggedInContactId != '') {
        if (this.searchedBookingId) {
          this.filter.searchText = this.searchedBookingId
        }
        else {
          this.bookingSearch();
        }
      }
    });

    //this.loadContacts();
    if (this.loggedInContactId != '') {
      if (this.searchedBookingId) {
        this.filter.searchText = this.searchedBookingId
      }
      else {
        this.bookingSearch()
      }
    }
    else {
      return { type: CartActionTypes.ERROR_LOG, payload: 'Internal User Loged in, No Contact...' }
    }
  }

  loadContacts() {
    this.contacts = [];

    // let _id = this.clientId;
    this._customerService.GetContactDetails().subscribe(data => {
      if (data != null) {
        this.contacts = data;
        this.bookingSearch();

      }
    },
      err => {
        this.contacts = [];
      });
  }

  ngOnDestroy() {
    if (this.cart_subscriber)
      this.cart_subscriber.unsubscribe();

    if (this.bsSubscrption)
      this.bsSubscrption.unsubscribe();

    super.ngOnDestroy();
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.tabSelectedIndex = tabChangeEvent.index;
    localStorage.setItem("selectedTab", this.tabSelectedIndex.toString());
    this.changeMode(this.tabSelectedIndex == 0);
  }

  updateStates(id) {
    this.selectedBookingStates = [];
    if (id) {
      this.selectedBookingStates.push(id);
    }
  }

  isCheckedState(val) {
    return this.selectedBookingStates.find((st) => st == val) !== undefined;
  }

  toggleSort() {
    this.ascending = !this.ascending;
    this.bookingSearch();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput
      .split(",")
      .map((str) => +str);
  }

  expand(bk) {
    if (this.isExpanded && this.selectedBookingId == bk.BookingId) {
      this.isExpanded = false;
      this.selectedBookingId = 0;
    } else {
      this.isExpanded = true;
      this.selectedBookingId = bk.BookingId;
    }

    if (this.isExpanded && bk.bookingInvoices == undefined) {
      this._bookingService.InvoiceSearch(bk.BookingId, new BaseParam()).safeSubscribe(this, res => {
        if (!res.isError) {
          bk.bookingInvoices = res.data;
        }
      });
    }

    if(this.isExpanded && bk.bookingDocuments == undefined){
      this._bookingService.BookingDocumentSearch(bk.BookingId).safeSubscribe(this, res => {
        if (!res.isError) {
          if (res.data.length > 0) {
            bk.bookingDocuments = [];
            res.data.forEach((doc) => {
              if(doc != null && !(doc?.name?.startsWith('INVOICE'))) {
                bk.bookingDocuments.push(doc);
              }
            })
          }
        }
      });
    }
  }

  bookingSearch() {
    if (this.isAdvance) this.advanceSearch();
    else this.simpleSearch();
  }

  simpleSearch() {
    this.isAdvance = false;
    var filter = new BookingFilter();
    filter.bookingRef = this.filter.bookingRef;

    this.filter = new BookingFilter();
    this.filter.bookingRef = filter.bookingRef;
    this.selectedBookingStates = [];
    this.onBookingSearch(filter);
  }

  advanceSearch() {
      this.isAdvance = true;
      var filter = this.filter;
      if (this.filter.eventDateFrom)
        filter.eventDateFrom = Utility.convertToISO(
          this.getDateOnly(new Date(this.filter.eventDateFrom))
        );
      if (this.filter.eventDateTo)
        filter.eventDateTo = Utility.convertToISO(
          this.getDateOnly(new Date(this.filter.eventDateTo))
        );
      if (this.filter.bookingDateFrom)
        filter.bookingDateFrom = Utility.convertToISO(
          this.getDateOnly(new Date(this.filter.bookingDateFrom))
        );
      if (this.filter.bookingDateTo)
        filter.bookingDateTo = Utility.convertToISO(
          this.getDateOnly(new Date(this.filter.bookingDateTo))
        );
      filter.bookingStatusId = this.selectedBookingStates.join(",");
      this.onBookingSearch(filter);
  }

  getDateOnly(dt: Date) {
    dt.setHours(0, 0, 0, 0);
    return dt;
  }

  onBookingSearch(filter: BookingFilter) {
    this.selectedBookingId = 0;
    var searchParam = new BaseParam();
    searchParam.paging = new Paging();
    searchParam.paging.number = (this.pagenumber + 1).toString();
    searchParam.paging.size = this.pageSize.toString();

    var currentFilter = JSON.parse(JSON.stringify(filter));

    //currentFilter.clientRef = this.clientRef;
    if (this.isBookingSearchByClient && this.contacts ? this.contacts.length > 1 : false) {
      currentFilter.contactRef = this.filterByContact;
    }

    var dt = new Date();
    if (this.isUpcomingBooking) {
      if (
        this.filter.eventDateFrom == undefined ||
        this.filter.eventDateFrom == ""
      )
        currentFilter.eventDateFrom = Utility.convertToISO(dt);
    } else {
      dt.setMinutes(new Date().getMinutes() - 1);
      if (this.filter.eventDateTo == undefined || this.filter.eventDateTo == "")
        currentFilter.eventDateTo = Utility.convertToISO(dt);
    }
    searchParam.filter = currentFilter;
    searchParam.sort = (this.ascending ? "" : "-") + this.sortBy;

    if (this.bsSubscrption) this.bsSubscrption.unsubscribe();

    // if ((currentFilter.contactRef && currentFilter.contactRef.length > 0) || (currentFilter.clientRef && currentFilter.clientRef.length > 0)) {
      this._loadingStore.dispatch(new FacilitySearchLoading());
    this.bsSubscrption = this._bookingService.BookingSearch(searchParam).safeSubscribe(this, (data) => {
      this.bookings = data.data;
      this.recordCount = data.total;
      //if (data.errorCode == "401") {
      //    this.openSnackBarError(["Session Timeout"]);
      //    ClearCart();
      //    this.store.dispatch(new SignOut());
      //    this.router.navigate(['/client/login']);
      //}
      if (this.searchedBookingId) {
        this.expand(this.bookings[0])
      }
    },
      err => {
        this.bookings = [];
        this.recordCount = 0;
      },
      () => {
          this._loadingStore.dispatch(new FacilitySearchLoaded());
      });
    // }
  }

  pageclickevent(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pagenumber = pageEvent.pageIndex;
    this.bookingSearch();
  }

  clear() {
    this.selectedBookingStates = [];
    this.selectedBookingStates.push();
    this.filter = new BookingFilter();
    this.filterByContact = '0';//this.contactRef;
  }

  changeMode(isUpcoming: boolean) {
    this.pagenumber = 0;
    this.isUpcomingBooking = isUpcoming;
    this.bookingSearch();
  }

  //onPayForInvoice(inv) {
  //    document.location.href = environment.ApiUrl + "/payment?InvoiceRef=" + inv.InvoiceRef;
  //}

  onInvoiceView(inv) {
    var windowReference = window.open();
    if (inv.InvoiceUrl && inv.InvoiceUrl != "") {
      windowReference.location.assign(inv.InvoiceUrl);
    } else {
      this._bookingService.InvoiceView(inv.InvoiceId).safeSubscribe(this, (res) => {
        if (res.data) {
          //     window.open((environment.WebApiUrl + res.data), '_blank');
          windowReference.location.assign(res.data);
        }
      },
        err => {
            windowReference.close();
            this.openSnackBarError(["Unable to load invoice information. Please try again."]);
      });
    }
  }

  openSnackBarError(message) {
    this.snackBar.openFromComponent(AlertMessageComponent, {
      data: message,
      duration: 3000,
      verticalPosition: "top",
    });
  }

  //wp-modals

  openWpMessage(): void {
    const dialogRef = this.dialog.open(WpMessageComponent, {
      panelClass: ["w60modal", "dialog-container-common"],
      width: "150px",
    });
  }

  openWpChangeRequest(): void {
    const dialogRef = this.dialog.open(WpChangeRequestComponent, {
      panelClass: ["w60modal", "dialog-container-common"],
      width: "150px",
    });
  }

  openWpRequestAccess(): void {
    const dialogRef = this.dialog.open(WpRequestAccessComponent, {
      panelClass: ["w60modal", "dialog-container-common"],
      width: "150px",
    });
  }

  openWpAlreadyAccess(): void {
    const dialogRef = this.dialog.open(WpAlreadyAccessComponent, {
      panelClass: ["w60modal", "dialog-container-common"],
      width: "150px",
    });
  }



  openBookingDetail(booking) {
    if (booking.IsDraftBooking) {
      if (environment.IsInternalUser) {
        window.open(environment.RegularPortalInternalUrl + "#/booking-stepper/?OpenBooking=" + booking.BookingId, "_self");
      }
      else {
        window.open(environment.RegularBookingPortalUrl + "#/booking-stepper/?OpenBooking=" + booking.BookingId, "_self");
      }
    }
    else if (booking.BookingTypeId == 6) {
      window.open(environment.WasteBookingPortalUrl + "#/wm-booking-cart/?bookingId=" + booking.BookingId + '&From=MyBooking', "_self");
    }
    else if (booking.BookingTypeId == 7) {
      if (this.isBooking) {
        const dialogRef = this.dialog.open(NavigationConfirmationComponent, {
          data: {
            heading: this.optimoTanslate.transform('NAVMODEL.Confirmation'),
            message: this.optimoTanslate.transform('NAVMODEL.Attempting to view an old booking will clear your active booking. Are you sure you want to proceed'),
            yesText: this.optimoTanslate.transform('NAVMODEL.Yes, Clear'),
            noText: this.optimoTanslate.transform('NAVMODEL.Close')
          },
          panelClass: [],
          height: 'auto',
          width: 'auto',
        });
        dialogRef.afterClosed().subscribe(res => {
          if (res == true) {
            this.openPublicBooking(booking);
          }
        });
      } else {
        this.openPublicBooking(booking)
      }
    }
    else if (booking.BookingTypeId == null || booking.BookingTypeId == 0) {
      if (this.isBooking) {
        const dialogRef = this.dialog.open(NavigationConfirmationComponent, {
          data: {
            heading: this.optimoTanslate.transform('NAVMODEL.Confirmation'),
            message: this.optimoTanslate.transform('NAVMODEL.Attempting to view an old booking will clear your active booking. Are you sure you want to proceed'),
            yesText: this.optimoTanslate.transform('NAVMODEL.Yes, Clear'),
            noText: this.optimoTanslate.transform('NAVMODEL.Close')
          },
          panelClass: [],
          height: 'auto',
          width: 'auto',
        });
        dialogRef.afterClosed().subscribe(res => {
          if (res == true) {
            this.openCasualPortalBooking(booking);
          }
        });
      } else {
        this.openCasualPortalBooking(booking)
      }
    }
    else {
      this.router.navigate(['/booking-details/' + booking.BookingId]);
    }
  }

  RedirectToPayment(invoiceref) {
    var url = this.PaymentPortalUrl;
    window.open(url + "payment?InvoiceRef=" + invoiceref+"&fromMyBooking='1'", "_self")

  }

  @HostListener('window:scroll', [])
  scrollHandler() {
    this.trigger.closeMenu();
    this.mySelect?.close();
  }

  checkForButtonEnable(filter : BookingFilter){
    let state =  (moment(filter.bookingDateFrom).format('L') > moment(filter.bookingDateTo).format('L')) || (moment(filter.eventDateFrom).format('L') > moment(filter.eventDateTo).format('L'))
    return state;
  }

  onDownloadDocument(bookingId, doc) {
    this._bookingService.DocumentDownload(bookingId, doc);
  }

  openPublicBooking(booking) {
    this.store.dispatch(new ResetState());
    const selectedbookedPackage: ISelectBooking = {
      id: booking.BookingId,
      bookingpackageCount: booking.BookingPackageCount
    }
    this.store.dispatch(new SetBookingType(BOOKING_CART.EDIT_BOOKING));
    this.store.dispatch(new SetSelectedBooking(selectedbookedPackage));
    let deliveryMethods: DeliveryMethod[];
    this._publicBookingService.getBookingDeliveryMethods(booking.BookingId).subscribe(
      (res: any[]) => {
        if (!!res) {
          deliveryMethods = res.sort((a, b) => a.order - b.order);
          this.store.dispatch(new SetEligibleDeliveryMethod(deliveryMethods));
        }
        window.open(
          environment.PublicWebPortalUrl +
          "#/booking-cart",
          "_self"
        );
      });
  }

  openCasualPortalBooking(booking){
    this.store.dispatch(new ResetState());
    if (booking.SalesChannelId == 5) {
      this.router.navigate(['/booking-details/' + booking.BookingId]);
    } else {
      this._publicBookingService.selectBooking(booking.BookingId).safeSubscribe(this, (result) => {
        if (result.isError) {
          console.log("Error:", result.Errors);
        } else {
          if ((result.data).bookingPackages.find(bpk => bpk.package.type !== 'publicPackage')) {
            this.router.navigate(['/booking-details/' + booking.BookingId]);
          } else {
            const selectedbookedPackage: ISelectBooking = {
              id: booking.BookingId,
              bookingpackageCount: booking.BookingPackageCount
            }
            this.store.dispatch(new SetBookingType(BOOKING_CART.EDIT_BOOKING));
            this.store.dispatch(new SetSelectedBooking(selectedbookedPackage));
            let deliveryMethods: DeliveryMethod[];
            this._publicBookingService.getBookingDeliveryMethods(booking.BookingId).subscribe(
              (res: any[]) => {
                if (!!res) {
                  deliveryMethods = res.sort((a, b) => a.order - b.order);
                  this.store.dispatch(new SetEligibleDeliveryMethod(deliveryMethods));
                }
                window.open(
                  environment.PublicWebPortalUrl +
                  "#/booking-cart",
                  "_self"
                );
              });
          }
        }
      });
    }
  }
}
